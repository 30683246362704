import React,{ useState, useCallback, useEffect} from "react";
import { axiosGetAction,axiosPostAction, axiosResponse } from "../../Gfunc";
import {TextField, Accordion,AccordionDetails, AccordionSummary,Typography,Switch} from '@material-ui/core';
import DataTable from "react-data-table-component";
import {ExpandMore} from '@material-ui/icons';
import { useStyles } from '../../css/muiStyle';
import  { merge,groupBy,getDate } from '../../Gfunc'
import {customStyles}from '../../css/datatableStyle'
import { Autocomplete } from "@material-ui/lab";
import {AlertCompo} from "../../js/components"



export default function Adm_prv(props){
  
  const Gfunc=require('../../Gfunc')
  const param=props.param
  const [data, setData] = useState([]);
  const [privs, setPrivs] = useState([]);
  const [rPrivs, setRprivs] = useState([]);
  const service='_rol.php'
  const [role, setRole] = useState('');
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [openAccord, setOpenAccord] = useState(false);
  const [, setSwitches] = useState('');
  const [severity, setSeverity] =useState("info");
  const [open, setOpen] =useState(false);
  const [messageA, setMessageA] =useState('');
  
   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  }

    //fetch roles
    const fetch= useCallback(async() => {
      const response=await axiosGetAction(service,"gets")
      if(response.data.err&&response.data.err!=="-1"){
        handleAlert(true,'error',response.data.err)
      }else{
      axiosResponse(response,()=>{
          setData(response.data.jData);
      }) 
    }
    },[]);
  
    
     //fetch all prives
     const fetchPrivs= useCallback(async() => {
      const response=await axiosGetAction(service,"getprivs")
      if(response.data.err&&response.data.err!=="-1"){
        handleAlert(true,'error',response.data.err)
      }else{
      axiosResponse(response,()=>{
          setPrivs(response.data.jData);
      }); 
     }
     },[]);
  
     //fetch role prives
     const fetchRolePrivs= async(id) => {
        const response=await axiosPostAction(service,"getrprvs",{idr:id})
        if(response.data.err&&response.data.err!=="-1"){
          handleAlert(true,'error',response.data.err)
        }else{
        axiosResponse(response,()=>{
          const rolePrivs=response.data.jData
          const conc=merge(privs,rolePrivs,'id')
          const priv = groupBy(conc, 'module')
          setRprivs(priv);
          handleSwitches("")  
        })
      }
      }

   //handle select change
   const handleChange =(val) => {
    //fetchRoleDet(val)
    setRole(val);
    setOpenAccord(true)
    fetchRolePrivs(val)
  };

  const handleOpenSelect=useCallback(()=>{
    if(data.length===0)fetch();
    if(privs.length===0) fetchPrivs();
  },[data,privs,fetch,fetchPrivs])


  useEffect(() => {
    handleOpenSelect()
  }, [handleOpenSelect]);

  //handle accordionchange
  const handleChangeAccordion = (panel)=>(event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  //handle switch
  const handleSwitches=(val)=>{  
    setSwitches(val);
  }
  //set or delete privs to a role
  const handlePrivs= async (row,key)=> {
    var response=null
    if(row.hasOwnProperty("name"))
      response=await axiosPostAction(service,"delrpriv",{idp:row.id,idr:role})
    else 
      response=await axiosPostAction(service,"setrpriv",{idp:row.id,idr:role})
      if(response.data.err&&response.data.err!=="-1"){
        handleAlert(true,'error',response.data.err)
      }else{
      axiosResponse(response,()=>{
      handleSwitches(row.id)
        const objIndex = rPrivs[key].findIndex((obj => obj.id === row.id)); 
        
        if(row.hasOwnProperty("name")){
          delete rPrivs[key][objIndex].name
          delete rPrivs[key][objIndex].date
        }else{
          rPrivs[key][objIndex].name=localStorage.getItem('nickname')
          rPrivs[key][objIndex].date=getDate()
        }
        handleSwitches('') 
    })
   }          
   };

  return (
    <div className="content">
        <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
        <div>   
         <div className="head">
         <fieldset className="fieldsetTop2" id="role">
               <legend>Affectation des privilèges:</legend>
               <div className="rowGrid" id="row-1">
                           <Autocomplete
                                disableClearable
                                //className={classes.textInput2}
                                id="iRole"
                                onChange={(e,newValue) =>{handleChange(data[Gfunc.getIndex(data,newValue,"name")].id)}}
                                className='smallAuto'
                                options={data.map((option) => option.name)}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant='outlined'
                                    label={'Role'}
                                    size="small" 
                                    required/>
                            )}/>
               </div>
              </fieldset>
            </div> 
        </div>
      <div className={classes.root}>
            {   
                 Object.keys(rPrivs).map((key, index) =>{ 
                  
                    const columns =[   
                          {
                              selector:row=>row.function,
                              minWidth:'300px !important',
                          },
                          { width:'60px ! important',
                            cell: (row) => {
                              return (
                                <div>  
                                <Switch size="small"
                                        checked={row.hasOwnProperty("name")}
                                        id={"s"+row.id}
                                        classes={{
                                          track: classes.switch_track,
                                          switchBase: classes.switch_base,
                                          colorPrimary: classes.switch_primary,
                                        }}
                                        onChange={()=>{handlePrivs(row,key)}}
                                        name={row.module}
                                        color='primary'
                                    />   
                                </div>  
                              );
                             }
                          },
                          {
                            selector: row=>row.name,
                            minWidth:'160px !important',
                            cell: (row) => {
                            return (
                              <div>  
                               <p className={classes.greenText}>{row.name}</p>
                              </div>  
                            );
                           }        
                          },
                          {
                            selector: row=>row.date,
                            minWidth:'120px !important',
                            cell: (row) => {
                              return (
                                <div>  
                                 <p className={classes.greenText}>{row.date}</p>
                                </div>  
                              );
                             }
                          }
                        ]
                        if(openAccord){
                           return (
                            <Accordion className={classes.accordionRoot} key={index} expanded={expanded === key} onChange={handleChangeAccordion(key)}>
                            <AccordionSummary
                                className={classes.accordionSummary}
                                expandIcon={<ExpandMore />}
                                aria-controls={key+"-content"}
                                id={key+"-header"}
                            >
                            <Typography className={classes.heading}>{key}</Typography>
                            </AccordionSummary>    
                            <AccordionDetails key={index} className={classes.accordionDetails}>
                                  <div className="accord-table">
                                        <DataTable 
                                            role={role}
                                            customStyles={customStyles}
                                            columns={columns}
                                            data={rPrivs[key]}
                                            noHeader={true}
                                            noTableHead={true}
                                            noDataComponent={param.ErreurData} 
                                            highlightOnHover
                                            paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous'}}
                                            />
                                  </div>
                            </AccordionDetails>
                        </Accordion>) 
                            }else return <></>     
                    }
                )
            }
        </div> 
    </div>
  );
}
