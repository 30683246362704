import React,{ useState, useEffect,useMemo} from "react";
import DataTable from "react-data-table-component";
import {CircularProgress,Paper,Box,TextField} from '@material-ui/core';
import { Search} from '@material-ui/icons';
import DraggableDialog from './modals/dig_qot_add'
import DraggableDialogLst from './modals/dig_qot_lst'
import { useStyles } from '../../css/muiStyle';
import {conditionalRowStyles,customStyles}from '../../css/datatableStyle'
import {NoDataComponent,NotAllowedComponent} from "../../js/components"
import { AlertCompo } from "../../js/components";
import '../../css/digit.css'

export default function Dig_qot(props){
  const classes=useStyles();
  const param=props.param
  const Gfunc=require('../../Gfunc')
  const librery=require('../../js/data_table_librery')
  const [data, setData] = useState([]);
  const [called, setCalled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search,setSearch]=useState('');
  const [noData,setNoData]=useState(false);
  const [doSearch,setDoSearch]=useState(false);
  const [notAllowed,setNotAllowed]=useState(false);
  const [errMessage,setErrMessage]=useState('');
  const [severity, setSeverity] =useState("info");
  const [open, setOpen] =useState(false);
  const [messageA, setMessageA] =useState('');
  const [records,setRecords]=useState('');
  const [resetDP, setResetDP] = useState(false);
  const service='_prt.php'

   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  }

 //fetch quota
  const fetch= async(page,servic = service, size = perPage) => {
    setCalled(true);
    setLoading(true);
    const response=await librery.axiosGet2(page,servic,'getqhist',size+"&search="+search)
    setLoading(false);  
    if(response.data.err&&response.data.err!=='-1'){
      setErrMessage(response.data.err)
      setNotAllowed(true)
    }else
    Gfunc.axiosResponse(response,()=>{
      setRecords(response.data.recordsFiltered)
      if(response.data.data!==undefined){
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
        setLoading(false);
        setNoData(true)
        setDoSearch(true)
      }else{
        setDoSearch(false)
        setData([])
      }   
    })  
  }

  useEffect(() => {
    if(!called)
    fetch(1);
  }, [fetch,called]);


  const columns =useMemo(
    () => [  
      {
        name: "DATE",
        minWidth:"180px !important",
        selector:row=>row.date,
        sortable: true,
        sortField:"date"
      },
      {
        name: "Agent",
        minWidth:"200px !important",
        selector: row=>row.agent,
        sortable: true,
        sortField:"agent"
      }, 
      {
          name: "Produit",
          minWidth:"350px !important",
          selector:row=>row.produit,
          sortable: true,
          sortField:"produit",
      },
    {
        name: "quantité",
        minWidth:'180px !important',
        selector: row=>row.qte,
        sortable: true,
        sortField:'qte', 
    },
  ],[classes]);
 
  // sort function handler
  const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage+"&search="+search)=>{
          setLoading(true);
          const response=await librery.axiosSort2(column,sortdirection,page,size,service,'getqhist')
          setLoading(false);
          if(response.data.err&&response.data.err!=='-1'){
            handleAlert(true,'error',response.data.err)
         }else
          Gfunc.axiosResponse(response,()=>{
            setData(response.data.data);
            setTotalRows(response.data.recordsFiltered);
          })     
  } 

   //recherche serverside
   const handleSearch=async(size = perPage)=>{
    setLoading(true);
    setCurrentPage(1)
    const recherche=search;
    const response=await librery.axiosSearch(service,recherche,1,size,"getqhist")
    setLoading(false); 
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
   }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.data!==undefined){
      setData(response.data.data);
      setTotalRows(response.data.recordsFiltered);
      }else setData([])
    })  
    return recherche
  }
  
  function handlePageChange (page) {
    fetch(page,service,perPage);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetch(page,service, newPerPage);
    setPerPage(newPerPage);
  };

  function liveSearch(e,search){
    if(param.liveSearch&&search.length>3){
      e.preventDefault();
      handleSearch();
    }else{ if (e.keyCode === 13) {
              e.preventDefault();
              handleSearch();
            }
    }
  }

  function liveSearch2() {
    handleSearch();
  }

  
  
  return (
    <div className="content">
        <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
        <Paper className={classes.paperBody} elevation={5}>
        <div className='table-header'>
          <div className='button-flex-wrapper'>
            <DraggableDialog handleAlert={handleAlert} callback={()=>{if(currentPage===1) fetch(1);else setResetDP(!resetDP)}}/>
            <DraggableDialogLst handleAlert={handleAlert} param={param}/>
          </div>
          {(doSearch)?
          <div className='search'>
               <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Search className={classes.blackIcon} sx={{ color: 'action.active', mr: 1, my: 0.5 }} onClick={(e)=>liveSearch2(e)}/>
                      <TextField className="smallText" variant="outlined" label="Recherche" value={search} size="small" margin='dense' onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} autoFocus/>
                </Box>
            </div>:null}
        </div>
        {(data.length!==0||doSearch)?
          <div>
            <DataTable 
              columns={columns}
              data={data}
              noDataComponent={param.ErreurData}
              paginationRowsPerPageOptions={[10,30,50,100]}
              progressPending={loading}
              progressComponent={<CircularProgress className={classes.circularProgress} />}
              pagination
              paginationServer
              paginationPerPage={perPage}
              paginationTotalRows={totalRows}
              paginationDefaultPage={1}
              paginationResetDefaultPage={resetDP}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              sortServer={true}
              onSort={handleOnSort}
              noHeader={true}
              highlightOnHover
              customStyles={customStyles}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}
              conditionalRowStyles = { conditionalRowStyles }
            />
            </div>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData || records===0)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
      </Paper>
    </div>    

  );
}