import React,{ useState, useEffect ,useCallback,useMemo, useRef} from "react";
import DataTable from "react-data-table-component";
import {TextField,Button,CircularProgress,Paper, Box} from '@material-ui/core';
import {Search,ErrorOutline,FilterListSharp, Check} from '@material-ui/icons';
import {customStyles, numberColumn}from '../../css/datatableStyle'
import { useStyles } from '../../css/muiStyle';
import { Autocomplete } from "@material-ui/lab";
import { NoDataComponent,NotAllowedComponent } from "../../js/components";
import '../../css/reports.css'
import { ValidatorForm } from "react-material-ui-form-validator";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { fr } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";

export default function Rep_noc (props){
  const param=props.param
  const Gfunc=require('../../Gfunc')
  const [data, setData] = useState([]);
  const [tous, setTous] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  var date = new Date();
  const [selectDateD,SetSelectDateD]=useState(Gfunc.LastXDay(10))
  //useState(new Date(date.getFullYear(), date.getMonth(), 1))
  const [search,setSearch]=useState('');
  const service='_rep.php'
  const classes=useStyles();
  const[noData,setNoData]=useState(false);
  const[doSearch,setDoSearch]=useState(false);
  const[notAllowed,setNotAllowed]=useState(false);
  const [cat,setCat]=useState([]);
  const [selectcat,setSelectCat]=useState({});
  //const [severity, setSeverity] =useState("info");
  //const [open, setOpen] =useState(false);
  //const [messageA, setMessageA] =useState('');
  const[errMessage,setErrMessage]=useState('');
  const[records,setRecords]=useState('');
  const [openA, setOpenA] =useState(false);
  const [message, setMessage] =useState('');
  const [severity, setSeverity] =useState('info');

  const handleAlert=(openA,severity,message)=>{
    setOpenA(openA)
    setSeverity(severity)
    setMessage(message)
    setTimeout(function(){
      setOpenA(false)
    }, 5000);
  }

  const  conditionalRowStyles  =  [ 
    {
      when: row => row.alert===true||row.conso<0,
        style: {
              color:"red"
        }
    },
  ]

 //fetch marque
  const fetch= useCallback(async() => {
    setLoading(true);
    const data={
        date:Gfunc.formatDate(selectDateD),
        catId:selectcat.id
    }
    const response=await Gfunc.axiosPostAction(service,"getnocso",data)
    if(response.data.err&&response.data.err!=='-1'){
      setErrMessage(response.data.err)
      setNotAllowed(true)    }else{
    Gfunc.axiosResponse(response,()=>{
       if(response.data.jData!==undefined) {
        const dat=response.data.jData
        setTous(dat)
        setData(dat);
        setTotalRows(response.data.recordsFiltered);
        setLoading(false);
        setNoData(true)
        setDoSearch(true)
        }else{
          setDoSearch(false)
          setData([])
        }
    })
  }
    setLoading(false);
  },[Gfunc,selectcat,selectDateD]);

    //recuperer les categories 
    const fetchCat= useCallback(async() => {
        const response = await Gfunc.axiosGetAction('_cat.php','gets');
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,'error',response.data.err)
        }else{
          Gfunc.axiosResponse(response,()=>{
                response.data.jData.unshift({id:'empty',name:'Toutes'})
                setCat(response.data.jData);   
          })
        }
    },[Gfunc]);
    
    //remplir Select Categorie 
    const handleOpenCat= useCallback(async () => {
       if(cat.length===0) fetchCat()
    },[cat,fetchCat])

  useEffect(() => {
    handleOpenCat()
  },[handleOpenCat]);

const columns =useMemo(
    () => [   
        {
            name: "Date",
            width:'120px !important',
            style:numberColumn,
            selector: row=>row.date,
            sortable: true,
        }, 
        {
            name: "Client",
            minWidth:'300px !important',
            selector: row=>row.client,
            sortable: true,
        },
        {
            name: "Tel",
            minWidth:'100px !important',
            selector: row=>row.tel,
            sortable: true,
        },  
  ],[]);

const handleSearch=()=>{
  const filters=[]
  var keys=[] 
  keys=Object.keys(tous[0])
    keys.map( key =>{ 
      const f={}  
      f["att"]=key
      filters.push(f)
      return true
    }) 
  setData(tous.filter((d)=>filters.some(filterEl =>((d[filterEl.att].toString().toLowerCase()).includes(search.toLowerCase())))))
}
function dynamicSort(property) {
  var sortOrder = 1;
  if(property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
  }
  return function (a,b) {
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
  }
}
const handleOnSort=(column, sortdirection)=>{  
  //objs.sort((a,b,att) => (a.last_nom > b.last_nom) ? 1 : ((b.last_nom > a.last_nom) ? -1 : 0))

  setData(data.sort( dynamicSort(column.selector)) );
}

  function liveSearch(e){
    if(search.length>-1){
      e.preventDefault();
      handleSearch();
    }
  }
  function liveSearch2() {
    handleSearch();
  }
  
  return (
    <div className="content">
        {/*<AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />*/}
        <Paper className={classes.paperBody} elevation={3}>
        <fieldset className="fieldsetTop" id="noconsom">
        <legend>Rapport des clients n'ayant effectué aucune consommation</legend>
        <ValidatorForm id="formAdd" ref={useRef(null)} onSubmit={fetch} >
            <div className="rowGrid" id="row-1">
              <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                   <KeyboardDatePicker disableToolbar value={selectDateD} variant="inline" placeholder="01/01/2020" onChange={date => SetSelectDateD(date)}
                                       minDate='10/10/2018' format="dd/MM/yyyy" className='smallText' margin="dense" inputVariant="outlined"
                                       invalidDateMessage="Le format de la date est incorrect" 
                               />
              </MuiPickersUtilsProvider>
              <Autocomplete
                    disableClearable
                    onChange={(e,newValue) =>{setSelectCat(newValue)}}
                    value={selectcat}
                    getOptionLabel={(option) => option.name}
                    options={cat}
                    onFocus={handleOpenCat}
                    className='smallAuto' 
                    renderInput={(params) =>  
                        <TextField margin='dense' 
                            size='small'{...params} variant="outlined"  label="Catégorie..." SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                        />}
                />
              <Button  className={classes.smallBtn} variant="contained" tabIndex="3" type='submit' id="bouton" name="addBtn" color="primary" startIcon={<Check/>}>VALIDER</Button>
            </div>
        </ValidatorForm>
        </fieldset><br/>
        <div className="table-header">
        <div className="button-flex-wrapper" >             
          </div>
          <div className='search'>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Search className={classes.blackIcon} sx={{ color: 'action.active', mr: 1, my: 0.5 }} onClick={(e)=>liveSearch2(e)}/>
                      <TextField className="smallText" id="searchBar" variant="outlined" label="Recherche" value={search} size="small" margin='dense' onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} autoFocus/>
                   </Box>
            </div>
          </div>
          {
            (data.length!==0 ||doSearch)?
            <div>
            <DataTable className="table"
              persistTableHead
              columns={columns}
              data={data}
              noDataComponent={param.ErreurData}
              pagination
              paginationRowsPerPageOptions={[10,20,30,50,100]}
              paginationPerPage={50}
              paginationTotalRows={totalRows}
              progressPending={loading}
              progressComponent={<CircularProgress className={classes.circularProgress} />}
              onSort={handleOnSort}
              highlightOnHover
              noHeader={true}
              customStyles={customStyles}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
              conditionalRowStyles = { conditionalRowStyles }
            />
          </div>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData || records===0)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
      </Paper>
    </div>    

  );
}