import React,{useState,useRef,useCallback} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog,TextField} from '@material-ui/core';
import Draggable from 'react-draggable';
import SaveIcon from '@material-ui/icons/Save';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import AddIcon from '@material-ui/icons/Add';
import {axiosPostAction,axiosResponse} from'../../../Gfunc'
import { useStyles,paperPropsSmallTall } from '../../../css/muiStyle';
import  '../../../css/achat.css';
import {AlertCompo} from '../../../js/components'
import { Autocomplete } from '@material-ui/lab';



//add new marque
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Ach_add_ach(props) {
    const Gfunc=require('../../../Gfunc')
    const [prod,setProd]=useState('');
    const [qte,setQte]=useState('');
    const [pin,setPin]=useState('');
    const [pout,setPout]=useState('');
    const [prodL, setProdL] = useState([]);
    const [open, setOpen] =useState(false);
    const addTitle="Ajouter un nouvel Achat"
    const alertError="L'achat est ajouté."
    const labelText1="Produit"
    const labelText2="Quantité"
    const labelText3="prix d'achat"
    const labelText4="Prix de vente"
    const service="_ach.php"
    const classes=useStyles();
    const [severity, setSeverity] =useState("info");
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');

   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpenA(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpenA(false)
    }, 5000);
  }

   //fetch products
   const fetchProd= useCallback(async(serv="_prd.php", act = "gets",act2="getspromo") => {
    const response = await Gfunc.axiosGetAction(serv,act);
    const response2 = await Gfunc.axiosGetAction(serv,act2);
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
      if(response2.data.err&&response.data.err!=="-1"){
        handleAlert(true,'error',response.data.err)
      }else{
        if(response2.data.jData&&response.data.jData)
          setProdL(response2.data.jData.concat(response.data.jData));
          else if(response2.data.jData) setProdL(response2.data.jData);
               else setProdL(response.data.jData);
      }    
    })
  }
  },[Gfunc])    

    const handleClickOpen = () => { 
      fetchProd();
      setPin('');
      setPout('');
      setProd('');
      setQte('');
      setOpen(true); 
    };

    const handleClose = () => { setOpen(false); };

      //submit un achat 
    const handleSubmit= async(e) =>{
      if(parseInt(pin)<=parseInt(pout)){const data={
        bon:parseInt(props.bon.substr(1)),
        produit:parseInt(prod),
        qte:parseInt(qte),
        prxin:parseFloat(pin),
        prxout:parseFloat(pout),
      }
        const response=await axiosPostAction(service,"addach",data)
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,'error',response.data.err)
        }else{
        axiosResponse(response,()=>{
                setOpen(false);
                props.handleAlert(true,'success',alertError);
                props.callback(props.bon.substr(1));   
                Gfunc.updateElem(props.bon,response.data.jData['mtn_bon'])        
        })     
      }}else{
        handleAlert(true,'error',"Le prix de vente doit être supérieur à celui d'achat!")
      }
    }
  return (
    <div>
      <Button className={classes.topTabBtn} variant="contained" color="primary" onClick={handleClickOpen} startIcon={<AddIcon/>}>Nouvel Achat</Button>
      <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmallTall}>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true}/>
        <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >{addTitle}</DialogTitle>
            <DialogContent id="ach">
            <ValidatorForm  id="achForm" ref={useRef(null)} onSubmit={handleSubmit} onError={errors => console.log(errors)} >
                    <Autocomplete
                                disableClearable
                                className='smallAuto'
                                id="iProd"
                                onChange={(e,newValue) =>{
                                    setProd(newValue.id)}
                                  } 
                                options={prodL}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) => option.id === value.id}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={labelText1}
                                    variant='outlined'
                                    margin='dense'
                                    size="small"
                                    autoFocus 
                                    required/>
                                )}/>
                    <TextValidator className="smallText" label={labelText2} size="small" margin="dense"  type='number' id="iQte" name="iQte" InputProps={{ inputProps: { min: 1 } }}  value={qte} variant="outlined"
                        onChange={e =>setQte(e.target.value)} required  />
                    <TextValidator className="smallText" label={labelText3} size="small" variant="outlined" margin="dense" type='number' InputProps={{ inputProps: { min: 0 ,step:0.0001} }} id="iPin" name="iPin"  value={pin} 
                        onChange={e =>setPin(e.target.value)} required/>
                    <TextValidator className="smallText" label={labelText4}  size="small" type='number' id="iPout" name="iPout" InputProps={{ inputProps: { min: 0 ,step:0.0001} }} value={pout} variant="outlined"
                        onChange={e =>setPout(e.target.value)} required/>
                 </ValidatorForm>
            </DialogContent>
            <DialogActions>
            <Button variant="contained" type='submit' color="primary" form="achForm" startIcon={<SaveIcon />}>VALIDER</Button>
            <Button variant="contained" onClick={handleClose} color="secondary">FERMER</Button>
            </DialogActions>
      </Dialog>
      </div>
  );
}
