import React ,{useRef,useCallback,useState,useMemo, useEffect} from "react";
import {Button,CircularProgress,Backdrop,TextField, Paper, Typography} from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";
import { ValidatorForm} from 'react-material-ui-form-validator';
import { MuiPickersUtilsProvider,KeyboardDatePicker} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { fr } from "date-fns/locale";
import { useStyles } from '../../css/muiStyle';
import  '../../css/reports.css'
import {AlertCompo, NoDataComponent} from '../../js/components'
import StackedBarChart from'../../compo/StackedBarChart'
import { Check } from "@material-ui/icons";
import PieCharts from'../../compo/Piechart'


export default function Rep_sai (props){
    var date = new Date();
    const Gfunc=require('../../Gfunc')
    const classes = useStyles();
    const [empty,setEmpty]=useState(false);
    const [submit,setSubmit]=useState(false);
    const [loading,setLoading]=useState(false)
    const [sommeTot,setSommeTot]=useState(0);
    const [percent,setPercent]=useState(0);
    const [users,setUsers]=useState([]);
    const [saisie,setSaisie]=useState([]);
    const [data,setData]=useState([]);
    const [prd,setPrd]=useState([]);
    const [selectprd,setSelectPrd]=useState('');
    const [mrq,setMrq]=useState([]);
    const [selectmrq,setSelectMrq]=useState('');
    const [selectDateD,SetSelectDateD]=useState(new Date(date.getFullYear(), date.getMonth(), 1))
  const [selectDateF, SetSelectDateF] = useState(new Date())
    const [severity, setSeverity] =useState('info');
    const [openA, setOpenA] =useState(false);
    const [message, setMessage] =useState('');
    const [trim,setTrim]=useState('');
    const [month,setMonth]=useState('');
    const [monthName,setMonthName]=useState('');
    const [ann,setAnn]=useState('');
    const [annees,setAnnees]=useState([]);
    const [mois,setMois]=useState([]);
    const qrt=[{id:1,name:"Q1"},{id:2,name:"Q2"},{id:3,name:"Q3"},{id:4,name:"Q4"}]
  const [qrts, setQrts] = useState([]);
    const color=["#3366cc","#dc3912","#ff9900","#109618","#990099","#0099c6","#dd4477","#66aa00","#b82e2e","#316395","#3366cc","#994499","#22aa99","#aaaa11","#6633cc","#e67300","#8b0707","#651067","#329262","#5574a6","#3b3eac","#b77322","#16d620","#b91383","#f4359e","#9c5935","#a9c413","#2a778d","#668d1c","#bea413","#0c5922","#743411"]
    const messageC='Aucune donnée trouvée'
    const service='_rep.php'

    const handleAlert=(openA,severity,message)=>{
        setOpenA(openA)
        setSeverity(severity)
        setMessage(message)
        setTimeout(function(){
          setOpenA(false)
        }, 5000);
      }
   
       //recuperer les marque 
    const fetchMrq= useCallback(async() => {
        const response = await Gfunc.axiosGetAction('_mrq.php','gets');
        if(response.data.err&&response.data.err!=='-1'){
           handleAlert(true,'error',response.data.err)
        }else{
          Gfunc.axiosResponse(response,()=>{
                response.data.jData.unshift({id:'empty',name:'Toutes'})
                setMrq(response.data.jData);   
          })
        }
    },[Gfunc]);

    //remplir Select Categories 
    const handleOpenMrq= useCallback(async () => {
       if(mrq.length===0) fetchMrq()
    },[mrq,fetchMrq])

  //fetch products
  const fetchPrd= useCallback(async(serv="_prd.php", act = "gets",act2="getspromo") => {
    const response = await Gfunc.axiosGetAction(serv,act);
    const response2 = await Gfunc.axiosGetAction(serv,act2);
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
      if(response2.data.err&&response.data.err!=="-1"){
        handleAlert(true,'error',response.data.err)
      }else{
        if(response2.data.jData&&response.data.jData){
          var arr=response2.data.jData.concat(response.data.jData)
          arr.unshift({id:'empty',name:'Toutes'})
          setPrd(arr);
        }
        else{ 
             if(response2.data.jData) {
              response2.data.jData.unshift({id:'empty',name:'Toutes'})
              setPrd(response2.data.jData);
             }
             else{ 
              response.data.jData.unshift({id:'empty',name:'Toutes'})
              setPrd(response.data.jData);
             }
            }
      }    
    })
  }
  },[Gfunc])

    //remplir Select Categorie 
  const handleOpenPrd= useCallback(async () => {
       if(prd.length===0) fetchPrd()
  },[prd,fetchPrd])

  const handleChangeMon = (newValue, year) => {
    setMonth(newValue.month)
    setMonthName(newValue.monthName)
    const cureentDay = new Date()
    const cureentMonth = cureentDay.getMonth() + 1
    setTrim('');
    var debmois=parseInt(newValue.month)
    var fin = cureentDay
    if (parseInt(year ?? ann) === cureentDay.getFullYear()) {
      if (debmois < cureentMonth) {
        fin = Gfunc.DateFinMonth(debmois, year ?? ann)
      }
    } else fin = Gfunc.DateFinMonth(debmois, year ?? ann)
    var deb = Gfunc.DateDebMonth(debmois, year ?? ann)

    SetSelectDateD(deb)
    SetSelectDateF(fin)
  }

  const handleChangeTrim = (semester, year) => {
    setMonth('');
    setMonthName('');
    setTrim(semester)
     var debtrim=qrt[Gfunc.getIndex(qrt,semester,"name")].id
    var days = Gfunc.TrimDebFin(debtrim, year ?? ann)
     SetSelectDateD(days[0])
    if (days[1] > date)
      SetSelectDateF(date)
    else
     SetSelectDateF(days[1])
  }

  const handleChangeAnn = async (ann) => {
    setAnn(ann)
    const mnts = await getMonths2(ann)
    if (parseInt(ann) === date.getFullYear()) {
      const currentQuarter = Math.ceil((date.getMonth() + 1) / 3); // Calculate the current quarter
      setQrts(qrt.filter(quarter => quarter.id <= currentQuarter))

      if (trim !== "") {
        if (parseInt(trim.substr(1)) >= currentQuarter) handleChangeTrim("Q" + currentQuarter, ann)
      } else
        if (mnts.length > 0) {
          handleChangeMon({ month: mnts[0].month, monthName: mnts[0].monthName }, ann)
        }
    } else {
      if (qrts.length < 4) setQrts([...qrt])


      //handleOpenMonth(ann)
      if (trim !== "") {
        handleChangeTrim(trim, ann)
      } else
        if (mnts.length > 0) {
          handleChangeMon({ month: mnts[0].month, monthName: mnts[0].monthName }, ann)
        }

    }
  }

  const getYears=useCallback(async()=>{
    const response= await Gfunc.axiosGetAction("_clo.php","getsyear");
    if(response.data.err&&response.data.err!=='-1'){
    handleAlert(true,"error",response.data.err);
    }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData){
        if (parseInt(response.data.jData[0]['year']) === date.getFullYear()) {
          const currentQuarter = Math.ceil((date.getMonth() + 1) / 3); // Calculate the current quarter
          setQrts(qrt.filter(quarter => quarter.id <= currentQuarter))
        }
          setAnnees(response.data.jData)
          setAnn(response.data.jData[0]['year'])
          if(response.data.jData.length>0){
            getMonths(response.data.jData[0]['year'])
          }
         // handleOpenMonth(response.data.jData[0]['year'])
      }
    })
  }, [mois, qrts])



//fetch year handler
const handleOpenYear= useCallback(async () => {
    if(annees.length===0) getYears()
},[annees,getYears])

  //handle get months
  const getMonths = useCallback(async (y) => {
    var mnts = []
    if (y !== '' && y !== undefined) {
      const response = await Gfunc.axiosPostAction("_clo.php", "getsmonth", { year: y });
      if (response.data.err && response.data.err !== '-1') {
        handleAlert(true, "error", response.data.err);
      } else
        Gfunc.axiosResponse(response, () => {
          if (response.data.jData) {
  // setMois(response.data.jData)
            if (response.data.jData.length > 0) {
              setMois(response.data.jData);
              setMonth(response.data.jData[0].month)
              setMonthName(response.data.jData[0].monthName)
              mnts = response.data.jData
            }
          }
        })
    } else {
      handleAlert(true, 'error', 'Vous devez choisir une année en amont')
    }
    return mnts
  }, [])

  //handle get months
  const getMonths2 = useCallback(async (y) => {
    var mnts = []
    if (y !== '' && y !== undefined) {
      const response = await Gfunc.axiosPostAction("_clo.php", "getsmonth", { year: y });
      if (response.data.err && response.data.err !== '-1') {
        handleAlert(true, "error", response.data.err);
      } else
        Gfunc.axiosResponse(response, () => {
          if (response.data.jData) {
            // setMois(response.data.jData)
            if (response.data.jData.length > 0) {
              setMois(response.data.jData);
              mnts = response.data.jData
            }
          }
        })
    } else {
      handleAlert(true, 'error', 'Vous devez choisir une année en amont')
    }
    return mnts
  }, [])



useEffect(()=>{
handleOpenYear();
},[handleOpenYear])

   //valider le formulaire
   const handleSubmit=async()=>{
    setLoading(true)
    var marque='';var produit='';var obj={};var tab=[];;var us=[]; var arrDate=[]; var arrU=[];var somme=0;var sommeU=0;

    var indM=mrq.findIndex((obj => obj.name ===selectmrq));
    if(indM!==-1){ marque=mrq[indM].id}

    var indP=prd.findIndex((obj => obj.name ===selectprd));
    if(indP!==-1){produit=prd[indP].id}

    const response=await Gfunc.axiosPostAction(service,"getsaisi",{date1:Gfunc.formatDate(selectDateD), date2:Gfunc.formatDate(selectDateF),
                                                                   brand:(marque!=='empty')?marque:'',produit:(produit!=='empty')?produit:''})
    setLoading(false);
    setSubmit(true)
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else{
          Gfunc.axiosResponse(response,()=>{
             if(response.data.jData){
               if(response.data.jData.saisie[0].length===0){setEmpty(true)}
               var arr=Gfunc.getDates1(selectDateD,selectDateF)
               arr.map((el)=>{
                  var index= response.data.jData.saisie[0].findIndex((obj => obj.day ===Gfunc.formatDate1(new Date(el))));
                  if(index!==-1){
                        arrDate.push({day:Gfunc.formatDate(el),users:response.data.jData.saisie[0][index].users})
                         //arrDate['users']=response.data.jData.saisie[0][index].users
                  }else{
                    arrDate.push({day:Gfunc.formatDate(el),users:[]})
                  }
               })
                arrDate.map((item)=>{
                  obj={}
                  if(item.users.length!==0){
                    item.users.map((el)=>{
                      if(Gfunc.include(us,el.user)===false){us.push(el.user)}
                      obj[el.user]=el.count
                  })
                  }else{
                    if(Gfunc.include(us,'')===false){us.push('')}
                    obj['']=0
                  }
                    let obj1={day:item.day}
                    var marged = { ...obj, ...obj1 }
                    tab.push(marged)
                })
                response.data.jData.byUser.sort((a, b) => b['nickname'].localeCompare(a['nickname']));
                response.data.jData.byUser.map((el)=>{
                   arrU.push({name:el.nickname,value:el.count})
                   somme=somme+el.count
                })
                us.sort((a, b) => b.localeCompare(a));
                response.data.jData.byUser.map((el)=>{
                    sommeU=sommeU+el.count
                })
                setSommeTot(sommeU)
                setUsers(us)
                setSaisie(tab)
                setPercent(somme)
                setData(arrU)
                
             }
          })
    } 
}

    return (
    <div className='content'>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={message}/>
        <Backdrop className={classes.backdrop} open={loading} >
             <CircularProgress color="inherit" />
        </Backdrop>
        <fieldset className="fieldsetTop" id="sai">
        <legend>Saisie des digits</legend>
         <div id='row-re'>
         <Autocomplete
                    disableClearable
                    value={ann}
                    onChange={(e,newValue) =>{handleChangeAnn(newValue);}}
                    options={annees.map((option) => option.year)}
                    className='smallAuto' 
                    renderInput={(params) =>  
                        <TextField margin='dense' 
                            size='small'{...params} variant="outlined"  label="Année..." SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                        />}
                />
              <Autocomplete
                    disableClearable
                    value={trim}
              onChange={(e, newValue) => { handleChangeTrim(newValue); }}
              options={qrts.map((option) => option.name)}
                    className='smallAuto' 
                    renderInput={(params) =>  
                        <TextField margin='dense' 
                            size='small'{...params} variant="outlined"  label="Trimestre..." SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                        />}
                />
              <Autocomplete
                    disableClearable
                    value={{month:month,monthName:monthName}}
                    onChange={(e,newValue) =>{handleChangeMon(newValue);}}
                    options={mois}
                    getOptionLabel={(option) => option.monthName}
                    getOptionSelected={(option) => option.month}
                    className='smallAuto' 
                    renderInput={(params) =>  
                        <TextField margin='dense' 
                            size='small'{...params} variant="outlined"  label="Mois..." SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                        />}
                />

        </div>
        <ValidatorForm id="formAdd" ref={useRef(null)} onSubmit={handleSubmit} >
            <div className="rowGrid" id="row-1">
              <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                   <KeyboardDatePicker disableToolbar value={selectDateD} variant="inline" placeholder="01/01/2020" onChange={date => SetSelectDateD(date)}
                                       minDate='10/10/2018' format="dd/MM/yyyy" className='smallText' margin="dense" inputVariant="outlined"
                                       invalidDateMessage="Le format de la date est incorrect" 
                               />
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                   <KeyboardDatePicker disableToolbar value={selectDateF} variant="inline" placeholder="10/10/2018" onChange={date => SetSelectDateF(date)}
                                        minDate="01/01/2018" format="dd/MM/yyyy" className='smallText' margin="dense" inputVariant="outlined"
                                        invalidDateMessage="Le format de la date est incorrect" 

                               />
              </MuiPickersUtilsProvider>
              <Autocomplete
                    disableClearable
                    onChange={(e,newValue) =>{setSelectMrq(newValue)}}
                    value={selectmrq}
                    options={mrq.map((option) => option.name)}
                    onFocus={handleOpenMrq}
                    className='smallAuto' 
                    renderInput={(params) =>  
                        <TextField margin='dense' 
                            size='small'{...params} variant="outlined"  label="Marque..." SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                        />}
                />
              <Autocomplete
                    disableClearable
                    onChange={(e,newValue) =>{setSelectPrd(newValue)}}
                    value={selectprd}
                    options={prd.map((option) => option.name)}
                    onFocus={handleOpenPrd}
                    className='smallAuto' 
                    renderInput={(params) =>  
                        <TextField margin='dense' 
                            size='small'{...params} variant="outlined"  label="Produit..." SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                        />}
                />
              <Button  className={classes.smallBtn} variant="contained" tabIndex="3" type='submit' id="bouton" name="addBtn" color="primary" startIcon={<Check/>}>VALIDER</Button>
            </div>
        </ValidatorForm>
        </fieldset>
        {
          (data.length!==0)?
           <div id='div-sai'>
           <Paper  id='report-paper'>
             {
               (saisie.length!==0)?
               <StackedBarChart data={saisie} array={users} dataKeyX='day' color={color} margin={{top: 20,right: 30,left: 20,bottom: 5 }}
                 padding={{ left: 10, right: 10 }}/>
               :<></>
             } 
            <Typography className='titleCharts' variant='h6'>Saisie journalier des digits </Typography>
           </Paper>
           <Paper id='report-paper'>
             <PieCharts data={data} color={color} percent={percent} dimension={'100%'} display='true' total={sommeTot}/>
             <Typography className='titleCharts' variant='h6'>Saisie des digits par utilisateur</Typography>
           </Paper>
           </div>
          :<></>
        }
        {
          (submit&&data.length===0&&empty)
          ?<NoDataComponent message={messageC}/>
          :<></>
        }
    </div>   
    );
    }