import React,{ useState, useEffect ,useMemo} from "react";
import TextField from '@material-ui/core/TextField';
import {CircularProgress,Paper, Box} from '@material-ui/core';
import {Search,Check} from '@material-ui/icons';
import {customStyles}from '../../css/datatableStyle'
import { useStyles } from '../../css/muiStyle';
import $ from 'jquery'
import  {AlertCompo,NoDataComponent,NotAllowedComponent} from '../../js/components';
import DialogPrinter from './modals/dig_prt_imprimer'
import DialogRePrinter from './modals/dig_prt_reimprimer'
import DialogTicketP from './modals/dig_prt_tickPrint'
import { useCallback } from "react";
import  '../../css/digit.css'
import DTable from "../../compo/DTable"; 


export default function Dig_prt (props){
 // const isBigScreen = useMediaQuery({ query: '(max-width: 1024px)' })
  const param=props.param
  const Gfunc=require('../../Gfunc')
  const librery=require('../../js/data_table_librery')
  const [data, setData] = useState([]);
  const [tiketdata, setTiketData] = useState([]);
  const [called, setCalled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const[noData,setNoData]=useState(false);
  const[doSearch,setDoSearch]=useState(false);
  const[notAllowed,setNotAllowed]=useState(false);
  const [severity, setSeverity] =useState("info");
  const [open, setOpen] =useState(false);
  const [messageA, setMessageA] =useState('');
  const[errMessage,setErrMessage]=useState('');
  const[records,setRecords]=useState('');
  const[rowClick,setRowClick]=useState('');
  const[defaultClick,setDefaultClick]=useState('');
  const[dataDet,setDatadet]=useState([]);
  const [resetDP, setResetDP] = useState(false);
  const [openP, setOpenP] = useState(false);
  const service='_prt.php'
  const classes=useStyles()
 
  const conditionalRowStyles=[
    { 
      when: row => row.type==='print',
       style: {
             color: "green",
       }
    },
    { 
      when: row => row.type==='reprint',
       style: {
             color: 'red',
       }
    },
    { 
      when: row => row.DT_RowId===rowClick || row.DT_RowId===defaultClick,
       style: {
        backgroundColor: '#e4d5b7',
      }
    }
  ]

  $(function() {
    $("[role='row'] > div:nth-child(5)").addClass("dtIcons");
    }) 
  //handle alerts properties
  const handleAlert=useCallback((open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  },[])

  const handleClick=useCallback(async(row)=>{
    setDatadet([])
    setLoading2(true)
    const response=await Gfunc.axiosPostAction(service,"getdet",{printId:row.DT_RowId.substr(1)})
    setLoading2(false)
     if(response.data.err&&response.data.err!=='-1'){
       handleAlert(true,'error',response.data.err)
     }else{
     Gfunc.axiosResponse(response,()=>{
        if(response.data.jData){
          setDatadet(response.data.jData)
        }
     })
   }
 },[Gfunc,handleAlert])
   
  const fetch=useCallback(async(page, size = perPage) => {
    setCalled(true)
    setLoading(true);
    const response=await librery.axiosGet(page,service,size)
    if(response.data.err &&response.data.err!=="-1"){
      setErrMessage(response.data.err)
      setNotAllowed(true)}else{
    Gfunc.axiosResponse(response,()=>{
      setRecords(response.data.recordsFiltered)
      if(response.data.data!==undefined){
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
        setLoading(false);
        setNoData(true)
        setDoSearch(true)
        setRowClick('')
        setDefaultClick(response.data.data[0].DT_RowId)
        handleClick(response.data.data[0])
      }else{
        setDoSearch(false)
        setData([])
      }
    })
  }
  },[perPage,service,Gfunc,librery,handleClick])

  useEffect(() => {
    if(!called)
    fetch(1);
  }, [fetch,called]);


const columns =useMemo(
    () => [   
      {
          name: "Produit",
          minWidth:'130px !important',
          selector: row=>row.produit,
          sortField: "produit",
          sortable: true,
      },
      {
          name: "Qte.",
          minWidth:'90px !important',
          selector: row=>row.qte,
          sortField: "qte",
          sortable: true,
      },
      {
          name: "Type",
          minWidth:'100px !important',
          selector: row=>row.type,
          sortField: "type",
          sortable: true,
      },
      {
        name: "DATE",
        minWidth: '130px !important',
        selector: row=>row.date_in.substr(0,16),
        sortField: "date_in",
        sortable: true
      },
      {
        name: "Owner",
        minWidth:'100px !important',
        selector: row=>row.owner,
        sortField: "owner",
        sortable: true
      },
      {
        name: "Utilisateur",
        minWidth:'115px !important',
        selector: row=>row.user,
        sortField: "user",
        sortable: true
      },
      {
        name: "Logo",
        width:'90px !important',
        sortField: "logo",
        sortable: true,
        cell: (row) => {
          return (
            <div>
             {
               (row.logo)?<Check className={classes.greenIcon} />:<></>
             }
            </div>
             );
           },
      },
        
    ],[classes.greenIcon]);
  

  const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage)=>{
      const response=await librery.axiosSort(column,sortdirection,page,size,service)
      if(response.data.err&&response.data.err!=="-1"){
        handleAlert(true,'error',response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
      })
    }     
  }
  
const HandleSearch=useCallback(async(e,val)=>{
  if(e.which === 13){
      if(val!==null && val!==''){
          const response=await Gfunc.axiosPostAction(service,"gettik",{tiketId:parseInt(val)})
            if(response.data.err&&response.data.err!=='-1'){
              handleAlert(true,'error',response.data.err)
            }else{
            Gfunc.axiosResponse(response,()=>{
              if(response.data.jData) {
                setOpenP(true)
                setTiketData(response.data.jData)
              }
            })
      }
    }
}
},[handleAlert,service,Gfunc])

const handleClose=()=>{
    setOpenP(false)
}
return (
    <div className="content">
       <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
       <DialogTicketP open={openP} data={tiketdata} dataG={data} handleClose={handleClose} param={param}/>
       <Paper className={classes.paperBody} elevation={5} id='paper-p'>
        <div className='table-header'>
          <div className='button-flex-wrapper'>
                <DialogPrinter handleAlert={handleAlert} callback={()=>{if(currentPage===1) fetch(1);else setResetDP(!resetDP)}}
                               setRowClick={setRowClick} setDefaultClick={setDefaultClick} param={param} setDatadet={setDatadet}/>
                <DialogRePrinter handleAlert={handleAlert}  setDatadet={setDatadet}
                                 callback={()=>{if(currentPage===1) fetch(1);else setResetDP(!resetDP)}} param={param}/>
          </div>
          <div className='search'>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Search className={classes.blackIcon} sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                      <TextField  className="smallText" id="searchBar" variant="outlined" label="Recherche"  size="small" margin='dense' 
                            onKeyPress={e => HandleSearch(e,e.target.value)} />
                 </Box>
           </div>
        </div>
        {
          (data.length!==0||doSearch)?
           <div id='print-det'>
            <div>
            <DTable
             currentPage={currentPage}
             data={data}
             columns={columns}
             loading={loading}
             fetch={fetch}
             handleOnSort={handleOnSort}
             resetDP={resetDP}
             totalRows={totalRows}
             perPage={perPage}
             setPerPage={setPerPage}
             setCurrentPage={setCurrentPage}
             paginationRowsPerPageOptions={[10,25,50,75,100]}
             customStyles={customStyles}
             conditionalRowStyles = { conditionalRowStyles }
             onRowClicked={(row)=>{handleClick(row);setRowClick(row.DT_RowId);setDefaultClick('')}}
             />
            </div>
          
           <div id='paper-print'>
           {(loading2)?<CircularProgress className={classes.circularProgress} />:<></>}
           {(dataDet.length!==0)?
           <table id="table-det">
            <thead>
              <tr>
                <th style={{width:'60px'}} >Ticket</th>
                <th style={{width:'220px'}} >Code</th>
                <th style={{width:'170px'}}>Serial</th>
                <th style={{width:'100px'}}>Statut</th>
              </tr>
            </thead>
            <tbody>
            {
             dataDet.map((el,index)=>{
               return (
                  <tr key={index} >
                    <td style={{width:'60px'}}>{el.tiketId}</td>
                    <td style={{width:'220px'}}>{el.digit}</td>
                    <td style={{width:'170px'}}>{el.serial}</td>
                    <td style={{width:'100px'}}>{(el.reprintId!==null)?'reprint':'print'}</td>
                  </tr>
                )
            })
            }
            </tbody>
            </table>
           :<></>
           }       
           </div>
            
        </div>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData || records===0)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
      </Paper>
    </div>    

  );
}