import React,{useState,useRef} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog,TextField,FormControl} from '@material-ui/core';
import Draggable from 'react-draggable';
import SaveIcon from '@material-ui/icons/Save';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import AddIcon from '@material-ui/icons/Add';
import {axiosPostAction,axiosResponse} from'../../../Gfunc'
import { useStyles,paperPropsSmallTall } from '../../../css/muiStyle';
import {AlertCompo} from '../../../js/components'
import { Autocomplete } from '@material-ui/lab';
import { fr } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider,KeyboardDatePicker, KeyboardTimePicker} from "@material-ui/pickers";
import { AccessTimeOutlined, Today } from '@material-ui/icons';


//add new marque
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Ach_add_nvbn(props) {
    const Gfunc=require('../../../Gfunc')
    const [frns,setFrns]=useState('');
    const [user,setUser]=useState('');
    const [note,setNote]=useState('');
    const [open, setOpen] =useState(false);
    const nom="bon"
    const addTitle="Ajouter un nouveau bon"
    const alertError="Le "+nom+" est ajouté."
    const labelText2="Fournisseur"
    const labelText3="Utilisateur"
    const labelText4="Note"
    const service="_ach.php"
    const classes=useStyles();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [severity, setSeverity] =useState("info");
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [selectedTime, setSelectedTime] = useState(new Date());


   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpenA(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpenA(false)
    }, 5000);
  }

    const handleClickOpen = () => { 
      props.handleOpenFrns()
      props.handleOpenUsers()
      setFrns('');
      setUser('');
      setNote('');
      setOpen(true); 
      setSelectedDate(new Date())
    };

    const handleClose = () => { setOpen(false); };


    //time handler
    const handleTimeChange = (date) => {
      setSelectedTime(date);
    };  
    //date handler
    const handleDateChange = (date) => {
      setSelectedDate(date);
    };    
    
    const handleSubmit= async(e) =>{
      const data={
        date:Gfunc.formatDate(new Date(selectedDate)),
        time:Gfunc.getClock(selectedTime),
        frns:parseInt(frns),
        user:parseInt(user),
        note:note,
      }
        e.preventDefault();
        const response=await axiosPostAction(service,"addbon",data)
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,'error',response.data.err)
        }else{
        axiosResponse(response,()=>{
                setOpen(false);
                if (response.data.jData) props.handleAlert(true,'success',alertError);
                props.callback();
        })
      }
    }
  return (
    <div>
      <Button className={classes.topTabBtn} variant="contained" color="primary" onClick={handleClickOpen} startIcon={<AddIcon/>}>Nouveau bon</Button>
      <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmallTall}>
        <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >{addTitle}</DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true}/>
            <DialogContent >
            <ValidatorForm id="bonForm"  ref={useRef(null)} onSubmit={handleSubmit} onError={errors => console.log(errors)} >  
            <div className="rowGrid" id='row-1'>
            <FormControl className='smallText' margin="dense">
            <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                              <KeyboardDatePicker
                              disableToolbar
                              size="small"
                              //inputProps={{className:classes.diagFormInput}}
                              KeyboardButtonProps={{style:{padding:"0px"}}}
                              variant="inline"
                              inputVariant="outlined"
                              maxDate={new Date()}  
                              invalidDateMessage="Le format de la date est incorrect"   
                              maxDateMessage="La date ne doit pas être supérieure à la date maximale"
                              format="dd/MM/yyyy"
                              name="iDate"
                              id="date-picker-inline"
                              label="Date"
                              keyboardIcon={<Today className={classes.blackSmallIcon}/>}
                              value={selectedDate}
                              onChange={handleDateChange}
                              required />
                  </MuiPickersUtilsProvider>  
                  </FormControl>
                  <FormControl className='smallText' margin="dense">
                  <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                                <KeyboardTimePicker
                                    showTodayButton={true}
                                    //inputProps={{tabIndex: "2",className:classes.FormInput}}
                                    inputVariant='outlined'
                                    KeyboardButtonProps={{style:{padding:"0px",height:'30px'}}}
                                    okLabel="Confirmer"
                                    cancelLabel='Annuler'
                                    todayLabel="Maintenant"
                                    invalidDateMessage="Format est incorrect"   
                                    size="small"
                                    autoOk={true}
                                    orientation='landscape'
                                    ampm={false}
                                    maxDate={new Date()} 
                                    format="HH:mm"
                                    id="iTime"
                                    label="Heur"
                                    value={selectedTime}
                                    keyboardIcon={<AccessTimeOutlined  className={classes.blackSmallIcon}/>}
                                    onChange={handleTimeChange} />            
                    </MuiPickersUtilsProvider> 
                    </FormControl> 
                    </div> 
                    <Autocomplete
                                disableClearable
                                className='smallAuto'
                                id="iFrns"
                                onChange={(e,newValue) =>{
                                    setFrns(newValue.id)}
                                  } 
                                options={props.frnsL}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) => option.id === value.id}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={labelText2}
                                    variant='outlined'
                                    margin='dense'
                                    size="small"
                                    autoFocus 
                                    required/>
                                )}/>
                                 <Autocomplete
                                disableClearable
                                className='smallAuto'
                                id="iUser"
                                onChange={(e,newValue) =>{
                                    setUser(newValue.id)}
                                  } 
                                options={props.userL}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) => option.id === value.id}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={labelText3}
                                    variant='outlined'
                                    margin='dense'
                                    size="small"
                                    required/>
                                )}/>
                    <TextValidator size="small" className='smallNote' variant="outlined" label={labelText4} id="iNote" name="iNote"  value={note}
                        onChange={e =>setNote(e.target.value)} 
                        placeholder="Insérer une note"
                        multiline
                        rows={4}
                         />
                         </ValidatorForm>
            </DialogContent>
            <DialogActions>
            <Button variant="contained" type='submit' color="primary" form="bonForm" startIcon={<SaveIcon />}>VALIDER</Button>
            <Button variant="contained" onClick={handleClose} color="secondary">FERMER</Button>
            </DialogActions>
        
      </Dialog>
      </div>
  );
}
