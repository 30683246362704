import DataTable from "react-data-table-component";
import {CircularProgress} from '@material-ui/core';
import {customStyles as DcustomStyles }from '../css/datatableStyle';
import { useContext } from "react";
import ParamContext from "../Context";
import { useStyles } from '../css/muiStyle';
import React from "react";


const DTable = React.memo(({ data, columns,loading, fetch, handleOnSort,resetDP,customStyles, perPage, currentPage, setPerPage , setCurrentPage,paginationRowsPerPageOptions,totalRows ,conditionalRowStyles, expandableRows, expandableRowsComponent, fixedHeader, title, noHeader,persistTableHead, onRowClicked}) => {
    const param=useContext(ParamContext);
    const classes = useStyles();

    function handlePageChange (page) {
        fetch(page,perPage);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetch(page, newPerPage);
        setPerPage(newPerPage);
      };
  
    return (
        <DataTable 
        persistTableHead={persistTableHead}
        //key={Math.floor(Math.random() * 100)}
        title={title}
        fixedHeader={fixedHeader}
        columns={columns}
        data={data}
        noHeader={(noHeader!==false)?true:false}
        noDataComponent={param.ErreurData}
        paginationRowsPerPageOptions={paginationRowsPerPageOptions}
        paginationPerPage={perPage}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationDefaultPage={currentPage}
        paginationResetDefaultPage={resetDP}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        sortServer={true}
        onSort={handleOnSort}
        highlightOnHover
        selectableRowsHighlight
        customStyles={customStyles??DcustomStyles}   
        conditionalRowStyles={conditionalRowStyles}
        paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}
        contextMessage={{ singular: 'élément sélectionné', plural: 'éléments sélectionnés' }}
        progressComponent={<CircularProgress className={classes.circularProgress} />}
        expandableRows ={expandableRows}
        expandableRowsComponent={expandableRowsComponent} 
        onRowClicked={onRowClicked}
        
      />
    );
  });
  export default DTable;