import React,{useCallback, useState} from 'react';
import {TextField,Paper,Button,DialogActions,DialogContent,DialogTitle,Dialog} from '@material-ui/core';
import {ArrowRight} from '@material-ui/icons';
import {axiosGetAction,axiosResponse,axiosPostAction,} from'../../../Gfunc'
import { useStyles,paperPropsSmallTall } from '../../../css/muiStyle';
import {AlertCompo, BackdropCompo, LoadingCircle}from '../../../js/components'
import Draggable from 'react-draggable';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Autocomplete } from '@material-ui/lab';
import { mdiSale} from '@mdi/js';
import Iconn from '@mdi/react';

//extraction client
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Dig_con_extr(props) {
  
    const [open, setOpen] = React.useState(false);
    const labelText2="Produit"
    const addMessage="Délivrance d'un code promo"
    const [client,setClient]=useState('')
    const [clientN,setClientN]=useState('')
    const [promos,setPromos]=useState('')
    const [prod,setProd]=useState('')
    const [prodL,setProdL]=useState([])
    const [tel,setTel]=useState('')
    const classes = useStyles();
    const [severity, setSeverity] =useState("info");
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [action, setAction] =useState(false);
    const [loading, setLoading] =useState(false);

   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpenA(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpenA(false)
    }, 5000);
  }

      
    const handleClickOpen = () => { setOpen(true); fetchProd(); setProd(''); setClient(''); setPromos('');setTel('')};

    const handleClose = () => { setOpen(false); };

  const fetchCli= async(tel) => {
    setClient('')
    setClientN('')
    setPromos('')
    setLoading(true)
    const response=await axiosPostAction("_dig.php","checkaccpromo",{tel:tel,produitId:prod})
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
     }else{
      axiosResponse(response,()=>{
        if(response.data.jData.clientId!=null){setClient(response.data.jData.clientId);
        setClientN(response.data.jData.client);
        setPromos(response.data.jData.nbPromo);
       }
       else{
        handleAlert(true,"error","Ce client n'est pas éligible")
       }
      })} 
      setLoading(false)
  }

    //fetch products
    const fetchProd= useCallback(async(serv="_prd.php", act = "getspromo") => {
      const response = await axiosGetAction(serv,act);
      if(response.data.err&&response.data.err!=="-1"){
        handleAlert(true,'error',response.data.err)
      }else{
      axiosResponse(response,()=>{
        if(response.data.jData)
          setProdL(response.data.jData);    
      })
      }
    },[])

    //get number of promos
    const getPromos= useCallback(async(cli,prd) => {
      setLoading(true)
      const response = await axiosPostAction("_dig.php","getdigcnt",{clientId:cli,produitId:prd});
      if(response.data.err&&response.data.err!=="-1"){
        handleAlert(true,'error',response.data.err)
      }else{
      axiosResponse(response,()=>{
          setPromos(response.data.jData);    
      })
      }
      setLoading(false)
    },[])

    //handle get number of promos
    const handleGetPromos= useCallback(async(client,prod) => {
      if(prod!==''&&client!==""){
        getPromos(client,prod)
      }
    },[getPromos])
  
  
  //set promotion
  const handleSubmit=async()=>{
    setAction(true)
    const response=await axiosPostAction("_dig.php","setpromo",{clientId:client,produitId:prod})
        if(response.data.err&&response.data.err!=='-1'){
             handleAlert(true,'error',response.data.err)
        }else{
        axiosResponse(response,()=>{
                  if(response.data.jData){
                    setOpen(false)
                    props.handleAlert(true,"success","Le code promo est délivré!")
                }
        })
      }  
    setAction(false)  
  }
  
    
  return (
    <div>
      <Button  className={classes.topTabBtn}  variant="contained" color="primary" onClick={handleClickOpen} startIcon={<Iconn path={mdiSale} color="black" size="18px"/>} >Code promo</Button>
      <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmallTall}>
            <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >{addMessage}</DialogTitle>
            <BackdropCompo className={classes.backdropDiag} open={action}/>
            <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true}/>
            <DialogContent>
            <ValidatorForm  id="promoForm" onSubmit={handleSubmit} onError={errors => console.log(errors)}  > 
            <Autocomplete
                                disableClearable
                                className='smallAuto'
                                id="iProd"
                                onChange={(e,newValue) =>{
                                    setProd(newValue.id)
                                    handleGetPromos(client,newValue.id)
                                  }
                                  } 
                                options={prodL}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) => option.id === value.id}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={labelText2}
                                    variant='outlined'
                                    margin='dense'
                                    size="small"
                                    autoFocus
                                    required/>
                                )}/>
                    <TextValidator className="smallText" label={"Telephone"} size="small" margin="dense" id="iTel" name="iTel"  value={tel} variant="outlined" inputProps={{ pattern: "[0][1-7][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]",maxLength: 10 }}
                        onChange={e =>{setTel(e.target.value);}} onKeyPress={(e)=>{if(e.target.value.length===9){ fetchCli(e.target.value+e.key)} }} onPaste={(e)=>{if(e.clipboardData.getData('Text').length===10)fetchCli(e.clipboardData.getData('Text'))}} disabled={prod===''} required  />
                    <TextValidator className="smallText" label={"Client"} size="small" margin="dense" id="iCli" name="iCli"  value={clientN} variant="outlined" 
                        disabled  />
                     <div id='nbrPromo'><h4> Le nombre de promo obtenu</h4><ArrowRight/>{(loading)?<LoadingCircle loading={loading} size="20px" color="green"/>:<p>{(promos==="")?"":new Intl.NumberFormat().format(promos)}</p>}</div>                
              </ValidatorForm>
            </DialogContent>
            <DialogActions>
                    <Button disabled={client===""||prod===''} variant="contained" type='submit' form="promoForm" color="primary">Délivrer</Button>
                    <Button variant="contained"  onClick={handleClose} color="secondary">FERMER</Button>
            </DialogActions>
        
      </Dialog>
      </div>
  );
}
