import React,{ useState, useEffect ,useCallback,useMemo,useRef} from "react";
import ContentEditable from 'react-contenteditable';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm} from 'react-material-ui-form-validator';
import {Delete,CheckCircleOutline,NotInterested} from '@material-ui/icons';
import {Button,Checkbox,Paper,Box,FormControlLabel} from '@material-ui/core';
import {Search,Description,Add} from '@material-ui/icons';
import $ from 'jquery';
import {customStyles}from '../../css/datatableStyle'
import { useStyles } from '../../css/muiStyle';
import '../../css/digit.css';
import {AutocompleteS, BackdropCompo} from "../../js/components"
import Autocomplete from '@material-ui/lab/Autocomplete';
import { AlertCompo,NoDataComponent,NotAllowedComponent } from "../../js/components";
import { mdiCheckboxMarkedCircleOutline, mdiCheckboxMultipleMarkedCircleOutline } from "@mdi/js";
import Iconn from "@mdi/react";
import DTable from "../../compo/DTable";

export default function Dig_chg (props){
  const lodashClonedeep = require('lodash.clonedeep');
  const classes = useStyles();
  const param=props.param
  const Gfunc=require('../../Gfunc')
  const librery=require('../../js/data_table_librery')
  const [data, setData] = useState([]);
  const [datach, setDatach] = useState([]);
  const [frns, setFrns] = useState([]);
  const [prod, setProd] = useState([]);
  const [lot, setLot] = useState([]);
  const [libre, setLibre] = useState([]);
  const [ch, setCh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [called, setCalled] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [search,setSearch]=useState('');
  const [digSearch,setdigSearch]=useState('');
  const [,setIcon]=useState('');
  const [selectF,setSelectF]=useState('')
  const [selectL,setSelectL]=useState('')
  const [selectP,setSelectP]=useState('')
  const [del,setDel]=useState('');
  const [selectedFile,SetselectedFile]=useState('')
  const [check,setCheck]=useState([])
  const [checkInfo,setCheckInfo]=useState({})
  const [errorFrns,setErrorFrns]=useState(false);
  const [errorLot,setErrorLot]=useState(false);
  const [errorProd,setErrorProd]=useState(false);
  const [errorFile,setErrorFile]=useState(false);
  const [reset,setReset]=useState(false);
  const [severity, setSeverity] =useState("info");
  const [open, setOpen] =useState(false);
  const [messageA, setMessageA] =useState('');
  const [resetDP, setResetDP] = useState(false);
  const [noData,setNoData]=useState(false);
  const [doSearch,setDoSearch]=useState(false);
  const [notAllowed,setNotAllowed]=useState(false);
  const [errMessage,setErrMessage]=useState('');
  const [records,setRecords]=useState('');
  const srv='digit'
  const service='_dig.php'
  const filter='&digSearch='+digSearch+"&mod=MYDAY"

   //handle alerts properties
   const handleAlert=useCallback((open,severity,message)=>{
      setOpen(open)
      setSeverity(severity)
      setMessageA(message)
      setTimeout(function(){
        setOpen(false)
      }, 5000);
   },[])

   const  conditionalRowStyles  =  [ 
    {
      when: row => row.isbanned!==true,
        style: {
              backgroundColor: "lightgray",
              color:"gray"
        }
    },
  ]

  const fetch= useCallback(async(page, size = perPage) => {
    setCalled(true)
    setLoading(true);
    setCheck([])
    setCh(false)
    const response = await librery.axiosGet(page,service,size+filter+'&search='+search);
    if(response.data.err&&response.data.err!=="-1"){
      setErrMessage(response.data.err)
      setNotAllowed(true)
    }else{
    Gfunc.axiosResponse(response,()=>{
      setRecords(response.data.recordsFiltered)
      if(response.data.data!==undefined){
        setData(response.data.data);
        setDatach(response.data.data)
        setTotalRows(response.data.recordsFiltered);
        setLoading(false);
        setNoData(true)
        setDoSearch(true)
      }
     else{
        setDoSearch(false)
        setData([])  
      }
    })
    }
  },[Gfunc,filter, perPage, search,librery])

    //fetch products
    const fetchProd= useCallback(async(serv="_prd.php", act = "gets",act2="getspromo") => {
      const response = await Gfunc.axiosGetAction(serv,act);
      const response2 = await Gfunc.axiosGetAction(serv,act2);
      if(response.data.err&&response.data.err!=="-1"){
        handleAlert(true,'error',response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
        if(response2.data.err&&response.data.err!=="-1"){
          handleAlert(true,'error',response.data.err)
        }else{
          if(response2.data.jData&&response.data.jData)
          setProd(response2.data.jData.concat(response.data.jData));
          else if(response2.data.jData) setProd(response2.data.jData);
               else setProd(response.data.jData);
        }    
      })
    }
    },[Gfunc,handleAlert])
    
    //fetch lot
    const fetchLot= useCallback(async(serv="_lot.php", act = "gets") => {
      const response = await Gfunc.axiosGetAction(serv,act);
      if(response.data.err&&response.data.err!=="-1"){
        handleAlert(true,'error',response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
          setLot(response.data.jData);    
      })
    }
    },[Gfunc,handleAlert])
    
    //fetch fournisseurs
    const fetchFrns= useCallback(async(serv="_frs.php", act = "gets") => {
      const response = await Gfunc.axiosGetAction(serv,act);
      if(response.data.err&&response.data.err!=="-1"){
        handleAlert(true,'error',response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
          setFrns(response.data.jData);    
      })
    }
    },[Gfunc,handleAlert])

  const uploadFile=useCallback(async() => {
    setLoadingSubmit(true)
    setErrorFrns(false);
    setErrorProd(false);
    setErrorLot(false);
    setErrorFile(false)
    if(selectedFile === '') setErrorFile(true);
    if(selectP === '') setErrorProd(true);
    if(selectF === '') setErrorFrns(true);
    if(selectL === '') setErrorLot(true);
    if(selectedFile!=='' && selectP!=='' && selectF!=='' && selectL!==''){
    var formData = new FormData();
    formData.append("frns", selectF);
    formData.append("prod", selectP);
    formData.append("lot", selectL);
    formData.append("fcsv", selectedFile);
    //const dat={frns:selectF,prod:selectP,lot:selectL}
    const response = await Gfunc.axiosPostAction(service,'loadcsv',formData);
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
      handleAlert(true,'success',"Les digits de fichier sont ajoutés.");
      if(currentPage===1) fetch(1);else setResetDP(!resetDP);
        SetselectedFile("")
        setSelectP("")
        //setSelectL("")
        //setSelectF("")
       setReset(!reset)
    })
    }
    }else handleAlert(true,'error','Veuillez remplir tous les champs!')
     const elem=document.getElementById("iProd");
     if(elem) elem.focus()
     setLoadingSubmit(false)
  },[selectF,selectP,selectL,selectedFile,Gfunc,resetDP,currentPage,reset,fetch,handleAlert])

  useEffect(() => {
    if(!called)
    fetch(1);
  }, [fetch,called]);

  const handleOnEdit=useCallback(async(e,row,att)=>{
    e.preventDefault();
    const edt1=e.target.innerHTML
    const edt=Gfunc.DeleteSpace(edt1);
    if(row[att]!==edt){
      const attrib=row[att];
      const response=await librery.axiosEdit(service,row,edt,att) 
      if(response.data.err&&response.data.err!=="-1"){
        handleAlert(true,'error',response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{row[att]=edt;e.target.innerHTML=edt})
      }
      if(response.data.err) {e.target.innerHTML=attrib}
    }else {e.target.innerHTML=edt}
  },[librery,Gfunc,handleAlert]);

const flagMan =useCallback( async( row)=>{
  const response= await Gfunc.axiosPostAction('flagman.php','1',({ srv:srv, id: row.DT_RowId.substr(1),att:'banned', val: row.isbanned }))
  if(response.data.err&&response.data.err!=="-1"){
    handleAlert(true,'error',response.data.err)
  }else{
  Gfunc.axiosResponse(response,()=>{ 
    const objIndex = data.findIndex((obj => obj.DT_RowId === row.DT_RowId));
      if (response.data.jData) {
        setIcon(row.DT_RowId)
        if(row.isbanned){
          data[objIndex].isbanned=false
        }else data[objIndex].isbanned=true
        setIcon('')     
      }else handleAlert(true,'error',response.data.err);
          })
   }
},[Gfunc,data,handleAlert])

const handleSetData=useCallback((row,att)=>{
  Gfunc.removeItemsByAtt(data,row.DT_RowId,att)
  setDel('')
},[data,Gfunc])

//supprimer un digit 
const handleDeleteItem=useCallback(async(row)=>{
  const ids=[]
  if(row.isbanned && !row.datusd){
  ids.push(row.DT_RowId.substr(1))
    const response=await Gfunc.axiosPostAction(service ,"deldigs",{ids:ids})
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
        handleAlert(true,'success',"Le digit est supprimé.")
        handleSetData(row,"DT_RowId")
        setDel(row.DT_RowId)
      })
    }
  }
},[Gfunc,handleSetData,handleAlert])

//fetch produit handler
const handleOpenProd = useCallback(async () => {
  if(prod.length===0)
  fetchProd()
  },[prod,fetchProd])

  //fetch fournisseur handler
const handleOpenFrns = useCallback(async () => {
  if(frns.length===0)
  fetchFrns()
  },[frns,fetchFrns])

  //fetch fournisseur handler
const handleOpenLot = useCallback(async () => {
  if(lot.length===0)
  fetchLot()
  },[lot,fetchLot])

  const HandleChange=useCallback((e,row)=>{
    var checkk =[...check]
    const tmp=parseInt(row.DT_RowId.substr(1));
    if(e.target.checked===true){
      if(!checkk.includes((tmp))){
        checkk.push(parseInt(tmp))
        let inf=checkInfo; inf[tmp]=row.isbanned
        setCheckInfo(inf)
      }
   }
   else{
       let index = checkk.indexOf(tmp);
       if(index>-1)checkk.splice(index,1)
   }
   setCheck(checkk)
  },[check,checkInfo])

  const Ch=({datach,setCheck,ch})=>{
    const test=(val)=>{
      setCh(val)
      if(val===true){
        let checkk=[]
        let inf=checkInfo; 
        lodashClonedeep(datach).map(e=>{
           let tmp=parseInt(e.DT_RowId.substr(1))
           checkk.push((tmp))
           inf[tmp]=e.isbanned
           return true
        })
        setCheckInfo(inf)
        setCheck(checkk)
      }else{
        setCheckInfo({})
        setCheck([])
      }  
    }
    return(  
      <FormControlLabel control={<Checkbox  className={classes.checkStelectableRow} defaultChecked={ch}
                    onChange={e=>test(e.target.checked)} inputProps={{ 'aria-label': 'controlled' }}/>}
                 />
    )
  }

const columns =useMemo(
    () => [ 
      {
        name:<Ch datach={datach} setCheck={setCheck} ch={ch}/>,
        width:"55px",
        cell: (row) => {
          return (
            <div >
               <FormControlLabel control={<Checkbox checked={check.includes(parseInt(row.DT_RowId.substr(1)))} className={classes.checkStelectableRow}
                  onChange={e=>HandleChange(e,row)} inputProps={{ 'aria-label': 'controlled' }}/>}
               />
            </div>
           );
         },
      },
      {
          name: "DIGIT",
          minWidth:'250px !important',
          selector: row=>row.digit,
          sortField: "digit",
          sortable: true,
          cell: (row) => {
            if(del===row.DT_RowId){                
              handleSetData(row,'DT_RowId')      
            }
           if(libre.length!==0){
           libre.map((item)=>{
             if(item.DT_RowId===row.DT_RowId && row.isbanned===true){
                row.isbanned=false;
             }
             return true
            })
            setTimeout(() => { setLibre('')},0) ;}
            return (
              <div>
                    <ContentEditable
                    html={row.digit}
                    name="digit"
                    data-column="item"
                    className="content-editable"
                    disabled={false}
                    onBlur={e => handleOnEdit(e,row,'digit')}
                    title={(!row.datupd)?"Entré le: "+row.datin +' par: ' +row.agent:"Édité le: "+row.datupd +' par: ' +row.usrupd}
                  />
              </div>
             );
           }
      },
      {
        name: "SERIAL",
        minWidth:'250px !important',
        selector: row=>row.serial,
        sortField: "serial",
        sortable: true,
        cell: (row) => {
            return (
              <div>
                  <ContentEditable
                    html={row.serial}
                    name="serial"
                    data-column="item"
                    className="content-editable"
                    disabled={false}
                    title={(!row.datupd)?"Entré le: "+row.datin +' par: ' +row.agent:"Édité le: "+row.datupd +' par: ' +row.usrupd}
                    onBlur={e => handleOnEdit(e,row,'serial')}
                  />
              </div>
             );
           },
      },
      {
        name: "FOURNISSEUR",
        minWidth:'150px !important',
        selector: row=>row.frns,
        sortField: "frns",
        sortable: true,
        conditionalCellStyles: [
          {
            when: row => row.frns !== row.__previousFrns,
          },
        ],
        cell: (row) => { 
            return (
              <div>
                {(row.isbanned)?
                <AutocompleteS
                service={service} action={'upd'} 
                defaultValue={row.frns} row={row} list={frns} 
                id={'idfrns'} name='frns' attr='name'
                onOpen={handleOpenFrns} handleAlert={handleAlert}/>:row.frns}
            </div>
            );
        },
      },
      {
        name: "PRODUIT",
        minWidth:'280px !important',
        selector: row=>row.prod,
        sortField: "prod",
        sortable: true,
        conditionalCellStyles: [
          {
            when: row => row.prod !== row.__previousProd,
          },
        ],
        cell: (row) => { 
            return (
              <div>
                {(row.isbanned)?
                  <AutocompleteS
                  service={service} action={'upd'} 
                  defaultValue={row.prod} row={row} list={prod} 
                  id={'idprod'} name='prod' attr='name'
                  onOpen={handleOpenProd} handleAlert={handleAlert}/>:row.prod}
              </div>
             );
        },
      },
      {
        name: "LOT",
        minWidth:'150px !important',
        selector: row=>row.lot,
        sortField: "lot",
        sortable: true,
        conditionalCellStyles: [
          {
            when: row => row.lot === row.__previousLot,
          },
        ],
        cell: (row) => { 
            return (
              <div>
                {(row.isbanned)?
                <AutocompleteS
                  service={service} action={'upd'} 
                  defaultValue={row.lot} row={row} list={lot} 
                  id={'idlot'} name='lot' attr='name'
                  onOpen={handleOpenLot} handleAlert={handleAlert}/>:row.lot}
              </div>   
           );
              }
      },
      { 
        right:true,
        cell: (row) => {
            return (
                <div className="rowGrid" id='twoIcons' >
                     <div onClick={()=>flagMan(row)} title="Liberer">{(row.isbanned)?<CheckCircleOutline className={classes.greenIcon} fontSize="small"/>:<NotInterested className={classes.redIcon} fontSize="small"/>}</div>
                     <div title="Supprimer"><Delete className={classes.redIcon}  fontSize="small" onClick={()=>{handleDeleteItem(row)}} /></div> 
                </div>
             );
        },   
      },  
    ],[handleOnEdit,flagMan,frns,handleDeleteItem,libre,lot,prod,del,handleSetData,handleOpenFrns,handleOpenLot,handleOpenProd,classes,check,ch,datach,HandleChange,handleAlert]);
   
  const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage+"&search="+search)=>{
      setLoading(true);
      const response=await librery.axiosSort(column,sortdirection,page,size+'&mod=MYDAY&digSearch='+digSearch,service)
      if(response.data.err&&response.data.err!=="-1"){
        handleAlert(true,'error',response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
        setLoading(false);
      })   
    }  
  }

  //recherche serverside
  const handleSearch=async(size = perPage)=>{
    var elem = document.getElementById("searchBar")
    if(elem) {
      elem.focus();
      elem.select()
    }
    setLoading(true);
    setCheck([])
    setCh(false)
    const recherche=search;
    setCurrentPage(1)
    const response=await librery.axiosSearch(service,recherche+filter,1,size,"get")
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
      if(response.data.data!==undefined){
      setData(response.data.data);
      setDatach(response.data.data);
      setTotalRows(response.data.recordsFiltered);
      }else setData([])
    })   
    setLoading(false);
    return recherche
  }
}

function liveSearch(e,search){
  if(param.liveSearch&&search.length>3){
     e.preventDefault();
     handleSearch();
  }else{ if (e.keyCode === 13) {
            e.preventDefault();
            handleSearch();
          }
  }
}
function liveSearch2() {
  handleSearch();
}

//valider formulaire avec touche clavier 
const detectTouche= (e)=>{
  if (e.keyCode === 13 && (e.target.name==="addBtn")) {
     // e.preventDefault();
     //do validation mannually
      setErrorFile(false);
      setErrorFrns(false);
      setErrorProd(false);
      setErrorLot(false);
      if(selectedFile === '') setErrorFile(true);
      if(selectP === '') setErrorProd(true);
      if(selectF === '') setErrorFrns(true);
      if(selectL === '') setErrorLot(true);
      if(selectF===''||selectL===''||selectP===''){
        handleAlert(true,'success',"Veuillez remplir tous les champs!")
      }
    }
  }

 //supprimer un ensemble d'elemets
const HandleDeletItems=useCallback(async()=>{
  const ids=[];
  if(check.length!==0){
    const x=window.confirm("Êtes-vous sure de supprimer ces digits?");
    check.map(e=>{
      if(checkInfo[e]) ids.push(e)
      return true
    })
    if(ids.length>0){
      if(x){
      const response=await Gfunc.axiosPostAction(service ,"deldigs",{ids:ids})
      if(response.data.err&&response.data.err!=="-1"){
        handleAlert(true,'error',response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
        handleAlert(true,'success','Les digits sélectionnés sont supprimés.')
        setLibre(check)
        ids.map((id)=>{
          const ind= data.findIndex(obj=>obj.DT_RowId==="c"+id)
          data.splice(ind,1)
         // Gfunc.removeItemsByAtt(data, id,"DT_RowId")
          return true
        })
        setCh(false)
        setCheck([]);
      })
    }
  }}else handleAlert(true,'error','Vous ne pouvez pas supprimer les digits libres!')
}else handleAlert(true,'error','Veuillez sélectionner au moins un élément!')
},[Gfunc,check,data,handleAlert,checkInfo])

// liberer multiple ligne
const handleLiberer=useCallback(async()=>{
  const ids=[];
  if(check.length!==0){
    check.map(e=>{
      if(checkInfo[e]) ids.push(e)
      return true;
    }) 
    if(ids.length!==0)  {
      const response=await Gfunc.axiosPostAction(service ,"releasedigs",{ids:ids})
      if(response.data.err&&response.data.err!=="-1"){
        handleAlert(true,'error',response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
        handleAlert(true,'success','Les digits selectionnés sont liberés avec succés.')
        setLibre(check)
        let dat=[...data]
        ids.map((id)=>{
          const index=Gfunc.getIndex(data,'c'+id,'DT_RowId')
           if(index>=0){
             dat[index].isbanned=false
           }
         return true
        })
        setData(dat)
        setCh(false)
        setCheck([]);
      })
    }
  }else handleAlert(true,'error',"Les digits sélectionnés sont déjà libérés!")
  }else handleAlert(true,'error','Veuillez sélectionner au moins un élément!')
 },[check,Gfunc,data,checkInfo,handleAlert])

   //handle liberer All
   const handleLibererAll=useCallback(async()=>{
    const response=await Gfunc.axiosPostAction(service ,"releaseall")
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else{
      if(response.data.jData>1){
        handleAlert(true,"success",response.data.jData+" éléments ont été liberés")
      }
      if(response.data.jData===1){
        handleAlert(true,"success",response.data.jData+" élément a été liberé")
      }
      if(response.data.jData===0){
        handleAlert(true,"warning"," Aucun élément n'a été liberé")
      }
      fetch(1);
    }
},[handleAlert,Gfunc,service,fetch])

//code jQuery pour boucler le focus des elements
$('form').each(function(){
  var list  = $(this).find('input*[tabindex],#last*[tabindex]').sort(function(a,b){ return a.tabIndex < b.tabIndex ? -1 : 1; }),
      first = list.first();
  list.last().on('keydown', function(e){
      if( e.keyCode === 9 ) {
          first.focus();
          return false;
      }
  });
});
 
const  onFileChange = e => {
     SetselectedFile(e.target.files[0]) 
     if(selectedFile ==null)
      handleAlert(true,'error',"Aucun fichier disponible")
  };
 
return (
    <div className="content">
       <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
       <BackdropCompo className={classes.backdrop} open={loadingSubmit}/>
       <fieldset className="fieldsetTop" id="charge">
          <legend>Ajouter digits</legend>
        <ValidatorForm  noValidate ref={useRef(null)} onSubmit={uploadFile} onKeyDown={e=>detectTouche(e)}  >
           <div className="rowGrid" id="row-1">            
           <Autocomplete
                                //key={"f"+reset}
                                disableClearable
                                className="smallAuto"
                                onChange={(e,newValue) =>{setSelectF(newValue.id)}} 
                                onFocus={handleOpenFrns}
                                //value={(selectF!=='')?(selectF):null}
                                options={frns}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) => option.id === value.id}
                                renderInput={(params) => (
                                <TextField
                                    error={errorFrns}
                                    {...params}
                                    inputProps={{...params.inputProps,tabIndex:'1'}}
                                    label="Fournisseur..."
                                    size="small" 
                                    variant='outlined'
                                    autoFocus
                                    //helperText={(errorFrns)?"Veuillez sélectionner un Fournisseur":null}
                                    required/>
                         )} />
                         <Autocomplete
                                //key={"l"+reset}
                                disableClearable
                                className="smallAuto"
                                onChange={(e,newValue) =>{setSelectL(newValue.id)}} 
                                onFocus={handleOpenLot}
                               // value={(selectL!=='')?(selectL):null}
                                options={lot}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) => option.id === value.id}
                                renderInput={(params) => (
                                <TextField
                                    error={errorLot}
                                    {...params}
                                    inputProps={{...params.inputProps,tabIndex:'2'}}
                                    label="Lot..."
                                    size="small" 
                                    variant='outlined'
                                    //helperText={(errorLot)?"Veuillez sélectionner un Lot":null}
                                    required/>
                          )} />
                              <Autocomplete
                                key={"p"+reset}
                                disableClearable
                                id="iProd"
                                className="smallAuto"
                                onChange={(e,newValue) =>{setSelectP(newValue.id)}} 
                                onFocus={handleOpenProd}
                               // value={(selectP!=='')?(selectP):null}
                                options={prod}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) => option.id === value.id}
                                renderInput={(params) => (
                                <TextField
                                    error={errorProd}
                                    {...params}
                                    inputProps={{...params.inputProps,tabIndex:'3'}}
                                    label="Produit..."
                                    size="small" 
                                    variant='outlined'
                                    //helperText={(errorProd)?"Veuillez sélectionner un Produit":null}
                                    required/>
                                )} />
             <TextField error={errorFile} required className="smallText" label="Fichier" variant="outlined" size="small" value={(selectedFile!=="")?selectedFile.name:"Importer un fichier."}  inputProps={{ className:classes.input}} disabled/>
                <input id="fileSelect" onChange={onFileChange} accept=" .csv" style={{ display: 'none' }} type="file"/>
                <label htmlFor="fileSelect">
                  <Button variant="contained"  id={(selectedFile)?'lastlast':'last'} component="span" tabIndex='4' color="primary" className={classes.smallBtn} startIcon={<Description/>}>
                    Choisir un fichier
                  </Button>
                </label> 
                {(selectedFile)?<Button className={classes.smallBtn} tabIndex='5' id="last" variant="contained"  type='submit' color="primary" startIcon={<Add/>}>Ajouter </Button>:<></>}
         </div>
        </ValidatorForm>
        </fieldset>
        <Paper className={classes.paperBody} elevation={3}>
        <div className='table-header'>
          <div className='button-flex-wrapper'>
                <Button  className={classes.topTabBtn}  variant="contained"  type='submit' id="bouton" color="primary" onClick={ HandleDeletItems } startIcon={<Delete />}>Supprimer</Button>  
                <Button   className={classes.topTabBtn}  variant="contained"  color="primary" onClick={handleLiberer} startIcon={<Iconn path={mdiCheckboxMarkedCircleOutline} color="black" size="20px"/>} >Liberer</Button>  
            <Button   className={classes.topTabBtn}  variant="contained"  color="primary" onClick={handleLibererAll} startIcon={<Iconn path={mdiCheckboxMultipleMarkedCircleOutline} color="black" size="20px"/>} >Liberer tous</Button>          </div>
          <div className='search'>
                <Box sx={{ display: 'grid', alignItems: 'center',gridTemplateColumns:"auto auto 150px",gridGap:"3px"}}>
                      <Search className={classes.blackIcon} sx={{ color: 'action.active' ,mr: 1, my: 0.5 }} onClick={(e)=>liveSearch2(e)}/>
                      <TextField  className="smallText" id="searchBar" variant="outlined"  label="Recherche" value={search} size="small" margin='dense' onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} />
                      <TextField className="smallText" id="searchBar" variant="outlined" label="Digit" value={digSearch} size="small" margin='dense' onKeyUp={e => liveSearch(e)} onChange={e => setdigSearch(e.target.value)} />
                </Box>
          </div>
        </div>
        <div style={{margin:"0 1%",minHeight:"20px",textAlign:"center"}}>{(check.length)?"Éléments sélectionnés: "+check.length:" "}</div>
        {
        (data.length!==0||doSearch)?
         <div>
           <DTable
             currentPage={currentPage}
             data={data}
             columns={columns}
             loading={loading}
             fetch={fetch}
             handleOnSort={handleOnSort}
             resetDP={resetDP}
             totalRows={totalRows}
             perPage={perPage}
             setPerPage={setPerPage}
             setCurrentPage={setCurrentPage}
             paginationRowsPerPageOptions={[10,25,50,75,100]}
             customStyles={customStyles}
             conditionalRowStyles = { conditionalRowStyles }/>
             </div>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData || records===0)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
        </Paper>
    </div>    
  );
}