import React,{ useState, useEffect ,useCallback,useMemo,useRef} from "react";
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import {Paper,Button,TextareaAutosize,Tooltip, Box, FormControlLabel, Checkbox} from '@material-ui/core';
import {Search,Today,GetApp, ListAlt} from '@material-ui/icons';
import { useStyles,textAreaEdit} from '../../css/muiStyle';
import {conditionalRowStyles,customStyles, numberColumn}from '../../css/datatableStyle'
import { MuiPickersUtilsProvider,KeyboardDatePicker} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { fr } from "date-fns/locale";
import "../../css/exploitation.css"
import ReactExport from "react-export-excel";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { AlertCompo,AutocompleteS,InputNbrEditable,NoDataComponent,NotAllowedComponent } from "../../js/components";
import { Link } from "react-router-dom";
import DTable from "../../compo/DTable";
import { mdiDelete } from "@mdi/js";
import Iconn from "@mdi/react";

export default function Exp_his (props){
  const param=props.param
  const Gfunc=require('../../Gfunc')
  const librery=require('../../js/data_table_librery')
  const [data, setData] = useState([]);
  const [prodL, setProdL] = useState([]);
  const [prodStkL, setProdStkL] = useState([]);
  const [selectedDate, setSelectedDate] = useState(Gfunc.LastXDay(10));
  const [selectedDate2, setSelectedDate2] = useState(new Date());
  const [note,setNote] = useState('');
  const [fProd,setFProd] = useState('');
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [search,setSearch]=useState('');
  const service='_cso.php'
  const classes=useStyles();
  const [called,setCalled]=useState(false);
  const [noData,setNoData]=useState(false);
  const [doSearch,setDoSearch]=useState(false);
  const [notAllowed,setNotAllowed]=useState(false);
  const [errMessage,setErrMessage]=useState('');
  const [records,setRecords]=useState('');
  const [display,setDisplay]=useState(true);
  const [Item, setItem] = useState('');
  const [selecto, setSelecto] = useState('');
  const labelText1="Date début"
  const labelText2="Date fin"
  const labelText3="Produit"
  const [severity, setSeverity] =useState("info");
  const [open, setOpen] =useState(false);
  const [messageA, setMessageA] =useState('');
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  const filter = "&search="+search+'&date1='+Gfunc.formatDate(new Date(selectedDate))+'&date2='+Gfunc.formatDate(new Date(selectedDate2))+"&produit="+fProd
  
  //for selection
  const [ch, setCh] = useState(false);
  const [datach, setDatach] = useState([]);
  const lodashClonedeep = require('lodash.clonedeep');
  const [check,setCheck]=useState([])
  const [checkInfo,setCheckInfo]=useState({})

   //handle alerts properties
   const handleAlert=useCallback((open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  },[])


  //fetch products
    const fetchProd= useCallback(async(serv="_prd.php", act = "gets",act2="getspromo") => {
      const response = await Gfunc.axiosGetAction(serv,act);
      const response2 = await Gfunc.axiosGetAction(serv,act2);
      if(response.data.err&&response.data.err!=="-1"){
        handleAlert(true,'error',response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
        if(response2.data.err&&response.data.err!=="-1"){
          handleAlert(true,'error',response.data.err)
        }else{
          if(response2.data.jData&&response.data.jData)
          setProdL(response2.data.jData.concat(response.data.jData));
          else if(response2.data.jData) setProdL(response2.data.jData);
               else setProdL(response.data.jData);
        }    
      })
    }
    },[Gfunc,handleAlert])

  //fetch products
    const fetchProdStk= useCallback(async(id) => {
      setProdStkL([])
      const response = await Gfunc.axiosPostAction("_prd.php","getprdstocks",{prodId:id});
      if(response.data.err&&response.data.err!=="-1"){
        handleAlert(true,'error',response.data.err)
      }else{
         if(response.data.jData){
          setProdStkL(response.data.jData)
         }
      }
      
    },[Gfunc,handleAlert])

   //fetch produit handler
const handleOpenProd = useCallback(async () => {
    if(prodL.length===0)
    fetchProd()
    },[prodL,fetchProd])

 //fetch 
  const fetch= useCallback(async(page, size = perPage) => {
    setCalled(true)
    setLoading(true);
    const response=await librery.axiosGet2(page+filter,service,'gethisto',size)
    if(response.data.err&&response.data.err!=='-1'){
       setNotAllowed(true)
       setErrMessage(response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
      setRecords(response.data.recordsFiltered)
      setDisplay(response.data.margeAccess)
      if(response.data.data!==undefined){
        setData(response.data.data);
        setDatach(response.data.data)
        setTotalRows(response.data.recordsFiltered);  
        setDoSearch(true)
        setNoData(true)
        setLoading(false)
      }else {setData([]);setNoData(false);}   
    })  
  setLoading(false);  
  return response;
  },[perPage,service,Gfunc,librery,filter])


  //fetch 
  const fetch2= useCallback(async(page, size = perPage) => {
    const response=await librery.axiosGet2(page+filter,service,'gethisto',size)
    if(response.data.err&&response.data.err!=='-1'){
       setNotAllowed(true)
       setErrMessage(response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
      setRecords(response.data.recordsFiltered)
      setDisplay(response.data.margeAccess)
      if(response.data.data!==undefined){
        setData(response.data.data);
        setDatach(response.data.data)
        setTotalRows(response.data.recordsFiltered);  
        setDoSearch(true)
        setNoData(true)
        setLoading(false)
      }else {setData([]);setNoData(false);}   
    })  
  return response;
  },[perPage,service,Gfunc,librery,filter])


  useEffect(() => {
      if(!called)
      fetch(1,perPage);
  }, [fetch,perPage,called]);
  
   //handle update note
 const handleOnEditNote=useCallback(async(note,row,att,action)=>{
  const edt1=note
  var edt=Gfunc.DeleteSpace(edt1);
  if(row[att]!==edt){
    const attrib=row[att];
    const response=await librery.axiosEdit2(service,action,row,edt,att) 
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
   }else{
    Gfunc.axiosResponse(response,()=>{row[att]=edt})
   }
    if(response.data.err) {row[att]=attrib}
  }else {row[att]=edt}
  setNote('')
  setSelecto('')
},[librery,Gfunc,handleAlert]);

   //modifier un champs avec un select
   const handleSelect=useCallback((row,att)=>{
    setSelecto(row[att])
    setItem(row.DT_RowId)
},[])


//for selection
const HandleChange=useCallback((e,row)=>{
  var checkk =[...check]
  const tmp=parseInt(row.DT_RowId.substr(1));
  if(e.target.checked===true){
    if(!checkk.includes((tmp))){
      checkk.push(parseInt(tmp))
      let inf=checkInfo; inf[tmp]=(row.status==="Attente")
      setCheckInfo(inf)
    }
 }
 else{
     let index = checkk.indexOf(tmp);
     if(index>-1)checkk.splice(index,1)
 }
 setCheck(checkk)
},[check,checkInfo])

//for selection
const Ch=({datach,setCheck,ch})=>{

  const test=(val)=>{
    setCh(val)
    if(val===true){
      let checkk=[]
      //let inf=checkInfo; 
      lodashClonedeep(datach).map(e=>{
         let tmp=parseInt(e.DT_RowId.substr(1))
         checkk.push((tmp))
         //inf[tmp]=(e.status==="Attente")
         return true
      })
      //setCheckInfo(inf)
      setCheck(checkk)
    }else{
      //setCheckInfo({})
      setCheck([])
    }  
  }
  return(  
    <FormControlLabel control={<Checkbox  className={classes.checkStelectableRow} defaultChecked={ch}
                  onChange={e=>test(e.target.checked)} inputProps={{ 'aria-label': 'controlled' }}/>}
               />
  )
}

const handleDeleteItem=useCallback(async(row)=>{
  const x=window.confirm("Êtes-vous sure de supprimer ces consommations?");

 if(x){ const response=await Gfunc.axiosPostAction('_cso.php',"del",{ids:[row.DT_RowId.substr(1)]})
  if(response.data.err&&response.data.err!=='-1'){
    handleAlert(true,"error",response.data.err)
  }else
   Gfunc.axiosResponse(response,()=>{
    if(response.data.jData){
        const objIndex =  data.findIndex((obj => obj.DT_RowId === row.DT_RowId )); 
        const nouveauTableau = [...data]; 
        nouveauTableau.splice(objIndex,1)
        setData(nouveauTableau);
     }
    })}
},[Gfunc,service,data])


 //supprimer un ensemble d'elemets
 const HandleDeletItems=useCallback(async()=>{
  if(check.length!==0){
    const x=window.confirm("Êtes-vous sure de supprimer ces consommations?");

      if(x){
      const response=await Gfunc.axiosPostAction('_cso.php',"del",{ids:check})
      if(response.data.err&&response.data.err!=="-1"){
        handleAlert(true,'error',response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
        handleAlert(true,'success','Les consommations sélectionnées sont supprimées.')
        fetch2(currentPage,perPage)
        setCh(false)
        setCheck([]);
      })
    }
  }
}else handleAlert(true,'error','Veuillez sélectionner au moins un élément!')
},[Gfunc,check,data,fetch,currentPage,perPage,handleAlert])


const columns =useMemo(
    () => [ 
      {//for selection
        name:<Ch datach={datach} setCheck={setCheck} ch={ch}/>,
        width:"55px !important",
        cell: (row) => {
          return (
            <div >
               <FormControlLabel control={<Checkbox checked={check.includes(parseInt(row.DT_RowId.substr(1)))} className={classes.checkStelectableRow}
                  onChange={e=>HandleChange(e,row)} inputProps={{ 'aria-label': 'controlled' }}/>}
               />
            </div>
           );
         },
      },
      {
        width:"24px !important",
        cell: (row) => {
          return (
            <Tooltip  title="Historique client">
               <Link className="invisibleLink"  to={{pathname: "/c/ch?c="+/*CryptoJS.AES.encrypt(*/row.clientId/*), "Secret Passphrase")*/+"&cn="+row.client}} target="_blank">
                 <ListAlt fontSize="small"  className={classes.blackIcon}  />
               </Link>
            </Tooltip>
           ); 
         },
      },    
      {
        name:'date',  
        minWidth:'150px !important',
        sortable: true,
        sortField: "date",
          cell: (row) => {
            return (
              <div>
                  {row.date+" > "+row.hour}
              </div>
             );
           },
      },
      {
        name:'client', 
        selector: row=>row.client,
        minWidth:'180px !important',
        sortField: "client",
        sortable: true
      },
      {
        name:'Produit', 
        selector: row=>row.produit,
        minWidth:'300px !important',
        sortField: "produit",
        sortable: true
      },
      (display)&&{
        name:'Achat', 
        selector: row=>row.achat,
        minWidth:'140px !important',
        style:numberColumn,
        sortField: "achat",
        sortable: true,
        cell: (row) => {
            return (
              <AutocompleteS
                    service={service} action={'updhist'} 
                    defaultValue={row.achat} row={row} list={prodStkL} 
                    id={'pdetId'} name='achat' attr='achat'
                    handleResponse={(resp)=>{
                      if(resp!==undefined&&resp['jData']){    
                         Gfunc.updateElem('m'+row.DT_RowId,resp.jData.marge)
                      }}}
                    onOpen={()=>fetchProdStk(row.prodId)} handleAlert={handleAlert}/>
             );
           },
      },
      {
        name: "Bundle",
        selector: row=>row.bundle,
        minWidth:'150px !important',
        sortField: "bundle",
        sortable: true,
      },
      {
        name: "Numéro",
        selector: row=>row.numero,
        width:'110px !important',
        sortField: "numero",
        sortable: true,
      },
      {
        name: "prix",
        selector: row=>row.prix,
        minWidth:'150px !important',
        style:numberColumn,
        sortable: false,
        cell: (row) => {
            return (
              <div>
                {(row.is_pct)?row.prix+"%":new Intl.NumberFormat().format(row.prix)} 
              </div>
             );
           },
      },
      {
        name:'qte.', 
        selector: row=>row.quantite,
        minWidth:'100px !important',
        style:numberColumn,
        sortField: "quantite",
        sortable: true,
        cell: (row) => {
            return (
              <div>
                {new Intl.NumberFormat().format(row.quantite)} 
              </div>
             );
           },
      },
      {
        name:'Montant', 
        selector: row=>row.montant,
        minWidth:'140px !important',
        style:numberColumn,
        sortField: "montant",
        sortable: true,
        cell: (row) => {
            return (
              <div key={Math.random()} >
                <InputNbrEditable
                                  service={service} action='updhist' min={0}
                                  row={row} att="montant" value={row.montant}
                                  handleAlert={handleAlert} 
                                  traetResp={(resp)=>{
                                    if(resp!==undefined&&resp['jData']){    
                                      Gfunc.updateElem('m'+row.DT_RowId,resp.jData.marge)
                                    }

                                  }}
                              />
              </div>
    
             );
           },
      },
      (display)&&{
        name:'Marge', 
        selector: row=>row.marge,
        minWidth:'120px !important',
        style:numberColumn,
        sortField: "marge",
        sortable: true,
        cell: (row) => {
            return (
              <div id={"m"+row.DT_RowId}>
                {new Intl.NumberFormat().format(row.marge)} 
              </div>
             );
           },
      }, 
      {
        name: "NOTE",
        selector: row=>row.note,
        minWidth:'250px !important',
        sortable: true,
        sortField:'note',
        cell: (row) => {
          return (
            <div className="note-container">
            { (row.note===selecto && row.DT_RowId===Item)?
            <ClickAwayListener onClickAway={()=>{(note)?handleOnEditNote(note,row,"note",'updhist'):setSelecto('')}}>
             <TextareaAutosize style={textAreaEdit} autoFocus onFocus={(e)=>{ e.target.value=note}}  onChange={(e)=>{setNote(e.target.value);}}
             /></ClickAwayListener>:
             (((row.note&&row.note.match(/\n/g)) || []).length>=1)?
             <Tooltip title={row.note.replaceAll("\n", "/ ")}>
                 <div className="content-editable" onClick={()=>{setNote(row.note);handleSelect(row,'note')}}>{row.note.substring(0, row.note.indexOf("\n"))+"..."}</div>
             </Tooltip>:
             <Tooltip title={(row.note)?row.note:''}>
             <div className="content-editable" onClick={()=>{setNote(row.note);handleSelect(row,'note')}} >{row.note}</div></Tooltip>}
             </div>
           );
         },
    },
    {
      right:true,
      cell: (row) => {         
        return (
          <div  className="rowGrid" id='twoIcons'>
           <Tooltip   title="Supprimer"><Iconn path={mdiDelete}  onClick={()=>handleDeleteItem(row)} size="20px" color="red"/></Tooltip > 
         </div>
         );
       },
    }  
  ],[Gfunc,note,handleSelect,Item,selecto,handleOnEditNote,prodStkL,fetchProdStk,handleAlert,classes.blackIcon,display,handleDeleteItem,HandleChange,datach,check]);
  
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };   
  const handleDateChange2 = (date) => {
    setSelectedDate2(date);
  };   

  // sort function handler
  const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage+"&search="+search)=>{
          setLoading(true);
          const response=await librery.axiosSort2(column,sortdirection,page,size,service,"gethisto&search="+search+'&date1='+Gfunc.formatDate(new Date(selectedDate))+'&date2='+Gfunc.formatDate(new Date(selectedDate2))+'&produit='+fProd)
          if(response.data.err&&response.data.err!=='-1'){
            handleAlert(true,'error',response.data.err)
         }else
          Gfunc.axiosResponse(response,()=>{
            setData(response.data.data);
            setTotalRows(response.data.recordsFiltered);
          })     
          setLoading(false);
  }
     //recherche serverside
   const handleSearch=async(size = perPage)=>{
    setLoading(true);
    setCurrentPage(1)
    const recherche=search;
    const response=await librery.axiosSearch(service,recherche,1,size,"gethisto&search="+search+'&date1='+Gfunc.formatDate(new Date(selectedDate))+'&date2='+Gfunc.formatDate(new Date(selectedDate2))+'&produit='+fProd)
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
      setSearch('')
   }else
    Gfunc.axiosResponse(response,()=>{
      setRecords(response.data.recordsFiltered)
      if(response.data.data!==undefined){
          setData(response.data.data);
          setTotalRows(response.data.recordsFiltered);
          setLoading(false);
          setNoData(true)
          setDoSearch(true)
      }else {setData([])}
    })  
    setLoading(false); 
    return recherche
  }

  //valider formulaire avec touche clavier 
    const detectTouche= (e)=>{
   
      }
  

  function liveSearch(e,search){
    if(param.liveSearch&&search.length>3){
      e.preventDefault();
      handleSearch();
    }else{ if (e.keyCode === 13) {
              e.preventDefault();
              handleSearch();
            }
    }
  }
  function liveSearch2() {
    handleSearch();
  }





  
  return (
    <div className="content">
        <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
        <fieldset className="fieldsetTop" id="hist">
          <legend>Afficher l'historique de consommation</legend>
        <ValidatorForm noValidate id="formAdd" ref={useRef(null)} onSubmit={()=>fetch(1)} onKeyPress={e=>detectTouche(e)}  >
            <div className="rowGrid" id="row-1">
                <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                label={labelText1}
                                size="small"
                                className='smallText'
                                KeyboardButtonProps={{style:{padding:"0px"}}}
                                inputVariant="outlined"
                                variant="inline"
                                maxDate={new Date()}  
                                invalidDateMessage="Le format de la date est incorrect"   
                                maxDateMessage="La date ne doit pas être supérieure à la date maximale"
                                format="dd/MM/yyyy"
                                name="iDate"
                                id="iDate"
                                orientation='landscape'
                                keyboardIcon={<Today className={classes.blackSmallIcon}/>}
                                value={selectedDate}
                                onChange={handleDateChange} />                                
                                <KeyboardDatePicker
                                size="small"
                                className='smallText'
                                KeyboardButtonProps={{style:{padding:"0px"}}}
                                inputVariant="outlined"
                                variant="inline"
                                maxDate={new Date()}  
                                invalidDateMessage="Le format de la date est incorrect"   
                                maxDateMessage="La date ne doit pas être supérieure à la date maximale"
                                format="dd/MM/yyyy"
                                name="iDate"
                                id="iDate"
                                orientation='landscape'
                                label={labelText2}
                                keyboardIcon={<Today className={classes.blackSmallIcon}/>}
                                value={selectedDate2}
                                onChange={handleDateChange2} />                                
                </MuiPickersUtilsProvider> 
                <Autocomplete
                            disableClearable
                            id='iProd'
                            onChange={(e,newValue) =>setFProd(prodL[Gfunc.getIndex(prodL,newValue,"name")].id)}
                            onFocus={handleOpenProd}
                            options={prodL.map((option) => option.name)}
                            getOptionSelected={(option, value) => option.id === value.id}
                            className='smallAuto'
                            renderInput={(params) => (
                                <TextValidator  
                                label={labelText3} {...params} value={(fProd)?prodL.find(x => x.id ===fProd).name:null} color="primary" variant="outlined" size="small" autoFocus/>
                            )}/> 
              <Button className={classes.smallBtn} variant="contained" type='submit' id="bouton" name="addBtn" color="primary" startIcon={<Search />}>Rechercher</Button>
             
              <ExcelFile element={ <Button  id='Row-Button1' className={classes.smallBtn} variant="contained" type='submit' name="addBtn1" color="primary" title='Exporter les Données' startIcon={<GetApp/>}>Exporter</Button> }>
               <ExcelSheet data={data} name="Exploitation (Historiques)"  >
                  <ExcelColumn label="Date" value="date" />
                  <ExcelColumn label="Client" value="client"/>
                  <ExcelColumn label="Produit" value="produit"/>
                  <ExcelColumn label="Prix" value="prix"/>
                  <ExcelColumn label="Numero" value="numero"/>
                  <ExcelColumn label="Quantité" value="quantite"/>
                  <ExcelColumn label="Montant" value="montant"/>
                  <ExcelColumn label="Marge" value="marge"/>
                  <ExcelColumn label="Agent d'ajout" value="agent_add"/>
                  <ExcelColumn label="Date d'ajout" value="date_add"/>
                  <ExcelColumn label="Agent d'édition" value="agent_upd"/>
                  <ExcelColumn label="Date d'édition" value="date_upd"/>
                  <ExcelColumn label="Note" value="note"/>
               </ExcelSheet>  
            </ExcelFile>  
         </div>
        </ValidatorForm>
        </fieldset>
        <Paper className={classes.paperBody} elevation={5}>
        <div className='table-header'>
          <div className='button-flex-wrapper'>
          <Button  className={classes.topTabBtn}  variant="contained" color="primary" onClick={ HandleDeletItems } startIcon={<Iconn path={mdiDelete} size="20px"/>}>Supprimer</Button>  
          </div>
          {(doSearch)?
          <div className='search'>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Search className={classes.blackIcon} sx={{ color: 'action.active', mr: 1, my: 0.5 }} onClick={(e)=>liveSearch2(e)}/>
                      <TextField className="smallText" id="searchBar" variant="outlined" label="Recherche" value={search} size="small" margin='dense' onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} />
                  </Box>
            </div>:null}
        </div>
        {(data.length!==0||doSearch)?
          <div>
            <DTable
             currentPage={currentPage}
             data={data}
             columns={columns}
             loading={loading}
             fetch={fetch}
             handleOnSort={handleOnSort}
             //resetDP={resetDP}
             totalRows={totalRows}
             perPage={perPage}
             setPerPage={setPerPage}
             setCurrentPage={setCurrentPage}
             paginationRowsPerPageOptions={[10,25,50,75,100]}
             customStyles={customStyles}
             conditionalRowStyles = { conditionalRowStyles }/>
            </div>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData || records===0)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
      </Paper>
    </div>    

  );
}