import React,{useState} from 'react';
import {TextField,Paper,Button,DialogActions,DialogContent,DialogTitle,Dialog} from '@material-ui/core';
import {GetApp} from '@material-ui/icons';
import {axiosResponse,axiosPostAction,getDate} from'../../../Gfunc'
import downloadCsv from 'download-csv'
import { useStyles,paperPropsSmallTall } from '../../../css/muiStyle';
import {AlertCompo}from '../../../js/components'
import Draggable from 'react-draggable';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Autocomplete } from '@material-ui/lab';

//extraction client
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Dig_con_extr(props) {
  
    const [open, setOpen] = React.useState(false);
    const nom="client"
    const labelText1="Client"
    const addMessage="Selectionner un "+nom
    const service="_cli.php"
    const [client,setClient]=useState('')
    const [clientN,setClientN]=useState('')
    const classes = useStyles();
    const [data, setData] = useState([]);
    
    const [severity, setSeverity] =useState("info");
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');

   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpenA(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpenA(false)
    }, 5000);
  }
    
    const handleClickOpen = () => { 
      console.log(props)
      if(props.ids.length>0){
        if(props.ids.every((x) => x.idprod === props.ids[0].idprod)){setOpen(true); 
        handleOpenSelect();}
        else props.handleAlert(true,'warning','Veuillez sélectionner des digits de même produits!')
      }
      else props.handleAlert(true,'warning','Veuillez sélectionner au moins un digit!')
    };
    const handleClose = () => { setOpen(false); setClient('') };

    const handleOpenSelect=async(event)=>{
      if(data.length===0)
        fetchClients();
    }
     //fetch clients
  const fetchClients= async() => {
    const response=await axiosPostAction(service,"gets",{})
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else{
    axiosResponse(response,()=>{
        setData(response.data.jData);
    })
  } 
  }
  
  //extarct digits
  const handleSubmit=async()=>{
    const ids=[]
    if(props.ids.length!==0){
      props.ids.map((item)=>{
         ids.push(item.DT_RowId.substr(1)) 
        return true;
      })
     } 
    const response=await axiosPostAction("_dig.php","extractdigs",{idcli:client,ids:ids})
    var ext=[]
    var result=[]
    if(client==='') handleAlert(true,'warning',"Veuillez sélectionner un client d'abord!")
    else {
        const headers = {  digit: "DIGIT" , serial: "SERIAL" ,prod: "PRODUIT" }; 
        if(response.data.err&&response.data.err!=='-1'){
             handleAlert(true,'error',response.data.err)
        }else{
        axiosResponse(response,()=>{
                  if (response.data.jData.length!==0){
                    response.data.jData.map(idss=>{
                      const objIndex =props.ids.findIndex((obj => obj.DT_RowId.substr(1) === idss.toString()));
                      ext.push(props.ids[objIndex])
                      return true
                    })
                    //filter only the wanted columns
                    ext.map((ex)=>{
                      result.push( (({  digit,serial, prod }) => ({ digit, serial, prod }))(ex));
                      return true;
                    })
                    downloadCsv(result, headers, clientN+"_"+getDate()+"_"+ext[0].prod+"_"+ext.length+".csv");
                   // props.handleClearRows();
                    props.callback();
                    setOpen(false);
                    setClient('')
                    props.Vider([])
                }else {props.handleAlert(true,'error','Les éléments sélectionnés sont déjà utilisés ou suspendus'); setOpen(false)}
        })
      }
    }
      
  }
  
    
  return (
    <div>
      <Button  className={classes.topTabBtn}  variant="contained" color="primary" onClick={handleClickOpen} startIcon={<GetApp />} >Extraire</Button>
      <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmallTall}>
            <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >{addMessage}</DialogTitle>
            <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true}/>
            <DialogContent>
            <ValidatorForm  id="conExt" onSubmit={handleSubmit} onError={errors => console.log(errors)}  > 
                    <Autocomplete
                                disableClearable
                                className='smallAuto'
                                id="iClient"
                                onChange={(e,newValue) =>{
                                    setClient(newValue.id)
                                    setClientN(newValue.name)
                                  }
                                  } 
                                options={data}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) => option.id === value.id}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={labelText1}
                                    variant='outlined'
                                    margin='dense'
                                    size="small"
                                    autoFocus 
                                    required/>
                                )}/>
              </ValidatorForm>
            </DialogContent>
            <DialogActions>
                    <Button variant="contained" type='submit' form="conExt" color="primary">TELECHARGER </Button>
                    <Button variant="contained"  onClick={handleClose} color="secondary">FERMER</Button>
            </DialogActions>
        
      </Dialog>
      </div>
  );
}
