import React,{} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog} from '@material-ui/core';
import Draggable from 'react-draggable';
import { useStyles, paperPropsTicketDig, paperPropsTick } from '../../../css/muiStyle';



function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Dig_prt_tickPrint(props) {
    const classes=useStyles();
    const param=props.param
   
  return (
    <div>
      <Dialog
            open={props.open}
            onClose={props.handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={(props.data.reprintId!==null&&props.data.length!==0)?paperPropsTick:paperPropsTicketDig}
      >
        <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" ></DialogTitle>
            <DialogContent>
             {
              (props.data.length!==0)?
              <div style={(props.data.reprintId!==null)?{display:'grid',gridTemplateColumns:'47% 47%',gridGap:"50px"}:{}}>
               <div>
               <div id='logos-tiket'> 
                    <img src={param.urlimg+props.data.topLogo} alt='logo-mrq' style={{height:'40px'}}/>
                    <img src={param.urlimg+'logo_ar.png'} alt='logo-iraha' style={{height:'40px'}}/>
               </div>
               <div id='head-tiket'>
                  <div id='time-tiket'> 
                        <p >{props.data.printDate}</p>
                        <p >TID:{' '+props.data.tiketId}</p>
                  </div>
                  <div id='info-tiket'> 
                        <p >{props.data.printHour}</p>
                        <p>{props.data.printOwner}</p>
                  </div>
               </div>
               <div id='p2'>{props.data.nom}</div> 
               <div id='info-prd-tiket'> 
                     <div >TTC:{' '+props.data.prix+'Da'}</div>
                     <div style={{textAlign:'right',marginRight:"3px"}}>Expire:{' '+props.data.expire}</div>
                </div>
                <div id='serial-ticket'>
                     <p>N° Série: {props.data.serial}</p>
                </div>
                <div id='prod-logo'>
                     <img src={(props.data)?param.urlimg+props.data.centerLogo:param.urlimg+'4g.png'} alt='logo-prod' style={{height:'25px'}}/>
                </div>
                <div id='p2' style={{fontSize:'12px',fontWeight:'bold'}}>CODE</div>
                <p id='digit-ticket'>{props.data.digit}</p>
                <div id='p2'></div>
                <div id='foot1'>لتعبئة حسابكم للانترنيت ،اتصل بالرقم : 1500</div>
                <div id='foot2'>اتصالات الجزائر غير مسؤولة عن التذكرة</div>
                <div id='foot3'>الغير مطبوعة امامكم</div>
               </div>
               <div>
                   
                 {
                  (props.data.reprintId!==null)? 
                  <div>
                    <div id='logos-tiket'> 
                    <img src={param.urlimg+props.data.topLogo} alt='logo-mrq' style={{height:'40px'}}/>
                    <img src={param.urlimg+'logo_ar.png'} alt='logo-iraha' style={{height:'40px'}}/>
                  </div>
                 <div id='head-tiket'>
                  <div id='time-tiket'> 
                        <p >{props.data.reprintDate}</p>
                        <p >TID:{' '+props.data.tiketId}</p>
                  </div>
                  <div id='info-tiket'> 
                        <p >{props.data.reprintHour}</p>
                        <p>{props.data.reprintOwner}</p>
                  </div>
               </div>
               <div id='p2'>{props.data.nom}</div> 
               <div id='info-prd-tiket'> 
                     <div style={{textAlign:'left'}} >TTC:{' '+props.data.prix+'Da'}</div>
                     <div style={{textAlign:'right',marginRight:"3px"}}>Expire:{' '+props.data.expire}</div>
                </div>
                <div id='serial-ticket'>
                     <p>N° Série: {props.data.serial}</p>
                </div>
                <div id='prod-logo'>
                     <img src={(props.data)?param.urlimg+props.data.centerLogo:param.urlimg+'4g.png'} alt='logo-prod' style={{height:'25px'}}/>
                </div>
                <div id='p2' style={{fontSize:'12px',fontWeight:'bold'}}>Duplicata</div>
                <p id='digit-ticket'>{props.data.digit}</p>
                <div id='p2'></div>
                <div id='foot1'>لتعبئة حسابكم للانترنيت ،اتصل بالرقم : 1500</div>
                <div id='foot2'>اتصالات الجزائر غير مسؤولة عن التذكرة</div>
                 <div id='foot3'>الغير مطبوعة امامكم</div>
                  </div>
                  :null
                 }
               </div>
              </div>
              :<p style={{fontWeight:'bold',textAlign:'center'}}>Aucun Ticket.</p>
             } 
           </DialogContent> 
            <DialogActions>
            <Button variant="contained" onClick={props.handleClose} color="secondary">FERMER</Button>
            </DialogActions>
      </Dialog>
      </div>
  );
}
