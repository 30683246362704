import React, { useState } from 'react';
import { DialogActions, DialogContent, DialogTitle, Paper, Button, Dialog, TextField, Tooltip } from '@material-ui/core';
import Draggable from 'react-draggable';
import { axiosPostAction, axiosResponse, formatNumber } from '../../../Gfunc'
import { useStyles, paperPropsSmallTall } from '../../../css/muiStyle';
import { AlertCompo } from '../../../js/components'
import { mdiAccount, mdiCalendarToday, mdiCardBulleted, mdiCash, mdiCheck, mdiClose, mdiFileCog, mdiPackageVariant, mdiPackageVariantClosed, mdiStocking, mdiStore, mdiStorefront } from '@mdi/js';
import Iconn from '@mdi/react';
import { Chip, Divider } from '@mui/material';
//Add a new role
function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    )
}

function Exp_rfd_treat(props) {
    const [note, setNote] = useState('');
    const [open, setOpen] = React.useState(false);
    const addMessage = "Traiter la demande de remboursement"
    const labelText = "Note"
    const service = "_rfd.php"
    const classes = useStyles();
    const [severity, setSeverity] = useState("info");
    const [openA, setOpenA] = useState(false);
    const [messageA, setMessageA] = useState('');

    //handle alerts properties
    const handleAlert = (open, severity, message) => {
        setOpenA(open)
        setSeverity(severity)
        setMessageA(message)
        setTimeout(function () {
            setOpenA(false)
        }, 5000);
    }

    const handleClickOpen = () => {
        setNote('');
        setOpen(true);
    };
    const handleClose = () => { setOpen(false); };

    const handleSubmit = async (e, val) => {
        e.preventDefault();
        const x = window.confirm("Êtes-vous sûrs " + (val ? "d'accepter" : "de refuser") + " cette demande?");
        if (x) {
            const data = { note: note, requestId: props.row.DT_RowId.substr(1) }
            const response = await axiosPostAction(service, val ? "val" : "rej", data)
            if (response.data.err && response.data.err !== '-1') {
                handleAlert(true, 'error', response.data.err)
            } else {
                axiosResponse(response, () => {
                    setOpen(false);
                    if (response.data.jData) props.handleAlert(true, 'success', "La demande de remboursement a été " + (val ? "acceptée." : "réfusée."));
                    props.callback();
                })
            }
        }
    }
    return (
        <div>
            <Tooltip title="Accepter / Refuser">
                <Iconn path={mdiFileCog} onClick={handleClickOpen} size="20px" color="black" />
            </Tooltip>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                PaperProps={paperPropsSmallTall}
                id="rfdDiag"
            >
                <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >{addMessage}</DialogTitle>
                <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true} />
                <DialogContent id='rfdForm' >{/**/}
                    <Divider textAlign="center">
                        <Chip label="Demande" size="small" />
                    </Divider>
                    <div className='towLabels'>
                        <Tooltip title="Date de demande">
                            <div id="infos">
                                <Iconn path={mdiCalendarToday} size={"20px"} />
                                <span className="label">Date: </span>
                                <span className="value">{props.row.dateRequest}</span>
                            </div>
                        </Tooltip>
                        <Tooltip title="Agent">
                            <div id="infos">
                                <Iconn path={mdiAccount} size={"20px"} />
                                <span className="label">Agent: </span>
                                <span className="value">{props.row.userRequest}</span>
                            </div>
                        </Tooltip>

                    </div>
                    <br />
                    <Divider textAlign="center">
                        <Chip label="Consommation" size="small" />
                    </Divider>
                    <div className='towLabels'>
                        <div id="infos">
                            <Iconn path={mdiCalendarToday} size={"20px"} />
                            <span className="label">Date: </span>
                            <span className="value">{props.row.dateConso}</span>
                        </div>
                        <div id="infos">
                            <Iconn path={mdiAccount} size={"20px"} />
                            <span className="label">Agent: </span>
                            <span className="value">{props.row.userConso}</span>
                        </div>
                    </div>
                    <br />
                    <Divider textAlign="center">
                        <Chip label="Détail" size="small" />
                    </Divider>
                    <div id="infos">
                        <Iconn path={mdiStorefront} size={"20px"} />
                        <span className="label">Client: </span>
                        <span className="value">{props.row.customer}</span>
                    </div>

                    <div id="infos">
                        <Iconn path={mdiCardBulleted} size={"20px"} />
                        <span className="label">Produit: </span>
                        <span className="value">{props.row.product}</span>
                    </div>

                    <div className='towLabels'>
                        <div id="infos">
                            <Iconn path={mdiPackageVariantClosed} size={"20px"} />
                            <span className="label">Qte: </span>
                            <span className="value">{props.row.qte}</span>
                        </div>
                        <div id="infos">
                            <Iconn path={mdiCash} size={"20px"} />
                            <span className="label">Montant: </span>
                            <span className="value">{formatNumber(props.row.mount)}</span>
                        </div>
                    </div>


                    <br />
                    <TextField className='smallNote' variant="outlined" size="small" label={labelText} id="iNote" note="iNote" value={note}
                        onChange={e => setNote(e.target.value)}
                        multiline
                        maxRows={3}
                        minRows={3}
                        autoFocus
                        required />
                    { /*<div className='rfdButtons'>
                        <Tooltip title="Accepter">
                            <Button variant="contained" color="primary" className={classes.iconBtn} style={{ background: "green " }} onClick={(e) => handleSubmit(e, true)} ><Iconn path={mdiCheck} size="20px" /></Button>
                        </Tooltip>
                        <Tooltip title="Refuser">
                            <Button variant="contained" color="primary" className={classes.iconBtn} style={{ background: "red" }} onClick={(e) => handleSubmit(e, false)}><Iconn path={mdiClose} size="20px" /></Button>
                        </Tooltip>
    </div>*/}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" style={{/*{ background: "green ", color: "white" }*/ }} onClick={(e) => handleSubmit(e, true)} startIcon={<Iconn path={mdiCheck} size="20px" />}>Accepter</Button>
                    <Button variant="contained" color="primary" style={{/*{ background: "red", color: "white" }*/ }} onClick={(e) => handleSubmit(e, false)} startIcon={<Iconn path={mdiClose} size="20px" />}>Refuser</Button>
                    <Button variant="contained" onClick={handleClose} color="secondary">FERMER</Button>
                </DialogActions>

            </Dialog>
        </div>
    );
}
export default Exp_rfd_treat;