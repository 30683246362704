import React, {Component} from 'react';
import { Helmet } from 'react-helmet'
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import ErrorPage from './components/error';
import Login from './components/login';
import Pages from './components/pages';
import "react-data-table-component";
import { ThemeProvider } from "@material-ui/core";
import EmptyPage from './components/empty';
import './App.css';
import { createAppTheme } from './css/theme.js';
var theme = null;

//const param =require("../public/param.json")
const version =require("./version.json")
const Gfunc=require('./Gfunc.js')

var Sims = null;

class App extends Component {
  state ={
    menu : {},
    mounted: false,
    loaded: false,
    isLogged:localStorage.getItem('isLoged'),
    param:null
  }
 
  //will be executed once the app is mounted 
 async componentDidMount(){
    var prm=await Gfunc.getParams();
   const link = document.getElementById('dynamic-favicon');
   theme = createAppTheme(!(prm.app === "2"));
   if (prm.app === "2") {
     link.href = "/favicon2.ico";
     import('./css/theme2.css')
       .then(() => {
         import('./css/global.css')
         import('./css/loginLogos2.css')
         console.log('styles2.css has been loaded');
       })
       .catch(err => {
         console.error('Error loading styles2.css', err);
       });
   } else {
     link.href = "/favicon.ico";
     import('./css/theme1.css')
       .then(() => {
         import('./css/global.css')
         import('./css/loginLogos1.css')
       })
       .catch(err => {
         console.error('Error loading styles1.css', err);
       });

   }
    if(localStorage.getItem('build')&&localStorage.getItem('build') !==version[0].build){
      localStorage.clear();
      window.location=("/");
      localStorage.setItem('build',version[0].build);
    }
    this.setState({
      mounted:true,
      param:prm
    })
  }  

 //recuperer le fichier json de menu 
 getMenu=async()=> {   
  try{ 
        const Gfunc=require('./Gfunc')
        const data=[]
        const response=await Gfunc.axiosGetAction('getMenu.php',"1")
        Gfunc.axiosResponse(response,()=>{
          Object.keys(response.data).map((key, index)=> {
            if(response.data[key]!==null){
              data[key]=response.data[key]
              data.length++
            }
            return true
          })   
        this.setState({loaded:true, menu:data })
        }) 
  }
  catch(error){
      localStorage.clear();
      window.location=("/");
  } 
}
  render(){
     // verify if the page is mounted then if it was loaded to getthe menu
    if(!this.state.loaded && this.state.mounted && this.state.isLogged==="true"){this.getMenu();}     
    if (this.state.mounted) {     
      return (
        <ThemeProvider theme={theme}>
          <Helmet>
          <title>{(this.state.isLogged!=='true')?this.state.param.webApp+" "+version[0].build:localStorage.getItem('title')}</title>
        </Helmet>
        <BrowserRouter >
          <Switch>
             <Route path="/error"  component={props => <EmptyPage {...props} />}/>
              {this.state.isLogged!=='true'?<Route exact path="/" component={props => <Login  {...props} param={this.state.param}/>}/>:<Pages menu={this.state.menu}  param={this.state.param}/>}
              {(this.state.loaded&&this.state.isLogged==="true")?<Pages menu={this.state.menu} param={this.state.param} />:<Route component={props => <ErrorPage />}/>}
          </Switch>
        </BrowserRouter>
        </ThemeProvider>
      );     
    } else {
      return <></>  
    }
  }
}
export default App;