import React,{ useState, useEffect ,useCallback,useMemo} from "react";
import ContentEditable from 'react-contenteditable';
import TextField from '@material-ui/core/TextField';
import DraggableDialog from "./modals/adm_usr_add";
import {Search,Lock,LockOpen} from '@material-ui/icons';
import {Paper, Box} from '@material-ui/core';
import RolDialog from './modals/adm_usr_rol'
import {conditionalRowStyles,customStyles}from '../../css/datatableStyle'
import axios from 'axios' ;
import { useStyles } from '../../css/muiStyle';
import '../../css/admin.css';
import {AutocompleteS,AlertCompo,NotAllowedComponent,NoDataComponent, TelUpd} from "../../js/components"
import DTable from "../../compo/DTable";

export default function Adm_usr (props){
  const param=props.param
  const Gfunc=require('../../Gfunc')
  const librery=require('../../js/data_table_librery')
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [called, setCalled] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [type, setType] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [search,setSearch]=useState('');
  const [,setIcon]=useState('');
  const[noData,setNoData]=useState(false);
  const[doSearch,setDoSearch]=useState(false);
  const[notAllowed,setNotAllowed]=useState(false);
  const [severity, setSeverity] =useState("info");
  const [open, setOpen] =useState(false);
  const [messageA, setMessageA] =useState('');
  const[errMessage,setErrMessage]=useState('');
  const[records,setRecords]=useState('');
  const service='_usr.php'
  const srv="user"
  const classes=useStyles()
  const [resetDP, setResetDP] = useState(false);
  const filter="&search="+search;
 
   //handle alerts properties
   const handleAlert=useCallback((open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  },[])

  const fetch= useCallback(async(page, size = perPage) => {
    setCalled(true);
    setLoading(true);
    const response=await librery.axiosGet(page,service,size+filter)
    if(response.data.err&&response.data.err!=="-1"){
        setErrMessage(response.data.err)
        setNotAllowed(true)
    }else{
    Gfunc.axiosResponse(response,()=>{
      setRecords(response.data.recordsFiltered)
      if(response.data.data!==undefined){
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
        setLoading(false);
        setNoData(true)
        setDoSearch(true)
      }else{
        setDoSearch(false)
        setData([])
      }
    })
  }
  },[librery,Gfunc,perPage,filter])

  const fetchType=useCallback(async() => {
    const response = await axios.get( param.urlService+service+'?do=gettyp',{ headers: Gfunc.getHeader()});
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else{
      Gfunc.axiosResponse(response,()=>{
        setType(response.data[1]);  
      })
    }    
  },[param.urlService,Gfunc,handleAlert])


  useEffect(() => {
    if(!called)
    fetch(1);
  }, [fetch,called]);
  
 const flagMan =useCallback(async(row)=>{
    const response= await Gfunc.axiosPostAction('flagman.php','1',({ srv:srv, id: row.DT_RowId.substr(1),att:'activ', val: row.activ }))
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else{    
     Gfunc.axiosResponse(response,()=>{ 
      if (response.data.jData) {
        const objIndex = data.findIndex((obj => obj.DT_RowId === row.DT_RowId));
        setIcon(row.DT_RowId)
        if(row.activ){
          data[objIndex].activ=0
        }else data[objIndex].activ=1
        setIcon('')   
 }else handleAlert(true,'error',response.data.err);  
   })
  }
},[Gfunc,data,handleAlert])
  
  //update field
  const handleOnEdit=useCallback(async(e,row,att)=>{
    const edt1=e.target.innerHTML
    const edt=Gfunc.DeleteSpace(edt1);
        if(row[att]!==edt){
          const attrib=row[att];
          const response=await librery.axiosEdit(service,row,edt,att) 
          if(response.data.err&&response.data.err!=="-1"){
            handleAlert(true,'error',response.data.err)
          }else
          Gfunc.axiosResponse(response,()=>{row[att]=edt;e.target.innerHTML=edt})
          if(response.data.err) {e.target.innerHTML=attrib}
        }else {e.target.innerHTML=edt}
  },[librery,Gfunc,handleAlert]);


   //update field webtelco
   const handleOnEditWebtelco=useCallback(async(e,row,att)=>{
    const edt1=e.target.innerHTML
    const edt=Gfunc.DeleteSpace(edt1);
       if(edt.length===3) {if(row['webtelco']!==edt){
          const attrib=row[att];
          const response=await librery.axiosEdit(service,row,edt,att) 
          if(response.data.err&&response.data.err!=="-1"){
            handleAlert(true,'error',response.data.err)
          }else
          Gfunc.axiosResponse(response,()=>{row[att]=edt;e.target.innerHTML=edt})
          if(response.data.err) {e.target.innerHTML=attrib}
        }else {e.target.innerHTML=edt}}
        else handleAlert(true,'warning',"Le code doit comporter 3 caractères!")
  },[librery,Gfunc,handleAlert]);

  //fetch user Type handler
const handleOpenType = useCallback(async () => {
    if(type.length===0)
    fetchType()
},[type,fetchType])

const handleLockIcon=(val)=>{
  setIcon(val);
}
useEffect((val) => {
handleLockIcon(val);
}, []);
 
const columns =useMemo(
    () => [  
      {
         name: "PSEUDO",
         selector:row=>row.nickname,
         minWidth:"150px !important",
         sortField: "nickname",
         sortable: true,
         cell: (row) => {
            return (
            <ContentEditable
            html={row.nickname}
            name="nickname"
            data-column="item"
            className="content-editable"
            disabled={false} 
            onBlur={e => handleOnEdit(e,row,'nickname')}
          />
             );
           }
      },
      {
        name: "NOM",
        selector:row=>row.nom,
        minWidth:"150px !important",
        sortField: "nom",
        sortable: true,
        cell: (row, column) => {
          return (
          <ContentEditable
          html={row.nom}
          name="nom"
          data-column="item"
          className="content-editable"
          disabled={false} 
          onBlur={e => handleOnEdit(e,row,'nom')}
        />
           );
         }
      },
      {
        name: "CONTACT",
        selector:row=>row.tel,
        minWidth:"120px !important",
        sortField: "tel",
        sortable: true,
        cell: (row, column) => {
          return (
            <div key={`tel-${row.DT_RowId}`}>
                {
                  <TelUpd
                    datein={row.date_in} agentin={row.usrin} dateupd={row.dateUpd} agentupd={row.agentUpd}
                    service={service} action='upd'
                    row={row} att="tel" value={row.tel} id='DT_RowId'
                    handleAlert={handleAlert}
                 />
                }
            </div>
           );
         }
      },
      {        
          name: "Type",
          selector:row=>row.typDesc,
          minWidth:"140px !important",
          sortField: "typDesc",
          sortable: true,
          cell: (row) => { 
              return (
              <AutocompleteS
              service={service} action={'upd'} 
              defaultValue={row.typDesc} row={row} list={type} 
              id={'typ'} name='typDesc' attr='descr'
              onOpen={handleOpenType} handleAlert={handleAlert}/>
               );
        }
      },
      {
        name: "WEBTELCO",
        selector:row=>row.webtelco,
        minWidth:"100px !important",
        sortField: "webtelco",
        sortable: true,
        cell: (row) => {
          return (
          <ContentEditable
          html={row.webtelco}
          name="webtelco"
          data-column="item"
          className="content-editable"
          onBlur={e => handleOnEditWebtelco(e,row,'id_webtelco',"webtelco")}
        />
           );
         }
      },
      {
        name: "DATE",
        selector:row=>row.date_in,
        minWidth:"110px !important",
        sortField: "date_in",
        sortable: true
      },
      {
        name: "UTILISATEUR",
        selector:row=>row.usrin,
        minWidth:"150px !important",
        sortField: "usrin",
        sortable: true
      },
      {
        right:true,
        sortable: true,
        cell: (row) => {         
          return (
            <div  className="rowGrid" id='twoIcons'>
             <div title="Ajouter/Supprimer roles"><RolDialog row={row} type={type} param={param}/></div> 
             <div onClick={()=>flagMan(row)} title="Activer/Desactiver">{(row.activ)?<LockOpen className={classes.redIcon} fontSize="small"/>:<Lock className={classes.greenIcon} fontSize="small"/> }</div> 
           </div>
           );
         },
      }  
    ],[flagMan,handleOnEdit,type,handleOpenType,classes,handleOnEditWebtelco,handleAlert,param]);

  const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage+"&search="+search)=>{
      setLoading(true);
      const response=await librery.axiosSort(column,sortdirection,page,size,service)
      if(response.data.err&&response.data.err!=="-1"){
        handleAlert(true,'error',response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
        setLoading(false);
      }) 
    }    
  }

  //recherche serverside
const handleSearch=async(size = perPage)=>{
  setLoading(true);
  setCurrentPage(1)
  const recherche=search;
  const response=await librery.axiosSearch(service,recherche,1,size,"get")
  if(response.data.err&&response.data.err!=="-1"){
    handleAlert(true,'error',response.data.err)
  }else{
  Gfunc.axiosResponse(response,()=>{
    if(response.data.data!==undefined){
    setData(response.data.data);
    setTotalRows(response.data.recordsFiltered);
    }else setData([])
  })  
   }
  setLoading(false); 
  return recherche
}

function liveSearch(e,search){
  if(param.liveSearch&&search.length>3){
     e.preventDefault();
     handleSearch();
  }else{ if (e.keyCode === 13) {
            e.preventDefault();
            handleSearch();
          }
  }
} 
function liveSearch2() {
  handleSearch();
}

  return (
    <div className="content">
        <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
        <Paper className={classes.paperBody} elevation={3}>
            <div className='table-header'>
              <div className='button-flex-wrapper'><DraggableDialog type={type} handleOpenType={handleOpenType} 
                   callback={()=>{if(currentPage===1) fetch(1);else setResetDP(!resetDP)}} /></div>
                <div className='search'>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Search className={classes.blackIcon} sx={{ color: 'action.active', mr: 1, my: 0.5 }} onClick={(e)=>liveSearch2(e)}/>
                      <TextField className="smallText" id="searchBar" variant="outlined" label="Recherche" value={search} size="small" margin='dense' onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} autoFocus/>
                   </Box>
                  </div>
            </div>
           {
             (data.length!==0 ||doSearch)?
            <div>
              <DTable
             currentPage={currentPage}
             data={data}
             columns={columns}
             loading={loading}
             fetch={fetch}
             handleOnSort={handleOnSort}
             resetDP={resetDP}
             totalRows={totalRows}
             perPage={perPage}
             setPerPage={setPerPage}
             setCurrentPage={setCurrentPage}
             paginationRowsPerPageOptions={[10,25,50,75,100]}
             customStyles={customStyles}
             conditionalRowStyles = { conditionalRowStyles }/>
            </div>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData || records===0)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
      </Paper>
    </div>    

  );
}
