import React,{ useState, useEffect ,useCallback,useMemo, useRef} from "react";
import DataTable  from "react-data-table-component";
import TextField from '@material-ui/core/TextField';
import {CircularProgress,Paper, Box, Button, Backdrop, Tooltip} from '@material-ui/core';
import {LocationOn, Person, Phone, Print, Search, Store} from '@material-ui/icons';
import { useStyles } from '../../css/muiStyle';
import {customStyles, numberColumn}from '../../css/datatableStyle'
import '../../css/client.css'
import { AlertCompo ,NoDataComponent,NotAllowedComponent} from "../../js/components";
import { Autocomplete } from "@material-ui/lab";
import { ValidatorForm } from "react-material-ui-form-validator";
import $ from 'jquery'
import { mdiAccountConvert, mdiCash } from "@mdi/js";
import Iconn from "@mdi/react";
import downloadCsv from "download-csv";

export default function Cli_det(props){
  const param=props.param
  const Gfunc=require('../../Gfunc')
  const [data, setData] = useState([]);
  const [tous, setTous] = useState([]);
  const [solde, setSolde] = useState(0);
  const [clientInfos, setClientInfos] = useState(null);
  const [clientL, setClientL] = useState([]);
  const [client, setClient] = useState('');
  const [subClient,setSubClient]=useState('');
  const [subClientN,setSubClientN]=useState('');
  const [subClientL, setSubClientL] = useState([]);
  const [clientN, setClientN] = useState('');
  const [loading, setLoading] = useState(false);
  const [search,setSearch]=useState('');
  const [nj,setNj]=useState(10);
  const [errorNj,setErrorNj]=useState(false);
  const [,setIcon]=useState('');
  const service='_cli.php'
  const classes=useStyles();
  const [severity, setSeverity] =useState("info");
  const [open, setOpen] =useState(false);
  const [messageA, setMessageA] =useState('');
  const [noData,setNoData]=useState(false);
  const [doSearch,setDoSearch]=useState(false);
  const [notAllowed,setNotAllowed]=useState(false);
  const [errMessage,setErrMessage]=useState('');
  const labelText1="Client"
  const labelText2="Nombre de jour";
  const labelText3="Sous client";

  //handle alerts properties
  const handleAlert=useCallback((open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  },[])


  const conditionalRowStyles=[
    { when: row => row.type==='a',
       style: {
             color: "green",
       }
     },
    { when: row => row.type==='c',
       style: {
             color: 'red',
       }
     }
  ]

   //fetch Client
   const fetchClient= useCallback(async(serv="_cli.php", act = "gets") => {
    const response = await Gfunc.axiosPostAction(serv,act,{mod:"ALL"});
    if(response.data.err&&response.data.err!=='-1'){
      setErrMessage(response.data.err)
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData!==undefined){
        setClientL(response.data.jData);  
      }  
    })
  },[Gfunc,handleAlert])

   //fetch client infos
   const fetchClientInfos= useCallback(async(client) => {
    setSolde("")
    const response = await Gfunc.axiosPostAction("_cli.php","getinfos",{clientId:client});
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData!==undefined){
        setClientInfos(response.data.jData);  
      }  
    })
  },[Gfunc,handleAlert])

  const handleSearch=()=>{
    const filters=[]
    var keys=[] 
    keys=Object.keys(tous[0])
      keys.map( key =>{ 
        const f={}  
        f["att"]=key
        filters.push(f)
        return true
      }) 
    setData(tous.filter((d)=>filters.some(filterEl =>{if(d[filterEl.att]!=null) return((d[filterEl.att].toString().toLowerCase()).includes(search.toLowerCase())); else return false})))
  }


   //fetch client handler
 const handleOpenClient = useCallback(async () => {
    if(clientL.length===0)
    fetchClient()
    },[clientL,fetchClient])

  
      //fetch subClient
  const fetchSubClient= useCallback(async(client) => {
    const response = await Gfunc.axiosPostAction("_cli.php","getsubs",{owner:client});
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData!==undefined){
        setSubClientL(response.data.jData);
      }  
    })
  },[Gfunc,handleAlert])


     //fetch subclient handler
  const handleRefund = useCallback(async (id) => {
    const response = await Gfunc.axiosPostAction("_cli.php", "refund", { consoId: id });
    if (response.data.err && response.data.err !== '-1') {
      handleAlert(true, 'error', response.data.err)
    } else
      Gfunc.axiosResponse(response, () => {
        if (response.data.jData !== undefined) {
          var dat = [...data];
          const ind = dat.findIndex(obj => obj.DT_RowId === "c" + id)
          dat[ind].canRefund = false;
          setData(dat)
          handleAlert(true, 'success', "La demande de remboursement a été envoyée.");
        }
      })
  }, [Gfunc, handleAlert, data])


        //valider le formulaire
const handleSubmit= useCallback(async(id) =>{
  setLoading(true);
  setErrorNj(false);
  if(nj === '') setErrorNj(true);
  
  if(nj!==''){
  const dat={
          clientId:(id)?id:client,
          subcomId:(subClient!=="")?subClient:null,
          days:parseInt(nj)
  }
   const response=await Gfunc.axiosPostAction(service,"gethisto",dat)
   if(response.data.err&&response.data.err!=="-1"){
    handleAlert(true,'error',response.data.err)
    setNotAllowed(true)
  }else{
   Gfunc.axiosResponse(response,()=>{
    if (response.data.jData) { 
       setData(response.data.jData.histo)
       setTous(response.data.jData.histo)
       setSolde(response.data.jData.solde)
       if(response.data.jData.histo.length>0) setDoSearch(true); else {setDoSearch(false);setNoData(true)}
      } 
      else{
      setData([])
      setTous([])
      setSolde(0)
      setDoSearch(false) 
      }
  })
}
}else{ handleAlert(true,'error',"Veuillez remplir tous les champs!")}
setLoading(false);
},[Gfunc,subClient,client,handleAlert,nj])


    const openClient = useCallback(async() => {
      
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });
      // Get the wanted value 
      let c = params.c; 
      let cn = params.cn; 
      //document.getElementById("row-1").innerHTML=cn;
      if(c!==null){
        /*
          let dc= CryptoJS.AES.decrypt(c, "Secret Passphrase");
          let dcc= dc.toString(CryptoJS.enc.Utf8);
          //document.getElementById("row-1").innerHTML=dc;
        */
        if(c){
        setClient(c);
        setClientN(cn);
        fetchClientInfos(c)
        handleSubmit(c);
      }}
      },[fetchClientInfos,handleSubmit])


    useEffect(() => {
        handleOpenClient()
      }, [handleOpenClient]);

    useEffect(() => {
      openClient()
      }
      ,[openClient]);

const handleLockIcon=(val)=>{
  setIcon(val);
}
useEffect((val) => {
handleLockIcon(val);
}, []);

  const mtnControl = [
    {
      when: row => row.mtn < 0 && row.type === "c",
      style: {
        color: "green",
        justifyContent: "end",
        paddingRight: '20px'
      }
    }]
const columns =useMemo(
    () => [ 
        {
            name: "DATE",
            selector:row=>row.date,
            width:"145px !important",
            sortField: "date",
            sortable: true,
            cell: (row) => {
              return (
                <Tooltip title={'Entré par: ' +row.agent}>
                     <div>{row.date}</div>
                </Tooltip>
                  
               );
             },
          },  
      {
          name: "Produit",
          selector:row=>row.produit,
          minWidth:"280px !important",
          sortField: "produit",
          sortable: true,
      },
      {
          name: "Bundle",
          selector:row=>row.bundle,
          minWidth:"150px !important",
          sortField: "bundle",
          sortable: true,
      },
      {
          name: "Numero",
          selector:row=>row.numero,
          width:"110px !important",
          sortField: "numero",
          sortable: true,
      },
      {
        name: "QTE.",
        minWidth:'90px !important',
        style:numberColumn,
        selector: row=>row.qte,
        sortField:'qte',
        sortable: true,
        cell: (row) => {
          return (
            <div>{new Intl.NumberFormat().format(row.qte)}</div>
          )
        }, 
      },
      {
        name: "Montant",
        minWidth:'110px !important',
        style:numberColumn,
        selector: row=>row.mtn,
        sortField:'mtn',
        conditionalCellStyles: mtnControl,
        sortable: true,
        cell: (row) => {
          return (
            <div>{new Intl.NumberFormat().format(row.mtn)}</div>
          )
        }, 
      },
      {
        name: "Solde",
        minWidth:'120px !important',
        style:numberColumn,
        selector: row=>row.solde,
        sortField:'solde',
        sortable: true,
        cell: (row) => {
          return (
            <div>{Gfunc.formatterTo2.format(row.solde)}</div>
          )
        }, 
      },
    {
      right: true,
      cell: (row) => {
        return (
          (row.type === "c" && row.qte > 0 && row.mtn > 0 && row.canRefund) &&
          <Tooltip title="Remboursement">
            <div onClick={() => handleRefund(row.DT_RowId.substr(1))} ><Iconn size="18px" path={mdiAccountConvert} color="black" /></div>
          </Tooltip>
        );
      },
    }
  ], [handleRefund]);
 

  //code jQuery pour boucler le focus des elements
$('form').each(function(){
    var list  = $(this).find('input*[tabindex],#last*[tabindex]').sort(function(a,b){ return a.tabIndex < b.tabIndex ? -1 : 1; }),
        first = list.first();
    list.last().on('keydown', function(e){
        if( e.keyCode === 9 ) {
            first.focus();
            return false;
        }
    });
  });

  function liveSearch(e){
    if(search.length>-1){
      e.preventDefault();
      handleSearch();
    }
  }
  function liveSearch2() {
    handleSearch();
  }

   //extarct digits
   const DownloadFile=async()=>{
   // const response=await axiosPostAction("_dig.php","extractdigsauto",{clientId:client,prodDetId:prod,qte:parseInt(qte)})
    var result=[]
   
        const headers = {  date: "Date" , agent: "Serial", produit:"Produit",mtn:"Montant", qte:"Quantité", solde:"Solde" }; 
                  if(data){
                        data.map((ex)=>{
                          result.push( (({ date,agent,produit,mtn,qte,solde}) => ({ date,agent,produit,mtn,qte,solde}))(ex));
                          return true;
                        })
                    downloadCsv(result, headers, "Historique_"+clientN+"_"+Gfunc.getDate()+"_"+result.length+".csv");
                   // props.handleClearRows();
                }
  }

  return (
    <div className="content">
        <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
        <Backdrop className={classes.backdrop} open={loading} >
          <CircularProgress color="inherit" />
        </Backdrop>
        <fieldset className="fieldsetTop" id="saisie">
          <legend>Historique client</legend>
        <ValidatorForm noValidate id="getHist" ref={useRef(null)} onSubmit={()=>handleSubmit(client)} /*onKeyPress={e=>detectTouche(e)} */ >
          <div>
            <div className="rowGrid" id="row-1">
            <Autocomplete
                                disableClearable
                                className='smallAuto'
                                id="iClient"
                                onChange={(e,newValue) =>{
                                    setClient(newValue.id)
                                    setClientN(newValue.name)
                                    setSubClient("")
                                    setSubClientN("")
                                    setSubClientL([])
                                    fetchClientInfos(newValue.id)
                                    fetchSubClient(newValue.id)
                                  }
                                  } 
                                value={{id:client,name:clientN}}
                                options={clientL}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) => option.id === value.id || value.id===''}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    inputProps={{...params.inputProps,tabIndex:'1'}}
                                    label={labelText1}
                                    variant='outlined'
                                    size="small"
                                    autoFocus 
                                    required/>
                                )}/> 
              <Autocomplete
                                disableClearable
                                className='smallAuto'
                                id="isubClient"
                                value={{id:subClient,name:subClientN}}
                                onChange={(e,newValue) =>{
                                  setSubClient(newValue.id)
                                  setSubClientN(newValue.name)
                                  }
                                  } 
                                options={subClientL}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) => option.id === value.id}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={labelText3}
                                    variant='outlined'
                                    margin='dense'
                                    size="small"
                                    autoFocus/>
                                )}/>
              <TextField className='smallText' id="iNj"  variant="outlined"  type="number" size="small" label={labelText2}  value={nj}
                  onChange={e =>setNj(e.target.value)} 
                  InputProps={{ inputProps: { min: 0, tabIndex: "2" } }}
                  required  error={errorNj}
                  />   
              <Button className={classes.smallBtn} tabIndex="3" id="last" variant="contained" type='submit' name="addBtn" color="primary" startIcon={<Search/>}>Rechercher</Button>
              {(data.length>0)?<Button className={classes.smallBtn} id="last" variant="contained" onClick={()=>DownloadFile()} name="addBtn" color="primary" startIcon={<Print/>}>Imprimer</Button>:null}
         </div>
         {(clientInfos!=null)?
        <>
        <fieldset id="cliInfs">
        <legend id="cliInfsLegend"> Information du client</legend>
          <div className="flex-infos">
            <Person fontSize="small"/>
            {clientInfos.nom+" "+clientInfos.prenom }
          </div>
          {(clientInfos.mag)?
          <div className="flex-infos">
            <Store fontSize="small"/>
            {clientInfos.mag}
          </div>:null}
         { (clientInfos.wilaya)?<div className="flex-infos">
            <LocationOn fontSize="small"/>
            {clientInfos.commune+" - "+clientInfos.wilaya}
          </div>:null}

          {(clientInfos.tel1)?<div className="flex-infos">
            <Phone fontSize="small"/>
            {clientInfos.tel1}
            {(clientInfos.tel2)?(" / "+clientInfos.tel2):null}
            {(clientInfos.tel3)?(" / "+clientInfos.tel3):null}
          </div>:null}

        </fieldset></>:null}
         </div>
         {(data.length!==0&& solde!=='')? <div className='table-header displayedDataGrid'><div className="staticsHeadWIcon"><Iconn path={mdiCash}/><div className="staticsHead"><h2>{Intl.NumberFormat().format(solde)}</h2><p>Solde</p></div></div></div>:null}
        </ValidatorForm>
        </fieldset>
        
        <Paper className={(doSearch)?classes.paperBody:''} elevation={5}>
        <div className='table-header'>
        {
           (data.length!==0||doSearch)? <div className='search'>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Search className={classes.blackIcon} sx={{ color: 'action.active', mr: 1, my: 0.5 }} onClick={(e)=>{liveSearch2(e)}}/>
                      <TextField className="smallText" id="searchBar" variant="outlined" label="Recherche" value={search} size="small" margin='dense' onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} />
                </Box>
            </div>:null}
        </div>
        {
           (data.length!==0||doSearch)?
           <div>
            <DataTable 
              persistTableHead
              columns={columns}
              data={data}
              noDataComponent={param.ErreurData}
              paginationRowsPerPageOptions={[10,25,50,75,100]}
              paginationPerPage={25}
              progressPending={loading}
              progressComponent={<CircularProgress className={classes.circularProgress} />}
              pagination
              paginationDefaultPage={1}
              noHeader={true}
              highlightOnHover
              customStyles={customStyles}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}
              conditionalRowStyles = { conditionalRowStyles }
            />
          </div>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData || data.length)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
      </Paper>
      
    </div>    

  );
}