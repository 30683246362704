import React,{ useState, useEffect ,useCallback} from "react";
import {Box, CircularProgress,FormControlLabel,Paper, Radio, RadioGroup, TextField} from '@material-ui/core';
import { useStyles } from '../../css/muiStyle';
import '../../css/client.css'
import { AlertCompo ,NoDataComponent,NotAllowedComponent} from "../../js/components";
import $ from 'jquery'
import Iconn from "@mdi/react";
import { mdiAccountAlert, mdiAccountGroup, mdiApproximatelyEqualBox, mdiArchiveCheck } from "@mdi/js";
import { Link } from "react-router-dom";
import { Search, SignalCellularNullSharp } from "@material-ui/icons";
import { grid } from "@mui/system";

export default function Cli_sld (){
  const Gfunc=require('../../Gfunc')
  const [data, setData] = useState([]);
  const [tous, setTous] = useState([]);
  const [binarsL, setBinarsL] = useState([]);
  const [ruptOnly,setRuptOnly]=useState(false);
  const [search,setSearch]=useState('');
  const [,setIcon]=useState('');
  const service='_cli.php'
  const classes=useStyles();
  const [severity, setSeverity] =useState("info");
  const [loading, setLoading] =useState(false);
  const [open, setOpen] =useState(false);
  const [messageA, setMessageA] =useState('');
  const[noData,setNoData]=useState(false);
  const[notAllowed,setNotAllowed]=useState(false);
  const[errMessage,setErrMessage]=useState('');
  const [value, setValue] = React.useState('tous');

  const handleChange = (event) => {
    setValue(event.target.value);
    handleSearch(ruptOnly,event.target.value)
  };

   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  }

   //fetch  binars
   const fetchBinars= useCallback(async(serv=service, act = "getbinars") => {
    setLoading(true)
    const response = await Gfunc.axiosGetAction(serv,act);
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
      setNotAllowed(true)
      setErrMessage(response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData!==undefined){
        setBinarsL(response.data.jData.soldes); 
        setTous(response.data.jData.soldes);
        //console.log(Gfunc.sumArObj(response.data.jData.soldes,"total"))
        setData(response.data.jData.total)
        setNoData(true)
      }  else{
        setData([])
      } 
    })
    setLoading(false)
  },[Gfunc,service])

    useEffect(() => {
        fetchBinars()
      }, [fetchBinars]);
      
$("td").mouseover(function() {
    $("td").removeClass("highlightElem");
    if(this.id){
    $("#"+this.id).addClass("highlightElem");
    if(this.id.startsWith("tot")){
        $("#"+this.id.substring(3)).addClass("highlightElem")
    }else{
        $("#tot"+this.id).addClass("highlightElem")
    }
}
});

const handleLockIcon=(val)=>{
  setIcon(val);
}
useEffect((val) => {
   handleLockIcon(val);
}, []);

const gettable=(binarsL)=>{
    var disp = [];
    var displ = [];
     for(let i=0;i<binarsL.length;i++){
          displ.push ( <td key={i} id={binarsL[i].id} style={(binarsL[i].total<2000)?{color:"red"}:{}} className={Gfunc.isOdd(i)?"clientName changeColor":"clientName"} ><Link className="invisibleLink"  to={{pathname: "/c/ch?c="+/*CryptoJS.AES.encrypt(*/binarsL[i].id/*), "Secret Passphrase")*/+"&cn="+binarsL[i].nom}} target="_blank"><p>{binarsL[i].nom}</p></Link><p>{Intl.NumberFormat().format(binarsL[i].total)}</p></td>) 
     }
     disp.push(<tr key={"tr"} className="soldeLine">{displ}</tr>)
     return disp;
}

//calcilate the item that are inferior to something
function getRuptureOnly(arr,att,value,rup){
  let dat=[]
  if(!rup){
    return arr
  }else{arr.reduce((accumulator, object) => {
      if(object[att]<value)
      return dat.push(object)
      return false
    }, 0);
  return dat}
}
const handleSearch=(rup,rad)=>{
  const filters=[]
  var keys=[] 
  var s=SignalCellularNullSharp
  keys=Object.keys(tous[0])
    keys.map( key =>{ 
      const f={}  
      f["att"]=key
      filters.push(f)
      return true
    }) 
  s=tous.filter((d)=>filters.some(filterEl =>{ 
    if(d[filterEl.att]) return((d[filterEl.att].toString().toLowerCase()).includes(search.toLowerCase())); return false}))

  s=getRuptureOnly(s,"total",2000,rup)

  if(rad!=="tous")
    s=s.filter((d)=>{if(d.promo!==undefined) return(d.promo===JSON.parse(rad)); return false})

  setBinarsL(s)
}

function liveSearch(e){
  if(search.length>-1){
    e.preventDefault();
    handleSearch(ruptOnly,value);
  }
}
function liveSearch2() {
  handleSearch(ruptOnly,value);
}
     
  return (
    <div className="content">
        <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />                          
        
        <Paper className={classes.paperBody} elevation={5} style={{height:"100%",margin:"0 auto",alignContent:"start"}}>
        <div style={{display:"grid",height:"100%"}}>
        <div id="searchFilter">
        <div className='table-header displayedDataGrid'>
          <div className="staticsHeadWIcon"><Iconn path={mdiArchiveCheck} color="black" size="24px" /><div className="staticsHead"><h2>{!isNaN(data)?Intl.NumberFormat().format(data):0}</h2><p>Stock marché</p></div></div>
          <div className="staticsHeadWIcon"><Iconn path={mdiApproximatelyEqualBox} color="black" size="24px" /><div className="staticsHead"><h2>{(tous.length>0)?Intl.NumberFormat().format(Math.round(Gfunc.sumArObj(tous,"total")/tous.length)):0}</h2><p>Moyenne par client</p></div></div>
          <div className="staticsHeadWIcon"><Iconn path={mdiAccountGroup} color="black" size="24px" /><div className="staticsHead"><h2>{Intl.NumberFormat().format(binarsL.length)}</h2><p>Nombre de clients</p></div></div>
          <div className="staticsHeadWIcon" style={{cursor:"pointer"}} onClick={()=>{setRuptOnly(!ruptOnly);handleSearch(!ruptOnly,value)}}><Iconn path={mdiAccountAlert} color="black" size="24px" /><div className="staticsHead" style={{background:(!ruptOnly)?"#fff":"#ff9999",borderRadius:"5px"}}><h2>{(binarsL.length>0)?Intl.NumberFormat().format(Gfunc.infTo(binarsL,"total",2000)):0}</h2><p>En rupture de stock</p></div></div>
        {/*
           (binarsL.length!==0||doSearch)? <div className='search'>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Search className={classes.blackIcon} sx={{ color: 'action.active', mr: 1, my: 0.5 }} onClick={(e)=>{}}/>
                      <TextField className="smallText" id="searchBar" variant="outlined" label="Recherche" value={search} size="small" margin='dense' onKeyUp={e => {}} onChange={e => setSearch(e.target.value)} />
                </Box>
        </div>:null*/}
        </div>
        <div className='search'>
                 <RadioGroup id="rad" style={{flexDirection: "row",justifyContent: "flex-end",paddingBottom:"7px"}} aria-label="gender" name="gender1" value={value} onChange={handleChange} >
                    <FormControlLabel value="tous" control={<Radio className={classes.radioBtn} size="small" color="primary" />} label="Tous" />
                    <FormControlLabel value={"true"} control={<Radio className={classes.radioBtn} size="small" color="primary" />} label="Bénéficiaires" />
                    <FormControlLabel value={"false"} control={<Radio className={classes.radioBtn} size="small" color="primary" />} label="Non bénéficiaires" />
                  </RadioGroup>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Search className={classes.blackIcon} sx={{ color: 'action.active', mr: 1, my: 0.5 }} onClick={(e)=>liveSearch2(e)}/>
                      <TextField className="smallText" id="searchBar" variant="outlined" label="Recherche" value={search} size="small" margin='dense' onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} autoFocus/>
                  </Box>
        </div>

      </div>
      {
          (loading>0)?<div className="tabSolde"><CircularProgress style={{margin:"80px auto"}}/></div>: (binarsL.length!==0)?
           <div className="tabSolde">
           
           <table id='tableSoldes'>
           <caption >Soldes binars des clients {(!ruptOnly)?null:"en rupture de stock"}</caption>
           
              <tbody>
                 { (binarsL.length>0)?
                 gettable(binarsL):null
                 }
                </tbody>
              </table>
           
          </div>
             :(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
             </div>
      </Paper>
   
    </div>    

  );
}