import React,{useMemo,useEffect,useState,useCallback} from "react";
import { useStyles } from '../../css/muiStyle';
import {Paper,Box,TextField, ButtonGroup, Button} from '@material-ui/core';
import {conditionalRowStyles,customStyles}from '../../css/datatableStyle'
import {Delete, Search} from '@material-ui/icons';
import { AlertCompo ,NoDataComponent,NotAllowedComponent,AutocompleteS,InputNbrEditable} from "../../js/components";
import DialogAdd from '../content/modals/exp_prx_add'
import DialogAdd2 from '../content/modals/exp_prx_add2'
import '../../css/exploitation.css'
import DTable from "../../compo/DTable";

export default function Exp_prx(props){
    const param=props.param
    const classes=useStyles();
    const Gfunc=require('../../Gfunc')
    const librery=require('../../js/data_table_librery')
    const [produit,setProduit]=useState([]);
    const [client,setClient]=useState([]);
    const [data, setData] = useState([]);
    const [called, setCalled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [search,setSearch]=useState('');
    const [severity, setSeverity] =useState("info");
    const [open, setOpen] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [noData,setNoData]=useState(false);
    const [doSearch,setDoSearch]=useState(false);
    const [notAllowed,setNotAllowed]=useState(false);
    const [errMessage,setErrMessage]=useState('');
    const [records,setRecords]=useState('');
    const [resetDP, setResetDP] = useState(false);
    const [sub, setSub] = useState(false);
    const service='_prx.php'
    const filter= "&search="+search
    const [subClientL, setSubClientL] = useState([]);

    //handle alerts properties
   const handleAlert=useCallback((open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
     }, 5000);
   },[])

    const fetch= useCallback(async(page, size = perPage) => {
        setCalled(true);
        setLoading(true);
        const response=await librery.axiosGet2(page,service,(!sub)?"get":"getsub",size+filter)
        setLoading(false);
        if(response.data.err&&response.data.err!=='-1'){
          setErrMessage(response.data.err)
          setNotAllowed(true)
        }else{
          Gfunc.axiosResponse(response,()=>{
            setRecords(response.data.recordsFiltered)
            if(response.data.data!==undefined){
              setData(response.data.data);
              setTotalRows(response.data.recordsFiltered);
              setNoData(true)
              setDoSearch(true)
            }else{
              setDoSearch(false)
              setData([])
            }   
          }) 
          setLoading(false)
      }
  },[Gfunc,librery,perPage,filter,service,sub])
    
  useEffect(() => {
    if(!called) fetch(1);
  }, [fetch,called]);
  
  useEffect(() => {
    setCurrentPage(1)
    setPerPage(10)
     fetch(1);
  }, [fetch,sub]);

  const DeletPrix =useCallback( async( row)=>{
    const response= await Gfunc.axiosPostAction(service,(!sub)?'del':"delsub",{id1:row.DT_RowId1.substr(1),id2:parseInt(row.DT_RowId2.substr(1))})
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else{
     Gfunc.axiosResponse(response,()=>{ 
        if (response.data.jData) {
            handleAlert(true,'success','Le prix a été supprimé avec succès')
            fetch(1)
        }
     })
    }
  },[Gfunc,fetch,handleAlert])

   //fetch client
   const fetchProd= useCallback(async(serv="_prd.php", act = "gets") => {
    const response = await Gfunc.axiosGetAction(serv,act);
    if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
    }else{
        Gfunc.axiosResponse(response,()=>{
            setProduit(response.data.jData);    
        })
    } 
  },[Gfunc,handleAlert])

    //fetch client handler
    const handleOpenProd= useCallback(async () => {
       if(produit.length===0) fetchProd()
    },[produit,fetchProd])

    //fetch client
    const fetchCli= useCallback(async(serv="_cli.php", act = "gets") => {
        const response = await Gfunc.axiosGetAction(serv,act);
        if(response.data.err&&response.data.err!=='-1'){
            handleAlert(true,'error',response.data.err)
        }else{
            Gfunc.axiosResponse(response,()=>{
                setClient(response.data.jData);    
            })
        } 
      },[Gfunc,handleAlert])

    //fetch client handler
    const handleOpenCli= useCallback(async () => {
      if(client.length===0) fetchCli()
      if(sub) setSubClientL([])
      },[client,fetchCli])

     //fetch Client
  const fetchSubClient= useCallback(async(client) => {
    const response = await Gfunc.axiosPostAction("_cli.php","getsubs",{owner:client});
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData!==undefined){
        setSubClientL(response.data.jData);
      }else setSubClientL([])
    })
  },[Gfunc,handleAlert])


  const columns =useMemo(
    () => [   
      {
          name: "Client",
          selector:row=>row.client,
          minWidth:"180px !important",
          sortField: "client",
          sortable: true,
          cell: (row) => {
            return (
                <div >  
             {(!sub)?
              <AutocompleteS
                  service={service} action={'upd'} men='prix'
                  defaultValue={row.client} row={row} list={client} 
                  id={'clientId'} name='client' attr='name' onOpen={handleOpenCli}
                  setLoading={setLoading}  handleAlert={handleAlert} 
                />:
                row.client
             }          
            </div>
             );
           },
      },
      (sub)&&{
        name: "Sous Client",
        selector:row=>row.subClient,
        minWidth:"180px !important",
        sortField: "subClient",
        sortable: true,
        cell: (row) => {
          return (
              <div >  
           {
            <AutocompleteS
                service={service} action={'updsub'} men='prix'
                defaultValue={row.subClient} row={row} list={subClientL} 
                id={'subClientId'} name='subClient' attr='name' onOpen={()=>fetchSubClient(row.clientId)}
                setLoading={setLoading}  handleAlert={handleAlert} 
              />
           }          
          </div>
           );
         },
     },
      {
        name: "Produit",
        selector:row=>row.produit,
        minWidth:"120px !important",
        sortField: "produit",
        sortable: true,
        cell: (row) => { 
            return (
            <div >  
             {
              <AutocompleteS
                  service={service} action={(!sub)?'upd':"updsub"} men='prix'
                  defaultValue={row.produit} row={row} list={produit} 
                  id={'produitId'} name='produit' attr='name' onOpen={handleOpenProd}
                  setLoading={setLoading} handleAlert={handleAlert} 
                />
             }          
            </div> 
            );
        }
      },
      {
        name: "Prix",
        minWidth:"120px !important",
        sortField: "price",
        sortable: true,
        cell: (row) => {
          return (
            <div key={Math.random()}>
              <InputNbrEditable service={service} action={(!sub)?'upd':"updsub"} min={1} men='prix'row={row} att="price" value={row.price} 
                                id='DT_RowId1'  idi={row.DT_RowId1} handleAlert={handleAlert} />
            </div>
           );
         },
      },
      {
        name: "DATE",
        selector:row=>row.dateAdd,
        minWidth:"120px !important",
        sortField: "dateAdd",
        sortable: true
      },
      {
        name: "Agent",
        selector:row=>row.agentAdd,
        minWidth:"120px !important",
        sortField: "agentAdd",
        sortable: true
      }, 
      { 
        right:true,
        cell: (row) => {
            return (
              <div title="Supprimer un prix" onClick={()=>{DeletPrix(row)}}><Delete className={classes.redIcon} fontSize="small"  /></div> 
             );
        },   
      },
    ],[classes,produit,client,service,DeletPrix,handleOpenCli,handleOpenProd,handleAlert,sub,subClientL]);
 
  const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage+"&search="+search)=>{
      setLoading(true);
      const response=await librery.axiosSort2(column,sortdirection,page,size,service,(!sub)?"get":"getsub")
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
        setLoading(false);
      }) 
    }    
  }

   //recherche serverside
   const handleSearch=async(size = perPage)=>{
    setLoading(true);
    setCurrentPage(1)
    const recherche=search;
    const response=await librery.axiosSearch(service,recherche,1,size,(!sub)?"get":"getsub")
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
      if(response.data.data!==undefined){
      setData(response.data.data);
      setTotalRows(response.data.recordsFiltered);
      }else setData([])
    })   
    setLoading(false);
    return recherche
  }
 } 
  function liveSearch(e,search){
    if(param.liveSearch&&search.length>3){
       e.preventDefault();
       handleSearch();
    }else{ if (e.keyCode === 13) {
              e.preventDefault();
              handleSearch();
            }
    }
  }
  function liveSearch2() {
    handleSearch();
  }

  return (
    (!sub)?<div className="content">
        <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
       
        <Box sx={{ display: 'flex',  flexDirection: 'column', alignItems: 'center', '& > *': {   m: 1,   },paddingBottom:"10px" }} >
        <ButtonGroup variant="contained" aria-label="outlined primary button group">
          <Button onClick={()=>setSub(false)} color="primary">Client</Button>
          <Button onClick={()=>setSub(true)} color="primary">Sous client</Button>
        </ButtonGroup></Box>
        {
        (data.length!==0||doSearch)?
        <Paper className={classes.paperBody} elevation={5}>
        <div className='table-header'>
          <div className='button-flex-wrapper'><DialogAdd handleAlert={handleAlert} callback={()=>{if(currentPage===1) fetch(1);else setResetDP(!resetDP)}}/></div>
          <div className='search'>
               <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Search className={classes.blackIcon} sx={{ color: 'action.active', mr: 1, my: 0.5 }} onClick={(e)=>liveSearch2(e)}/>
                      <TextField className="smallText" id="searchBar" variant="outlined" label="Recherche" value={search} size="small" margin='dense' onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} autoFocus/>
                </Box>
          </div>
        </div>
        <div>
        <DTable
             currentPage={currentPage}
             data={data}
             columns={columns}
             loading={loading}
             fetch={fetch}
             handleOnSort={handleOnSort}
             resetDP={resetDP}
             totalRows={totalRows}
             perPage={perPage}
             setPerPage={setPerPage}
             setCurrentPage={setCurrentPage}
             paginationRowsPerPageOptions={[10,25,50,75,100]}
             customStyles={customStyles}
             conditionalRowStyles = { conditionalRowStyles }/>
          </div>
        </Paper>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData || records===0)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
    </div> :
    <div className="content">
    <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
   
    <Box sx={{ display: 'flex',  flexDirection: 'column', alignItems: 'center', '& > *': {   m: 1,   },paddingBottom:"10px" }} >
    <ButtonGroup variant="contained" aria-label="outlined primary button group">
      <Button onClick={()=>{setSub(false);setSearch("")}} color="primary">Client</Button>
      <Button onClick={()=>{setSub(true);setSearch("")}} color="primary">Sous client</Button>
    </ButtonGroup></Box>
    
    
    <Paper className={classes.paperBody} elevation={5}>
    <div className='table-header'>
      <div className='button-flex-wrapper'><DialogAdd2 subClients={subClientL} fetchSubClient={fetchSubClient} handleAlert={handleAlert} callback={()=>{if(currentPage===1) fetch(1);else setResetDP(!resetDP)}}/></div>
      <div className='search'>
           <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Search className={classes.blackIcon} sx={{ color: 'action.active', mr: 1, my: 0.5 }} onClick={(e)=>liveSearch2(e)}/>
                  <TextField className="smallText" id="searchBar" variant="outlined" label="Recherche" value={search} size="small" margin='dense' onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} autoFocus/>
            </Box>
      </div>
    </div>
    <div>
    {(data.length!==0||doSearch)? <DTable
         data={data}
         columns={columns}
         loading={loading}
         fetch={fetch}
         handleOnSort={handleOnSort}
         resetDP={resetDP}
         totalRows={totalRows}
         perPage={perPage}
         setPerPage={setPerPage}
         setCurrentPage={setCurrentPage}
         paginationRowsPerPageOptions={[10,25,50,75,100]}
         customStyles={customStyles}
         conditionalRowStyles = { conditionalRowStyles }/>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData || records===0)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
      </div>
    </Paper>
  </div>    
  
  );
}