import React,{ useRef,useState, useEffect ,useCallback,useMemo} from "react";
import ContentEditable from 'react-contenteditable';
import TextField from '@material-ui/core/TextField';
import {Delete,CheckCircleOutline,NotInterested, Storage} from '@material-ui/icons';
import {Button,Checkbox,Paper, Box, FormControlLabel,FormControl} from '@material-ui/core';
import {Search} from '@material-ui/icons';
import $ from 'jquery';
import axios from "axios"
import {customStyles} from '../../css/datatableStyle'
import { useStyles } from '../../css/muiStyle';
import {AutocompleteS, BackdropCompo} from "../../js/components"
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ValidatorForm } from "react-material-ui-form-validator";
import { AlertCompo ,NoDataComponent,NotAllowedComponent} from "../../js/components";
import { mdiCheckboxMarkedCircleOutline, mdiCheckboxMultipleMarkedCircleOutline } from "@mdi/js";
import Iconn from "@mdi/react";
import  '../../css/digit.css'
import DTable from "../../compo/DTable";


export default function Dig_plv (props){
  const lodashClonedeep = require('lodash.clonedeep');
  const classes = useStyles();
  const param=props.param
  const Gfunc=require('../../Gfunc')
  const librery=require('../../js/data_table_librery')
  const [data, setData] = useState([]);
  const [datach, setDatach] = useState([]);
  const [frns, setFrns] = useState([]);
  const [prod, setProd] = useState([]);
  const [lot, setLot] = useState([]);
  const [libre, setLibre] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [called, setCalled] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(-1);
  const [currentPage, setCurrentPage] = useState(1);
  const [search,setSearch]=useState('');
  const [digSearch,setdigSearch]=useState('');
  const [doSearch,setDoSearch]=useState(false);
  const [,setIcon]=useState('');
  const [del,setDel]=useState('');
  const [qte,setQte]=useState('');
  const [prx,setPrx]=useState('');
  const [produits,setProduits]=useState([]);
  const [selectP,setSelectP]=useState('');
  const [errorQ,setErrorQ]=useState(false);
  const [errorP,setErrorP]=useState(false);
  const [errorPr,setErrorPr]=useState(false);
  const [check,setCheck]=useState([])
  const [checkInfo,setCheckInfo]=useState({})
  const [severity, setSeverity] =useState("info");
  const [open, setOpen] =useState(false);
  const [messageA, setMessageA] =useState('');
  const[noData,setNoData]=useState(false);
  const[ch,setCh]=useState(false);
  const[notAllowed,setNotAllowed]=useState(false);
  const[errMessage,setErrMessage]=useState('');
  const[records,setRecords]=useState('');
  const [reset,setReset]=useState(false);
  const filter='&digSearch='+digSearch+"&mod=MYDAY"
  const srv='digit'
  const service='_dig.php'

   //handle alerts properties
   const handleAlert=useCallback((open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  },[])

  const  conditionalRowStyles  =  [ 
    {
      when: row => row.isbanned!==true,
        style: {
              backgroundColor: "lightgray",
              color:"gray"
        }
    },
  ]

  const fetch= useCallback(async(page, size = perPage) => {
    setCalled(true)
    setLoading(true);
    setCheck([])
    setCh(false)
    const response = await librery.axiosGet(page,service,size+filter+'&search='+search);
    if(!called) setLoadingSubmit(false)
    if(response.data.err&&response.data.err!=="-1"){
      setErrMessage(response.data.err)
      setNotAllowed(true)
    }else{
    Gfunc.axiosResponse(response,()=>{
      setRecords(response.data.recordsFiltered)
      if(response.data.data!==undefined){
        setData(response.data.data);
        setDatach(response.data.data)
        setTotalRows(response.data.recordsFiltered);
        setLoading(false);
        setNoData(true)
        setDoSearch(true)
      }
     else{
        setDoSearch(false)
        setData([])  
      }
    })
    }
  },[Gfunc,filter, perPage, search,librery,called])
  
  const fetchFrns= useCallback(async() => {
    const response = await axios.get( param.urlService+"_frs.php?do=gets" ,{ headers: Gfunc.getHeader()});
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
        setFrns(response.data.jData);    
    })
  }
  },[Gfunc,param.urlService,handleAlert]);
  
 const fetchProd= useCallback(async() => {
    const response = await axios.get( param.urlService+"_prd.php?do=gets" ,{ headers: Gfunc.getHeader()});
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
        setProd(response.data.jData);    
    })
  }
  },[Gfunc,param.urlService,handleAlert]);

 const fetchProdPrv= useCallback(async() => {
    const response = await axios.get( param.urlService+"_dig.php?do=getprdprv" ,{ headers: Gfunc.getHeader()});
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else{
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData.length!==0){
          setProduits(response.data.jData); 
        }
      })
    }   
  },[Gfunc,param.urlService,handleAlert]);

  const fetchLot= useCallback(async() => {
    const response = await Gfunc.axiosGetAction( "_lot.php","gets");
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
        setLot(response.data.jData);    
    })
  }
  },[Gfunc,handleAlert]);

  useEffect(() => {
    if(!called){setLoadingSubmit(true);fetch(1);}
  }, [fetch,called]);


const handleOnEdit=useCallback(async(e,row,att)=>{
  e.preventDefault();
  const edt1=e.target.innerHTML
  const edt=Gfunc.DeleteSpace(edt1);
  if(row[att]!==edt){
    const attrib=row[att];
    const response=await librery.axiosEdit(service,row,edt,att) 
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{row[att]=edt;e.target.innerHTML=edt})
    }
    if(response.data.err) {e.target.innerHTML=attrib}
  }else {e.target.innerHTML=edt}
},[librery,Gfunc,handleAlert]);

const flagMan =useCallback( async( row)=>{
  const response= await Gfunc.axiosPostAction('flagman.php','1',({ srv:srv, id: row.DT_RowId.substr(1),att:'banned', val: row.isbanned }))
  if(response.data.err&&response.data.err!=="-1"){
    handleAlert(true,'error',response.data.err)
  }else{
  Gfunc.axiosResponse(response,()=>{ 
    const objIndex = data.findIndex((obj => obj.DT_RowId === row.DT_RowId));
      if (response.data.jData) {
        setIcon(row.DT_RowId)
        if(row.isbanned){
          data[objIndex].isbanned=false
        }else data[objIndex].isbanned=true
        setIcon('')     
      }else handleAlert(true,'error',response.data.err);
          })
        }
},[Gfunc,data,handleAlert])

const handleSetData=useCallback((row,att)=>{
  Gfunc.removeItemsByAtt(data,row.DT_RowId,att)
  setDel('')
},[data,Gfunc])

//supprimer un digit 
const handleDeleteItem=useCallback(async(row)=>{
  const ids=[]
  if(row.isbanned && !row.datusd){
  ids.push(row.DT_RowId.substr(1))
    const response=await Gfunc.axiosPostAction(service ,"deldigs",{ids:ids})
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
        handleAlert(true,'success',"Le digit est supprimé.")
        handleSetData(row,"DT_RowId")
        setDel(row.DT_RowId)
      })
  }
}
},[Gfunc,handleSetData,handleAlert])


//fetch produit handler
const handleOpenProd = useCallback(async () => {
  if(prod.length===0)
  fetchProd()
  },[prod,fetchProd])

//fetch produit handler
const handleOpenProdPrv = useCallback(async () => {
  if(produits.length===0)
  fetchProdPrv()
  },[produits,fetchProdPrv])

  //fetch fournisseur handler
const handleOpenFrns = useCallback(async () => {
  if(frns.length===0)
  fetchFrns()
  },[frns,fetchFrns])

  //fetch fournisseur handler
const handleOpenLot = useCallback(async () => {
  if(lot.length===0)
  fetchLot()
  },[lot,fetchLot])

  const HandleChange=useCallback((e,row)=>{
    var checkk =[...check]
    const tmp=parseInt(row.DT_RowId.substr(1));
    if(e.target.checked===true){
      if(!checkk.includes((tmp))){
        checkk.push(parseInt(tmp))
        let inf=checkInfo; inf[tmp]=row.isbanned
        setCheckInfo(inf)
      }
   }
   else{
       let index = checkk.indexOf(tmp);
       if(index>-1)checkk.splice(index,1)
   }
   setCheck(checkk)
  },[check,checkInfo])

  const Ch=({datach,setCheck,ch})=>{
    const test=(val)=>{
      setCh(val)
      if(val===true){
        let checkk=[]
        let inf=checkInfo; 
        lodashClonedeep(datach).map(e=>{
           let tmp=parseInt(e.DT_RowId.substr(1))
           checkk.push((tmp))
           inf[tmp]=e.isbanned
           return true
        })
        setCheckInfo(inf)
        setCheck(checkk)
      }else{
        setCheckInfo({})
        setCheck([])
      }  
    }
    return(
      <FormControlLabel control={<Checkbox  className={classes.checkStelectableRow} defaultChecked={ch}
                    onChange={e=>test(e.target.checked)} inputProps={{ 'aria-label': 'controlled' }}/>}
                 />
    )
  }

const columns =useMemo(
    () => [ 
      {
        name:<Ch datach={datach} setCheck={setCheck} ch={ch}/>,
        width:"55px",
        cell: (row) => {
          return (
            <div>
               <FormControlLabel control={<Checkbox checked={check.includes(parseInt(row.DT_RowId.substr(1)))} className={classes.checkStelectableRow}
                  onChange={e=>HandleChange(e,row)} inputProps={{ 'aria-label': 'controlled' }}/>}
               />
            </div>
           );
         },
      },
      {
          name: "DIGIT",
          minWidth:'250px !important',
          selector: row=>row.digit,
          sortField: "digit",
          sortable: true,
          cell: (row) => {
            if(del===row.DT_RowId){                
              handleSetData(row,'DT_RowId')      
            }
           if(libre.length!==0){
           libre.map((item)=>{
             if(item.DT_RowId===row.DT_RowId && row.isbanned===true){
                row.isbanned=false;
             }
             return true
            })
            setTimeout(() => { setLibre('')},100) ;            }
            return (
              <div>
                <ContentEditable
                html={row.digit}
                name="digit"
                data-column="item"
                className="content-editable"
                disabled={false}
                onBlur={e => handleOnEdit(e,row,'digit')}
                title={(!row.datupd)?"Entré le: "+row.datin +' par: ' +row.agent:"Édité le: "+row.datupd +' par: ' +row.usrupd}/>
                </div>
             );
           }
      },
      {
        name: "SERIAL",
        minWidth:'250px !important',
        selector: row=>row.serial,
        sortField: "serial",
        sortable: true,
        cell: (row) => {
            return (
              <div>
                <ContentEditable
                  html={row.serial}
                  name="serial"
                  data-column="item"
                  className="content-editable"
                  disabled={false}
                  title={(!row.datupd)?"Entré le: "+row.datin +' par: ' +row.agent:"Édité le: "+row.datupd +' par: ' +row.usrupd}
                  onBlur={e => handleOnEdit(e,row,'serial')}/>
            </div>
             );
           },
      },
      {
        name: "FOURNISSEUR",
        minWidth:'150px !important',
        selector: row=>row.frns,
        sortField: "frns",
        sortable: true,
        cell: (row) => { 
            return (
              <div>
                {(row.isbanned)?
                <AutocompleteS
                service={service} action={'upd'} 
                defaultValue={row.frns} row={row} list={frns} 
                id={'idfrns'} name='frns' attr='name'
                onOpen={handleOpenFrns} handleAlert={handleAlert}/>:row.frns}
                </div>
            );
        },
      },
      {
        name: "PRODUIT",
        minWidth:'280px !important',
        selector: row=>row.prod,
        sortField: "prod",
        sortable: true,
        cell: (row) => { 
            return (
              <div>
                {(row.isbanned)?
                  <AutocompleteS
                  service={service} action={'upd'} 
                  defaultValue={row.prod} row={row} list={prod} 
                  id={'idprod'} name='prod' attr='name'
                  onOpen={handleOpenProd} handleAlert={handleAlert}/>:row.prod}
              </div>
             );
        },
      },
      {
        name: "LOT",
        minWidth:'150px !important',
        selector: row=>row.lot,
        sortField: "lot",
        sortable: true,
        cell: (row) => { 
            return (  
              <div>
                {(row.isbanned)?
                  <AutocompleteS
                  service={service} action={'upd'} 
                  defaultValue={row.lot} row={row} list={lot} 
                  id={'idlot'} name='lot' attr='name'
                  onOpen={handleOpenLot} handleAlert={handleAlert}/>:row.lot}
            </div>
           );
              }
      },
      {
        right:true,
        cell: (row) => {
            return (
                <div className="rowGrid" id='twoIcons'>
                     <div onClick={()=>flagMan(row)} title="Liberer">{(row.isbanned)?<CheckCircleOutline className={classes.greenIcon} fontSize="small"/>:<NotInterested className={classes.redIcon} fontSize="small"/>}</div>  
                     <div title="Supprimer"><Delete className={classes.redIcon}  fontSize="small" onClick={()=>{handleDeleteItem(row)}} /></div>         
                </div>
             );
        },   
      },  
    ],[handleOnEdit,flagMan,frns,handleDeleteItem,libre,lot,prod,del,handleSetData,handleOpenFrns,handleOpenLot,handleOpenProd,
       classes,check,datach,handleAlert,service,HandleChange,ch]);
  
   
  const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage+"&search="+search+filter)=>{
      setLoading(true);
      const response=await librery.axiosSort(column,sortdirection,page,size+"&mod=MYDAY",service)
      if(response.data.err&&response.data.err!=="-1"){
        handleAlert(true,'error',response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
        setLoading(false);
      }) 
    }    
  }

  //recherche serverside
  const handleSearch=async(size = perPage)=>{
    var elem = document.getElementById("searchBar")
    if(elem) {
      elem.focus();
      elem.select()
    }
    setLoading(true);
    setCheck([])
    setCh(false)
    setCurrentPage(1)
    const recherche=search;
    const response=await librery.axiosSearch(service,recherche+filter,1,size,"get")
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
      if(response.data.data!==undefined){
        setData(response.data.data);
        setDatach(response.data.data);
        setTotalRows(response.data.recordsFiltered);
      }else setData([])
    })   
    setLoading(false);
    return recherche
  }
}

//Lancer une recherche en appuant sur entrer ou bien a chaque tape de clavier si le livesearch est activé (To activate live search go to param.jsom in public folder)
function liveSearch(e,search){
  if(param.liveSearch&&search.length>3){
     e.preventDefault();
     handleSearch();
  }else{ if (e.keyCode === 13) {
            e.preventDefault();
            handleSearch();
          }
  }
}
// Lancer une recherche depuis l'icone
function liveSearch2() {
  handleSearch();
}

//valider le formulaire de prelevement 
const handleSubmit= async() =>{
  setLoadingSubmit(true)
    if(selectP === '') setErrorPr(true);
    if(qte === '') setErrorQ(true);
    if(prx === '') setErrorP(true);
    if(selectP!=='' && qte!=='' && prx!==''){
      const response = await Gfunc.axiosPostAction(service, "getprv", { prod: selectP, qte: parseInt(qte), prix: JSON.parse(prx) })
     if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else{
     Gfunc.axiosResponse(response,()=>{
      if (response.data.jData) { 
         fetch(1)
         setSelectP("")
         setQte("")
         setPrx("")
         setReset(!reset)
        } 
    })
  }
    const elem=document.getElementById('iProd')
    if(elem){
      elem.focus()
    }
    }
  setLoadingSubmit(false)
}

//valider formulaire avec touche clavier 
  const detectTouche= (e)=>{
    if (e.keyCode === 13 && (e.target.name==="addBtn")) {
        setErrorP(false);
        setErrorPr(false);
        setErrorQ(false);
        if(prx === '') setErrorP(true);
        if(qte === '') setErrorQ(true);
        if(selectP === '') setErrorPr(true);
        if(prx===''||qte===''|| selectP===''){
          handleAlert(true,'error',"Veuillez remplir tous les champs!")
        }
      }
    }
  
 //supprimer un ensemble d'elemets
const HandleDeletItems=useCallback(async()=>{
  const ids=[]
  if(check.length!==0){
    const x=window.confirm("Êtes-vous sure de supprimer ces digits?");
    if(x){
      check.map(e=>{
        if(checkInfo[e]) ids.push(e)
        return true
      })
    if(ids.length!==0){ 
      const response=await Gfunc.axiosPostAction(service ,"deldigs",{ids:ids})
      if(response.data.err&&response.data.err!=="-1"){
        handleAlert(true,'error',response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
        handleAlert(true,'success','Les digits sélectionnés sont supprimés.')
        setLibre(check)
        ids.map((id)=>{
          const ind= data.findIndex(obj=>obj.DT_RowId==="c"+id)
          data.splice(ind,1)
        return true
        })
       setCh(false)
       setCheck([])
      })
    }
    }else handleAlert(true,'error','Vous ne pouvez pas supprimer les digits libres!')
  }
}else handleAlert(true,'error','Veuillez sélectionner au moins un élément!')
},[Gfunc,data,check,handleAlert,checkInfo])


// liberer multiple ligne
const handleLiberer=useCallback(async()=>{
  const ids=[]
  if(check.length!==0){
    check.map(e=>{
      if(checkInfo[e]) ids.push(e)
      return true;
    })  
    if(ids.length!==0){
      const response=await Gfunc.axiosPostAction(service ,"releasedigs",{ids:ids})
      if(response.data.err&&response.data.err!=="-1"){
        handleAlert(true,'error',response.data.err)
      }else{
        Gfunc.axiosResponse(response,()=>{
          handleAlert(true,'success','Les digits selectionnés sont liberés.')
          setLibre(check)
          ids.map((id)=>{
          const ind= datach.findIndex(obj=>obj.DT_RowId==="c"+id)
          datach.splice(ind,1)
          return true
          })
          setCh(false)
          setCheck([])
        })
    }
    }else handleAlert(true,'error',"Les digits sélectionnés sont déjà libérés!")
  }else handleAlert(true,'error','Veuillez sélectionner au moins un élément!')
 },[Gfunc,check,datach,handleAlert,checkInfo])


   //handle liberer All
   const handleLibererAll=useCallback(async()=>{
    const response=await Gfunc.axiosPostAction(service ,"releaseall")
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else{
      if(response.data.jData>1){
        handleAlert(true,"success",response.data.jData+" éléments ont été liberés")
      }
      if(response.data.jData===1){
        handleAlert(true,"success",response.data.jData+" élément a été liberé")
      }
      if(response.data.jData===0){
        handleAlert(true,"warning"," Aucun élément n'a été liberé")
      }
      fetch(1);
    }
},[handleAlert,Gfunc,service,fetch])
 
  //code jQuery pour boucler le focus des elements
$('form').each(function(){
  var list  = $(this).find('input*[tabindex],#last*[tabindex]').sort(function(a,b){ return a.tabIndex < b.tabIndex ? -1 : 1; }),
      first = list.first();
  list.last().on('keydown', function(e){
      if( e.keyCode === 9 ) {
          first.focus();
          return false;
      }
  });
});

return (
    <div className="content" >
      <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
      <BackdropCompo className={classes.backdrop} open={loadingSubmit}/>
      <fieldset className="fieldsetTop" id="plv">
          <legend>Prélèvement digital</legend>
        <ValidatorForm noValidate id="formAdd" ref={useRef(null)} onSubmit={handleSubmit} onKeyPress={e=>detectTouche(e)}  >
            <div className="rowGrid" id="row-1">
              <Autocomplete
                   key={"p"+reset}
                   disableClearable
                   className='smallAuto' 
                   id="iProd"
                   onChange={(e,newValue) =>{
                    const index=Gfunc.getIndex(produits,newValue,"name")
                    setQte(produits[index].defQte);setPrx(produits[index].vente);setSelectP(produits[index].id);setErrorPr(false)}} 
                    onFocus={handleOpenProdPrv}
                   // value={(selectP!=='')?(selectP):null}
                    options={produits.map((option) => option.name)}
                    renderInput={(params) => (
                   <TextField 
                                    error={errorPr}
                                    {...params}
                                    inputProps={{...params.inputProps,tabIndex:'1'}}
                                    label="Produit"
                                    size="small" 
                                    variant='outlined'
                                    autoFocus
                                    //helperText={(errorFrns)?"Veuillez sélectionner un Fournisseur":null}
                                    required/>
               )} />
              <TextField type='number' className='smallText' id="input1" variant="outlined" size="small"   label="Quantité"  inputProps={{ tabIndex: "2",className:classes.input }}
                  value={qte}
                  onChange={e =>{setQte(e.target.value);setErrorQ(false)}} 
                  required  error={errorQ}
                  />
              <TextField type='number' className='smallText' id="input1" variant="outlined" size="small"   label="Prix:(Da)"  inputProps={{ tabIndex: "2",className:classes.input }}
                  value={prx}
                  onChange={e =>{setPrx(e.target.value);setErrorQ(false)}} 
                  required  error={errorP}
                  />
              <FormControl  >
              <Button className={classes.smallBtn} id='last' tabIndex="4" margin="dense" variant="contained"  type='submit'  name="addBtn" color="primary" startIcon={<Storage/>}> Prélèvement </Button>
              </FormControl>
         </div>
        </ValidatorForm>
        </fieldset>
      <Paper className={classes.paperBody} elevation={3}>
        <div className='table-header'>
          <div className='button-flex-wrapper'>
                <Button className={classes.topTabBtn} variant="contained"  type='submit' id="bouton" color="primary" onClick={ HandleDeletItems } startIcon={<Delete />}>Supprimer</Button>  
                <Button   className={classes.topTabBtn}  variant="contained"  color="primary" onClick={handleLiberer} startIcon={<Iconn path={mdiCheckboxMarkedCircleOutline} color="black" size="20px"/>} >Liberer</Button>  
            <Button   className={classes.topTabBtn}  variant="contained"  color="primary" onClick={handleLibererAll} startIcon={<Iconn path={mdiCheckboxMultipleMarkedCircleOutline} color="black" size="20px"/>} >Liberer tous</Button>            </div>
          <div className='search'>
                <Box sx={{ display: 'grid', alignItems: 'center',gridTemplateColumns:"auto auto 150px",gridGap:"3px"}}>
                      <Search className={classes.blackIcon} sx={{ color: 'action.active' ,mr: 1, my: 0.5 }} onClick={(e)=>liveSearch2(e)}/>
                      <TextField  className="smallText" id="searchBar" variant="outlined"  label="Recherche" value={search} size="small" margin='dense' onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} />
                      <TextField className="smallText" id="searchBar" variant="outlined" label="Digit" value={digSearch} size="small" margin='dense' onKeyUp={e => liveSearch(e)} onChange={e => setdigSearch(e.target.value)} />
                </Box>
           </div>
        </div>
        <div style={{margin:"0 1%",minHeight:"20px",textAlign:"center"}}>{(check.length)?"Éléments sélectionnés: "+check.length:" "}</div>
      {(data.length!==0||doSearch)?
        <div>
          <DTable
             currentPage={currentPage}
             data={data}
             columns={columns}
             loading={loading}
             fetch={fetch}
             handleOnSort={handleOnSort}
            // resetDP={resetDP}
             totalRows={totalRows}
             perPage={data.length}
             setPerPage={setPerPage}
             setCurrentPage={setCurrentPage}
             paginationRowsPerPageOptions={[10,25,50,75,100]}
             customStyles={customStyles}
             conditionalRowStyles = { conditionalRowStyles }/>
           </div>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData || records===0)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
      </Paper>
    </div>    

  );
}