import React,{useState,useRef,useCallback} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog, TextField,FormControlLabel,Checkbox,Divider,CircularProgress,Backdrop} from '@material-ui/core';
import Draggable from 'react-draggable';
import {Print,ArrowForward,RotateLeft} from '@material-ui/icons';
import { ValidatorForm} from 'react-material-ui-form-validator';
import {axiosPostAction, axiosResponse} from'../../../Gfunc'
import { useStyles, paperPropsRep } from '../../../css/muiStyle';
import {AlertCompo} from '../../../js/components'
import Autocomplete from '@material-ui/lab/Autocomplete';
import DialogPrint from './dig_part_imprimer_el'


function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Dig_prt_reimprimer(props) {
    const param=props.param
    const Gfunc=require('../../../Gfunc')
    const [open, setOpen] = useState(false);
    const [data, setData] =useState([]);
    const [tous, setTous] =useState([]);
    const [dataa, setDataa] =useState([]);
    const [severity, setSeverity] =useState("info");
    const [openP, setOpenP] = useState(false);
    const [openA, setOpenA] =useState(false);
    const [desactiver, setDesactiver] =useState(false);
    const [checked, setChecked] =useState(true);
    const [change, setChange] =useState(false);
    const [action, setAction] =useState(false);
    const [disabledClt, setDisabledClt] =useState(false);
    const [disabledCom, setDisabledCom] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [deb, setDeb] =useState('');
    const [fin, setFin] =useState('');
    const [clt, setClt] =useState([]);
    const [dig, setDig] =useState([]);
    const [digselected, setDigSelected] =useState([]);
    const [com, setCom] =useState([]);
    const [selectCom, setSelectCom] =useState('');
    const [selectClient, setSelectClient] =useState('');
    const service="_prt.php"
    const classes=useStyles();

   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpenA(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpenA(false)
    }, 5000);
  }
    const handleClickOpen = () => { 
      setOpen(true);setSelectClient('');setSelectCom('');setDisabledClt(false);setDisabledCom(false);setChange(false);;setTous([])
      setDeb('');setFin('');setDesactiver(false);setDigSelected([]);setData([]);setSelectClient('')
    };
    const handleClose = () => { setOpen(false); };
    const handleCloseP = () => { setOpenP(false);setData([]) };

 //fetch client 
 const fetchCli= useCallback(async(id) => {
    const response = await Gfunc.axiosPostAction("_cli.php","gets",{})
    if(response.data.err){
      setOpen(false)
     props.handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
        setClt(response.data.jData);   
    })
  },[Gfunc,props]);

//handle the call for fetch client
const handleOpenClt=(event)=>{
    if(clt.length===0)
    fetchCli();
}

 //fetch commercial 
const fetchCom= useCallback(async(id) => {
    const response = await Gfunc.axiosGetAction("_usr.php","getcom")
    if(response.data.err){
      setOpen(false)
     props.handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
        setCom(response.data.jData);   
    })
  },[Gfunc,props]);

//handle the call for fetch commercial
const handleOpenCom=(event)=>{
    if(com.length===0)
    fetchCom();
}

const handleChange=(e)=>{
    setChecked(!checked)
  }
const HandleValider=async()=>{
    setDig([])
    const response=await axiosPostAction(service,"getbyrange",{start:parseInt(deb),end:parseInt(fin)})
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else{
    axiosResponse(response,()=>{
      setDesactiver(true)
      if(response.data.jData){
        setDig(response.data.jData)
        setTous(response.data.jData)
      }
    })
  }
}

const reset=()=>{
  setSelectClient('');setSelectCom('');setChecked(false);setChange(false);setTous([]);
  setDesactiver(false);setDigSelected([]);setChecked(true);setDisabledClt(false);setDisabledCom(false)
}

const handleSubmit= async(e) =>{
    setAction(true)
    var dat=[]
  if(digselected.length!==0){
      const owner=(selectCom!=='')?com[Gfunc.getIndex(com,selectCom,"name")].id.toString():
                  (selectClient!=='')?clt[Gfunc.getIndex(clt,selectClient,"name")].id:''
      const produit=dig[Gfunc.getIndex(dig,digselected[0],"tiketId")].produitId
      const response=await axiosPostAction(service,"reprint",{produitId:produit,ids:digselected,
                                                            logo:checked,owner:owner})
   setAction(false)
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else{
       axiosResponse(response,()=>{
         if(response.data.jData){
          dat.push(response.data.date)
          dat.push(response.data.hour)
          setData(response.data.jData)
          setOpenP(true)
          setDataa(dat)
        }
       })
     }
  }else{ handleAlert(1,'error','Vous devez sélectionner les digits à réimprimer.') }
}

const handleChangeCheck=useCallback((e,item)=>{
   var array=digselected
  if(e.target.checked===true){
    array.push(item.tiketId)
  }else{
    const ind=array.indexOf(item.tiketId);
    array.splice(ind,1)
  }
  setDigSelected(array)
},[digselected])


const handleSelectAll=useCallback((e)=>{
  var tab=[]
  tous.map((el)=>{
    tab.push(el.tiketId)
  })
  if(e.target.checked===true){
    setDigSelected(tab)
  }else{
    setDigSelected([])
  }
},[tous,handleChangeCheck])


const PrdName=(value)=>{
  if(value.includes("Mobilis")){return value.replace("Mobilis", "M-").toString()}
  if(value.includes("Ooredoo")){return value.replace("Ooredoo", "O-").toString()}
  if(value.includes("Djezzy")){return value.replace("Djezzy", "D-").toString()}
  if(value.includes("Idoom")){return value.replace("Idoom", "I-").toString()}
}


  return (
    <div>
      <Button className={classes.topTabBtn} variant="contained" color="primary" onClick={handleClickOpen} startIcon={<Print/>}>Réimprimer</Button>
      <Dialog
            open={open}
            id='frns'
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsRep}
      >
         <Backdrop className={classes.backdropDiag} open={action} >
           <CircularProgress color="inherit" />
         </Backdrop>  
        <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >Réimprimer un ticket</DialogTitle>    
          <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true}/>
            <DialogContent>
            {(desactiver)?<div onClick={reset}><RotateLeft className={classes.greenIcon} id='icon-reset' /></div>:<></>}
                <ValidatorForm ref={useRef(null)} id="Form" onSubmit={handleSubmit} onError={errors => console.log(errors)} >
                <fieldset className="fieldsetTop2" id="re">
                <legend>Choix des produit</legend>
                    <div id='reprint_row_1'>
                       <TextField type='number' className='smallText' variant="outlined" size="small" label="TID début" id="id" name="id" 
                            value={deb} onChange={e =>setDeb(e.target.value)}  required  disabled={desactiver}/> 
                        <TextField type='number' className='smallText' variant="outlined" size="small" label="TID fin" id="if" name="if" 
                            value={fin} onChange={e =>setFin(e.target.value)}  required disabled={desactiver} />       
                        <Button  className={classes.smallValidate} form='form' variant="contained" onClick={()=>HandleValider()}
                                 color="primary" id='last'  disabled={desactiver}>{<ArrowForward/>}</Button>

                      </div>
                    </fieldset><br/>
                   <div>
                   {
                      (desactiver)?
                      <div id='tab-reprint'>
                       <div>
                        <div id='reprint_row_22'>
                           <Autocomplete
                                disableClearable
                                disabled={disabledCom}
                                className='smallAuto'
                                onChange={(e,newValue) =>{setSelectCom(newValue);setDisabledClt(true)}} 
                                onFocus={handleOpenCom}
                                value={(selectCom!=='')?(selectCom):null}
                                options={com.map((option) => option.name)}
                                renderInput={(params) => (
                                <TextField 
                                    {...params}
                                    label="Commercial" size="small"  margin='dense' variant='outlined' 
                                     />
                                )} />
                             <Autocomplete
                                disableClearable
                                disabled={disabledClt}
                                className='smallAuto'
                                onChange={(e,newValue) =>{setSelectClient(newValue);setDisabledCom(true)}} 
                                onFocus={handleOpenClt}
                                value={(selectClient!=='')?(selectClient):null}
                                options={clt.map((option) =>option.name)}
                                renderInput={(params) => (
                                <TextField 
                                    {...params}
                                    label="Client" size="small"  margin='dense' variant='outlined' 
                                                                        //autoFocus
                                     />
                                )} />

                         </div>
                         <div id='reprint_row_2'>
                          <FormControlLabel
                            control={<Checkbox checked={checked} onChange={e=>handleChange(e)} name="checked"color="primary" /> }
                            label="Logo"
                          />
                        </div>
                       </div>
                       <div >
                         <div id='reprint-header'>
                         <b>Les digits à réimprimer:</b>
                          <FormControlLabel  
                                    control={<Checkbox defaultChecked={change} onChange={e=>{handleSelectAll(e);setChange(e.target.checked)}} color="primary" /> }
                                    className={classes.checkGreen}
                            />
                         </div>
                          <div id='reprint-select'>
                          {
                            (dig.length!==0)?
                              dig.map((item,index) => {
                              return(
                              <div >
                              <div id='card-check'>
                                <div key={(Math.floor(Math.random() * (1000 - 1 + 1)) + 1)+index}> 
                                  <FormControlLabel  
                                    control={<Checkbox defaultChecked={digselected.indexOf(item.tiketId)>-1} onChange={e=>{handleChangeCheck(e,item);}} color="primary" /> }
                                    label={item.produit} className={classes.checkGreen}
                                  />
                                </div>
                                <div title='Serial'><b>{item.serial}</b></div>
                                <div id='card-tick' title='TID'><b>{item.tiketId}</b></div>
                              </div>
                               <Divider />
                               </div>
                              )})
                            :null
                          }
                          </div>
                       </div>
                      </div>:<></>
                   }

                </div>
              </ValidatorForm>
              <div>
                {
                  (data.length!==0)?
                     <DialogPrint open={openP} handleCloseP={handleCloseP} handleClose={handleClose} data={data} dataa={dataa}
                      logo={checked} callback={()=>props.callback()} copie={true} param={param} setDatadet={props.setDatadet} 
                      produit={PrdName(data[0].nom)}
                      owner={(selectCom!=='')?com[Gfunc.getIndex(com,selectCom,"name")].id.toString():(selectClient!=='')?clt[Gfunc.getIndex(clt,selectClient,"name")].id:""}/>
                  :<></>
                }
              </div>
           </DialogContent>
            <DialogActions>
            <Button variant="contained" type='submit' form="Form" disabled={!desactiver}
            color="primary" startIcon={<Print />}>VALIDER</Button>
            <Button variant="contained" onClick={handleClose} color="secondary">FERMER</Button>
            </DialogActions>
      </Dialog>
      </div>
  );
}
