import React,{useState} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog} from '@material-ui/core';
import Draggable from 'react-draggable';
import DataTable from "react-data-table-component";
import { useStyles,paperProps, paperPropsFixed, paperPropsBigFixed } from '../../../css/muiStyle';
import {customStyles, numberColumn}from '../../../css/datatableStyle'
import {ReactComponent as Creance} from '../../../img/icons/creance.svg'



//add new marque
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Tol_dg2_sld(props) {
    const param=props.param
    const Gfunc=require('../../../Gfunc')
    const [open, setOpen] =useState(false);
    const [loading, setLoading] =useState(false);
    const [data, setData] =useState([]);
    const addTitle="Soldes niv. 2"
    const service="_usd.php"
    const classes=useStyles();

     
    const fetch= async() => {
      setLoading(true)
      const response=await Gfunc.axiosPostAction(service,'getsoldh2',{date:props.date, prodId: props.prodId})
      if(response.data.err&&response.data.err!=='-1'){
        alert(response.data.err)
        setOpen(false)
      }else
        Gfunc.axiosResponse(response,()=>{
          if(response.data.jData!==undefined){
            setData(response.data.jData);
        }
      })
      setLoading(false)
    }

    const handleClickOpen = () => { 
      fetch()
      setOpen(true); 
    };

    const handleClose = () => { setOpen(false); };
 

    const columns = [
        {
          name: "heure",
          selector: row=>row.heure,
          sortable: true,
        },
        {
          name: "Soldes",
          style:numberColumn,
          selector: row=>row.solde,
          sortable: true,
          cell: (row) => {
            return (
              <div>
                {new Intl.NumberFormat().format(row.solde)} 
              </div>
             );
           },
        },
        {
          name: "Ecart",
          style:numberColumn,
          selector: row=>row.ecart,
          sortable: true,
          cell: (row) => {
            return (
              <div>
                {new Intl.NumberFormat().format(row.ecart)} 
              </div>
             );
           },
        },
      ];
  return (
    <div>
      <Button className={classes.topTabBtn} variant="contained" color="primary" onClick={handleClickOpen} startIcon={<Creance fill='black' style={{height:'20px',width:'20px',padding:"1px"}}/>}>Soldes niv. 2</Button>
      <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsFixed}
      >
        <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >{addTitle}</DialogTitle>
            <DialogContent>
              {/*<Paper className='crnsTotal'>
               <Typography><b>Total:</b> {Intl.NumberFormat().format((data.length!==0)?data[data.length-1]:0)}</Typography>
  </Paper>*/}
              <DataTable 
                  columns={columns}
                  data={data.slice(0, -1)}
                  noDataComponent={param.ErreurData}
                  progressPending={loading}
                  highlightOnHover
                  noHeader={true}
                  customStyles={customStyles}
                  paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}            
                />
            </DialogContent>
            <DialogActions>
            <Button variant="contained" onClick={handleClose} color="secondary">FERMER</Button>
            </DialogActions>
      </Dialog>
      </div>
  );
}