import React,{ useState, useEffect ,useCallback,useMemo, useRef} from "react";
import TextField from '@material-ui/core/TextField';
import DraggableDialog from "./modals/exp_ord_add";
import { Search, SearchRounded} from '@material-ui/icons';
import {Paper, Box, Tooltip, Checkbox, FormControlLabel, Button} from '@material-ui/core';
import {conditionalRowStyles,customStyles, numberColumn}from '../../css/datatableStyle'
import { useStyles } from '../../css/muiStyle';
import '../../css/exploitation.css';
import {AlertCompo,NotAllowedComponent,NoDataComponent} from "../../js/components"
import DTable from "../../compo/DTable";
import { mdiDelete, mdiSim, mdiCloseCircle, mdiCloseCircleMultiple, mdiCircleMultiple, mdiTimerOff } from "@mdi/js";
import Iconn from "@mdi/react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { fr } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";


export default function Exp_ord (props){
  const param=props.param
  const Gfunc=require('../../Gfunc')
  const librery=require('../../js/data_table_librery')
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [called, setCalled] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(25);
  const [type, setType] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [search,setSearch]=useState('');
  const [,setIcon]=useState('');
  const[noData,setNoData]=useState(false);
  const[doSearch,setDoSearch]=useState(false);
  const[notAllowed,setNotAllowed]=useState(false);
  const [severity, setSeverity] =useState("info");
  const [open, setOpen] =useState(false);
  const [messageA, setMessageA] =useState('');
  const[errMessage,setErrMessage]=useState('');
  const[records,setRecords]=useState('');
  const service='_ord.php'
  const srv="ord"
  const classes=useStyles()
  const [resetDP, setResetDP] = useState(false);
  const [selectDateD,SetSelectDateD]=useState(new Date())
  const [echecs, setEchecs] = useState(false);
  const filter="&search="+search+"&d="+Gfunc.formatDate(selectDateD)+((echecs)?"&r=1":"");
  const [check,setCheck]=useState([])
  const [checkInfo,setCheckInfo]=useState({})
  const [clientL, setClientL] = useState([]);
  const [subClientL, setSubClientL] = useState([]);
  const [bdlL, setBdlL] = useState([]);
  const [simL, setSimL] = useState([]);
  const [stats, setStats] = useState([]);
  const [ch, setCh] = useState(false);
  const [datach, setDatach] = useState([]);
  const lodashClonedeep = require('lodash.clonedeep');
 


   //handle alerts properties
   const handleAlert=useCallback((open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  },[])


  //fetch stats
  const fetchStats= useCallback(async(serv="_ord.php", act = "getdstat") => {
    const response = await Gfunc.axiosPostAction(serv,act,{date:Gfunc.formatDate(selectDateD)});
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData!==undefined){
        setStats(response.data.jData);
      }  
    })
  },[Gfunc,selectDateD,handleAlert]) 
  
  const fetch= useCallback(async(page, size = perPage) => {
    setCalled(true);
    setLoading(true);
    setCh(false)
    fetchStats()
    const response=await librery.axiosGet(page,service,size+filter)
    if(response.data.err&&response.data.err!=="-1"){
        setErrMessage(response.data.err)
        setNotAllowed(true)
    }else{
    Gfunc.axiosResponse(response,()=>{
      setRecords(response.data.recordsFiltered)
      if(response.data.data!==undefined){
        setData(response.data.data);
        setDatach(response.data.data)
        setTotalRows(response.data.recordsFiltered);
        setLoading(false);
        setNoData(true)
        setDoSearch(true)
      }else{
        setDoSearch(false)
        setData([])
      }
    })
  }
  },[librery,Gfunc,perPage,filter,fetchStats])

  //fetch Client
  const fetchClient= useCallback(async(serv="_cli.php", act = "gets") => {
    const response = await Gfunc.axiosPostAction(serv,act,{});
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData!==undefined){
        setClientL(response.data.jData);
      }  
    })
  },[Gfunc,handleAlert])


     //fetch client handler
 const handleOpenClient = useCallback(async () => {
  if(clientL.length===0)
  fetchClient()
  setSubClientL([])
  },[clientL,fetchClient])

  //fetch Client
  const fetchSubClient= useCallback(async(client) => {
    const response = await Gfunc.axiosPostAction("_cli.php","getsubs",{owner:client});
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData!==undefined){
        setSubClientL(response.data.jData);
      }else setSubClientL([])
    })
  },[Gfunc,handleAlert])


  //fetch Client
  const fetchBdl= useCallback(async(serv="_bdl.php", act = "gets") => {
    const response = await Gfunc.axiosPostAction(serv,act,{});
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData!==undefined){
        setBdlL(response.data.jData);
      }  
    })
  },[Gfunc,handleAlert])

        //fetch Sim
        const fetchSim= useCallback(async(serv="_sim.php", act = "gets") => {
          const response = await Gfunc.axiosGetAction(serv,act);
          if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,'error',response.data.err)
          }else
          Gfunc.axiosResponse(response,()=>{
          if(response.data.jData!==undefined){
              setSimL(response.data.jData);  
          }  
          })
      },[Gfunc,handleAlert])
  
      //fetch sim handler
      const handleOpenSim = useCallback(async () => {
          if(simL.length===0)
          fetchSim()
          },[simL,fetchSim])


     //fetch client handler
 const handleOpenBdl= useCallback(async () => {
  if(clientL.length===0)
  fetchBdl()
  },[bdlL,fetchBdl])


  useEffect(() => {
    if(!called)
    fetch(1);
  }, [fetch,called]);
  

const handleLockIcon=(val)=>{
  setIcon(val);
}
useEffect((val) => {
handleLockIcon(val);
}, []);

const handleDeleteItem=useCallback(async(row)=>{
  const response=await Gfunc.axiosPostAction(service,"del",{ids:[row.DT_RowId.substr(1)]})
  if(response.data.err&&response.data.err!=='-1'){
    handleAlert(true,"error",response.data.err)
  }else
   Gfunc.axiosResponse(response,()=>{
    if(response.data.jData){
        const objIndex =  data.findIndex((obj => obj.DT_RowId === row.DT_RowId )); 
        const nouveauTableau = [...data]; 
        nouveauTableau.splice(objIndex,1)
        setData(nouveauTableau);
     }
    })
},[Gfunc,service,data])


 //supprimer un ensemble d'elemets
 const HandleDeletItems=useCallback(async()=>{
  const ids=[];
  if(check.length!==0){
    const x=window.confirm("Êtes-vous sure de supprimer ces commandes?");
    check.map(e=>{
      if(checkInfo[e]) ids.push(e)
      return true
    })
    if(ids.length>0){
      if(x){
      const response=await Gfunc.axiosPostAction(service ,"del",{ids:ids})
      if(response.data.err&&response.data.err!=="-1"){
        handleAlert(true,'error',response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
        handleAlert(true,'success','Les commandes sélectionnées sont supprimées.')
        const dat=[...data];
        ids.map((id)=>{
          const ind= dat.findIndex(obj=>obj.DT_RowId==="c"+id)
          dat.splice(ind,1)
         // Gfunc.removeItemsByAtt(data, id,"DT_RowId")
          return true
        })
        setData(dat)
        setCh(false)
        setCheck([]);
      })
    }
  }}else handleAlert(true,'error','Vous ne pouvez pas supprimer les commandes qui sont rejetées ou validées!')
}else handleAlert(true,'error','Veuillez sélectionner au moins un élément!')
},[Gfunc,check,data,handleAlert])

const handleRejectOrder=useCallback(async(row)=>{
  const response=await Gfunc.axiosPostAction(service,"rej",{ids:[row.DT_RowId.substr(1)]})
  if(response.data.err&&response.data.err!=='-1'){
    handleAlert(true,"error",response.data.err)
  }else
   Gfunc.axiosResponse(response,()=>{
    if(response.data.jData){
      const objIndex = data.findIndex((obj => obj.DT_RowId === row.DT_RowId));
      const nouveauTableau = [...data]; 
      nouveauTableau[objIndex].status = "Echec"
      nouveauTableau[objIndex].statusId = 3
      setData(nouveauTableau);
      fetchStats();
     }
    })
}, [Gfunc, service, data, fetchStats])

// liberer multiple ligne
const handleRejectOrders=useCallback(async()=>{
  const ids=[];
  if(check.length!==0){
    check.map(e=>{
      if(checkInfo[e]) ids.push(e)
      return true;
    }) 
    if(ids.length!==0)  {
      const response=await Gfunc.axiosPostAction(service ,"rej",{ids:ids})
      if(response.data.err&&response.data.err!=="-1"){
        handleAlert(true,'error',response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
        handleAlert(true,'success','Les commandes selectionnées sont rejetées avec succés.')
        let dat=[...data]
        ids.map((id)=>{
          const index=Gfunc.getIndex(data,'c'+id,'DT_RowId')
           if(index>=0){
             dat[index].status="Echec"
           }
         return true
        })
        setData(dat)
        fetchStats()
        setCh(false)
        setCheck([]);
      })
    }
  }else handleAlert(true,'error',"Les commandes sélectionnées ne peuvent pas être rejetées!")
  }else handleAlert(true,'error','Veuillez sélectionner au moins un élément!')
}, [check, Gfunc, data, checkInfo, handleAlert, fetchStats])

const HandleChange=useCallback((e,row)=>{
  var checkk =[...check]
  const tmp=parseInt(row.DT_RowId.substr(1));
  if(e.target.checked===true){
    if(!checkk.includes((tmp))){
      checkk.push(parseInt(tmp))
      let inf=checkInfo; inf[tmp]=(row.status==="Attente")
      setCheckInfo(inf)
    }
 }
 else{
     let index = checkk.indexOf(tmp);
     if(index>-1)checkk.splice(index,1)
 }
 setCheck(checkk)
},[check,checkInfo])

const Ch=({datach,setCheck,ch})=>{

  const test=(val)=>{
    setCh(val)
    if(val===true){
      let checkk=[]
      let inf=checkInfo; 
      lodashClonedeep(datach).map(e=>{
         let tmp=parseInt(e.DT_RowId.substr(1))
         checkk.push((tmp))
         inf[tmp]=(e.status==="Attente")
         return true
      })
      setCheckInfo(inf)
      setCheck(checkk)
    }else{
      setCheckInfo({})
      setCheck([])
    }  
  }
  return(  
    <FormControlLabel control={<Checkbox  className={classes.checkStelectableRow} defaultChecked={ch}
                  onChange={e=>test(e.target.checked)} inputProps={{ 'aria-label': 'controlled' }}/>}
               />
  )
}

const ExpanableComponent = ({ data }) => {

    return (
      <Paper className='ordPaper grid-wrapper'>
            <div className="flex-wrapper conAgent">
                <b>Date:</b> 
                <div className="smallSelectDT">
                {data.date.split(" ")[0]}  
                </div> 
            </div>
        <div className="flex-wrapper conAgent">
                <b>Source:</b> 
                <div className="smallSelectDT">
                {data.source}  
                </div> 
            </div>
            <div className="flex-wrapper conAgent">
                <b>Trans:</b> 
                <div className="smallSelectDT">
                <Tooltip title={data.transfertDate}><div> {data.transfertHour} </div></Tooltip>
                </div> 
            </div>
        </Paper>
    )
}
 
const columns =useMemo(
    () => [  
      {
        name:<Ch datach={datach} setCheck={setCheck} ch={ch}/>,
        width:"55px !important",
        cell: (row) => {
          return (
            <div >
               <FormControlLabel control={<Checkbox checked={check.includes(parseInt(row.DT_RowId.substr(1)))} className={classes.checkStelectableRow}
                  onChange={e=>HandleChange(e,row)} inputProps={{ 'aria-label': 'controlled' }}/>}
               />
            </div>
           );
         },
      },
      {
        name:'Heure',  
        width:'75px !important',
        selector:row=>row.date,
        sortable: true,
        sortField: "date",
        cell: (row) => {
          return (
            <Tooltip title={'Entré par: ' +row.user}>
                 <div>{row.date.split(" ")[1]}</div>
            </Tooltip>
              
           );
         },
      },
      {
        name:'Client',
        selector: row=>row.client,
        minWidth:'180px !important',
        sortable: true,
        sortField: "client",
      },
      {
      name: 'SIM',
      selector: row => row.simCode,
      minWidth: '100px !important',
      sortable: true,
      sortField: "simCode",
    },
    {
        name: "Bundle",
        selector: row=>row.bundle,
        minWidth:'120px !important',
        sortable: true,
        sortField: "bundle",
      },
      {
        name: "Prix",
        selector: row=>row.prix,
        width:'75px !important',
        style:numberColumn,
        sortable: true,
        sortField: "prix",
        cell: (row) => {
            return (
                <div>{Intl.NumberFormat().format(row.prix)+"%"} </div>
             );
           },
      },
      {
        name: "Qte",
        selector: row=>row.qte,
        style:numberColumn,
        width:'100px !important',
        sortable: true,
        sortField: "qte",
        cell: (row) => {
            return (
                  <div> {(new Intl.NumberFormat().format(row.qte)+(row.sbqQte?(" +"+Intl.NumberFormat().format(row.sbqQte)):""))} </div>
             );
           },
      }, 
      {
        name: "Numero",
        selector: row=>row.numero,
        minWidth:'110px !important',
        sortable: true,
        sortField: "numero",
      },
      {
        name: "Note",
        selector: row=>row.note,
        minWidth:'300px !important',
        sortable: true,
        sortField: "note",
        cell: (row) => {
          return (
            <div className="note-container">
            {((row.note&&row.note.match(/\r/g) || []).length>=1)?
             <Tooltip title={row.note.replaceAll("\r", "/ ")}>
                  <div className="noEditibaleNote">{row.note.substring(0, row.note.indexOf("\r")) + "..."}</div>
             </Tooltip>:
               <Tooltip title={row.note?row.note:''}>
                  <div className="noEditibaleNote" >{row.note}</div></Tooltip>}
             </div>
           );
         },
      },
      {
        right:true,
        sortable: true,
        cell: (row) => {         
          return (
            (row.statusId===1)?<div  className="rowGrid" id='twoIcons'>
             <Tooltip title="Rejeter"><Iconn path={mdiCloseCircle} onClick={()=>handleRejectOrder(row)} size="20px" color="black"/></Tooltip > 
             <Tooltip   title="Supprimer"><Iconn path={mdiDelete}  onClick={()=>handleDeleteItem(row)} size="20px" color="red"/></Tooltip > 
           </div>:<></>
           );
         },
      }  
    ],[handleDeleteItem,handleRejectOrder,HandleChange,datach,check]);

  const handleOnSort = async (column, sortdirection, page = currentPage, size = perPage + filter) => {
      setLoading(true);
      const response=await librery.axiosSort(column,sortdirection,page,size,service)
      if(response.data.err&&response.data.err!=="-1"){
        handleAlert(true,'error',response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
        setLoading(false);
      }) 
    }    
  }

  //recherche serverside
const handleSearch=async(size = perPage)=>{
  setLoading(true);
  setCurrentPage(1)
  const recherche=search;
  const response = await librery.axiosSearch(service, recherche + "&d=" + Gfunc.formatDate(selectDateD) + ((echecs) ? "&r=1" : ""), 1, size, "get")
  if(response.data.err&&response.data.err!=="-1"){
    handleAlert(true,'error',response.data.err)
  }else{
  Gfunc.axiosResponse(response,()=>{
    if(response.data.data!==undefined){
    setData(response.data.data);
    setTotalRows(response.data.recordsFiltered);
    }else setData([])
  })  
   }
  setLoading(false); 
  return recherche
}

function liveSearch(e,search){
  if(param.liveSearch&&search.length>3){
     e.preventDefault();
     handleSearch();
  }else{ if (e.keyCode === 13) {
            e.preventDefault();
            handleSearch();
          }
  }
} 

function liveSearch5() {
  handleSearch();
}


  return (
    <div className="content">
        <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
        <Paper className={classes.paperBody} elevation={3}>
        <fieldset className="fieldsetTop" id="orderField">
        <legend>Commandes effectuées:</legend>
        <ValidatorForm id="formAdd" ref={useRef(null)} onSubmit={()=>fetch(currentPage)} >
            <div className="rowGrid" id="row-1">
              <div className="rowGrid" id="row-11">
              <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                   <KeyboardDatePicker disableToolbar value={selectDateD} variant="inline" placeholder="01/01/2020" onChange={date => SetSelectDateD(date)}
                                       minDate='10/10/2018' format="dd/MM/yyyy" className='smallText' margin="dense" inputVariant="outlined"
                                       invalidDateMessage="Le format de la date est incorrect" />
              </MuiPickersUtilsProvider>
              <FormControlLabel labelPlacement="end" style={{justifyContent:"left",margin:0}} control={ <Checkbox checked={echecs} onChange={e=>{setEchecs(!echecs)}} name="checked" color="primary"/> } label="Echecs"/>
              <Button  className={classes.smallBtn} variant="contained" tabIndex="3" type='submit' id="bouton" name="addBtn" color="primary" startIcon={<SearchRounded/>}>Rechercher</Button>
              </div>              
            </div>
          </ValidatorForm>
        </fieldset><br />
        <div style={{ width: "98%", margin: "10px auto" }}>
          <Paper className="button-flex-wrapper" style={{ background: "var(--secondary-light)" }}>

            <div className="ordersStats">
              {
                (stats.sims != null && stats.sims.length >= 0) ?
                  <>
                    {(stats.total != null) && <div className="staticsHeadWIcon"><Iconn path={mdiCircleMultiple} color="black" size="18px" /><div className="staticsHead"><h5>{Intl.NumberFormat().format(stats.total)}</h5><p>Total</p></div></div>}
                    {(stats.echec != null) && <div className="staticsHeadWIcon" ><Iconn path={mdiCloseCircleMultiple} color="black" size="18px" /><div className="staticsHead"><h5>{Intl.NumberFormat().format(stats.echec)}</h5><p>Echecs</p></div></div>}
                    {(stats.timeout != null) && <div className="staticsHeadWIcon" style={{ marginRight: "auto" }}><Iconn path={mdiTimerOff} color="black" size="18px" /><div className="staticsHead"><h5>{Intl.NumberFormat().format(stats.timeout)}</h5><p>Timeout</p></div></div>}

              {
                      (stats.sims != null && stats.sims.length > 0) && stats.sims.map(e => {
                  return <div className="staticsHeadWIcon"><Iconn path={mdiSim} color="black" size="18px" /><div className="staticsHead">

                    <Tooltip title="Quantité"><h5>{Intl.NumberFormat().format(e.qte)}</h5></Tooltip>
                    <Tooltip title="Nombre de transitions"><h5>{Intl.NumberFormat().format(e.nb)}</h5></Tooltip>
                    <p>{e.sim}</p></div></div>
                })
              }
                  </>
                  : null}
            </div>
          </Paper>
        </div>
            <div className='table-header'>
                <div className='button-flex-wrapper'>
                  <DraggableDialog clients={clientL} handleOpenCli={handleOpenClient} subClients={subClientL} fetchSubClient={fetchSubClient} simL={simL} handleOpenSim={handleOpenSim} bdlL={bdlL} handleOpenBdl={handleOpenBdl}
                    callback={()=>{if(currentPage===1) fetch(1);else setResetDP(!resetDP)}} />
                  <Button  className={classes.topTabBtn}  variant="contained" color="primary" onClick={ HandleDeletItems } startIcon={<Iconn path={mdiDelete} size="20px"/>}>Supprimer</Button>  
                  <Button  className={classes.topTabBtn}  variant="contained" color="primary" onClick={ handleRejectOrders} startIcon={<Iconn path={mdiCloseCircleMultiple} size="20px" color="black"/>}>Rejeter</Button>  

                </div>
                <div className='search'>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Search className={classes.blackIcon} sx={{ color: 'action.active', mr: 1, my: 0.5 }} onClick={(e)=>liveSearch5(e)}/>
                      <TextField className="smallText" id="searchBar" variant="outlined" label="Recherche" value={search} size="small" margin='dense' onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} autoFocus/>
                   </Box>
                  </div>
            </div>
           {
             (data.length!==0 ||doSearch)?
            <div>
              <DTable
                currentPage={currentPage}
                data={data}
                columns={columns}
                loading={loading}
                fetch={fetch}
                handleOnSort={handleOnSort}
                resetDP={resetDP}
                totalRows={totalRows}
                perPage={perPage}
                setPerPage={setPerPage}
                setCurrentPage={setCurrentPage}
                paginationRowsPerPageOptions={[15, 25, 50, 75, 100]}
                customStyles={customStyles}
                conditionalRowStyles={conditionalRowStyles}
                expandableRows
                expandableRowsComponent={ExpanableComponent}
             />
            </div>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData || records===0)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
      </Paper>
    </div>    

  );
}
