import { createTheme } from '@material-ui/core';

const palette1 = {
  primary: { light: "#EADCA6", main: "#8a5e3f", dark: "#482e1e" },//rouge
  secondary: { light: "#F3EFCC", main: "#e4d5b7", dark: "#E2C275" },//gris
  grey: { light: '#f6f6f6', main: '#ccc', dark: '#434343' },
  yellow: { light: "#FFFEA9", main: "#fbdf46", dark: "#f7bd4d" },//yello
  error: { main: "#e53935" },//rouge foncé
  success: { main: "#388e3c" },//vert
  bleu: { light: '#c5cae9', main: '#3f51b5', dark: "#0e073a" }
}

const palette2 = {
  primary: {
    light: "#a0d8d8",
    main: "#4f9696",
    dark: "#2e5e5e"
  },
  secondary: {
    light: "#F5E6CC",
    main: "#DCC5A1",
    dark: "#B89B6B"
  },
  grey: {
    light: "#ececec",
    main: "#bbbbbb",
    dark: "#3c3c3c"
  },
  yellow: {
    light: "#fef9a0",
    main: "#f8d834",
    dark: "#f4b83a"
  },
  error: { main: "#e53935" },//rouge foncé
  success: { main: "#388e3c" },//vert
  bleu: { light: '#c5cae9', main: '#3f51b5', dark: "#0e073a" }

}
export const createAppTheme = (usePalette1) => {
  const palette = usePalette1 ? palette1 : palette2;

  return createTheme({
    palette: palette,
    typography: {
      button: {
        fontSize: 13,
        fontWeight: "bold",
      }
    },
    overrides: {
      MuiAutocomplete: {
        paper: {
          width:'fit-content',
          minWidth:'100%',
          whiteSpace:"nowrap",
          fontSize:'13px !important',
        },
        option: {
          background:'white !important',
          '&[data-focus="true"]': {
            backgroundColor: '#eee !important',
          },
        },
      },
    },
  })
}
