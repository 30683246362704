import React, { useState, useMemo, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DataTable from "react-data-table-component";
import { Box, DialogActions, DialogContent, DialogTitle, Paper, Tab, Tabs, TextField, Tooltip, Typography, useTheme } from '@material-ui/core';
import Draggable from 'react-draggable';
import { Add, IndeterminateCheckBox } from '@material-ui/icons';
import { useStyles, paperPropsLarge } from '../../../css/muiStyle';
import { customStyles } from '../../../css/datatableStyle'
import { AlertCompo } from '../../../js/components';
import Iconn from '@mdi/react';
import { mdiContentCopy, mdiExpandAll } from '@mdi/js';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}


//ajouter les  bundles aux sims
function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    )
}

export default function Ref_sim_bdls(props) {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [data, setData] = useState([]);
    const [option, setOption] = useState([]);
    const [open, setOpen] = useState(false);
    const param = props.param
    const Gfunc = require('../../../Gfunc')
    const service = '_sim.php'
    const [sim, setSim] = useState({});
    const [simL, setSimL] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);
    const classes = useStyles();
    const [, setDel] = useState(-1);
    var bundle = [];
    const [severity, setSeverity] = useState("info");
    const [openA, setOpenA] = useState(false);
    const [messageA, setMessageA] = useState('');

    //handle alerts properties
    const handleAlert = (open, severity, message) => {
        setOpenA(open)
        setSeverity(severity)
        setMessageA(message)
        setTimeout(function () {
            setOpenA(false)
        }, 5000);
    }

    //get les bundles d'un sim
    const fetch = useCallback(async () => {
        const response = await Gfunc.axiosPostAction(service, "getsbndl", { ids: props.row.DT_RowId.substr(1), unsets: false })
        if (response.data.err && response.data.err !== '-1') {
            handleAlert(true, 'error', response.data.err)
        } else
            Gfunc.axiosResponse(response, () => {
                setData(response.data.jData)
            })
    }, [Gfunc, props.row.DT_RowId])

    //get les bundles non associe a l'sim 
    const fetchbundles = useCallback(async () => {
        const response = await Gfunc.axiosPostAction(service, "getsbndl", { ids: props.row.DT_RowId.substr(1), unsets: true })
        if (response.data.err && response.data.err !== '-1') {
            handleAlert(true, 'error', response.data.err)
        } else
            Gfunc.axiosResponse(response, () => {
                setOption(response.data.jData)
            })
    }, [Gfunc, props.row.DT_RowId])

    const handleSetData = useCallback((row, att) => {
        Gfunc.removeItemsByAtt(data, row.id, att)
    }, [Gfunc, data])


    //suppression d'un bundle
    const handleDeletBundle = useCallback(async (row) => {
        const response = await Gfunc.axiosPostAction(service, "delsbndl", { ids: props.row.DT_RowId.substr(1), idb: row.id })
        if (response.data.err && response.data.err !== '-1') {
            handleAlert(true, 'error', response.data.err)
        } else
            Gfunc.axiosResponse(response, () => {
                handleSetData(row, "id")
                setDel(row.id)
                fetchbundles();
                handleAlert(true, "success", 'Le bundle est supprimé')
            })
    }, [Gfunc, props.row.DT_RowId, handleSetData, fetchbundles])


    //ajouter des bundle a une sim
    const handleAddBundle = useCallback(async () => {
        if (selectedValues.length > 0) {
            var idrss = []
        selectedValues.map((opt) => {
            idrss.push(opt.id)
            return null
        }
        )
        const response = await Gfunc.axiosPostAction(service, "setsbndls", { ids: props.row.DT_RowId.substr(1), idbs: idrss })
        if (response.data.err && response.data.err !== '-1') {
            handleAlert(true, 'error', response.data.err)
        } else
            Gfunc.axiosResponse(response, () => {
                handleAlert(true, 'success', "Les bundles sont attribués à la Sim sélectionné.")
                selectedValues.map((item) => {
                    Gfunc.removeItemsByAtt(option, item.id, "id")
                    data.push(item)
                    return true;
                })
            })
        setSelectedValues([])
        } else handleAlert(true, 'Error', 'Au moins un bundle doit être sélectionné!')

    }, [selectedValues, data, option, Gfunc, service, props])

    //ajouter des bundle a une sim
    const handleCopySim = useCallback(async () => {

        const response = await Gfunc.axiosPostAction(service, "copy", { idSource: sim.id, idCible: JSON.parse(props.row.DT_RowId.substr(1)) })
        if (response.data.err && response.data.err !== '-1') {
            handleAlert(true, 'error', response.data.err)
        } else
            Gfunc.axiosResponse(response, () => {
                handleAlert(true, 'success', "Les bundles de la Sim sélectionnée sont attribués à la Sim actuelle.")
                //setOpen(false)
                fetch();
                fetchbundles();
            })

    }, [Gfunc, service, sim, props])


    const columns = useMemo(
        () => [
            {
                name: "Bundle",
                selector: row => row.name,
                sortable: true,
            },
            {
                right: true,
                cell: (row) => {

                    return (
                        <div onClick={() => handleDeletBundle(row)} title="Supprimer bundle"><IndeterminateCheckBox className={classes.redIcon} fontSize="small" /></div>
                    );
                },
            },
        ], [handleDeletBundle, classes]);


    //ouvrir la fenetre de dialog
    const handleClickOpen = () => {
        setData([])
        setOption([])
        setSelectedValues([])
        handleOpenSim()
        setSim({})
        fetch();
        fetchbundles();
        setOpen(true);
    };

    //fermer la fenetre de dialog
    const handleClose = () => { setOpen(false); };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    /* const handleChangeIndex = (index) => {
         setValue(index);
     };*/

    //fetch Sim
    const fetchSim = useCallback(async (serv = "_sim.php", act = "gets") => {
        const response = await Gfunc.axiosGetAction(serv, act);
        if (response.data.err && response.data.err !== '-1') {
            handleAlert(true, 'error', response.data.err)
        } else
            Gfunc.axiosResponse(response, () => {
                if (response.data.jData !== undefined) {
                    setSimL(response.data.jData.filter((item) => item.id !== JSON.parse(props.row.DT_RowId.substr(1))));
                }
            })
    }, [Gfunc, handleAlert])

    //fetch sim handler
    const handleOpenSim = useCallback(async () => {
        if (simL.length === 0)
            fetchSim()
    }, [simL, fetchSim])




    return (
        <div >
            <Tooltip title="Attribuer des bundles">
                <Iconn path={mdiExpandAll} onClick={handleClickOpen} size="18px" color="black" />
            </Tooltip>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                PaperProps={paperPropsLarge}
                id="diagSim"
            >
                <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >Les bundles de: <b>{props.row.simCode}</b></DialogTitle>
                <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true} />
                <DialogContent className={"simBdlContainer " + classes.dialogueContent}>

                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        <Tab label="Attribuer bundles" {...a11yProps(0)} />
                        <Tab label="Copier bundles" {...a11yProps(1)} />
                    </Tabs>

                    <>

                        <TabPanel value={value} index={0} dir={theme.direction}>
                            <fieldset className="fieldsetTop" id="simBdl">
                                <legend>Ajouter des bundles</legend>
                                {(option.length > 0) ?
                                    <div className="rowGrid" style={{ gridTemplateColumns: "auto 140px", alignItems: "center" }}>
                                        <Autocomplete
                                            multiple
                                            disableCloseOnSelect
                                            limitTags={4}
                                            className='smallAuto'
                                            id="tags-outlined"
                                            options={option}
                                            filterSelectedOptions
                                            getOptionLabel={(option) => option.name}
                                            value={selectedValues}
                                            onChange={(event, newValue) => {
                                                setSelectedValues(newValue);
                                            }}
                                            noOptionsText="Il n'y a plus d'options"
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Bundles"
                                                    placeholder="Selectioner les bundles"
                                                />
                                            )}
                                        />
                                        <div><Button className={classes.topTabBtn} variant="contained" onClick={handleAddBundle} color="primary" startIcon={<Add />}>AJOUTER</Button></div>
                                    </div> : <></>
                                }
                            </fieldset>
                        </TabPanel>
                        <TabPanel value={value} index={1} dir={theme.direction}>
                            <fieldset className="fieldsetTop selectSim" id="Noc">
                                <legend>Copier les bundles d'une sim</legend>
                                <div className="rowGrid" style={{ gridTemplateColumns: "auto 140px", alignItems: "center", height: "100%" }}>
                                    <Autocomplete
                                        disableClearable
                                        className='smallAuto'
                                        id="iSims"
                                        onChange={(e, newValue) => {
                                            setSim(newValue)
                                        }
                                        }
                                        options={simL}
                                        getOptionLabel={(option) => (option.number + "[" + (option.simCode ?? "") + "]")}
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Sim"
                                                fullWidth
                                                variant='outlined'
                                                margin='dense'
                                                size="small"
                                                autoFocus
                                                required />
                                        )} />
                                    <div><Button className={classes.topTabBtn} variant="contained" onClick={handleCopySim} color="primary" startIcon={<Iconn path={mdiContentCopy} onClick={handleClickOpen} size="20px" color="black" />}>Copier</Button></div>
                                </div>
                            </fieldset>
                        </TabPanel>
                    </>

                    <div id='' style={{ width: '92%', marginLeft: 'auto', marginRight: 'auto', overflow: "auto" }}>
                        <DataTable
                            className="table"
                            columns={columns}
                            data={data}
                            noHeader={true}
                            defaultSortAsc={true}
                            customStyles={customStyles}
                            noDataComponent={param.ErreurData}
                            highlightOnHover
                            paginationComponentOptions={{ rowsPerPageText: 'Element par page:', rangeSeparatorText: 'sur' }}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" autoFocus onClick={handleClose} color="secondary">FERMER</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
