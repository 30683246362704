import React from 'react'

export default function AT_Ticket({el,props,param}){
    return (
        <div style={{fontFamily:"Arial"}} >
            <div style={{display:'flex',gridGap:"5px",width:"5.2cm"}}>
            <img src={param.urlimg+el.topLogo} style={{height:"50px"}}/>
            {(props.logo)?<img src={param.urlimg+'logo_ar.png'} style={{height:"40px"}}/>:null}
            </div>
            <div style={{display:'grid',gridTemplateColumns:'55% 40%',fontSize:"12px",width:'5.2cm'}}>
                <div style={{textAlign:'left'}}>
                    {props.dataa[0]}
                </div>
                <div style={{textAlign:'right'}}>
                    {props.dataa[1]}
                </div>
            </div>
            <div style={{display:'grid',gridTemplateColumns:'55% 40%',fontSize:"12px",width:"5.2cm"}}>
                <div style={{textAlign:'left'}}>
                TID:{el.tiketId}
                </div>
                <div style={{textAlign:'right'}}>
                    {props.owner}
                </div>
            </div>
            <div id='p1'>
            {el.nom}
            </div>
            <div style={{display:'grid',gridTemplateColumns:'40% 60%',fontSize:"11px",width:'5cm',marginBottom:"3px"}}>
                <div style={{textAlign:'left'}}>
                TTC:{el.prix} DA
                </div>
                <div style={{textAlign:'right'}}>
                Expire: {el.expire}
                </div>
            </div>
            <div style={{fontSize:'12px',marginBottom:'5px'}}>N° Série :{el.serial}</div>
            <div style={{textAlign:'center',width:'5cm',marginBottom:"3px" ,height:"40px"}}>
            {(el.nom && (el.nom.search("4G ")!==-1 || el.nom.search("Idoom ")!==-1))?
            <img src={param.urlimg+el.centerLogo} style={{height:(el.nom.search("4G")!==-1)?"40px":"30px"}}/>:null}</div>
            <div id='p1' style={{fontSize:'12px',fontWeight:'bold'}}>{(props.copie===true)?'Duplicata':'CODE'}</div>
            <div style={{textAlign:'center',fontSize:'18px',width:'5cm',fontWeight:'bold'}}>{el.digit}</div>
            <div id='p1'></div>
            <div style={{fontSize:"12px"}}>لتعبئة حسابكم للانترنيت ،اتصل بالرقم : 1500</div>
            <div style={{textAlign:'center',fontSize:'13px',width:'5.2cm'}}>اتصالات الجزائر غير مسؤولة عن التذكرة</div>
            <div style={{textAlign:'center',fontSize:'13px',width:'5.2cm',marginBottom:"50px"}}>الغير مطبوعة امامكم</div>
       </div>
    );
}