import React,{ useState, useEffect ,useCallback,useMemo} from "react";
import DataTable from "react-data-table-component";
import ContentEditable from 'react-contenteditable';
import {CircularProgress,Paper,Box, Checkbox, ClickAwayListener, TextareaAutosize, Tooltip,TextField} from '@material-ui/core';
import {Delete, Lock, LockOpen, Search} from '@material-ui/icons';
import DraggableDialog from './modals/ref_bdl_add'
import DraggableDialogDet from './modals/ref_bdl_det'
import RefBdlSims from './modals/ref_bdl_sims'
import { textAreaEdit, useStyles } from '../../css/muiStyle';
import {conditionalRowStyles,customStyles, customStyles2, numberColumn}from '../../css/datatableStyle'
import {AutocompleteS, InputNbrEditable, NoDataComponent,NotAllowedComponent} from "../../js/components"
import { AlertCompo } from "../../js/components";

export default function Ref_bdl (props){
  const classes=useStyles();
  const param=props.param
  const Gfunc=require('../../Gfunc')
  const librery=require('../../js/data_table_librery')
  const [data, setData] = useState([]);
  const [called, setCalled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [search,setSearch]=useState('');
  const [noData,setNoData]=useState(false);
  const [doSearch,setDoSearch]=useState(false);
  const [notAllowed,setNotAllowed]=useState(false);
  const [errMessage,setErrMessage]=useState('');
  const [severity, setSeverity] =useState("info");
  const [open, setOpen] =useState(false);
  const [messageA, setMessageA] =useState('');
  const [records,setRecords]=useState('');
  const [resetDP, setResetDP] = useState(false);
  const [selecto,setSelecto]=useState('')
  const [Item,setItem]=useState('')
  const [ItemN,setItemN]=useState('')
  const [note,setNote]=useState('')
  const [opr,setOpr]=useState([]);
  const [currentRow, setCurrentRow] = useState(null);
  const [lastClicked, setLastClicked] = useState('');
  const [details,setDetails]=useState([])
  const [ussd,setUssd]=useState([])
  const [selectUssd,setSelectUssd]=useState('')
  const service='_bdl.php'

   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  }

 //fetch bundle
  const fetch= async(page,servic = service, size = perPage) => {
    setCalled(true);
    setLoading(true);
    const response=await librery.axiosGet(page,servic,size+"&search="+search)
    if(response.data.err&&response.data.err!=='-1'){
      setErrMessage(response.data.err)
      setNotAllowed(true)
    }else
    Gfunc.axiosResponse(response,()=>{
      setRecords(response.data.recordsFiltered)
      if(response.data.data){
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
        setLoading(false);
        setNoData(true)
        setDoSearch(true)
      }else{
        setDoSearch(false)
        setData([])
      }   
    })  
  setLoading(false);  
  }

  useEffect(() => {
    if(!called)
    fetch(1);
  }, [fetch,called]);

  //update field
  const handleOnEdit=useCallback(async(e,row,att,action,id)=>{
    const edt1=e.target.innerHTML
    const edt=Gfunc.DeleteSpace(edt1);

      if(row[att]!==edt){
        const attrib=row[att];
        const response=await librery.axiosEdit2(service,action,row,edt,att,id,'text') 
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,'error',response.data.err)
       }else
       if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
    }else{
        Gfunc.axiosResponse(response,()=>{row[att]=edt;e.target.innerHTML=edt})
      }
        if(response.data.err) {e.target.innerHTML=attrib}
      } else { e.target.innerHTML = edt }
  },[librery,Gfunc]);

  const handleSelect=useCallback((row,att)=>{
    setSelecto(row[att])
    setItem(row.DT_RowId)
    setItemN(att)
  },[])
  
   //handle update note
  const handleOnEditNote=useCallback(async(note,row,att,action)=>{
    const edt1=note
    var edt=Gfunc.DeleteSpace(edt1);
    if(row[att]!==edt){
      edt=edt.replaceAll("\n", "\r")
      const attrib=row[att];
      const response=await librery.axiosEdit2(service,action,row,edt,att) 
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{row[att]=edt})
      if(response.data.err) {row[att]=attrib}
    }else {row[att]=edt}
    setNote('')
    setSelecto('')
    setItemN("")
    setItem("")
  },[librery,Gfunc]);

  //fetch oprators
  const fetchOpr= useCallback(async() => {
    const response = await Gfunc.axiosPostAction(service,"getops")
    if(response.data.err){
      handleAlert(true,'error',response.data.err)
    }else 
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData)
        setOpr(response.data.jData);   
    })
    return response.data.jData
  },[Gfunc])

  //fetch operators handler
  const handleOpenOpr= useCallback(async () => {
    if(opr.length===0) fetchOpr();
  },[opr,fetchOpr])

  //fetch ussd form
  const fetchUssd= useCallback(async(row) => {
    const opr=await fetchOpr()
    var operateur=opr[Gfunc.getIndex(opr,row.operator,"name")].id
    const mtn=(row.mount!==null&&row.useMount)?row.mount:null
    const pin=(row.usePass)?"PIN":null
    const response = await Gfunc.axiosPostAction(service,"getussd",{ussdOperator:operateur,ussdBundle:row.ussd,mount:mtn,
                                                     pass:pin})
    if(response.data.err){
      handleAlert(true,'error',response.data.err)
    }else 
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData)
        setUssd(response.data.jData);   
    })
    return response.data.jData
  },[Gfunc])

   
  // fetch details de bundle 
  const fetchDetail= useCallback(async(data) => { 
    if(lastClicked!==parseInt(data.DT_RowId.substr(1))){
      setLastClicked(parseInt(data.DT_RowId.substr(1)))
    }
    const response = await Gfunc.axiosPostAction(service,"getrsp",{bundleId:parseInt(data.DT_RowId.substr(1))})
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,"error",response.data.err)
    }else
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData){
            setDetails(response.data.jData)
        }
      })
      return response.data.jData
  },[Gfunc]);


  const flagMan = useCallback(async(row,att,idName,id,srv,arr)=>{
    const response= await Gfunc.axiosPostAction('flagman.php','1',({ srv:srv, id:id,att:att, val:row[att] }))
    if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
    }else{
        Gfunc.axiosResponse(response,async()=>{
          if(response.data.jData){
            var objIndex = arr.findIndex((obj => obj[idName] === row[idName]));
            const nouveauTableau = [...arr]; 
            nouveauTableau[objIndex][att] = !row[att]; 
            if(att==='usePass'||att==='useMount'||att==='activ'){
              setData(nouveauTableau);
              if(att!=='activ'){
                const str=await fetchUssd(row,att,!row[att])
                Gfunc.updateElemS("preview"+row.DT_RowId.substr(1),(str!==undefined)?str:"")
              }
            }else{
              setDetails(nouveauTableau)
            }
          }
        })
      }
    },[Gfunc]);
  

  const columns =useMemo(
    () => [   
      {
        width: "30px !important",
        cell: (row) => { return (<div><RefBdlSims row={row} param={param} /></div>) }
      },
      {
        name: "Nom",
          width:"180px !important",
          selector:row=>row.bundle,
          sortable: true,
          sortField:"bundle",
          cell: (row) => {
            return (
              <Tooltip title={"Entré le: "+row.date +' par: ' +row.agent}>
                <div>
                 <ContentEditable
                  html={(row.bundle!==null)?row.bundle:''}
                  name="bundle"
                  data-column="item"
                  className="content-editable"
                  disabled={false} 
                  onBlur={e => handleOnEdit(e,row,'bundle','upd','DT_RowId')}
                />
              </div>
              </Tooltip>
              
                
             );
           },
      },
      {
          name: "USSD",
          minWidth:"115px !important",
          selector:row=>row.ussd,
          sortable: true,
          sortField:"ussd",
          cell: (row) => {
            return (
                <ContentEditable
                  html={(row.ussd!==null)?row.ussd:''}
                  name="ussd"
                  data-column="item"
                  className="content-editable"
                  disabled={false} 
                  onBlur={e => handleOnEdit(e,row,'ussd','upd','DT_RowId')}
                />
             );
           },
      },
      {
        name: "Opérateur",
        minWidth:'110px !important',
        selector: row=>row.operator,
        sortable: true,
        sortField:'operator', 
        cell: (row) => { 
          return (
               <AutocompleteS service={service} action={'upd'} defaultValue={row.operator} row={row} list={opr} 
                              id={'operatorId'} name='operator' attr='name' onOpen={handleOpenOpr} handleAlert={handleAlert}/>
          );
       }, 
    },
    {
        name: "Montant",
        minWidth:'100px !important',
        style:numberColumn,
        selector: row=>row.mount,
        sortField:'mount', 
        sortable: true,
        cell: (row) => {
          return (
                (row.useMount)?
                  <InputNbrEditable service={service} action='upd' min={1} row={row} att="mount" value={row.mount} id='DT_RowId' 
                                    idi={row.DT_RowId} handleAlert={handleAlert} men='bundle'  
                                    handleResponse={
                                      async(row)=>{
                                         const str=await fetchUssd(row)
                                         Gfunc.updateElemS("preview"+row.DT_RowId.substr(1),(str!==undefined)?str:"")                                  
                                      }
                                    }
                  />
                :<div>{row.mount}</div>
           );
         },
    },
    {
      name: "Coût",
      minWidth:'120px !important',
      style:numberColumn,
      selector: row=>row.cost,
      sortField:'cost', 
      sortable: true,
      cell: (row) => {
        return (
                <InputNbrEditable service={service} action='upd' min={1} row={row} att="cost" value={row.cost} id='DT_RowId' 
                                  idi={row.DT_RowId} handleAlert={handleAlert} men='bundle'  />
         );
       },
    },
    {
        name: "U.montant",
        minWidth:'110px !important',
        selector: row=>row.useMount,
        sortable: true,
        sortField:'useMount', 
        cell: (row) => {
          return (
           <div className="checkbox">
            <Checkbox className={classes.checkGreen} checked={row.useMount} onChange={()=>{flagMan(row,'useMount',"DT_RowId",parseInt(row.DT_RowId.substr(1)),'bundle',data)}}/>
           </div> 
           );
         },
    },
    {
        name: "U.pin",
        minWidth:'110px !important',
        selector: row=>row.usePass,
        sortable: true,
        sortField:'usePass', 
        cell: (row) => {
          return (
            <div className="checkbox">
              <Checkbox className={classes.checkGreen} checked={row.usePass} onChange={()=>{flagMan(row,'usePass',"DT_RowId",parseInt(row.DT_RowId.substr(1)),'bundle',data)}} /> 
            </div>
           );
         },
    },
    {
      name: "Aperçu",
      minWidth:"250px !important",
      selector:row=>row.preview,
      cell: (row) => {
        return (
          <div id={"preview"+row.DT_RowId.substr(1)}>
             {row.preview}
          </div>
         );
       },
    },
    {
      name: "DATE",
      width:"100px !important",
      selector:row=>row.date,
      sortable: true,
      sortField:"date"
    },
    {
      name: "Note",
      selector:row=>row.note,
      sortable: true,
      minWidth:'160px !important',
      sortField:'note',
      cell: (row) => {
        return (
          <div className="note-container">
          { (row.note===selecto && ItemN==='note' && row.DT_RowId===Item)?
              <ClickAwayListener onClickAway={() => { (note != null) ? handleOnEditNote(note, row, "note", 'upd') : setSelecto('') }}>
           <TextareaAutosize style={textAreaEdit} autoFocus onFocus={(e)=>{ e.target.value=note}}  onChange={(e)=>{setNote(e.target.value);}}
           /></ClickAwayListener>:
           ((row.note&&row.note.match(/\r/g) || []).length>=1)?
           <Tooltip title={row.note.replaceAll("\r", "/ ")}>
               <div className="content-editable" onClick={()=>{setNote(row.note);handleSelect(row,'note')}}>{row.note.substring(0, row.note.indexOf("\r"))+"..."}</div>
           </Tooltip>:
             <Tooltip title={row.note?row.note:''}>
           <div className="content-editable" onClick={()=>{setNote(row.note);handleSelect(row,'note')}} >{row.note}</div></Tooltip>}
           </div>
         );
       },
    },
    {
      right:true,
      cell: (row) => {
        return (
          <div className="rowGrid" id="twoIcons">

              <DraggableDialogDet row={row} fetchDetail={fetchDetail} handleAlert={handleAlert}/>
              <div onClick={()=>flagMan(row,'activ',"DT_RowId",parseInt(row.DT_RowId.substr(1)),'bundle',data)} title="Activer/Désactiver">
                {
                (row.activ)?
                 <LockOpen className={classes.redIcon} fontSize="small"/>
                :<Lock className={classes.greenIcon} fontSize="small"/> 
                }
              </div> 
            </div>
         );
       },
    }
    ], [handleOnEdit, handleAlert, handleOnEditNote, handleSelect, flagMan, textAreaEdit, classes, data, selecto, ItemN, Item, note]);
 
  // sort function handler
  const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage+"&search="+search)=>{
          setLoading(true);
          const response=await librery.axiosSort(column,sortdirection,page,size,service)
          if(response.data.err&&response.data.err!=='-1'){
            handleAlert(true,'error',response.data.err)
         }else
          Gfunc.axiosResponse(response,()=>{
            setData(response.data.data);
            setTotalRows(response.data.recordsFiltered);
          })     
          setLoading(false);
  }

   //recherche serverside
   const handleSearch=async(size = perPage)=>{
    setLoading(true);
    setCurrentPage(1)
    const recherche=search;
    const response=await librery.axiosSearch(service,recherche,1,size,"get")
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
   }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.data!==undefined){
      setData(response.data.data);
      setTotalRows(response.data.recordsFiltered);
      }else setData([])
    })  
    setLoading(false); 
    return recherche
  }
  
   function handlePageChange (page) {
    fetch(page,service,perPage);
    setCurrentPage(page);
  };
   const handlePerRowsChange = async (newPerPage, page) => {
    fetch(page,service, newPerPage);
    setPerPage(newPerPage);
  };

  function liveSearch(e,search){
    if(param.liveSearch&&search.length>3){
      e.preventDefault();
      handleSearch();
    }else{ if (e.keyCode === 13) {
              e.preventDefault();
              handleSearch();
            }
    }
  }

  function liveSearch2() {
    handleSearch();
  }


  const handleDeleteItem=useCallback(async(row)=>{
    const response=await Gfunc.axiosPostAction(service,"delrsp",{id:parseInt(row.responseId)})
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,"error",response.data.err)
    }else
     Gfunc.axiosResponse(response,()=>{
      if(response.data.jData){
          const objIndex =  details.findIndex((obj => obj.responseId ===row.responseId )); 
          const nouveauTableau = [...details]; 
          nouveauTableau.splice(objIndex,1)
          setDetails(nouveauTableau);
       }
      })
  },[Gfunc,service,details])

  const ExpanableComponent = ({data ,detail,lastClicked,handleDeleteItem,fetchDetail}) => {
    const [called,setCalled]=useState(false);
    
    // fetch detail de bundle 
    const fetchDet= useCallback(async() => { 
      setDetails([])
      await fetchDetail(data)
      setCalled(true)
    },[Gfunc,fetchDetail,data]);

    useEffect(() => {
      if(!called&&lastClicked!==parseInt(data.DT_RowId.substr(1)))
      fetchDet();
      return ()=>{
        setCalled(true)
      }
    }, [fetchDet,lastClicked,data,called]);

   
    const columnsB =useMemo(
      () => [ 
            {
              name: "Étape",
              selector: row=>row.step,
              sortable: true, 
              width:'120px !important',
              cell: (row) => {
                return (
                  <InputNbrEditable service={service} action='updrsp' min={1} row={row} att="step" value={row.step} id='responseId' 
                                    idi={row.responseId} handleAlert={handleAlert} position='center' />
                 );
               },
            },
            {
              name: "Succès",
              selector: row=>row.success,
              sortable: true, 
              minWidth:'250px !important',
              cell: (row) => {
                return (
                  <div>
                    <ContentEditable
                      html={(row.success!==null)?row.success:''}
                      name="succ"
                      data-column="item"
                      className="content-editable-multiline"
                      disabled={false} 
                      onBlur={e => handleOnEdit(e,row,'success','updrsp','responseId')}
                    />
                  </div >
                 );
               }, 
            },
            {
          name: "Succès 2",
          selector: row => row.success2,
          sortable: true,
          minWidth: '250px !important',
          cell: (row) => {
            return (
              <div>
                <ContentEditable
                  html={(row.success2 !== null) ? row.success2 : ''}
                  name="succ2"
                  data-column="item"
                  className="content-editable-multiline"
                  disabled={false}
                  onBlur={e => handleOnEdit(e, row, 'success2', 'updrsp', 'responseId')}
                />
              </div >
            );
          },
        },
        {
              name: "Réponse",
              selector: row=>row.response,
              sortable: true, 
              minWidth:'250px !important',
              cell: (row) => {
                return (
                  <div>
                    {
                      (!row.passResponse)?
                        <ContentEditable
                          html={(row.response!==null)?row.response:''}
                          name="response"
                          data-column="item"
                          className="content-editable-multiline"
                          disabled={false} 
                          onBlur={e => handleOnEdit(e,row,'response','updrsp','responseId')}
                        />
                      :<div>{row.response}</div>
                    }
                  </div >
                 );
               },  
            },
        {
          name: "Réponse 2",
          selector: row => row.response2,
          sortable: true,
          minWidth: '250px !important',
          cell: (row) => {
            return (
              <div>
                {
                  (!row.passResponse) ?
                    <ContentEditable
                      html={(row.response2 !== null) ? row.response2 : ''}
                      name="response2"
                      data-column="item"
                      className="content-editable-multiline"
                      disabled={false}
                      onBlur={e => handleOnEdit(e, row, 'response2', 'updrsp', 'responseId')}
                    />
                    : <div>{row.response2}</div>
                }
              </div >
            );
          },
        },
        {
              name: "Résponse pin",
              minWidth:'200px !important',
              selector: row=>row.passResponse,
              sortable: true,
              cell: (row) => {
                return (
                  <div className="checkbox">
                   <Checkbox className={classes.checkGreen} checked={row.passResponse} onChange={()=>{flagMan(row,'passResponse','responseId',row.responseId,'bundle_response',detail)}} /> 
                  </div>
                 );
               },
           },
           {
              name: "Agent",
              selector: row=>row.agent,
              sortable: true, 
              width:'110px !important', 
            },
            {
              name: "Date",
              selector: row=>row.date,
              sortable: true, 
              width:'110px  !important',  
           },
           {
            right:true,
            cell: (row) => {
              return (
                  <Delete fontSize="small"  className={classes.redIcon} onClick={()=>{handleDeleteItem(row)}} />
               );
             },
           }
      ],[handleDeleteItem,handleOnEdit,classes]);

    return (
        <div id="prodDet" className="bundles-det" style={{maxHeight:"200px",width:"100%", padding:"10px",overflowY:"auto",overflowX:'hidden'}}>
          <DataTable
                fixedHeader
                persistTableHead
                noDataComponent={param.ErreurData}
                columns={columnsB}
                data={detail}
                dense
                customStyles={customStyles2}
                highlightOnHover
                striped
              /> 
        </div>
      )
  }

  return (
    <div className="content">
        <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
        <Paper className={classes.paperBody} elevation={5}>
        <div className='table-header'>
          <div className='button-flex-wrapper'><DraggableDialog handleAlert={handleAlert} callback={()=>{if(currentPage===1) fetch(1);else setResetDP(!resetDP)}}/></div>
          {(doSearch)?
          <div className='search'>
               <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Search className={classes.blackIcon} sx={{ color: 'action.active', mr: 1, my: 0.5 }} onClick={(e)=>liveSearch2(e)}/>
                      <TextField className="smallText" variant="outlined" label="Recherche" value={search} size="small" margin='dense' onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} autoFocus/>
                </Box>
            </div>:null}
        </div>
        {(data.length!==0||doSearch)?
          <div>
            <DataTable 
              expandOnRowClicked
              expandableRows 
              expandableRowsComponent={(props)=><ExpanableComponent {...props} datta={data} setDatta={setData}  Gfunc={Gfunc} flagMan={flagMan}
                         fetchDetail={fetchDetail} handleDeleteItem={handleDeleteItem} detail={details} lastClicked={lastClicked} handleAlert={handleAlert}/>} 
              onRowClicked={(row) => setCurrentRow(row)}
              onRowExpandToggled={(bool, row) => setCurrentRow(row)}
              expandableRowExpanded={(row) => (row === currentRow)}
              columns={columns}
              data={data}
              noDataComponent={param.ErreurData}
              paginationRowsPerPageOptions={[10,25,50,100]}
              progressPending={loading}
              progressComponent={<CircularProgress className={classes.circularProgress} />}
              pagination
              paginationServer
              paginationPerPage={perPage}
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              sortServer={true}
              onSort={handleOnSort}
              noHeader={true}
              highlightOnHover
              customStyles={customStyles}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}
              conditionalRowStyles = { conditionalRowStyles }
            />
            </div>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData || records===0)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
      </Paper>
    </div>    

  );
}