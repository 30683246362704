import React from 'react'

import '../css/global.css'
import err from "../img/default.jpg"

export default function Digitalisation() {
    return (

<div className="container">
            <div className="error-template">
               <img src={err} alt="err" id='err'/>  
                <h1>Page introuvable</h1>           
                <p>Consulter le service client</p>
                <div className="error-actions">
                <button onClick={()=>window.location=('/')} className='btn' style={{ textDecoration: 'none',color:'black' }}>Retour à l'accueil </button>          
               </div>
            </div>
</div>

    )
}
