import React from 'react'

export default function Mo_Ticket({el,props,param}){
    return (
        <div style={{fontFamily:"Arial"}} >
            <div style={{width:'5.2cm',textAlign:'center',marginBottom:(props.logo)?'6px':"0px"}}>
                {(props.logo)?<img src={param.urlimg+'logo_ar.png'} style={{height:"40px"}}/>:null}</div>
            <div style={{ width: '100%', textAlign: 'center', fontSize: "12px", fontWeight: 'bold', width: '5.2cm', color: "transparent" }}>.</div>
            <div style={{display:'grid',gridTemplateColumns:'55% 40%',fontSize:"12px",width:'5.2cm'}}>
                <div style={{textAlign:'left'}}>{props.dataa[0]}</div>
                <div style={{textAlign:'right'}}>{props.dataa[1]} </div>
            </div>
            <div style={{display:'grid',gridTemplateColumns:'55% 40%',fontSize:"12px",width:"5.2cm"}}>
                <div style={{textAlign:'left'}}> TID:{el.tiketId}</div>
                <div style={{textAlign:'right'}}>{props.owner} </div>
            </div>
            <div id='p1'>{el.nom}</div>
            <div style={{display:'grid',gridTemplateColumns:'48% 48%',gridGap:'5px',fontSize:"11px",width:'5cm',marginBottom:"3px"}}>
                <div style={{textAlign:"start"}}>
                    <p>N° Série :</p>
                    <p>Expire: </p>
                    <p>Montant TTC:</p>
                </div>
                <div style={{textAlign:"end"}}>
                    <p>{(el.serial)?el.serial:"--"}</p>
                    <p> {el.expire}</p>
                    <p>{el.prix} DA </p>
                </div>
            </div>
            <div style={{textAlign:'center',width:'5cm',marginBottom:"3px"}}>
                <img src={param.urlimg+"logo_mobilis.png"} style={{height:'40px'}}/></div>
            <div id='p1' style={{fontSize:'12px',fontWeight:'bold'}}>{(props.copie===true)?'Duplicata':'CODE'}</div>
            <div style={{textAlign:'center',fontSize:'18px',width:'5cm',fontWeight:'bold'}}>{el.digit}</div>
            <div id='p1'></div>
            <div style={{textAlign:'center',fontSize:'11px',width:'5.2cm',marginBottom:(props.logo)?"50px":'73px'}}>Composer *111* Code ci-dessus # OK</div>
       </div>
    );
}