import React from 'react'
import { PieChart,Pie,Cell,Tooltip,ResponsiveContainer ,Legend} from 'recharts';

export default function Pie_chart(props){
    function CustomTooltip({ payload, label, active,...props }) {
        if (active) {  
           //var clr=(Gfunc.rgbToHex(payload[0].payload.fill)!==undefined)?Gfunc.rgbToHex(payload[0].payload.fill):payload[0].payload.fill
             return (
                     <div className="custom-tooltip" style={{ border:"2px solid"+payload[0].payload.fill}}>
                         <p className="intro"><b>{`${payload[0].name}`}</b>: {(payload.length!==0)?new Intl.NumberFormat().format(`${payload[0].value}`):0  }</p>
                         <b className="intro">{((`${payload[0].value}`*100)/props.percent).toFixed(2)+'%'}</b>
                         {(props.display==='true')? <p><b>Total :</b>{new Intl.NumberFormat().format(props.total)}</p>:<></>}
                     </div>
         );}
       return null;
    }

    const CostumLegend = (props) => {    
      return (
          <div>
                <p className='legend'>{props.name}</p>
          </div>
      );
    }

    return (
      <div style={{ width:props.dimension, height: 300 }}>
       <ResponsiveContainer>
          <PieChart width={'100%'}  height={'100%'} title={props.title}>
              <Pie dataKey="value" data={props.data} fill="#8884d8" >
                {
                 props.data.map((index,i) =>
                    <Cell key={`cell-${index}`} fill={props.color[i]}/>
                )}        
              </Pie>
              <Tooltip content={<CustomTooltip percent={props.percent} total={props.total} display={props.display} moy={props.moy}/>} /> 
              <Legend content={<CostumLegend name={props.title} />}/>
          </PieChart>
       </ResponsiveContainer>
     </div>
    );
}