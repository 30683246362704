import React ,{useState} from 'react';
import axios from 'axios';
import '../css/login.css';



const Login=(props)=> {
    const param=props.param
    const vers=require('../version.json');
    const [email,setEmail]=useState('');
    const [password,setPassword]=useState('');

    const handlerSubmit= async (e) =>{
                e.preventDefault();
                //Recuperation des donnes du formulaire 
                const email=e.target.email.value;
                const password=e.target.password.value;
                //appelle du fichier jason qui contient url  de service    
                const param=props.param
                var Gfunc = require('../Gfunc');
                 //envoie des donnees vers le service 
             const response=   await axios({
                    method: 'POST',
                    url:param.urlService+'auth.php',
                    headers: {
                        'content-type': 'application/json' ,'appID': param.appID},
                    data:JSON.stringify({ login: email,password: password })     
                })
                console.log(email,password)
                    if(response.data.err){
                      alert(response.data.err)
                    }else{
                      Gfunc.axiosResponse(response,()=>{ 
                          localStorage.setItem('build', vers[0].build);
                          localStorage.setItem('token', response.data.jData.token);
                          localStorage.setItem('isLoged', true);
                          localStorage.setItem('name',response.data.jData.name);
                          localStorage.setItem('nickname',response.data.jData.nickname);
                          localStorage.setItem('param',JSON.stringify({'urlService':param.urlService,'appID':param.appID}));
                          window.location=('/');
                      })
                   }
            //recuperation des donnees du service pour l'autorisation d'acces ou non 
                 }
    return (
        <div className="login-page">
        <div className="SingUpLoginBox">
            <div className="formContainer">
                <form id="f1" onSubmit={handlerSubmit}>
                  <div id="logoDiv"></div>
                  <div id="loginDiv">
                      <div id="emailDiv"><input type="text" id="email" name="email" placeholder="Votre login" value={email} onChange={e =>setEmail(e.target.value)} /></div>
                      <div id="passDiv"><input type ="password" id="password" name="password" placeholder="Mot de passe" value={password} onChange={e =>setPassword(e.target.value)} required/></div>
                      <div  id="buttonDiv" ><button id='subo' type="submit"/></div>
                  </div>
                </form>
            </div>
          {(!(param.app === "2")) ? <><div id="logosDiv">
            <div id="digitartsDiv" />
            <div id="partnerDiv" />
            <div id="irahaArDiv" />
          </div>
            <div id="logosDiv2">
              <div id="bqDiv" />
              <div id="liberoDiv" />
              <div id="webTelcoDiv" />
              <div id="timefanDiv" />
              <div id="irahtiDiv" />
              <div id="smartTelcoDiv" />
              <div id="smsOrDiv" />
              <div id="raqabaDiv" />
            </div>
            /</> : <>
              <div id="logosDiv">
                <div id="digitartsDiv" />
                <div id="massarDiv" />
              </div>
              <div id="logosDiv2">
                <div id="liberoDiv" />
                <div id="baynaDiv" />
                <div id="massariDiv" />
              </div>
          </>}
        </div>
        <div id="authBottom"><span id="copyr">{param.webApp} {vers[0].build} &copy; {param.copyright}</span></div>
        </div>
    )
  }
  
  export default Login;