import React,{useState,useCallback} from 'react';
import {CircularProgress,TextField,DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog} from '@material-ui/core';
import Draggable from 'react-draggable';
import { useStyles, paperPropsBigCaiss} from '../../../css/muiStyle';
import {NoDataComponent,NotAllowedComponent} from '../../../js/components'
import DataTable from "react-data-table-component";
import {customStyles, numberColumn}from '../../../css/datatableStyle'
import Autocomplete from '@material-ui/lab/Autocomplete';


//the dialog that adds users to a role**********
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Caisse_hist(props) {
    const param=props.param
    const Gfunc=require('../../../Gfunc')
    const [open, setOpen] =useState(false);
    const classes=useStyles();
    const [loading, setLoading] =useState(false);
    const [data,setData]=useState([]);
    const [caiss,setCaiss]=useState([]);
    const [errMessage,setErrMessage]=useState('');
    const [records,setRecords]=useState('')
    const [notAllowed,setNotAllowed]=useState(false);
    const [noData,setNoData]=useState(false);
    const service='_cai.php'

  
  //dialog functions************************
   const handleClickOpen = () => { 
      setOpen(true); setData([]);setCaiss([]);setNoData('')
    };
   const handleClose = () => { setOpen(false); };
    
     //fetch 
   const fetch=async(val) => {
      setLoading(true)
      const response=await Gfunc.axiosPostAction(service,'getcaihist&mod=ALL',{caissierId:val})
      if(response.data.err &&response.data.err!=="-1"){
        setErrMessage(response.data.err)
        setNotAllowed(true)  
      }else{
      Gfunc.axiosResponse(response,()=>{
        setRecords(response.data.recordsFiltered)
        if(response.data.jData!==undefined){
          setData(response.data.jData);
          setLoading(false);
          setNoData(true)
        }else{
          setData([])
        }
      })
    }
    }

  const columns = [
    {
      name: "Date",
      selector: row=>row.date,
      sortable: true,
      width:'150px !important'
    },
    {
      name: "Opération",
      selector: row=>row.operation,
      sortable: true,
      minWidth:'150px !important',
     
    },
    {
      name: "bénéficiaire",
      selector: row=>row.scope,
      sortable: true,
      minWidth:'170px !important',
     
    },
    {
      name: "Note",
      selector: row=>row.note,
      sortable: true,
      minWidth:'230px !important',
      cell: (row) => {
          return (
            <div  title={row.note}>
              {row.note} 
            </div>
           );
         },
    },
    {
      name: "Montant",
      style:numberColumn,
      selector: row=>row.mtn,
      sortable: true,
      width:'140px !important',
      cell: (row) => {
        return (
          <div>
            {new Intl.NumberFormat().format(row.mtn)} 
          </div>
         );
       },
    },
    {
      name: "Caisse",
      style:numberColumn,
      selector: row=>row.conso,
      sortable: true,
      width:'150px !important',
      cell: (row) => {
        return (
          <div>
            {new Intl.NumberFormat().format(row.conso)} 
          </div>
         );
       },
    },
  ];
    
    //fetch categorie
    const fetchCaiss= useCallback(async(serv="_usr.php", act = "gets") => {
      const response = await Gfunc.axiosGetAction(serv,act);
      Gfunc.axiosResponse(response,()=>{
        setCaiss(response.data.jData);    
     })
    },[Gfunc])
  
    //fetch categorie handler
    const handleOpenCaiss = useCallback(async () => {
      if(caiss.length===0)
      fetchCaiss()
      },[caiss,fetchCaiss])
  
    const handleChange=(val)=>{
      setRecords('')
      fetch(val,1)
    }

  return (
    <div>
        <span id="myCaiss" onClick={handleClickOpen} className='caisse'>Caisse: <label className="mtn" id='caisseIr'>{props.caisse}</label> $</span>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                PaperProps={paperPropsBigCaiss}
                id="diagRol"
            >
              <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >Historique de la caisse</DialogTitle>
              <DialogContent className={classes.dialogueContent}>
              <div className="head">
         <fieldset className="fieldsetTop2" id="role">
               <legend>Choix du caissier:</legend>
               <div className="rowGrid" id="row-1">
                           <Autocomplete
                                disableClearable
                                id="iCaiss"
                                onFocus={handleOpenCaiss}
                                onChange={(e,newValue) =>{handleChange(caiss[Gfunc.getIndex(caiss,newValue,"name")].id);}}
                                className='smallAuto'
                                options={caiss.map((option) => option.name)}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant='outlined'
                                    label={'Caissier'}
                                    size="small" 
                                    />
                            )}/>
               </div>
              </fieldset>
            </div>
                <br/>
            <div id='caiss-table'>
                {
                  (data.length!==0)?
                   <DataTable 
                    columns={columns}
                    data={data}
                    pagination
                    noDataComponent={param.ErreurData}
                    progressPending={loading}
                    paginationPerPage={25}
                    progressComponent={<CircularProgress className={classes.circularProgress} />}
                    highlightOnHover
                    noHeader={true}
                    customStyles={customStyles}
                    paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}            
                  />
                  :(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData || records===0)?<NoDataComponent message="Aucun résultat trouvé"/>:null
                }
              </div>  
             </DialogContent>
              <DialogActions>
                    <Button variant="contained" autoFocus onClick={handleClose} color="secondary">FERMER</Button>
              </DialogActions>
            </Dialog>
      </div>
  );
}
