import axios from 'axios';


//Global functions that can be used in diffrent projects
    //treat the response from api axios
    export function axiosResponse(response,f=()=>{}){
      if (response.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        //console.log(response.response.data);
        //console.log(response.response.status);
        //console.log(response.response.headers);
       /* if(response.response.status>400){
          localStorage.clear();
        }
        window.location=("/"); */
      } else if (response.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
       // console.log(response);
       // console.log(response.request);
       // console.log(response.message);
        //changer le lien 
        if(response.request.status<100)
       { 
        alert("Une erreur s'est produite");
       // if(localStorage.getItem("netError")!==response.message||!window.location.pathname.split("/")[1].includes("error")){window.location=("/error?message="+response.message); }}
       }
       //else {if(localStorage.getItem("netError")!=="undefined") window.location=("/") }
       //localStorage.setItem("netError",response.message);
      } else {
        // Something happened in setting up the request that triggered an Error
        //console.log('Error', response.message);
      }
      if(response.data) {if(response.data.err==null){
          f();
      }else{  if(localStorage.length!==0)
                { if(response.data.err==="-1"){
                      alert("Votre session est expiré");
                      localStorage.clear();
                      window.location=("/"); 
                  }else{
                      alert(response.data.err);
                  }}
       }}
 }
      
    //get headers 
    export function getHeader(){
        const param=JSON.parse(localStorage.getItem("param"));
        const headers={
          'content-type': 'application/json, multipart/form-data',
          'session':localStorage.getItem('token'),
          'appID':param.appID,
          }
          return headers;
    }
    
    // manage the ajax's errors in datatables 
    export function errorMan(data){
                if (data.err!=null && data.err!==undefined){
                    if(data.err==='-1') {
                        alert('votre session est expiré ');
                        localStorage.setItem('isLoged', false);
                        window.location=("/");  
                    } else alert(data.err);
                }
    }
    //test if an object is empty 
    export function isEmpty(obj) {
      for(var key in obj) {
          if(obj.hasOwnProperty(key))
              return false;
      }
      return true;
    }
    //get response from add service
    export function axiosAdd(service, data){
        const param=JSON.parse(localStorage.getItem("param"));
        const response= axios({
            method: 'POST',
            url:param.urlService+service+'?do=add',
            headers: getHeader(),
            data:JSON.stringify(data)     
        }).catch(function(error){
          error["data"]=""
          return error
       })
        return response
      }
 //get service
 export function axiosGetAction(service ,action){
    const param=JSON.parse(localStorage.getItem("param"));
    const response= axios({
        method: 'GET',
        url:param.urlService+service+'?do='+action,
        headers: getHeader(),    
    }).catch(function(error){
      error["data"]=""
      return error
   })
    return response
  }
 //POST axios json
 export function axiosPostAction(service ,action,data){
    const param=JSON.parse(localStorage.getItem("param"));
    if(!(data instanceof FormData))
    data=JSON.stringify(data)
    const response= axios({
        method: 'POST',
        url:param.urlService+service+'?do='+action,
        headers: getHeader(), 
        data:data,
    }).catch(function(error){
      error["data"]=""
      return error
   })
    return response
  }
 //POST axios json
 export function axiosPostAction2(service ,action,data,file){
    const param=JSON.parse(localStorage.getItem("param"));
    if(!(data instanceof FormData))
    data=JSON.stringify(data)
    const response= axios({
        method: 'POST',
        url:param.urlService+service+'?do='+action,
        headers: getHeader(), 
        data:data+file,
    }).catch(function(error){
      error["data"]=""
      return error
   })
    return response
  }
 //POST axios formdata
 export function axiosPostAction3(data){
    const param=JSON.parse(localStorage.getItem("param"));
    const response= axios({
        method: 'POST',
        url:param.urlService+"http://localhost:80/backend/index.php",
        headers: getHeader(), 
        data:data
    }).catch(function(error){
      error["data"]=""
      return error
   })
    return response
  }
 //POST axios
 export function axiosFlagman(srv,id,att,val){
    const param=JSON.parse(localStorage.getItem("param"));
    const response=axios({
        method: 'POST',
        url:param.urlService+'flagman.php',
        headers: getHeader(),
        data:JSON.stringify({ srv:srv, id:id,att:att, val: val })    
    }).catch(function(error){
      error["data"]=""
      return error
   })
    return response
  }
    
// group by key function
export const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  };

  // join two arrays of objects in a prop
  export const merge=(a, b, prop)=> {
    let merged = [];
      for(let i=0; i<a.length; i++) {
        merged.push({
        ...a[i], 
        ...(b.find((itmInner) => itmInner[prop] === a[i][prop]))}
        );
      }
    return merged
  }

  export const getDate=()=>{
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); 
    var yyyy = today.getFullYear();
    
    today = dd + '-' + mm + '-' + yyyy;
    return today
  }

  export const getDate2=()=>{
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); 
    var yyyy = today.getFullYear();
    
    today = yyyy + '-' + mm + '-' + dd;
    return today
  }


  export const formatDate=(today)=>{
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); 
    var yyyy = today.getFullYear();
    
    today = dd + '-' + mm + '-' + yyyy;
    return today
  }
  export const formatDate1=(today)=>{
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); 
    var yyyy = today.getFullYear();
    
    today = yyyy + '-' + mm + '-' + dd;
    return today
  }

  export const normalDate=(today)=>{
    var dateParts = today.split("-");
    var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    return dateObject 
  }


  //delete an item from an array of object by some attribute
  export function removeItemsByAtt(arr,value,att) {
    var index = arr.map(x => {
      return x[att];
    }).indexOf(value);
    arr.splice(index, 1);
  }

  //return the index of an Object by a property value
  export function getIndex(arr,val,att) {
      var index = arr.map(x => {
      return x[att];
    }).indexOf(val);
    return index;
  }
   //eliminer les espaces pour les chaines de caractere
   export const DeleteSpace=(edt)=>{
    do{
        edt=edt.replace("&nbsp;","");
        edt=edt.replace("amp;","");
        //edt=edt.replace(" ","")
        
    } while (edt.indexOf("&nbsp;")!==-1||edt.indexOf("amp;")!==-1);
    var text = edt.replace(/<[^>]*>/g, "")
    /*do{
      var ind = edt.indexOf("<div>");
      if(ind!==-1){ 
        edt=edt.replace("<div>"," ");
        edt=edt.replace("<br>"," ");
        edt=edt.replace("</div>"," ");
      }   
    } while (edt.indexOf("<div>")!==-1);*/
   /* do{
      var ind = edt.indexOf("</r>");
      if(ind!==-1){ 
        edt=edt.replace("</r>","");
      }   
    } while (edt.indexOf("/r")!==-1);*/
    return text
  }
  //remove unwanted spaces
  export const DeleteSpace2=(edt)=>{
    edt=edt.replace(/&nbsp;/g,"");
    edt=edt.replace(/amp;/g,"");
    return edt.replace(/\s\s+/g, ' ').trim();
  }

  //get all keys
  export const getKeys=(obj)=>{
      const filters=[]
      Object.keys(obj).map( key =>{ 
          const f={}  
          f["att"]=key
          filters.push(f)
          return true;
        }) 
    return filters
  }

  //filter all (search multi creterias)
  export const searchAll=(data,filters,search)=>{
    const dat=data
    return (dat.filter((d)=>filters.some(filterEl =>((d[filterEl.att].toString().toLowerCase()).includes(search.toLowerCase())))))
  }

 //get next element
 export const navObj = (obj, currentKey, direction) => {
  return Object.keys(obj).indexOf(currentKey) + direction;
};

 //get Time in seconds from hours and minutes
 export const getClock = (date) => {
  var seconds = date.getSeconds();
  var minutes = date.getMinutes();
  var hour = date.getHours();
  return(hour+':'+minutes+':'+seconds);
};

 //get x last days
 export function LastXDay (x) {
  var d = new Date();
  d.setDate(d.getDate() - x);
  return d;
}

//check if phone number
export function phonenumber(inputtxt){
  //var phoneno = /^\d{10}$/;
  var phoneno = '[0][5-7][4-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]';
  if(inputtxt.match(phoneno) ){ return {response:true,message:"C'est le bon format"};}
  else return {response:false,message:"Vous devez insérer le bon format"};
}

 //Check if it is the same day
 export const datesAreOnSameDay = (first, second) =>{
  if (!first||!second) return false
  return(
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate())
};

//Check if valide date
export const dateIsValide = (day) =>{
  if(day===null||undefined) return false 
  return (Object.prototype.toString.call(day) === "[object Date]"&&!isNaN(day)&&(datesAreOnSameDay(new Date(),day)||day.getTime()<new Date().getTime()))
};

//fetch update elemnumber
export const updateElem = (id,val) => {
  var elem = document.getElementById(id);
  if(elem!=null&&elem!==undefined) elem.innerHTML= new Intl.NumberFormat().format(val);
}
//fetch update elem string
export const updateElemS = (id,val) => {
  var elem = document.getElementById(id);
  if(elem!=null&&elem!==undefined) elem.innerHTML= val;
}

//calculate unique items
export function countTableId(a,att){
  // sets are, well, sets of unique items. no duplicates allowed
  let uniqueId = new Set();
  for(let elem of a){
      uniqueId.add(elem[att]);
  }
  // the size of the set is the number of unique items we added
  return uniqueId.size;
}
//if empty string returns null
export function stringToNull(str){
  if(str==='')
  return null;
  return str
}
//if empty string returns null
export function zeroToNull(val){
  if(val===0)
  return null;
  return val
}

//check if a number is odd
export function isOdd(num) { return num % 2;}

//sum of value in an array of objects 
export  function sumArObj(arr,att){
  const sum = arr.reduce((accumulator, object) => {
    return accumulator + object[att];
  }, 0);
  return sum;
}

//calcilate the item that are superior to something
export  function supTo(arr,att,value){
  let cpt=0
  cpt = arr.reduce((accumulator, object) => {
    if(object[att]>value)
    return accumulator + 1;
    return accumulator + 0;
  }, 0);
  return cpt;
}
//calcilate the item that are inferior to something
export  function infTo(arr,att,value){
  let cpt=0
  cpt = arr.reduce((accumulator, object) => {
    if(object[att]<value)
    return accumulator + 1;
    return accumulator + 0;
  }, 0);
  return cpt;
}

//format to 2 decimals to use it do : formatterTo2.format(Some value)
export const formatterTo2 = new Intl.NumberFormat('fr-FR', {
  minimumFractionDigits: 2,      
  maximumFractionDigits: 2,
});

export const getParams=async()=>{
  var param= null
  const cacheBuster = Math.random();
  await fetch(`/param.json?cacheBuster=${cacheBuster}`
  ,{
    headers : { 
      'Content-Type': 'application/json',
      'Accept': 'application/json'
     }
  }
  )
    .then(async function(response){
      param=await response.json();
      //localStorage.setItem('urlService', true);
      //localStorage.setItem('appID',response.data.jData.name);
    })
    .then(function(myJson) {
      //console.log(myJson);
    });
    return param
}

export function addDays(date, days) {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
}

export function getDates(startDate, stopDate) {
 var dateArray =[]
 var currentDate = startDate;
 while (currentDate <= stopDate) {
   dateArray.push(currentDate)
   currentDate = currentDate.addDays(1);
 }
 return dateArray;
}

//add days between two days to an array 
export function getDates1(startDate, endDate) {
  let dates = [];
  let currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
}

export const ConcatDate=(day)=>{
  var dd = String(day.getDate()).padStart(2, '0');
  var mm = String(day.getMonth() + 1).padStart(2, '0'); 
  var today = mm +dd
  return today
}

export const couleur=()=>{
  return '#'+((1<<24)*(Math.random()+1)|0).toString(16).substr(1)
}

export const couleurs=(val)=>{
  var tab=[]
  for(let pas = 0; pas <val; pas++){
    var col='#'+(Math.random()*0xFFFFFF<<0).toString(16);
    if(col!=='#000000'&&col!=='#FFFFFF'){
      tab.push(col)   
    }
  }
     return tab
}

export  const rgbToHex = (rgb) => {
  //console.log(rgb)
  var index = rgb.indexOf("rgb"); 
  if(index!==-1)
    {
     const rgbExcludeFirst = rgb.split('rgb(')[1]; 
     const rgbExcludeLast = rgbExcludeFirst.split(')')[0]; 
     const rgbValueArray = rgbExcludeLast.split(',');  
     const first= Number(rgbValueArray[0]).toString(16)
     const second=Number( rgbValueArray[1]).toString(16)
     const third=Number(rgbValueArray[2]).toString(16)
     return '#'+first+second+third
    }else return rgb
  };

  export const ColorApp=(col)=>{
    switch (col) {
      case 'iRahati':return '#4cb050'
      case 'Boutiqa':return '#9a2120'
      default:return '#482e1e'
    }
  }

 export function getDaysInMonth(year, month) {
    return new Date(year, month, 0).getDate();
 }

 export const DateDebMonth=(mois,annee)=>{
    var today=new Date();
    return (annee!=='')?new Date(annee,mois-1, 1):new Date(today.getFullYear(),mois-1, 1);
 }

 export const DateFinMonth=(mois,annee)=>{
    var today=new Date();
    return (annee!=='')?new Date(annee, mois, 0):new Date(today.getFullYear(), mois, 0);
 }

 const getTrimMonth=(trim)=>{
   switch (trim) {
      case 1:return ['01','03']
      case 2:return ['04','06']
      case 3:return ['07','09']
      default:return ['10','12']
   }
 }

 export const TrimDebFin=(trim,annee)=>{
   var result=[]
    var array =getTrimMonth(trim);
    result.push(DateDebMonth(array[0],annee))
    result.push(DateFinMonth(array[array.length-1],annee))
    return result;
 }
 export const AnnDebFin=(ann,date1,date2)=>{
  var dd = String(date1.getDate()).padStart(2, '0');
  var mm = String(date1.getMonth() + 1).padStart(2, '0'); 
  var day = new Date(ann,mm-1, dd)
  
  var dd1 = String(date2.getDate()).padStart(2, '0');
  var mm1 = String(date2.getMonth() + 1).padStart(2, '0'); 
  var day1 = new Date(ann,mm1-1, dd1)
  return [day,day1]
 }
 /*export const getyear=()=>{
  var today=new Date()
  console.log(today)
  var date =today.getFullYear()
  console.log(date)
  const array=[{id:1,name:(date).toString()},{id:2,name:(date-1).toString()},{id:3,name:(date-2).toString()},{id:4,name:(date-3).toString()},
               {id:5,name:(date-4).toString()}]
  console.log(array)
  return array;
}

 export const getMonthYear=(ann)=>{
  console.log(ann)
  var array=[]
    var today=new Date()
    var monthNames = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
   if(ann===1){
    var mm = String(today.getMonth() + 1).padStart(2, '0'); 
    console.log(mm,mm.substr(1)-1)
    console.log(monthNames.slice(0,mm.substr(1)-1))
    var m=monthNames.slice(0,mm.substr(1)-1)
    monthNames.map((el,index)=>{
      array.push({id:index+1,name:el})
    })
    console.log(array)
    return array
   }else{
    return [{id:1,name:"Janvier"},{id:2,name:" Février"},{id:3,name:"Mars"},{id:4,name:"Avril"},
            {id:5,name:"Mai"},{id:6,name:"Juin"},{id:7,name:"Juillet"},{id:8,name:"Août"},
            {id:9,name:"Septembre"},{id:10,name:"Octobre"},{id:11,name:"Novembre"},{id:12,name:"Décembre"}];
   }
 }

 export const getTrimYear=()=>{
  var today=new Date()
  console.log(today)
  var date =today.getFullYear()
  console.log(date)
  const array=[{id:1,name:(date).toString()},{id:2,name:(date-1).toString()},{id:3,name:(date-2).toString()},{id:4,name:(date-3).toString()},
               {id:5,name:(date-4).toString()}]
  console.log(array)
  return array;
}*/

export function getYear(date){
  var yyyy = date.getFullYear();
  return yyyy.toString(); 
}

export function MonthName(num){
  switch (num) {
    case '01': return 'Janvier';
    case '02': return 'Fevrier';
    case '03': return 'Mars';
    case '04': return 'Avril';
    case '05': return 'Mai';
    case '06': return 'Juin';
    case '07': return 'Juillet';
    case '08': return 'Aout';
    case '09': return 'Septembre';
    case '10': return 'Octobre';
    case '11': return 'Novembre';
    default:return'Decembre';    
  }
 }
export function MonthName1(num){
  switch (num) {
    case 'Janvier': return '01';
    case 'Fevrier': return '02';
    case 'Mars': return '03';
    case 'Avril': return '04';
    case 'Mai': return '05';
    case 'Juin': return '06';
    case 'Juillet': return '07';
    case 'Aout': return '08';
    case 'Septembre': return '09';
    case 'Octobre': return '10';
    case 'Novembre': return '11';
    default:return'12';    
  }
 }
export function getMonth(date){
  var mm = String(date.getMonth()+1).padStart(2, '0');
  var monthName=MonthName(mm)
  return monthName;
}

export function include(arr, obj) {
  if(arr.length===0){
    return false
  }else{
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] === obj) {
        return true
      }
    }
    return false
  }
  
}
export function isHex(str){
  var regex = /[0-9A-Fa-f]{6}/g;
  if (str.match(regex)){
    return true
  }else{
    return false
  }
}
export function isNumeric(str) {
  if (typeof str != "string") return false // we only process strings!  
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}
//Vérifie l'égalité de deux tableau
export function isEqual(array1,array2){
  let isEqual = true;
if (array1.length !== array2.length) {
  isEqual = false;
} else {
  for (let i = 0; i < array1.length; i++) {
    if (array1[i] !== array2[i]) {
      isEqual = false;
      break;
    }
  }
}
 return isEqual
}
//fonction pour detecter l'element manquant d'un tableau par rapport un autre
export function  MissingElem(array1,array2){
  const missElem = array2.find(element => !array1.includes(element));
  return missElem

}
//fonction pour detecter tous les elements manquant d'un tableau par rapport un autre
export function  MissingElems(array1,array2){
  const missElem = array2.filter(element => !array1.includes(element));
  return missElem

}
//fonction pour detecter tous les elements manquant d'un tableau d'objets par rapport un autre tableau simple 
export function  MissingObjs(array1,array2,att){
  const missObjs = array2.filter(obj2 =>!array1.some(obj1=> obj2 === obj1[att])
  );
  return missObjs
}


export function sortTable(data,att){
  const sortedData = data.sort((a, b) => {
    if (a[att] < b[att]) {
      return -1;
    } else if (a[att] > b[att]) {
      return 1;
    }
    return 0;
  });
  return sortedData
}


export const Caisse=async ()=>{
  const response=await axiosGetAction('_cai.php' ,'getcaisse')
  updateElem("caisseIr",response.data.jData)   
}

export function formatNumber(val){
  return Intl.NumberFormat(undefined, { maximumFractionDigits: 4 }).format(val);
}

export function formatNumberX(val, x) {
  return Intl.NumberFormat(undefined, { maximumFractionDigits: x }).format(val);
}