import React,{useCallback, useMemo, useState} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog, Tooltip, TextField} from '@material-ui/core';
import Draggable from 'react-draggable';
import DataTable from "react-data-table-component";
import { useStyles,paperPropsBig } from '../../../css/muiStyle';
import {customStyles, numberColumn}from '../../../css/datatableStyle'
import Iconn from "@mdi/react"
import { mdiCashRegister } from '@mdi/js';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Add, IndeterminateCheckBox } from '@material-ui/icons';
import { AlertCompo } from '../../../js/components';
import { Autocomplete } from '@material-ui/lab';


//add new marque
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

const Exp_cns_encs = React.memo((props) => {
    const Gfunc=require('../../../Gfunc')
    const param=props.param
    const [open, setOpen] =useState(false);
    const [loading, setLoading] =useState(false);
    const [control, setControl] =useState(false);
    const [userL, setUserL] =useState(false);
    const [caisse, setCaisse] =useState('');
    const [caisseN, setCaisseN] =useState('');
    const [data, setData] =useState([]);
    const [mtn, setMtn] =useState(0);
    const [note, setNote] =useState("");
    const addTitle="Encaissement"
    // const service="_cso.php"
    const classes=useStyles();
    const labelText1="Montant"
    const labelText2="Note"
    const labelText4="Caissier"
    const [severity, setSeverity] =useState("info");
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [, setDel] = useState('');

   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpenA(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpenA(false)
    }, 5000);
  }

    const fetch= async(id=caisse) => {
      setLoading(true)
      const response=await Gfunc.axiosPostAction("_cai.php",'getcaihist',{caissierId:id})
      if(response.data.err&&response.data.err!=='-1'){
        alert(response.data.err)
        setOpen(false)
      }else
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData!==undefined){
          setData(response.data.jData);
        }
      })
      setLoading(false)
    }

    const handleClickOpen = () => { 
      setMtn(0);
      setNote('')
      //fetch()
      fetchUser()
      setCaisse("")
      setCaisseN("")
      setData([])
      setOpen(true); 
    };

     const Caisse=async()=>{
        const response=await Gfunc.axiosGetAction('_cai.php' ,'getcaisse');
        Gfunc.axiosResponse(response,()=>{
            Gfunc.updateElem("caisseIr",response.data.jData)
        })
      }

    const handleSubmit = async() => { 
        const dat={
            caissierId:caisse,
            mtn:parseFloat(mtn),
            note:note
          }
          const respon=await Gfunc.axiosPostAction("_cai.php","addcai",dat)
          if(respon.data.err&&respon.data.err!=='-1'){
            handleAlert(true,'error',respon.data.err)
          }else
          Gfunc.axiosResponse(respon,()=>{
            //props.getConso();
            Caisse();
           // data.unshift({date:Gfunc.getDate(),caissier:localStorage.getItem("name"),note:note,mtn:mtn,conso:respon.data.jData});
            //setOpen(false);
            fetch(caisse)
            setControl(!control)
          })
    };
    
    //delete 
const handleSetData=useCallback((row,att)=>{
  Gfunc.removeItemsByAtt(data,row.DT_RowId,att)
  setDel('')
},[data,Gfunc])

   //handle delete 
const handleDelete=useCallback(async(row)=>{
  const x=window.confirm("Êtes-vous sure de supprimer cet élément?");
  if(x){
    const response = await Gfunc.axiosPostAction("_cai.php","del",{caisseId:row.DT_RowId.substr(1)})
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else{
        Gfunc.axiosResponse(response,()=>{
            handleAlert(true,'success',"L'élément est supprimé avec succès.")
            handleSetData(row,"DT_RowId")
            setDel(row.DT_RowId)
            setControl(!control)
        })
  }
}
},[Gfunc,handleSetData,handleAlert])

//fetch users
const fetchUser= useCallback(async(serv="_usr.php", act = "gets") => {
  const response = await Gfunc.axiosGetAction(serv,act);
  if(response.data.err){
    handleAlert(true,'error',response.data.err)
  }else{
  Gfunc.axiosResponse(response,()=>{
      setUserL(response.data.jData);    
  })
}
},[Gfunc])

    const handleClose = () => { setOpen(false); };
 

    const columns = useMemo(
      () =>[
        {
          name: "Date",
          width:'135px !important',
          selector: row=>row.date,
          sortable: true,
        },
        {
          name: "Opération",
          selector: row=>row.operation,
          sortable: true,
          minWidth:'150px !important',
         
        },
        {
          name: "Note",
          selector: row=>row.note,
          sortable: true,
          minWidth:'250px !important',
          cell: (row) => {
              return (
                <div  title={row.note}>
                  {row.note} 
                </div>
               );
             },
        },
        {
          name: "Montant",
          style:numberColumn,
          selector: row=>row.mtn,
          sortable: true,
          width:'140px !important',
          cell: (row) => {
            return (
              <div>
                {new Intl.NumberFormat().format(row.mtn)} 
              </div>
             );
           },
        },
        {
          name: "Caisse",
          style:numberColumn,
          selector: row=>row.conso,
          sortable: true,
          width:'150px !important',
          cell: (row) => {
            return (
              <div>
                {new Intl.NumberFormat().format(row.conso)} 
              </div>
             );
           },
        },
        {   right:true,
            maxWidth:'30px !important',
            cell: (row,index) => {
              return (
                        <div className="rowGrid" id="oneIcons" title="Supprimer"><IndeterminateCheckBox fontSize="small" onClick={()=>handleDelete(row,index)} className={classes.redIcon}/> </div> 
                      );
             },
        }  
      ],[handleDelete]);
  return (
    <div>
      <Button className={classes.diagTabBtn} variant="contained" color="primary" onClick={handleClickOpen} startIcon={ <Iconn path={mdiCashRegister} size={0.7} color={"white"}/>}>Encaissement</Button>
      <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsBig}
      >
        <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >{addTitle}</DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true} />
            <DialogContent>
                <fieldset className="fieldsetTop2" id="encs">
                    <legend>Ajouter un encaissement:</legend>
                    <ValidatorForm id="encsForm" onSubmit={handleSubmit} onError={errors => console.log(errors)} >
                    <Autocomplete
                                disableClearable
                                className='smallAuto'
                                id="iCaisse"
                                onChange={(e,newValue) =>{
                                  //const idf=props.userL[Gfunc.getIndex(props.userL,newValue,"name")].id
                                    setCaisse(newValue.id)
                                    setCaisseN(newValue.name)
                                    fetch(newValue.id)
                                  }}
                                value={{id:parseInt(caisse),name:caisseN}}
                                options={userL}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) => (option.id=== value.id)}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={labelText4}
                                    variant='outlined'
                                    margin='dense'
                                    size="small"
                                    required/>
                                )} />
                        <TextValidator margin="dense"  label={labelText1} size="small" type='number' value={mtn} variant="outlined"
                            className='smallText'
                            id="iMtn"
                            InputProps={{ inputProps: { min: 0 /*,step:"0.01"*/} }}
                            onChange={e =>setMtn(e.target.value)} required autoFocus/>
                        <TextValidator size="small" variant="outlined" label={labelText2} id="iNote" name="iNote"  value={note}
                            margin="dense"
                            className='smallText'
                            onChange={e =>setNote(e.target.value) } />
                        <Button className={classes.diagTabBtn} type="submit" variant="contained" color="primary" startIcon={<Add/>}>Ajouter</Button>
                    </ValidatorForm>
                </fieldset>
              <DataTable 
                  key={control}
                  columns={columns}
                  data={data}
                  pagination
                  noDataComponent={param.ErreurData}
                  progressPending={loading}
                  highlightOnHover
                  noHeader={true}
                  customStyles={customStyles}
                  paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}            
                />
            </DialogContent>
            <DialogActions>
            <Button variant="contained" onClick={handleClose} color="secondary">FERMER</Button>
            </DialogActions>
      </Dialog>
      </div>
  );
});
export default Exp_cns_encs;