import React,{ useState, useEffect ,useCallback,useMemo} from "react";
import DataTable from "react-data-table-component";
import TextField from '@material-ui/core/TextField';
import {CircularProgress,Paper, Box, TextareaAutosize, ClickAwayListener, Tooltip} from '@material-ui/core';
import {Search,IndeterminateCheckBox,ZoomIn} from '@material-ui/icons';
import AddNvBn from './modals/ach_add_nvbn'
import AddVers from './modals/ach_add_vers'
import AddCrnc from './modals/ach_add_crnc'
import AddAch from './modals/ach_add_ach'
import { textAreaEdit, useStyles } from '../../css/muiStyle';
import {conditionalRowStyles,customStyles, numberColumn}from '../../css/datatableStyle'
import  {Detail_Bon as DetailBon,AutocompleteS,DatePickerUpd, InputNbrEditable,AlertCompo,NoDataComponent,NotAllowedComponent} from '../../js/components';
import DTable from "../../compo/DTable";


export default function Ach_add (props){
  const param=props.param
  const Gfunc=require('../../Gfunc')
  const librery=require('../../js/data_table_librery')
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [called, setCalled] = useState(false);
  const [resetDP, setResetDP] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [note, setNote] = useState('');
  const [perPage, setPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [search,setSearch]=useState('');
  const service='_ach.php'
  const classes=useStyles();
  const [selecto, setSelecto] = useState('');
  const [Item, setItem] = useState('');
  const [ItemN, setItemN] = useState('');
  const [, setDel] = useState('');
  const [clickedRow, setClickedRow] = useState('');
  const [frnsL, setFrnsL] = useState([]);
  const [userL, setUserL] = useState([]);  
  const [control, setControl] = useState(false);
  const[achatNum,setAchatNum]=useState(0);
  const[noData,setNoData]=useState(false);
  const[doSearch,setDoSearch]=useState(false);
  const[notAllowed,setNotAllowed]=useState(false);
  const [severity, setSeverity] =useState("info");
  const [open, setOpen] =useState(false);
  const [messageA, setMessageA] =useState('');
  const[errMessage,setErrMessage]=useState('');
  const[records,setRecords]=useState('');
  const filter='getbons&search='+search;

   //handle alerts properties
   const handleAlert=useCallback((open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  },[])
  
  //get bon details 
  const fetchDetails= useCallback(async(bon) => {
    setLoading2(true)
    const response=await Gfunc.axiosPostAction(service,'getbon',{bon:bon})
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
      if(response.data.jData!==undefined){
        setData2(response.data.jData)
        setAchatNum(Gfunc.countTableId(response.data.jData,'produitId'))
      }else{
      setData2([])
      setAchatNum(0)
    }
    })
    setLoading2(false)
  }
  },[Gfunc,handleAlert]);

  const handleRowClick=useCallback((row,dat)=>{
    const updatedData = dat.map(item => {
        if (row !== item.DT_RowId) {
          return {
            ...item,
            toggleSelected: false
          };
        }
        return {
          ...item,
          toggleSelected: true
        };
      });
    setData(updatedData);
    fetchDetails(row.substr(1));
    setClickedRow(row)
  },[fetchDetails])

  const fetch=useCallback(async(page,size = perPage) => {
    setCalled(true)
    setLoading(true);
    const response=await librery.axiosGet2(page,service,filter,size)
    if(response.data.err&&response.data.err!=="-1"){
      setErrMessage(response.data.err)
      setNotAllowed(true)
    }else{
    Gfunc.axiosResponse(response,()=>{
        setRecords(response.data.recordsFiltered)
        if(response.data.data!==undefined){
            setData(response.data.data);
            setTotalRows(response.data.recordsFiltered);
            var test=Gfunc.getIndex(response.data.data,clickedRow,"DT_RowId")
              if(test!==-1){
                handleRowClick(clickedRow,response.data.data)}
              else{
                handleRowClick(response.data.data[0].DT_RowId,response.data.data)
              } 
              setNoData(true)
              setDoSearch(true)
        }else{
          setDoSearch(false)
          setData([])
        }
    })
  }
    setLoading(false);
  },[Gfunc,librery,perPage,service,filter,clickedRow,handleRowClick])
  
  //fetch after add nouveau bon so that it selects the new added bon instead of the previoudly clicked row
  const fetch2= async(page,servic = service,size = perPage) => {
    setCalled(true)
    setLoading(true);
    const response=await librery.axiosGet2(page,servic,'getbons&search='+search,size)
    if(response.data.err&&response.data.err!=="-1"){
        setErrMessage(response.data.err)
        setNotAllowed(true)
      }else
      Gfunc.axiosResponse(response,()=>{
        if(response.data.data!==undefined){
          setData(response.data.data);
          handleRowClick(response.data.data[0].DT_RowId,response.data.data)         
          setTotalRows(response.data.recordsFiltered);
          setDoSearch(true)
        }else {setDoSearch(false);setData([])}
        setNoData(true)
      })  
      setLoading(false)
  }  
 
    //fetch fournisseurs
    const fetchFrns= useCallback(async(serv="_frs.php", act = "gets") => {
        const response = await Gfunc.axiosGetAction(serv,act);
        if(response.data.err && response.data.err!=='-1'){
          handleAlert(true,'error',response.data.err)
        }else{
        Gfunc.axiosResponse(response,()=>{
            setFrnsL(response.data.jData);    
        })
      }
      },[Gfunc,handleAlert])

    //fetch users
    const fetchUser= useCallback(async(serv="_usr.php", act = "gets") => {
        const response = await Gfunc.axiosGetAction(serv,act);
        if(response.data.err){
          handleAlert(true,'error',response.data.err)
        }else{
        Gfunc.axiosResponse(response,()=>{
            setUserL(response.data.jData);    
        })
      }
      },[Gfunc,handleAlert])

  useEffect(() => {
    if(!called)
    fetch(1);
  }, [fetch,called]);
  

//delete bon
const handleSetData=useCallback((row,att)=>{
    Gfunc.removeItemsByAtt(data,row.DT_RowId,att)
    setDel('')
},[data,Gfunc])

//delete achat
const handleSetData2=useCallback((row,att)=>{
    Gfunc.removeItemsByAtt(data2,row.id,att)
    setDel('')
},[data2,Gfunc])

//handle delete bon
const handleDeleteBon=useCallback(async(row)=>{
  const x=window.confirm("Êtes-vous sure de supprimer cet élément?");
  if(x){
    const response = await Gfunc.axiosPostAction(service,"delbon",{bon:row.DT_RowId.substr(1)})
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else{
        Gfunc.axiosResponse(response,()=>{
            handleAlert(true,'success',"L'élément est supprimé avec succès.")
            handleSetData(row,"DT_RowId")
            setDel(row.DT_RowId)
            setTotalRows(totalRows-1)
        })
        if(data.length===0){
          setData2([])
          fetch(1)
        }else
        if(row.DT_RowId===clickedRow&&data.length!==0) handleRowClick(data[0].DT_RowId,data)
  }
}
},[Gfunc,handleSetData,service,data,fetch,totalRows,clickedRow,handleRowClick,handleAlert])

//handle delete achat
const handleDeleteAchat=useCallback(async(row)=>{
  const x=window.confirm("Êtes-vous sure de supprimer cet élément?");
  if(x){
    const response = await Gfunc.axiosPostAction(service,"delach",{achat:row.id})
    if(response.data.err&&response.data.err!=="-1"){
        handleAlert(true,'error',response.data.err)
    }else{
        Gfunc.axiosResponse(response,()=>{
            handleAlert(true,'success',"L'élément est supprimé avec succès.")
            handleSetData2(row,"id")
            setDel(row.id)
            setAchatNum(Gfunc.countTableId(data2,'produitId'))
            Gfunc.updateElem(clickedRow,response.data.jData['mtn_bon'])
        })
  }
}
},[Gfunc,handleSetData2,service,clickedRow,data2,handleAlert])



const handleOnSort=useCallback(async(column, sortdirection,page=currentPage, size = perPage+"&search="+search)=>{
  const response=await librery.axiosSort2(column,sortdirection,page,size,service,"getbons")
  if(response.data.err&&response.data.err!=="-1"){
    handleAlert(true,'error',response.data.err)
  }else{
  Gfunc.axiosResponse(response,()=>{
    setLoading(true);
    setData(response.data.data);
    setTotalRows(response.data.recordsFiltered);
    data.slice(0)
    setLoading(false);
  }) 
  }    
},[currentPage,perPage,Gfunc,librery,service,data,search,handleAlert])

//recherche serverside
const handleSearch=async(size = perPage)=>{
  setLoading(true);
  setCurrentPage(1)
  const recherche=search;
  const response=await librery.axiosSearch(service,recherche,1,size,"getbons")
  if(response.data.err&&response.data.err!=="-1"){
    handleAlert(true,'error',response.data.err)
  }else{
  Gfunc.axiosResponse(response,()=>{
    if(response.data.data!==undefined){
    setData(response.data.data);
    setTotalRows(response.data.recordsFiltered);
    }else setData([])
  })  
  setLoading(false); 
  return recherche
}
}

function liveSearch2() {
  handleSearch();
}

//fetch fournisseur handler
const handleOpenFrns = useCallback(async () => {
if(frnsL.length===0)
fetchFrns()
},[frnsL,fetchFrns])

//fetch utilisateurs handler
const handleOpenUsers = useCallback(async () => {
if(userL.length===0)
fetchUser()
},[userL,fetchUser])

//handle live search
function liveSearch(e,search){
  if(param.liveSearch&&search.length>3){
  e.preventDefault();
  handleSearch();
  }else{ if (e.keyCode === 13) {
          e.preventDefault();
          handleSearch();
        }
  }
}

 //handle update note
 const handleOnEditNote=useCallback(async(note,row,att,action)=>{
  const edt1=note
  var edt=Gfunc.DeleteSpace(edt1);
  if(row[att]!==edt){
    const attrib=row[att];
    const response=await librery.axiosEdit2(service,action,row,edt,att) 
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{row[att]=edt})
    if(response.data.err) {row[att]=attrib}
  }else {row[att]=edt}
  setNote('')
  setSelecto('')
},[librery,Gfunc,handleAlert]);


 //modifier un champs avec un select
 const handleSelect=useCallback((row,att)=>{
  setSelecto(row[att])
  setItem(row.DT_RowId)
  setItemN(att)
},[])


const columns =useMemo(
    () => [ 
        {   width:"45px !important",
            cell: (row) => {
              return (
                 <div  title="Détail"><ZoomIn fontSize="small" className={classes.blackIcon} onClick={()=>handleRowClick(row.DT_RowId,data)}  /> </div>
               );
             },
          }  , 
      {
          name: "DATE",
          width:'110px !important',
          maxWidth:'110px !important',
          selector: row=>row.date,
          sortField: "date",
          sortable: true,
          cell: (row) => {
            return (
              <DatePickerUpd selecto={selecto} Item={Item} ItemN={ItemN} row={row}
                  name="date" minDate={Gfunc.LastXDay(300)}
                  service={service} action='updbon' extra=''
                  handleSelect={handleSelect} setItem={setItem} handleAlert={handleAlert}/>
             );
           },
      },
      {
        name: "FOURNISSEUR",
        minWidth:'150px !important',
        maxWidth:'300px !important',
        selector: row=>row.frns,
        sortField: "frns",
        sortable: true,
        cell: (row) => { 
            return (
                  <AutocompleteS
                    service={service} action={'updbon'} 
                    defaultValue={row.frns} row={row} list={frnsL} 
                    id={'idfrns'} name='frns' attr='name'
                    onOpen={handleOpenFrns} handleAlert={handleAlert}/>
            );
        },
    },
    {
        name: "ACHETEUR",
        minWidth:'150px !important',
        maxWidth:'300px !important',
        selector: row=>row.saller,
        sortField: "saller",
        sortable: true,
        cell: (row) => { 
            return (
                 <AutocompleteS
                  service={service} action={'updbon'} 
                  defaultValue={row.saller} row={row} list={userL} 
                  id={'idsaller'} name='saller' attr='name'
                  onOpen={handleOpenUsers} handleAlert={handleAlert}/>
            );
         },
    },
    {
        name: "MONTANT",
        minWidth:'150px !important',
        style:numberColumn,
        selector: row=>row.mtn,
        sortable: true,
        sortField:'mtn',
        cell: (row) => {
          return (
            <div id={row.DT_RowId}>
              {new Intl.NumberFormat().format(row.mtn)} 
            </div>
          );
        },
    },
    {
      name: "NOTE",
      selector: row=>row.note,
      minWidth:'300px !important',
      sortable: true,
      sortField:'note',
      cell: (row) => {
        return (
          <div className="note-container">
          { (row.note===selecto && row.DT_RowId===Item)?
          <ClickAwayListener onClickAway={()=>{(note)?handleOnEditNote(note,row,"note",'updbon'):setSelecto('')}}>
           <TextareaAutosize style={textAreaEdit} autoFocus onFocus={(e)=>{ e.target.value=note}}  onChange={(e)=>{setNote(e.target.value);}}
           /></ClickAwayListener>:
           (((row.note&&row.note.match(/\n/g)) || []).length>=1)?
           <Tooltip title={row.note.replaceAll("\n", "/ ")}>
               <div className="content-editable" onClick={()=>{setNote(row.note);handleSelect(row,'note')}}>{row.note.substring(0, row.note.indexOf("\n"))+"..."}</div>
           </Tooltip>:
           <Tooltip title={(row.note)?row.note:''}>
           <div className="content-editable" onClick={()=>{setNote(row.note);handleSelect(row,'note')}} >{row.note}</div></Tooltip>}
           </div>
         );
       },
  },
      {
        right:true,
        cell: (row) => {
          return (
            <div id="icons" >
              <div className='rowGrid' id="row-1" >
                <div title="Imprimer"><DetailBon icon={true} num="numero" row={row}/></div> 
                <div onClick={()=>handleDeleteBon(row)} title="Supprimer"><IndeterminateCheckBox  fontSize="small" className={classes.redIcon}/> </div> 
              </div>      
            </div>
           );
         },
      }  
    ],[frnsL,Item,selecto,handleSelect,handleDeleteBon,handleRowClick,classes,userL, handleOpenFrns,handleOpenUsers,data,ItemN,Gfunc,handleOnEditNote,note,handleAlert]);


    const columnsD =useMemo(
        () => [   
          {
              name: "DATE",
              minWidth:'120px !important',
              selector: row=>row.date,
              sortField: "date",
             // sortable: true,
          },
          {
            name: "PAR",
            minWidth:'120px !important',
            selector: row=>row.userin,
            sortField: "userin",
            //sortable: true,
        },
        {
            name: "PRODUIT",
            minWidth:'300px !important',
            selector: row=>row.produit,
            sortField: "produit",
            //sortable: true,
        },
        {
          name: "QTE.",
          minWidth:'90px !important',
          style:numberColumn,
          selector: row=>row.qte,
          sortField:'qte',
          sortable: true,
          cell: (row) => {
            return (
                <InputNbrEditable
                    datein={row.dateCreate} agentin={row.userCreate} dateupd={row.dateUpdate} agentupd={row.userUpdate}
                    service={service} action='updach' min={0}
                    row={row} att="qte" value={row.qte} id='id'
                    //handleAlert={handleAlert}
                    traetResp={(resp)=>{
                      if(resp!==undefined&&resp['jData']){ 
                        const index=Gfunc.getIndex(data,clickedRow,'DT_RowId')
                        data[index]['mtn']=resp.jData['mtn_bon']
                        const index2=Gfunc.getIndex(data2,row.id,'id')
                        data2[index2]['qte']=row.qte
                        setData2(data2)
                        setControl(!control)
                        Gfunc.updateElem(clickedRow,resp.jData['mtn_bon'])
                        Gfunc.updateElem(row.id,resp.jData['mtn_achat'])
                        }
                    }}
                />
             );
           },
       },
        {
            name: "PRIX",
            minWidth:'130px !important',
            selector: row=>row.prx,
            sortField: "prx",
            style:numberColumn,
            //sortable: true,
            cell:(row)=>{
              return ( <div>{new Intl.NumberFormat().format(row.prx)}</div>)
            }
        },
        {
            name: "TOTAL",
            minWidth:'150px !important',
            style:numberColumn,
            selector: row=>row.mtn,
            sortable: true,
            sortField:'mtn',
            cell:(row)=>{
              return (<div id={row.id}>{new Intl.NumberFormat().format(row.mtn)}</div>)
            }
        },  
        {   right:true,
            cell: (row) => {
              return (
                 <div className="rowGrid" id="oneIcons" title="Supprimer"><IndeterminateCheckBox fontSize="small"  onClick={()=>handleDeleteAchat(row)} className={classes.redIcon}/> </div> 
               );
             },
        }  
        ],[handleDeleteAchat,Gfunc,classes.redIcon,clickedRow,control,data,data2]);
 
  return (
    <div className="content" >
       <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
       <Paper className={classes.paperBody} elevation={3}>
       { /*<p className="paperTitle">Liste des bons d'achat</p>*/}
        <div className='table-header'>
            <div className="button-flex-wrapper">
                <AddNvBn handleAlert={handleAlert} handleOpenFrns={handleOpenFrns} handleOpenUsers={handleOpenUsers} frnsL={frnsL} 
                         userL={userL} callback={()=>{if(currentPage===1) fetch2(1);else setResetDP(!resetDP);}} param={param}/>
                <AddVers  frnsL={frnsL} handleOpenFrns={handleOpenFrns} userL={userL} handleOpenUsers={handleOpenUsers} callback={()=>fetch(currentPage)} param={param}/>
                <AddCrnc  callback={()=>fetch(currentPage)} param={param}/>
            </div>  
            <div className='search'>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Search className={classes.blackIcon} sx={{ color: 'action.active', mr: 1, my: 0.5 }} onClick={(e)=>liveSearch2(e)}/>
                      <TextField className="smallText" id="searchBar" variant="outlined" label="Recherche" value={search} size="small" margin='dense' onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} autoFocus/>
                   </Box>
                </div>
            </div>   
            {
              (data.length!==0)?
            <div id={(data.length<=5)?"bonDT1":"bonDT"}> 
                <DTable
                  currentPage={currentPage}
                  noHeader={false}
                  fixedHeader={data.length>5}
                  title="Liste des bons d'achat"
                  data={data}
                  columns={columns}
                  loading={loading}
                  fetch={fetch}
                  handleOnSort={handleOnSort}
                  resetDP={resetDP}
                  totalRows={totalRows}
                  perPage={perPage}
                  setPerPage={setPerPage}
                  setCurrentPage={setCurrentPage}
                  paginationRowsPerPageOptions={[5,10,20,50]}
                  customStyles={customStyles}
                  conditionalRowStyles = { conditionalRowStyles }/>         
              </div>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData || records===0)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
            {clickedRow!==''?
            <div >
              <br/>
                  <div className="table-header">
                  <div className="button-flex-wrapper" >
                      <AddAch data={data2} bon={clickedRow} callback={fetchDetails} handleAlert={handleAlert}/>  
                  </div> 
                  </div>
                  {
                    (data2.length!==0||doSearch)?
                    <div id={(data2.length<=5)?"achatDT1":"achatDT"}>
                    <DataTable 
                        columns={columnsD}
                        data={data2}
                        title={"Détail du bon ["+achatNum+"]"}
                        noDataComponent={param.ErreurData}
                        progressPending={loading2}
                        progressComponent={<CircularProgress className={classes.circularProgress} />}
                        highlightOnHover
                        customStyles={customStyles}
                      />
                  </div>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData || records===0)?<NoDataComponent message="Aucun résultat trouvé"/>:null}

              </div>:<></>}
      </Paper>
    </div> 
  );
}