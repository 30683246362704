import React,{ useState, useEffect ,useCallback,useMemo} from "react";
import TextField from '@material-ui/core/TextField';
import {Button,Paper,Box} from '@material-ui/core';
import {Search,DesktopAccessDisabled} from '@material-ui/icons';
import {conditionalRowStyles,customStyles}from '../../css/datatableStyle'
import { useStyles } from '../../css/muiStyle';
import { AlertCompo,NotAllowedComponent,NoDataComponent } from "../../js/components";
import DTable from "../../compo/DTable";

export default function Adm_ses(props){
  const param=props.param
  const Gfunc=require('../../Gfunc')
  const librery=require('../../js/data_table_librery')
  const [data, setData] = useState([]);
  const [called, setCalled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search,setSearch]=useState('');
  const [,setIcon]=useState('');
  const [noData,setNoData]=useState(false);
  const [doSearch,setDoSearch]=useState(false);
  const [notAllowed,setNotAllowed]=useState(false);
  const [severity, setSeverity] =useState("info");
  const [open, setOpen] =useState(false);
  const [messageA, setMessageA] =useState('');
  const [errMessage,setErrMessage]=useState('');
  const [records,setRecords]=useState('');
  const service='_ses.php'
  const srv="session"
  const classes=useStyles();
  const filter="&search="+search

  //handle alerts properties
  const handleAlert=useCallback((open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  },[])

  const fetch= useCallback(async(page, size = perPage) => {
    setCalled(true)
    setLoading(true);
    const response=await librery.axiosGet(page,service,size+filter)
    if(response.data.err&&response.data.err!=="-1"){
        setErrMessage(response.data.err)
        setNotAllowed(true)
    }else{
    Gfunc.axiosResponse(response,()=>{
      setRecords(response.data.recordsFiltered)
      if(response.data.data!==undefined){
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
        setLoading(false);
        setNoData(true)
        setDoSearch(true)
      }else{
        setDoSearch(false)
        setData([])
      }
    })
  }
  },[Gfunc,librery,service,filter,perPage])

  useEffect(() => {
    if(!called)
    fetch(1);
  }, [fetch,called]);
  
 const flagMan = useCallback(async(row)=>{
    const response= await Gfunc.axiosPostAction('flagman.php','1',({ srv:srv, id: row.DT_RowId.substr(1),att:'closed', val: row.closed }))
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else{
     Gfunc.axiosResponse(response,()=>{ 
      if (response.data.jData) {
        const objIndex = data.findIndex((obj => obj.DT_RowId === row.DT_RowId));
        setIcon(row.DT_RowId)
        if(row.closed){
          data[objIndex].closed=0
        }else data[objIndex].closed=1
        setIcon('')     
      }else handleAlert(true,'error',response.data.err);      
            })
    }
},[Gfunc,data,handleAlert])
const handleLockIcon=(val)=>{
  
  setIcon(val);
}
useEffect((val) => {
handleLockIcon(val);
}, []);

const columns =useMemo(
    () => [   
      {
          name: "OUVERTURE",
          selector:row=>row.open_date,
          minWidth:'160px !important',
          sortField: "open_date",
          sortable: false,
      },
      {
        name: "UTILISATEUR",
        selector:row=>row.user,
        minWidth:'150px !important',
        sortField: "user",
        sortable: false
      },
      {
          name:'ACTIVITE',
          selector:row=>row.last_action,
          minWidth:'160px !important',
          sortField:"last_action",
          sortable: false
      },
      {
          name:"IP",
          selector:row=>row.ip,
          minWidth:'160px !important',
          sortField:"ip",
          sortable: false
      },
      {
        name:"MACHINE",
        selector:row=>row.host,
        minWidth:'160px !important',
        sortField:"host",
        sortable: false
      },
      {
        right:true,
        cell: (row) => {
          return (
              <div onClick={()=>flagMan(row)} title="Fermer session">{(!row.closed)?<DesktopAccessDisabled className='redIcon' fontSize="small" />:<></> }</div>        
           );
         },
      }  
    ],[flagMan]);
  
  const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage+"&search="+search)=>{
      setLoading(true);
      const response=await librery.axiosSort(column,sortdirection,page,size,service)
      if(response.data.err&&response.data.err!=="-1"){
        handleAlert(true,'error',response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
        setLoading(false);
      }) 
    }    
  }

  //recherche serverside
const handleSearch=async(size = perPage)=>{
  setLoading(true);
  setCurrentPage(1)
  const recherche=search;
  const response=await librery.axiosSearch(service,recherche,1,size,"get")
  if(response.data.err&&response.data.err!=="-1"){
    handleAlert(true,'error',response.data.err)
  }else{
  Gfunc.axiosResponse(response,()=>{
    if(response.data.data!==undefined){
    setData(response.data.data);
    setTotalRows(response.data.recordsFiltered);
    }else setData([])
  })  
  setLoading(false); 
  return recherche
}
}

function liveSearch(e,search){
  if(param.liveSearch&&search.length>3){
     e.preventDefault();
     handleSearch();
  }else{ if (e.keyCode === 13) {
            e.preventDefault();
            handleSearch();
          }
  }
}

function liveSearch2() {
  handleSearch();
}

//kill all session
const handleKillSession=async()=>{
    const response=await Gfunc.axiosGetAction(service,"kall")
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
      handleAlert(true,'success',"Toutes les sessions sont expirées")
      fetch(1)
    })
  }
}

  return (
    <div className="content">
      <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
      <Paper className={classes.paperBody} elevation={5}>
        <div className='table-header'>
          <div className='button-flex-wrapper' ><Button  className={classes.topTabBtn}  variant="contained" color="primary" onClick={handleKillSession}>Fermer toutes les sessions</Button></div>
          <div className='search'>
               <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Search className={classes.blackIcon} sx={{ color: 'action.active', mr: 1, my: 0.5 }} onClick={(e)=>liveSearch2(e)}/>
                      <TextField className="smallText" id="searchBar" variant="outlined" label="Recherche" value={search} size="small" margin='dense' onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} autoFocus/>
                </Box>
            </div>
        </div>
        {
          (data.length!==0 ||doSearch)?
          <div>
            <DTable
             currentPage={currentPage}
             data={data}
             columns={columns}
             loading={loading}
             fetch={fetch}
             handleOnSort={handleOnSort}
             //resetDP={resetDP}
             totalRows={totalRows}
             perPage={perPage}
             setPerPage={setPerPage}
             setCurrentPage={setCurrentPage}
             paginationRowsPerPageOptions={[10,25,50,75,100]}
             customStyles={customStyles}
             conditionalRowStyles = { conditionalRowStyles }/>
           </div>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData || records===0)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
      </Paper>
    </div>    

  );
}