import React,{ useState, useEffect ,useCallback,useMemo} from "react";
import DataTable from "react-data-table-component";
import ContentEditable from 'react-contenteditable';
import {TextField,CircularProgress,Checkbox,Paper,Box} from '@material-ui/core';
import {Search,Lock,LockOpen} from '@material-ui/icons';
import Addprod from './modals/ref_prd_add'
import {conditionalRowStyles,customStyles, customStyles2, numberColumn}from '../../css/datatableStyle'
import { useStyles } from '../../css/muiStyle';
import "../../css/referentiel.css"
import {NoDataComponent,NotAllowedComponent,AutocompleteS, InputNbrEditable, AlertCompo} from "../../js/components"


export default function Ref_prd(props){
  const param=props.param
  const Gfunc=require('../../Gfunc')
  const librery=require('../../js/data_table_librery')
  const [data, setData] = useState([]);
  const [called, setCalled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [search,setSearch]=useState('');
  const [,setIcon]=useState('');
  //const [pselected, setSelectedData] = useState([ ]);
  const [wal, setWal] = useState([]);
  const [mrq, setMrq] = useState([]);
  const [cat, setCat] = useState([]);
  const [simL, setSimL] = useState([]);
  const service='_prd.php'
  const srv="produit"
  const classes = useStyles();
  const[noData,setNoData]=useState(false);
  const[doSearch,setDoSearch]=useState(false);
  const[notAllowed,setNotAllowed]=useState(false);
  const[errMessage,setErrMessage]=useState('');
  const [openA, setOpenA] =useState(false);
  const [message, setMessage] =useState('');
  const [severity, setSeverity] =useState('info');
  const [resetDP, setResetDP] = useState(false);
    
    const handleAlert=(openA,severity,message)=>{
      setOpenA(openA)
      setSeverity(severity)
      setMessage(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }
      
  // fetch digs
  const fetch= async(page,servic = service, size = perPage) => {
    setCalled(true);
    setLoading(true);
    const response=await librery.axiosGet(page,servic,size+"&search="+search)
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
      setErrMessage(response.data.err)
      setNotAllowed(true)
    }else
     Gfunc.axiosResponse(response,()=>{
       if(response.data.data!==undefined){
         setData(response.data.data);
         setTotalRows(response.data.recordsFiltered); 
         setDoSearch(true) 
       }else {setDoSearch(false);setData([])}
       setNoData(true)     
     })  
   setLoading(false); 
  }

    //fetch Sim
    const fetchSim= useCallback(async(serv="_sim.php", act = "gets") => {
      const response = await Gfunc.axiosGetAction(serv,act);
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData!==undefined){
          setSimL(response.data.jData);  
        }  
      })
    },[Gfunc,handleAlert])
  
     //fetch sim handler
   const handleOpenSim = useCallback(async () => {
      if(simL.length===0)
      fetchSim()
      },[simL,fetchSim])
  
  //fetch categorie
  const fetchCat= useCallback(async(serv="_cat.php", act = "gets") => {
    const response = await Gfunc.axiosGetAction(serv,act);
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
        setCat(response.data.jData);    
    })
  },[Gfunc])
  
  //fetch marque
  const fetchMrq= useCallback(async(serv="_mrq.php", act = "gets") => {
    const response = await Gfunc.axiosGetAction(serv,act);
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
        setMrq(response.data.jData);    
    })
  },[Gfunc])
  
  //fetch fournisseur handler
const handleOpenMrq = useCallback(async () => {
  if(mrq.length===0)
  fetchMrq()
  },[mrq,fetchMrq])


  //fetch wallet
  const fetchWal= useCallback(async(serv="_wal.php", act = "gets") => {
    const response = await Gfunc.axiosGetAction(serv,act);
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
        setWal(response.data.jData);    
    })
  },[Gfunc])
  
  //fetch wallet handler
const handleOpenWal = useCallback(async () => {
  if(wal.length===0)
  fetchWal()
  },[wal,fetchWal])

  //fetch fournisseur handler
const handleOpenCat = useCallback(async () => {
  if(cat.length===0)
  fetchCat()
  },[cat,fetchCat])

  useEffect(() => {
    if(!called)
    fetch(1);
  }, [fetch,called]);
  
  //update field
const handleOnEdit=useCallback(async(e,row,att,action,id)=>{
    const edt1=e.target.innerHTML
    const edt=Gfunc.DeleteSpace(edt1); 
        if(row[att].toString()!==edt){
          const attrib=row[att];
          const response=await librery.axiosEdit2(service,action,row,edt,att,id) 
          if(response.data.err&&response.data.err!=='-1'){
            handleAlert(true,'error',response.data.err)
          }else
          Gfunc.axiosResponse(response,()=>{row[att]=edt;e.target.innerHTML=edt})
          if(response.data.err) {e.target.innerHTML=attrib}
        }else {e.target.innerHTML=edt}
  },[librery,Gfunc]);


   // sort function handler
   const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage+"&search="+search)=>{
    setLoading(true);
    const response=await librery.axiosSort(column,sortdirection,page,size,service)
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
      Gfunc.axiosResponse(response,()=>{
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
      })     
    setLoading(false);
}
  
    //recherche serverside
   const handleSearch=async(size = perPage)=>{
    setLoading(true);
    setCurrentPage(1)
    const recherche=search;
    const response=await librery.axiosSearch(service,recherche,1,size,"get")
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.data!==undefined){
      setData(response.data.data);
      setTotalRows(response.data.recordsFiltered);
      }else setData([])
    })  
    setLoading(false); 
    return recherche
  }
  //handle page change
  function handlePageChange (page) {
    fetch(page,service,perPage);
    setCurrentPage(page);
  };
  
  //handle row number
  const handlePerRowsChange = async (newPerPage, page) => {
    fetch(page,service, newPerPage);
    setPerPage(newPerPage);
  };


  //handle icon change
 const handleLockIcon=useCallback((val)=>{
    setIcon(val);
 },[]);

 useEffect((val) => {
  handleLockIcon(val);
 }, [handleLockIcon]);

const flagMan =useCallback(async(row,att)=>{
    const response= await Gfunc.axiosPostAction('flagman.php','1',({ srv:srv, id: row.DT_RowId.substr(1),att:att, val: row[att] }))
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{ 
      if (response.data.jData) {
        const objIndex = data.findIndex((obj => obj.DT_RowId === row.DT_RowId));
        setIcon(row.DT_RowId)
        if(row[att]){
          data[objIndex][att]=(row[att]===1)?0:false
        }else data[objIndex][att]=(row[att]===0)?1:true
        setIcon('')     
      }else alert(response.data.err);  
   })
},[Gfunc,data])

const columns =useMemo(
    () => [ 
      {
        name: "PRODUIT",
        sortField: "produit",
        minWidth:"200px !important",
        selector: row=>row.produit,
        sortable: true,
        cell: (row) => {
            return (
              (!row.topup)? 
                <ContentEditable
                html={row.produit}
                name="prod"
                data-column="item"
                className="content-editable"
                disabled={false} 
                onBlur={e => handleOnEdit(e,row,"produit",'upd')}
                />:<div>{row.produit}</div>
             );
           },
      },
      {
        name: "Categorie",
        minWidth:"150px !important",
        selector: row=>row.categorie_id,
        sortField: "categorie_id",
        sortable: true,
        cell: (row) => { 
            return (  
              (!row.topup)?    
          <div>
              <AutocompleteS
                service={service} action={'upd'} 
                defaultValue={row.categorie} row={row} list={cat} 
                id={'categorie_id'} name='categorie' attr='name'
                onOpen={handleOpenCat} handleAlert={handleAlert}/> 
          </div> :<div>{row.categorie}</div>
           );
              }
        },
      {
        name: "Depuis",
        minWidth:"100px !important",
        selector: row=>row.date_add,
        sortField: "date_add",
        sortable: true,  
      },
      {
        name: "Marque",
        minWidth:"150px !important",
        selector: row=>row.marque,
        sortField: "marque",
        sortable: true,
        cell: (row) => { 
            return (
              (!row.topup)?  
            <div >  
              <AutocompleteS
              service={service} action={'upd'} 
              defaultValue={row.marque} row={row} list={mrq} 
              id={'marque_id'} name='marque' attr='name'
              onOpen={handleOpenMrq} handleAlert={handleAlert} /> 
            </div> :<div>{row.marque}</div>
            );
        },
      },
      {
        name: "Sim",
        minWidth:"150px !important",
        selector: row=>row.sim_cod,
        sortField: "sim_cod",
        sortable: true,
        cell: (row) => { 
            return (
            <div >  
              <AutocompleteS
              service={service} action={'upd'} 
              defaultValue={row.sim_cod} row={row} list={simL} 
              id={'sim_id'} name='sim_cod' attr='simCode' attr2='number'
              onOpen={handleOpenSim} handleAlert={handleAlert} /> 
            </div>
            );
        },
      },
      {
        name: "Stock",
        minWidth:"100px !important",
        style:numberColumn,
        selector:row=>row.qte,
        sortable: false,  
        cell:(row)=>{
          return(
            <div>{Gfunc.formatNumber(row.qte)}</div>
          )
        } 
      },
      {
        name: "limite",
        width:"100px !important",
        style:numberColumn,
        selector:row=>row.limite,
        sortable: false,  
        cell:(row)=>{
          return(
            (!row.topup)?  
            <InputNbrEditable
                service={service} action='upd' min={1}
                row={row} att="limite" value={row.limite} id='DT_RowId' idi={row.DT_RowId}
                handleAlert={handleAlert}
              />
              :<div>{row.limite}</div>
          )
        } 
      },
      {
        name: "wallet",
        width:"130px !important",
        selector:row=>row.wallet,
        sortable: false, 
        sortField: "wallet",
        cell: (row) => { 
          return ( 
            (row.topup)?    
            <AutocompleteS
              service={service} action={'upd'} 
              defaultValue={row.wallet} row={row} list={wal} 
              id={'wallet_id'} name='wallet' attr='name'
              onOpen={handleOpenWal} handleAlert={handleAlert}/>:null );} 
      },
      {
        name:"promo",
        width:"100px !important",
        sortable: true,
        sortField: "promo",
        cell:(row) =>{
         return(
           <div>
             
                                               <Checkbox
                                               className={classes.checkGreen}
                                               checked={row.promo}
                                               onChange={()=>flagMan(row,"promo")}
                                               name="checked"
                                               color="primary"/> 
           </div>
         )
        }
      },
      {
        name: "Nom T.",
        sortField: "tiketName",
        minWidth:"200px !important",
        selector: row=>row.tiketName,
        sortable: true,
        cell: (row) => {
            return (
              (!row.topup)? 
                <ContentEditable
                html={row.tiketName}
                name="tiketName"
                data-column="item"
                className="content-editable"
                disabled={false} 
                onBlur={e => handleOnEdit(e,row,"tiketName",'upd')}
                />:<div>{row.tiketName}</div>
             );
           },
      },
      {
        name: "Prix T.",
        width:"100px !important",
        style:numberColumn,
        selector:row=>row.tiketPrice,
        sortable: false,  
        cell:(row)=>{
          return(
            (!row.topup)?  
            <InputNbrEditable
                service={service} action='upd' min={1}
                row={row} att="tiketPrice" value={row.tiketPrice} id='DT_RowId' idi={row.DT_RowId}
                handleAlert={handleAlert}
              />
              :<div>{row.tiketPrice}</div>
          )
        } 
      },
      { 
        right:true,
        cell: (row) => {
          return (
            <div onClick={()=>flagMan(row,'activ')} title="Activer/Désactiver">{(row.activ)?<LockOpen className={classes.redIcon} fontSize="small"/>:<Lock className={classes.greenIcon} fontSize="small"/> }</div>        
           );
         },
      },
    ],[handleOnEdit,flagMan,classes,handleOpenCat,cat,mrq,handleOpenMrq,wal,handleOpenWal,simL,handleOpenSim]);
  
 
function liveSearch(e,search){
  if(param.liveSearch&&search.length>3){
     e.preventDefault();
     handleSearch();
  }else{ if (e.keyCode === 13) {
            e.preventDefault();
            handleSearch();
          }
  }
}
function liveSearch2() {
  handleSearch();}

const ExpanableComponent = ({ data }) => {
  const columnsB =useMemo(
    () => [ 
           {
              name: "Agent",
              selector: row=>row.agent_add,
              sortable: true,  
           },
           {
              name: "Date",
              selector: row=>row.date_add,
           },
           {
              name: "Achat",
              selector: row=>row.achat,
              style:numberColumn,
              sortable: true, 
              cell:(row)=>{
                return(
                  <div>{Gfunc.formatNumber(row.achat)}</div>
                )
              } 
           },
           {
              name: "Vente 1",
              selector: row=>row.vente_1,
              sortable: true,  
              cell:(row)=>{
                return(<InputNbrEditable
                  service={service} action='upddet' min={row.achat}
                  row={row} att="vente_1" value={row.vente_1} id='id'
                  handleAlert={handleAlert}
              />)
              } 
           },
           {
              name: "Vente 2",
              selector: row=>row.vente_2,
              sortable: true, 
              cell:(row)=>{
                return(<InputNbrEditable
                  service={service} action='upddet' min={row.achat}
                  row={row} att="vente_2" value={row.vente_2} id='id'
                  handleAlert={handleAlert}
              />)
              } 
           },
           {
              name: "Qte.",
              selector: row=>row.qte,
              style:numberColumn,
              sortable: true, 
              cell:(row)=>{
                return(<div>{Gfunc.formatNumber(row.qte)}</div>)
              } 
           },
    ],[]);
  
      return (
          <div >
            <div className="flex-wrapper-centered">
              <div id="prodDet" style={{maxHeight:"200px",width:"100%", padding:"5px 10px" , overflowY:"auto"}}>
              <DataTable
                fixedHeader
                persistTableHead
                columns={columnsB}
                data={data.stock}
                dense
                customStyles={customStyles2}
                highlightOnHover
                striped
              /> 
              </div>
            </div>
          </div>
      )
  }
  return (
    <div className="content">
      <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={message}/>
       <Paper className={classes.paperBody} elevation={3}>
         <div className="table-header">
          <div className="button-flex-wrapper" >
            <Addprod  handleAlert={handleAlert} catL={cat} mrqL={mrq} param={param} simL={simL}
                      handleOpenMrq={handleOpenMrq} handleOpenCat={handleOpenCat} handleOpenSim={handleOpenSim} 
                      callback={()=>{if(currentPage===1) fetch(1);else setResetDP(!resetDP)}} />
          </div>
          {(doSearch)?
          <div className='search'>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Search className={classes.blackIcon} sx={{ color: 'action.active', mr: 1, my: 0.5 }} onClick={(e)=>liveSearch2(e)}/>
                      <TextField className="smallText" id="searchBar" variant="outlined" label="Recherche" value={search} size="small" margin='dense' onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} autoFocus/>
              </Box>
          </div> 
          :null}
          </div>
          { (data.length!==0||doSearch)?
            <DataTable
              columns={columns}
              data={data}
              noHeader={true}
              defaultSortAsc={true}
              noDataComponent={param.ErreurData}
              paginationRowsPerPageOptions={[10,25,50,75,100]}
              paginationPerPage={25}
              progressPending={loading}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationDefaultPage={1}
              paginationResetDefaultPage={resetDP}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              sortServer={true}
              onSort={handleOnSort}
              highlightOnHover
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}
              expandOnRowClicked
              expandableRows 
              progressComponent={<CircularProgress className={classes.circularProgress} />}
              expandableRowsComponent={ExpanableComponent} 
              ignoreRowClick
              selectableRowsComponent={Checkbox}
              selectableRowsComponentProps={{ className: classes.checkStelect }}
              selectableRowsHighlight
              customStyles={customStyles}
              contextMessage={{ singular: 'élément sélectionné', plural: 'éléments sélectionnés' }}
              conditionalRowStyles = { conditionalRowStyles }
            />
            :(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
            </Paper>
    </div> 
  );
}