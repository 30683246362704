import React,{useState,useRef,useCallback,useEffect,} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog, TextField} from '@material-ui/core';
import Draggable from 'react-draggable';
import SaveIcon from '@material-ui/icons/Save';
import { ValidatorForm} from 'react-material-ui-form-validator';
import AddIcon from '@material-ui/icons/Add';
import {axiosPostAction, axiosResponse} from'../../../Gfunc'
import { useStyles,paperPropsSmallTall } from '../../../css/muiStyle';
import {AlertCompo} from '../../../js/components'
import { Autocomplete } from '@material-ui/lab';
import '../../../css/exploitation.css'


function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Exp_ord_add(props) {
    const classes=useStyles();
    const Gfunc=require('../../../Gfunc')
    const [client,setClient]=useState('');
    const [subClient,setSubClient]=useState('');
    const [bdl,setBdl]=useState('');
    const [sim,setSim]=useState('');
    const [qte,setQte]=useState("");
    const [prix,setPrix]=useState('');
    const [tel, setTel] =useState('');
    const [open, setOpen] = useState(false);
    const [severity, setSeverity] =useState("info");
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const reg=/\(|\)/g
    const service="_ord.php"
    const labelText1="Client"
    const labelText2="Sim"
    const labelText3="Qte"
    const labelText4="Tarif"
    const labelText5="Numero"
    const labelText6="Bundle"
    const labelText7="Sous Client"


   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
        setOpenA(open)
        setSeverity(severity)
        setMessageA(message)
        setTimeout(function(){
        setOpenA(false)
        }, 5000);
    }

    const handleClickOpen = () => { 
      setOpen(true); 
      props.handleOpenCli()
      props.handleOpenSim()
      props.handleOpenBdl()
      setClient('');setSim('');setPrix('');setQte("")
    };

    const handleClose = () => { setOpen(false); };
  

    //submit
    const handleSubmit= async(e) =>{
        const response=await axiosPostAction(service,"add",{clientId:client,subcomId:subClient,simId:sim, qte:qte,bundleId:bdl,tarif:parseFloat(prix),numero:tel})
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,'error',response.data.err)
        }else{
        axiosResponse(response,()=>{
          if(response.data.jData){
            setOpen(false);
            props.callback();
          }
        })
      }
    }


  return (
    <div>
      <Button className={classes.topTabBtn} variant="contained" color="primary" onClick={handleClickOpen} startIcon={<AddIcon/>}>NOUVELLE COMMANDE</Button>
      <Dialog
         open={open}
         id='frns'
         onClose={handleClose}
         PaperComponent={PaperComponent}
         aria-labelledby="draggable-dialog-title"
         PaperProps={paperPropsSmallTall}
       >
        <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >Ajouter une nouvelle commande</DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true}/>
            <DialogContent>
                <ValidatorForm ref={useRef(null)} id="ordForm" onSubmit={handleSubmit} onError={errors => console.log(errors)} >
                  <div className="rowGrid" id='row-1'>
                  <Autocomplete
                                disableClearable
                                className='smallAuto'
                                id="iClient"
                                onChange={(e,newValue) =>{
                                    setClient(newValue.id)
                                    setSubClient(null)
                                    props.fetchSubClient(newValue.id)
                                  }
                                  } 
                                options={props.clients}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) => option.id === value.id}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={labelText1}
                                    variant='outlined'
                                    margin='dense'
                                    size="small"
                                    autoFocus 
                                    required/>
                                )}/>
                     <Autocomplete
                                disableClearable
                                className='smallAuto'
                                id="iSim"
                                onChange={(e,newValue) =>{
                                    setSim(newValue.id)}
                                  } 
                                options={props.simL}
                                getOptionLabel={(option) => option.number + (option.simCode!=null?` [ ${option.simCode} ]`:"")}
                                getOptionSelected={(option, value) => option.id === value.id}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={labelText2}
                                    variant='outlined'
                                    margin='dense'
                                    size="small"
                                    autoFocus 
                                    required/>
                                )}/>
                  </div>
                 { props.subClients.length>0&&<Autocomplete
                                disableClearable
                                className='smallAuto'
                                id="isubClient"
                                onChange={(e,newValue) =>{
                                  setSubClient(newValue.id)
                                  }
                                  } 
                                options={props.subClients}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) => option.id === value.id}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={labelText7}
                                    variant='outlined'
                                    margin='dense'
                                    size="small"
                                    autoFocus/>
                                )}/>}
                  <div className="rowGrid" id='row-2'> 
                 
                    <TextField margin="dense"  label={labelText3} size="small" type='number' value={qte} variant="outlined" className='smallText' 
                             InputProps={{ inputProps: { min: 0 } }} onChange={e =>setQte(e.target.value)} />
                    <TextField margin="dense"  label={labelText4} size="small" type='number' value={prix} variant="outlined" className='smallText' 
                             InputProps={{ inputProps: { min: 0 ,step:"0.01"} }} onChange={e =>setPrix(e.target.value)}  required
                   />
                    <Autocomplete
                                disableClearable
                                className='smallAuto'
                                id="iBdl"
                                onChange={(e,newValue) =>{
                                    setBdl(newValue.id)}
                                  } 
                                options={props.bdlL}
                                getOptionLabel={(option) => option.name /*+ (option.name!=null?` [ ${option.simCode} ]`:"")*/}
                                getOptionSelected={(option, value) => option.id === value.id}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={labelText6}
                                    variant='outlined'
                                    margin='dense'
                                    size="small" 
                                    />
                                )}/>
                  </div>
                  <div className="rowGrid" id='row-1'> 
                        <TextField className='smallText'variant="outlined" size="small" label={labelText5} id="phone" name="phone" value={tel} 
                            onChange={e =>setTel(e.target.value)} required
                            inputProps={{ pattern: "[0][5-7][4-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]",maxLength:10 }} 
                  />
                  </div>
                
                  
              </ValidatorForm>
           </DialogContent>
            <DialogActions>
            <Button variant="contained" type='submit' form="ordForm" color="primary" startIcon={<SaveIcon />}>VALIDER</Button>
            <Button variant="contained" onClick={handleClose} color="secondary">FERMER</Button>
            </DialogActions>
      </Dialog>
      </div>
  );
}
