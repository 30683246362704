import React,{useState,useRef,useCallback, useEffect} from "react";
import { ValidatorForm} from 'react-material-ui-form-validator';
import { Button, Paper, TextField, Typography,CircularProgress,Backdrop } from "@material-ui/core";
import { MuiPickersUtilsProvider,KeyboardDatePicker} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { fr } from "date-fns/locale";
import { Check } from "@material-ui/icons";
import { useStyles } from '../../css/muiStyle';
import { Autocomplete } from "@material-ui/lab";
import AreaCharts from'../../compo/AreaChart'
import PieCharts from'../../compo/Piechart'
import { NoDataComponent,AlertCompo } from "../../js/components";
import '../../css/reports.css'

export default function Rep_cns(props){
    var date = new Date();
    const classes = useStyles();
    const Gfunc=require('../../Gfunc')
    const [loading,SetLoading]=useState(false)
    const [consos,setConsos]=useState([]);
    const [consosTotC,setConsosTotC]=useState(0);
    const [consosTotM,setConsosTotM]=useState(0);
    const [consosTotR,setConsosTotR]=useState(0);
    const [consosMoyR,setConsosMoyR]=useState(0);
    const [consosMoyC,setConsosMoyC]=useState(0);
    const [consosMoyM,setConsosMoyM]=useState(0);
  const [consosMoyRp, setConsosMoyRp] = useState(0);
  const [consosMoyCp, setConsosMoyCp] = useState(0);
  const [consosMoyMp, setConsosMoyMp] = useState(0);
  const [projection, setProjection] = useState(false);
    const [consosProp,setConsosProp]=useState(0);
    const [consossom,setConsosSom]=useState(0);
    const [conTaille,setConTaille]=useState(0);
    const [submit,setSubmit]=useState(false);
    const [mrqc,setMrqc]=useState([]);
    const [mrqm,setMrqm]=useState([]);
    const [mrqr,setMrqr]=useState([]);
    const [mrqsm,setMrqsm]=useState(0);
    const [mrqsc,setMrqsc]=useState(0);
    const [mrqsr,setMrqsr]=useState(0);
    const [mrqmoyc,setMrqmoyc]=useState(0);
    const [mrqmoym,setMrqmoym]=useState(0);
    const [mrqmoyr,setMrqmoyr]=useState(0);
    const [mrqprop,setMrqProp]=useState(0);
    const [ColorMrqsm,setColorMrqsm]=useState([]);
    const [ColorMrqsc,setColorMrqsc]=useState([]);
    const [ColorMrqsr,setColorMrqsr]=useState([]);
    const [zonec,setZonec]=useState([]);
    const [zonem,setZonem]=useState([]);
    const [zoner,setZoner]=useState([]);
    const [zonesr,setZonesr]=useState(0);
    const [zonesm,setZonesm]=useState(0);
    const [zonesc,setZonesc]=useState(0);
    const [zonemoyc,setZonemoyc]=useState(0);
    const [zonemoym,setZonemoym]=useState(0);
    const [zonemoyr,setZonemoyr]=useState(0);
    const [zoneprop,setZoneProp]=useState(0);
    const [ColorZonesr,setColorZonesr]=useState([]);
    const [ColorZonesm,setColorZonesm]=useState([]);
    const [ColorZonesc,setColorZonesc]=useState([]);
    const [appc,setAppc]=useState([]);
    const [appm,setAppm]=useState([]);
    const [appr,setAppr]=useState([]);
    const [appsm,setAppsm]=useState(0);
    const [appsc,setAppsc]=useState(0);
    const [appsr,setAppsr]=useState(0);
    const [appmoyc,setAppmoyc]=useState(0);
    const [appmoym,setAppmoym]=useState(0);
    const [appmoyr,setAppmoyr]=useState(0);
    const [appprop,setAppProp]=useState(0);
    const [ColorAppsm,setColorAppsm]=useState([]);
    const [ColorAppsc,setColorAppsc]=useState([]);
    const [ColorAppsr,setColorAppsr]=useState([]);
    const [prodc,setProdc]=useState([]);
    const [prodm,setProdm]=useState([]);
    const [prodr,setProdr]=useState([]);
    const [prodsc,setProdsc]=useState(0);
    const [prodsm,setProdsm]=useState(0);
    const [prodsr,setProdsr]=useState(0);
    const [prodmoyr,setProdmoyr]=useState(0);
    const [prodmoym,setProdmoym]=useState(0);
    const [prodmoyc,setProdmoyc]=useState(0);
    const [prodprop,setProdProp]=useState(0);
    const [Colorprodsm,setColorProdsm]=useState([]);
    const [Colorprodsc,setColorProdsc]=useState([]);
    const [Colorprodsr,setColorProdsr]=useState([]);
    const [catec,setCatec]=useState([]);
    const [catem,setCatem]=useState([]);
    const [cater,setCater]=useState([]);
    const [catesm,setCatesm]=useState(0);
    const [catesc,setCatesc]=useState(0);
    const [catesr,setCatesr]=useState(0);
    const [catemoym,setCatemoym]=useState(0);
    const [catemoyc,setCatemoyc]=useState(0);
    const [catemoyr,setCatemoyr]=useState(0);
    const [cateprop,setCateProp]=useState(0);
    const [ColorCatesm,setColorCatesm]=useState([]);
    const [ColorCatesc,setColorCatesc]=useState([]);
    const [ColorCatesr,setColorCatesr]=useState([]);
    const [selectDateD,SetSelectDateD]=useState(new Date(date.getFullYear(), date.getMonth(), 1))
  const [selectDateF, SetSelectDateF] = useState(new Date())
    const [cat,setCat]=useState([]);
    const [selectcat,setSelectCat]=useState('');
    const [prd,setPrd]=useState([]);
    const [selectprd,setSelectPrd]=useState('');
    const [mrq,setMrq]=useState([]);
    const [selectmrq,setSelectMrq]=useState('');
    const [zone,setZone]=useState([]);
    const [selectZone,setSelectZone]=useState('');
    const [openA, setOpenA] =useState(false);
    const [message, setMessage] =useState('');
    const [severity, setSeverity] =useState('info');
    const [trim,setTrim]=useState('');
    const [month,setMonth]=useState('');
    const [monthName,setMonthName]=useState('');
    const [ann,setAnn]=useState('');
    //const [mois,setMois]=useState([]);
    const [annees,setAnnees]=useState([]);
    const [mois,setMois]=useState([]);
    
    //[{id:1,name:"Janvier"},{id:2,name:" Février"},{id:3,name:"Mars"},{id:4,name:"Avril"},
    //            {id:5,name:"Mai"},{id:6,name:"Juin"},{id:7,name:"Juillet"},{id:8,name:"Août"},
    //            {id:9,name:"Septembre"},{id:10,name:"Octobre"},{id:11,name:"Novembre"},{id:12,name:"Décembre"}];
    const qrt=[{id:1,name:"Q1"},{id:2,name:"Q2"},{id:3,name:"Q3"},{id:4,name:"Q4"}]
  const [qrts, setQrts] = useState([]);

    //const annees=[{id:1,name:"2023"},{id:2,name:"2022"},{id:3,name:"2021"},{id:4,name:"2020"},{id:5,name:"2019"}]
    const messageC='Aucune donnée trouvée'
    const service='_rep.php'


    const handleAlert=(openA,severity,message)=>{
      setOpenA(openA)
      setSeverity(severity)
      setMessage(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }

    //recuperer les categories 
    const fetchCat= useCallback(async() => {
        const response = await Gfunc.axiosGetAction('_cat.php','gets');
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,'error',response.data.err)
        }else{
          Gfunc.axiosResponse(response,()=>{
                response.data.jData.unshift({id:'empty',name:'Toutes'})
                setCat(response.data.jData);   
          })
        }
    },[Gfunc]);

    //remplir Select Categorie 
    const handleOpenCat= useCallback(async () => {
       if(cat.length===0) fetchCat()
    },[cat,fetchCat])

    //recuperer les marque 
    const fetchMrq= useCallback(async() => {
        const response = await Gfunc.axiosGetAction('_mrq.php','gets');
        if(response.data.err&&response.data.err!=='-1'){
           handleAlert(true,'error',response.data.err)
        }else{
          Gfunc.axiosResponse(response,()=>{
                response.data.jData.unshift({id:'empty',name:'Toutes'})
                setMrq(response.data.jData);   
          })
        }
    },[Gfunc]);

    //remplir Select Categories 
    const handleOpenMrq= useCallback(async () => {
       if(mrq.length===0) fetchMrq()
    },[mrq,fetchMrq])

  //fetch products
  const fetchPrd= useCallback(async(serv="_prd.php", act = "gets",act2="getspromo") => {
    const response = await Gfunc.axiosGetAction(serv,act);
    const response2 = await Gfunc.axiosGetAction(serv,act2);
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
      if(response2.data.err&&response.data.err!=="-1"){
        handleAlert(true,'error',response.data.err)
      }else{
        if(response2.data.jData&&response.data.jData){
          var arr=response2.data.jData.concat(response.data.jData)
          arr.unshift({id:'empty',name:'Toutes'})
          setPrd(arr);
        }
        else{ 
             if(response2.data.jData) {
              response2.data.jData.unshift({id:'empty',name:'Toutes'})
              setPrd(response2.data.jData);
            }
             else{
               response.data.jData.unshift({id:'empty',name:'Toutes'})
               setPrd(response.data.jData);
              }
            }
      }    
    })
  }
  },[Gfunc])

    //remplir Select Categorie 
    const handleOpenPrd= useCallback(async () => {
       if(prd.length===0) fetchPrd()
    },[prd,fetchPrd])

    //recuperer les zones
    const fetchZone= useCallback(async() => {
        const response = await Gfunc.axiosGetAction('_cli.php','getszone');
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,'error',response.data.err)
        }else{
          Gfunc.axiosResponse(response,()=>{
                response.data.jData.unshift({id:'empty',name:'Toutes'})
                setZone(response.data.jData);   
          })
        }
    },[Gfunc]);

    //remplir Select Categorie 
    const handleOpenZone= useCallback(async () => {
       if(zone.length===0) fetchZone()
    },[zone,fetchZone])
  

      //handle get months
  const getMonths=useCallback(async(y)=>{
    var mnts = []
    if(y!==''&&y!==undefined){
        const response= await Gfunc.axiosPostAction("_clo.php","getsmonth",{year:y});
        if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,"error",response.data.err);
        }else
        Gfunc.axiosResponse(response,()=>{
          if(response.data.jData){
             // setMois(response.data.jData)
              if(response.data.jData.length>0){
                setMois(response.data.jData);
                setMonth(response.data.jData[0].month)
                setMonthName(response.data.jData[0].monthName)
                mnts = response.data.jData
              }
          }
        })
    }else{
        handleAlert(true,'error','Vous devez choisir une année en amont')
    }
    return mnts
    },[])

  //handle get months
  const getMonths2 = useCallback(async (y) => {
    var mnts = []
    if (y !== '' && y !== undefined) {
      const response = await Gfunc.axiosPostAction("_clo.php", "getsmonth", { year: y });
      if (response.data.err && response.data.err !== '-1') {
        handleAlert(true, "error", response.data.err);
      } else
        Gfunc.axiosResponse(response, () => {
          if (response.data.jData) {
            // setMois(response.data.jData)
            if (response.data.jData.length > 0) {
              setMois(response.data.jData);
              mnts = response.data.jData
            }
          }
        })
    } else {
      handleAlert(true, 'error', 'Vous devez choisir une année en amont')
    }
    return mnts
  }, [])


  //fetch utilisateurs handler
 /* const handleOpenMonth = useCallback(async (year) => {
    getMonths(year)
  },[mois,getMonths])*/

    //handle get Year
    const getYears=useCallback(async()=>{
      const response= await Gfunc.axiosGetAction("_clo.php","getsyear");
      if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,"error",response.data.err);
      }else
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData){
          if (parseInt(response.data.jData[0]['year']) === date.getFullYear()) {
            const currentQuarter = Math.ceil((date.getMonth() + 1) / 3); // Calculate the current quarter
            setQrts(qrt.filter(quarter => quarter.id <= currentQuarter))
          }
            setAnnees(response.data.jData)
            setAnn(response.data.jData[0]['year'])
            if(response.data.jData.length>0){
              getMonths(response.data.jData[0]['year'])
            }
           // handleOpenMonth(response.data.jData[0]['year'])
        }
      })
    }, [mois, qrts])



  //fetch year handler
  const handleOpenYear= useCallback(async () => {
      if(annees.length===0) getYears()
  },[annees,getYears])


useEffect(()=>{
  handleOpenYear();
},[handleOpenYear])



    //valider le formulaire
    const handleSubmit=async()=>{
      setProjection(false)
      setConsos([])
      SetLoading(true)
      var marque = ''; var produit = ''; var categorie = ''; var Zone = ''; var arr = []; var arrDate = [];
      var prodc = []; var prodm = []; var prodr = []; var sommeC = 0; var sommeM = 0; var sommeR = 0;
      var appc = []; var appm = [];; var appr = []; var sommeappC = 0; var sommeappM = 0; var sommeappR = 0;
      var catc = []; var catm = []; var catr = []; var sommecatC = 0; var sommecatM = 0;; var sommecatR = 0;
      var zonc = []; var zonm = []; var zonr = []; var sommezonC = 0; var sommezonM = 0; var sommezonR = 0;
      var brandc = []; var brandm = []; var brandr = []; var sommebrandC = 0; var sommebrandM = 0; var sommebrandR = 0;
      var indM = mrq.findIndex((obj => obj.name === selectmrq));
      if (indM !== -1) { marque = mrq[indM].id }
      var indP = prd.findIndex((obj => obj.name === selectprd));
      if (indP !== -1) { produit = prd[indP].id }
      var indC = cat.findIndex((obj => obj.name === selectcat));
      if (indC !== -1) { categorie = cat[indC].id }
      var indZ = zone.findIndex((obj => obj.name === selectZone));
      if (indZ !== -1) { Zone = zone[indZ].id }

      const response = await Gfunc.axiosPostAction(service, "getconso", {
        date1: Gfunc.formatDate(selectDateD), date2: Gfunc.formatDate(selectDateF),
        brand: (marque !== 'empty') ? marque : '', produit: (produit !== 'empty') ? produit : '',
        categorie: (categorie !== 'empty') ? categorie : '', zone: (Zone !== 'empty') ? Zone : ''
      })
      SetLoading(false)
      setSubmit(true)
      if (response.data.err && response.data.err !== '-1') {
        handleAlert(true, 'error', response.data.err)
      } else {
        Gfunc.axiosResponse(response, () => {
          if (response.data.jData) {
      //byconso
            var bycon = 0; var sommconsm = 0;
            arr = Gfunc.getDates1(selectDateD, selectDateF)
            response.data.jData.consos.map(el => {
              if (el.hasOwnProperty('marge')) {
                bycon = bycon + 1
              }
              if (el.marge !== null) {
                sommconsm = sommconsm + 1
              }
            })
            arr.map((el) => {
              arrDate.push({ date: Gfunc.formatDate(el), str: Gfunc.ConcatDate(el) })
            })
            var totaleC = 0; var totaleMa = 0; var totaleRep = 0;
            arrDate.map((el) => {
              var index = response.data.jData.consos.findIndex((obj => obj.day === el.str));
              if (index !== -1) {
                el['ca'] = response.data.jData.consos[index]['ca']
                el['nbc'] = response.data.jData.consos[index]['nbc']
                if (bycon > 0) {
                  el['marge'] = response.data.jData.consos[index]['marge']
                }
              } else {
                el['ca'] = 0
                el['nbc'] = 0
                if (bycon > 0) {
                  el['marge'] = 0
                }
              }
              totaleC = totaleC + el.ca
              totaleMa = totaleMa + el.marge
              totaleRep = totaleRep + el.nbc
            })

            setConsosTotC(totaleC)
            setConsosTotM(totaleMa)
            setConsosTotR(totaleRep)
            if ((selectDateD.getFullYear() === date.getFullYear()) && (selectDateD.getMonth() === date.getMonth()) && (selectDateF.getMonth() === date.getMonth())) {
              const days = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
              setProjection(true)
              setConsosMoyRp(Math.round((totaleRep / arrDate.length) * days))
              setConsosMoyCp(Math.round(totaleC / arrDate.length) * days)
              setConsosMoyMp(Math.round((totaleMa) / (arrDate.length) * days))
            }
            setConsosMoyR(Math.round(totaleRep / arrDate.length))
            setConsosMoyC(Math.round(totaleC / arrDate.length))
            setConsosMoyM(Math.round(totaleMa / arrDate.length))
            setConsos(arrDate); setConTaille(response.data.jData.consos.length); setConsosProp(bycon); setConsosSom(sommconsm)
            var bypro = 0
            //byprod
            response.data.jData.byProd.map(el => {
              if (el.hasOwnProperty('marge')) {
                bypro = bypro + 1
              }
        var chprod = (el.ca > 0) ? el.ca : 0
        var maprod = (el.marge > 0) ? el.marge : 0
        var rprod = (el.nbc > 0) ? el.nbc : 0
        prodc.push({ name: el.produit, value: chprod })
        prodm.push({ name: el.produit, value: maprod })
        prodr.push({ name: el.produit, value: rprod })
        sommeC = sommeC + chprod
        sommeM = sommeM + maprod
        sommeR = sommeR + rprod
      })
            var colorProdc = Gfunc.couleurs(response.data.jData.byProd.length)
            var colorProdm = Gfunc.couleurs(response.data.jData.byProd.length)
            var colorProdr = Gfunc.couleurs(response.data.jData.byProd.length)
            setProdsc(sommeC); setProdsm(sommeM); setProdsm(sommeR); setProdc(prodc); setProdm(prodm); setProdr(prodr); setColorProdsc(colorProdc);
            setColorProdsm(colorProdm); setColorProdsm(colorProdr); setProdProp(bypro); setProdmoyc(sommeC / response.data.jData.byProd.length);
            setProdmoym(sommeM / response.data.jData.byProd.length)
            setProdmoyr(sommeR / response.data.jData.byProd.length)

            var colorsarr = []; var byap = 0
            //byApp
            response.data.jData.byApp.map(el => {
              if (el.hasOwnProperty('marge')) {
                byap = byap + 1
              }
        var chapp = (el.ca > 0) ? el.ca : 0
        var maapp = (el.marge > 0) ? el.marge : 0
        var rapp = (el.nbc > 0) ? el.nbc : 0
        appc.push({ name: el.source, value: chapp })
        appm.push({ name: el.source, value: maapp })
        appr.push({ name: el.source, value: rapp })
        sommeappC = sommeappC + chapp
        sommeappM = sommeappM + maapp
        sommeappR = sommeappR + rapp
        var elemCol = Gfunc.ColorApp(el.source)
        colorsarr.push(elemCol)
      })
            var colorAppc = colorsarr
            var colorAppm = colorsarr
            var colorAppr = colorsarr
            setAppsc(sommeappC); setAppsm(sommeappM); setAppsr(sommeappR); setAppc(appc); setAppm(appm); setAppr(appr); setColorAppsc(colorAppc);
            setColorAppsm(colorAppm); setColorAppsr(colorAppr); setAppProp(byap); setAppmoyc(sommeappC / response.data.jData.byApp.length);
            setAppmoym(sommeappM / response.data.jData.byApp.length)
            setAppmoyr(sommeappR / response.data.jData.byApp.length)
            var bybr = 0
            //bybrand
            response.data.jData.byBrand.map(el => {
              if (el.hasOwnProperty('marge')) {
                bybr = bybr + 1
              }
        var chbrand = (el.ca > 0) ? el.ca : 0
        var mabrand = (el.marge > 0) ? el.marge : 0
        var rbrand = (el.nbc > 0) ? el.nbc : 0
        brandc.push({ name: el.brand, value: chbrand })
        brandm.push({ name: el.brand, value: mabrand })
        brandr.push({ name: el.brand, value: rbrand })
        sommebrandC = sommebrandC + chbrand
        sommebrandM = sommebrandM + mabrand
        sommebrandR = sommebrandR + rbrand
      })
            var colorBrandc = Gfunc.couleurs(response.data.jData.byBrand.length)
            var colorBrandm = Gfunc.couleurs(response.data.jData.byBrand.length)
            var colorBrandr = Gfunc.couleurs(response.data.jData.byBrand.length)
            setMrqsc(sommebrandC); setMrqsm(sommebrandM); setMrqsr(sommebrandR); setMrqc(brandc); setMrqm(brandm); setMrqr(brandr); setColorMrqsm(colorBrandc);
            setColorMrqsc(colorBrandm); setColorMrqsr(colorBrandr); setMrqProp(bybr); setMrqmoyc(sommebrandC / response.data.jData.byBrand.length);
            setMrqmoym(sommebrandM / response.data.jData.byBrand.length); setMrqmoyr(sommebrandR / response.data.jData.byBrand.length);

            var byca = 0;
            //bycategorie
            response.data.jData.byCategorie.map(el => {
              if (el.hasOwnProperty('marge')) {
                byca = byca + 1
              }
        var chcat = (el.ca > 0) ? el.ca : 0
        var macat = (el.marge > 0) ? el.marge : 0
        var rcat = (el.nbc > 0) ? el.nbc : 0
        catc.push({ name: el.categorie, value: chcat })
        catm.push({ name: el.categorie, value: macat })
        catr.push({ name: el.categorie, value: rcat })
        sommecatC = sommecatC + chcat
        sommecatM = sommecatM + macat
        sommecatR = sommecatR + rcat
      })
            var colorCatc = Gfunc.couleurs(response.data.jData.byCategorie.length)
            var colorCatm = Gfunc.couleurs(response.data.jData.byCategorie.length)
            var colorCatr = Gfunc.couleurs(response.data.jData.byCategorie.length)
            setCatesc(sommecatC); setCatesm(sommecatM); setCatesr(sommecatR); setCatec(catc); setCatem(catm); setCater(catr); setColorCatesc(colorCatc);
            setColorCatesm(colorCatm); setColorCatesr(colorCatr); setCateProp(byca); setCatemoyc(sommecatC / response.data.jData.byCategorie.length);
            setCatemoym(sommecatM / response.data.jData.byCategorie.length); setCatemoyr(sommecatR / response.data.jData.byCategorie.length)

            var byzon = 0
            //byzone
            response.data.jData.byZone.map(el => {
              if (el.hasOwnProperty('marge')) {
                byzon = byzon + 1
              }
        var chzon = (el.ca > 0) ? el.ca : 0
        var mazon = (el.marge > 0) ? el.marge : 0
        var rzon = (el.nbc > 0) ? el.nbc : 0
        zonc.push({ name: el.zone, value: chzon })
        zonm.push({ name: el.zone, value: mazon })
        zonr.push({ name: el.zone, value: rzon })
        sommezonC = sommezonC + chzon
        sommezonM = sommezonM + mazon
        sommezonR = sommezonR + rzon
      })
            var colorZonc = Gfunc.couleurs(response.data.jData.byZone.length)
            var colorZonm = Gfunc.couleurs(response.data.jData.byZone.length)
            var colorZonr = Gfunc.couleurs(response.data.jData.byZone.length)
            setZonesc(sommezonC); setZonesm(sommezonM); setZonesr(sommezonR); setZonec(zonc); setZonem(zonm); setZoner(zonr); setColorZonesc(colorZonc);
            setColorZonesm(colorZonm); setColorZonesr(colorZonr); setZoneProp(byzon); setZonemoyc(sommezonC / response.data.jData.byZone.length);
            setZonemoym(sommezonM / response.data.jData.byZone.length); setZonemoyr(sommezonR / response.data.jData.byZone.length)
          }
        })
      }
    }
  const handleChangeMon = (newValue, year) => {
    setMonth(newValue.month)
    setMonthName(newValue.monthName)
  const cureentDay = new Date()
  const cureentMonth = cureentDay.getMonth() + 1
    setTrim('');
    var debmois=parseInt(newValue.month)
  var fin = cureentDay
  if (parseInt(year ?? ann) === cureentDay.getFullYear()) {
    if (debmois < cureentMonth) {
      fin = Gfunc.DateFinMonth(debmois, year ?? ann)
    }
  } else fin = Gfunc.DateFinMonth(debmois, year ?? ann)
  var deb = Gfunc.DateDebMonth(debmois, year ?? ann)

    SetSelectDateD(deb)
    SetSelectDateF(fin)
  }

  const handleChangeTrim = (semester, year) => {
    setMonth('');
    setMonthName('');
  setTrim(semester)
     var debtrim=qrt[Gfunc.getIndex(qrt,semester,"name")].id
  var days = Gfunc.TrimDebFin(debtrim, year ?? ann)
     SetSelectDateD(days[0])
  if (days[1] > date)
    SetSelectDateF(date)
  else
     SetSelectDateF(days[1])
  }

  const handleChangeAnn = async (ann) => {
    setAnn(ann)
  const mnts = await getMonths2(ann)
  if (parseInt(ann) === date.getFullYear()) {
    const currentQuarter = Math.ceil((date.getMonth() + 1) / 3); // Calculate the current quarter
    setQrts(qrt.filter(quarter => quarter.id <= currentQuarter))

    if (trim !== "") {
      if (parseInt(trim.substr(1)) >= currentQuarter) handleChangeTrim("Q" + currentQuarter, ann)
    } else
      if (mnts.length > 0) {
        handleChangeMon({ month: mnts[0].month, monthName: mnts[0].monthName }, ann)
      }
  } else {
    if (qrts.length < 4) setQrts([...qrt])


    //handleOpenMonth(ann)
    if (trim !== "") {
      handleChangeTrim(trim, ann)
    } else
      if (mnts.length > 0) {
        handleChangeMon({ month: mnts[0].month, monthName: mnts[0].monthName }, ann)
      }

  }
  }

  return (
    <div className="content">
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={message}/>
        <Backdrop className={classes.backdrop} open={loading} >
             <CircularProgress color="inherit" />
        </Backdrop>
       <fieldset className="fieldsetTop" id="consom">
        <legend>Rapport des consommations</legend>
         <div id='row-re'>
             <Autocomplete
                    disableClearable
                    value={ann}
                    onChange={(e,newValue) =>{handleChangeAnn(newValue);}}
                    options={annees.map((option) => option.year)}
                    className='smallAuto' 
                    renderInput={(params) =>  
                        <TextField margin='dense' 
                            size='small'{...params} variant="outlined"  label="Année..." SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                        />}
                />
              <Autocomplete
                    disableClearable
                    value={trim}
            onChange={(e, newValue) => { handleChangeTrim(newValue); }}
            options={qrts.map((option) => option.name)}
                    className='smallAuto' 
                    renderInput={(params) =>  
                        <TextField margin='dense' 
                            size='small'{...params} variant="outlined"  label="Trimestre..." SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                        />}
                />
              <Autocomplete
                    disableClearable
                    value={{month:month,monthName:monthName}}
                    onChange={(e,newValue) =>{handleChangeMon(newValue);}}
                    options={mois}
                    getOptionLabel={(option) => option.monthName}
                    getOptionSelected={(option) => option.month}
                    className='smallAuto' 
                    renderInput={(params) =>  
                        <TextField margin='dense' 
                            size='small'{...params} variant="outlined"  label="Mois..." SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                        />}
                />

        </div>
        <ValidatorForm id="formAdd" ref={useRef(null)} onSubmit={handleSubmit} >
            <div className="rowGrid" id="row-1">
              <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
              <KeyboardDatePicker disableToolbar value={selectDateD} variant="inline" placeholder="01/01/2020" onChange={date => { SetSelectDateD(date) }}
                                       minDate='10/10/2018' format="dd/MM/yyyy" className='smallText' margin="dense" inputVariant="outlined"
                                       invalidDateMessage="Le format de la date est incorrect" 
                               />
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                   <KeyboardDatePicker disableToolbar value={selectDateF} variant="inline" placeholder="10/10/2018" onChange={date => SetSelectDateF(date)}
                                        minDate="01/01/2018" format="dd/MM/yyyy" className='smallText' margin="dense" inputVariant="outlined"
                                        invalidDateMessage="Le format de la date est incorrect" 

                               />
              </MuiPickersUtilsProvider>
              <Autocomplete
                    disableClearable
                    onChange={(e,newValue) =>{setSelectCat(newValue)}}
                    value={selectcat}
                    options={cat.map((option) => option.name)}
                    onFocus={handleOpenCat}
                    className='smallAuto' 
                    renderInput={(params) =>  
                        <TextField margin='dense' 
                            size='small'{...params} variant="outlined"  label="Catégorie..." SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                        />}
                />
              <Autocomplete
                    disableClearable
                    onChange={(e,newValue) =>{setSelectMrq(newValue)}}
                    value={selectmrq}
                    options={mrq.map((option) => option.name)}
                    onFocus={handleOpenMrq}
                    className='smallAuto' 
                    renderInput={(params) =>  
                        <TextField margin='dense' 
                            size='small'{...params} variant="outlined"  label="Marque..." SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                        />}
                />
              <Autocomplete
                    disableClearable
                    onChange={(e,newValue) =>{setSelectPrd(newValue)}}
                    value={selectprd}
                    options={prd.map((option) => option.name)}
                    onFocus={handleOpenPrd}
                    className='smallAuto' 
                    renderInput={(params) =>  
                        <TextField margin='dense' 
                            size='small'{...params} variant="outlined"  label="Produit..." SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                        />}
                />
               <Autocomplete
                    disableClearable
                    onChange={(e,newValue) =>{setSelectZone(newValue)}}
                    value={selectZone}
                    options={zone.map((option) => option.name)}
                    onFocus={handleOpenZone}
                    className='smallAuto' 
                    renderInput={(params) =>  
                        <TextField margin='dense' 
                            size='small'{...params} variant="outlined"  label="Zone..." SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                        />}
                />
              <Button  className={classes.smallBtn} variant="contained" tabIndex="3" type='submit' id="bouton" name="addBtn" color="primary" startIcon={<Check/>}>VALIDER</Button>
            </div>
        </ValidatorForm>
        </fieldset>
        {
            (conTaille!==0)?
              <Paper id='report-paper' >
                    <div id={(consosProp>0)?'reports':"reports1"}>
                    <div>
                          <AreaCharts width={600} height={200} data={consos}
                              margin={{ top: 15, right: 30, left:50, bottom: 5 }} barSize={20} title="NBR"
                              dataKeyX="date" padding={{ left: 10, right: 10 }} dataKeyY="nbc" fill="#2E8BC0" name="Nombre de transactions"/>
                          <div style={{display:'flex',backgroundColor:'#2E8BC0',justifyContent:'center',width:'50%',padding:"3px",margin:'0 auto',color:'white'}} >
                            <div style={{display:'block',textAlign:'start'}}>
                              <p><b>Total: </b></p>
                              <p><b>Moyenne: </b></p> 
                    {(projection && <p><b>Projection: </b></p>)}
                            </div>
                            <div style={{display:'block',textAlign:'end'}}>
                              <p>{new Intl.NumberFormat().format(consosTotR)}</p>
                              <p>{new Intl.NumberFormat().format(consosMoyR)}</p>
                    {(projection && <p>{new Intl.NumberFormat().format(consosMoyRp)}</p>)}
                            </div>
                          </div>
              </div>

              <div>
                <AreaCharts width={600} height={200} data={consos}
                  margin={{ top: 15, right: 30, left: 50, bottom: 5 }} barSize={20} title="Quantité"
                  dataKeyX="date" padding={{ left: 10, right: 10 }} dataKeyY="ca" fill="#ff8000" name="Quantité" />
                <div style={{ display: 'flex', backgroundColor: '#ff8000', justifyContent: 'center', width: '50%', padding: "3px", margin: '0 auto', color: 'white' }} >
                  <div style={{ display: 'block', textAlign: 'start' }}>
                    <p><b>Total: </b></p>
                    <p><b>Moyenne: </b></p> 
                    {(projection && <p><b>Projection: </b></p>)}
                  </div>
                  <div style={{ display: 'block', textAlign: 'end' }}>
                    <p>{new Intl.NumberFormat().format(consosTotC)}</p>
                    <p>{new Intl.NumberFormat().format(consosMoyC)}</p>
                    {(projection && <p>{new Intl.NumberFormat().format(consosMoyCp)}</p>)}

                  </div>
                </div>
              </div>
                        <div>
                          {
                            (consosProp>0&&consossom!==0)?
                            <div>
                              <AreaCharts width={600} height={200} data={consos} title="Marge"
                                margin={{ top: 15, right: 30, left:50, bottom: 5 }} barSize={20} 
                                dataKeyX="date" padding={{ left: 10, right: 10 }} dataKeyY="marge" fill="#964B00" name="Marge "/>
                            <div style={{display:'flex',backgroundColor:'#964B00',justifyContent:'center',width:'50%',padding:"3px",margin:'0 auto',color:'white'}} >
                              <div style={{display:'block',textAlign:'start'}}>
                                <p><b>Total: </b></p>
                                <p><b>Moyenne: </b></p> 
                          {(projection && <p><b>Projection: </b></p>)}
                              </div>
                              <div style={{display:'block',textAlign:'end'}}>
                                <p>{new Intl.NumberFormat().format(consosTotM)}</p>
                                <p>{new Intl.NumberFormat().format(consosMoyM)}</p>
                          {(projection && <p>{new Intl.NumberFormat().format(consosMoyMp)}</p>)}

                              </div>
                            </div>
                             </div>:<></>
                            
                          }
                        </div>
                    </div>
                    <Typography className='titleCharts' variant='h6'>Consommation journalière Binar</Typography>
              </Paper>:<></>
        }
        <div id='div-pies'>
            {
                (prodsc!==0||prodsm!==0||prodsr!==0)?
                  <Paper id='report-paper' >
                    <div id={(prodsc!==0&&prodsm!==0)?'reports':"reports1"}>
                      {(prodsr!==0)?<PieCharts data={prodr} color={Colorprodsr} percent={prodsr} dimension={'100%'} title="Nombre de transitions" 
                                               display='true' total={prodsr} moy={Math.round(prodmoyr)}/>:<></>}
                      {(prodsc!==0)?<PieCharts data={prodc} color={Colorprodsc} percent={prodsc} dimension={'100%'} title="Quantité" 
                                               display='true' total={prodsc} moy={Math.round(prodmoyc)}/>:<></>}
                      {(prodsm!==0&&prodprop>0)?<PieCharts data={prodm} color={Colorprodsm} percent={prodsm} dimension={'100%'} title="Marge" 
                                                           display='true' total={prodsm} moy={Math.round(prodmoym)}/>:<></>}                    
                    </div>
                    <Typography className='titleCharts' variant='h6'>Consomation Binar des produits par marque </Typography>
                  </Paper>:<></>
            }
            {
                (appsc!==0||appsm!==0)?
                  <Paper id='report-paper' >
                    <div id={(appsc!==0&&appsm!==0&&appsr!==0)?'reports':"reports1"}>
                      {(appsr!==0)?<PieCharts data={appr} color={ColorAppsr} percent={appsr} dimension={'100%'} title="Nombre de transitions"
                                              display='true' total={appsr} moy={Math.round(appmoyr)}/>:<></>}
                      {(appsc!==0)?<PieCharts data={appc} color={ColorAppsc} percent={appsc} dimension={'100%'} title="Quantité"
                                              display='true' total={appsc} moy={Math.round(appmoyc)}/>:<></>}
                      {(appsm!==0&&appprop>0)?<PieCharts data={appm} color={ColorAppsm} percent={appsm} dimension={'100%'} title="Marge"
                                                         display='true' total={appsm} moy={Math.round(appmoym)}/>:<></>}                    
                    </div>
                    <Typography className='titleCharts' variant='h6'>Consommation Binar par application </Typography>
                  </Paper>:<></>
            }
            {
                (mrqsc!==0||mrqsm!==0||mrqsr!==0)?
                  <Paper id='report-paper' >
                    <div id={(mrqsc!==0&&mrqsm!==0)?'reports':"reports1"}>
                      {(mrqsr!==0)?<PieCharts data={mrqr} color={ColorMrqsr} percent={mrqsr} dimension={'100%'} title="Nombre de transitions"
                                              display='true' total={mrqsr} moy={Math.round(mrqmoyr)}/>:<></>}
                      {(mrqsc!==0)?<PieCharts data={mrqc} color={ColorMrqsc} percent={mrqsc} dimension={'100%'} title="Quantité"
                                              display='true' total={mrqsc} moy={Math.round(mrqmoyc)}/>:<></>}
                      {(mrqsm!==0&&mrqprop>0)?<PieCharts data={mrqm} color={ColorMrqsm} percent={mrqsm} dimension={'100%'} title="Marge"
                                                         display='true' total={mrqsm} moy={Math.round(mrqmoym)}/>:<></>}                    
                    </div>
                    <Typography className='titleCharts' variant='h6'>Consomation Binar par marque</Typography>
                  </Paper>:<></>
            }
            {
                (catesc!==0||catesm!==0||catesr!==0)?
                  <Paper id='report-paper' >
                    <div id={(catesc!==0&&catesm!==0)?'reports':"reports1"}>
                      {(catesr!==0)?<PieCharts data={cater} color={ColorCatesr} percent={catesr} dimension={'100%'} title="Nombre de transitions"
                                               display='true' total={catesr} moy={Math.round(catemoyr)}/>:<></>}
                      {(catesc!==0)?<PieCharts data={catec} color={ColorCatesc} percent={catesc} dimension={'100%'} title="Quantité"
                                               display='true' total={catesc} moy={Math.round(catemoyc)}/>:<></>}
                      {(catesm!==0&&cateprop>0)?<PieCharts data={catem} color={ColorCatesm} percent={catesm} dimension={'100%'} title="Marge"
                                                           display='true' total={catesm} moy={Math.round(catemoym)}/>:<></>}                    
                    </div>
                    <Typography className='titleCharts' variant='h6'>Consomation Binar par catégorie</Typography>
                  </Paper>:<></>
            }
            {
                (zonesc!==0||zonesm!==0||zonesr!==0)?
                  <Paper id='report-paper' >
                    <div id={(zonesc!==0&&zonesm!==0)&&zonesr!==0?'reports':"reports1"}>
                      {(zonesr!==0)?<PieCharts data={zoner} color={ColorZonesr} percent={zonesr} dimension={'100%'} title="Nombre de transitions"
                                               display='true' total={zonesr} moy={Math.round(zonemoyr)}/>:<></>}
                      {(zonesc!==0)?<PieCharts data={zonec} color={ColorZonesc} percent={zonesc} dimension={'100%'} title="Quantité"
                                               display='true' total={zonesc} moy={Math.round(zonemoyc)}/>:<></>}
                      {(zonesm!==0&&zoneprop>0)?<PieCharts data={zonem} color={ColorZonesm} percent={zonesm} dimension={'100%'} title="Marge"
                                                           display='true' total={zonesm} moy={Math.round(zonemoym)}/>:<></>}                    
                    </div>
                    <Typography className='titleCharts' variant='h6'>Consomation Binar par zone</Typography>
                  </Paper>:<></>
            }
      </div>
      {
      (submit&&zonesc===0&&zonesm===0&&catesc===0&&catesm===0&&mrqsc===0&&mrqsm===0&&appsc===0&&appsm===0&&prodsc===0&&prodsm===0&&conTaille===0)
      ?<NoDataComponent message={messageC}/>
      :<></>
      }
    </div>    

  );
}