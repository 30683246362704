import React from "react";
import {BarChart,Bar,XAxis,YAxis,CartesianGrid,Tooltip,ResponsiveContainer} from "recharts";


export default function StackedBarChart({data,dataKeyX,color,array,margin,padding}) {
   
    //const Gfunc=require('../Gfunc')
    function CustomTooltip({ payload, label, active,...props }) {
        var tot=0
        payload.map((el)=> {
          tot=tot+el.value;
          return true;
        })
        if (active) {  
             return (
                     <div className="custom-tooltip" style={{ border:"2px solid"+payload[0].fill}}>
                        <p className="intro" style={{marginBottom:'5px'}}><b></b><b>{label}</b></p>
                        { 
                        payload.map((el, index)=> {
                                return <p className="intro1" style={{color:el.fill}} key={index}>
                                       {
                                        (el.value===0)?<>Aucun digit n'a été saisi</>:<><b>{el.dataKey+': '}</b>{new Intl.NumberFormat().format(el.value)}</>
                                       } 
                                       </p>
                         })
                        }  
                        <p className="intro" style={{marginTop:'7px'}}><b>Total:</b>{' '+tot}</p> 
                     </div>
         );}
       return null;
    }
  /*const CustomizedLabel = ({ x, y, width, value }) => (
      <text x={x + width+50 } y={y} fill="black" textAnchor="middle" dy={-6} >
        {value}
      </text>
  );*/
  return (
    <div style={{ width:'100%', height: 300 }}>
      <ResponsiveContainer>
        <BarChart width={'100%'}  height={'100%'} data={data} margin={margin}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={dataKeyX} scale="point" padding={padding} tick={{ fill: 'black',fontSize:'12px',dy:3,angle: -20}}/>
        <YAxis tick={{ fill: 'black',fontSize:'13px'}}/>
        <Tooltip content={<CustomTooltip />} />
        { /*<Legend />*/}
            {
                array.map((el,index)=> {
                            return <Bar dataKey={el} stackId="a" fill={color[index]} index={index} /*label={<CustomizedLabel/>}*//>
                })
            }
        </BarChart>
     </ResponsiveContainer>
    </div>
  );
}