
export const customStyless = {
    title: {
      style: {
        fontColor: 'grey',
        fontWeight: '90px',
      }
    },
    tableWrapper: {
      style: {
        display: 'table',        
      },
    }, 
    header: {
      style: {
        border: "solid 1px" + "var(--secondary-main)",
        fontSize: '18px',
        textAlign:"center",
        color: "white",
        backgroundColor: "var(--primary-dark)",
        minHeight: '20px',
        height:"25px",
        width:"98%",
        maxWidth:"100%",
        margin:"5px",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },  
    table: {
      style: {
        width: '98%',
        border: "solid 1px" + "var(--secondary-main)",
        marginLeft: "auto",
        marginRight: "auto",
      },
      "&:first-child":{
        borderRadius: "2rem 0rem!important",
      }
    },
    rows: {
      style: {
        minHeight: '20px',
        maxHeight: '30px',
        height:"22px",
      },
      highlightOnHoverStyle: {
        color: "var(--primary-main)",
        backgroundColor: "var(--secondary-light)",
        transitionDuration: '0.15s',
        transitionProperty: 'background-color',
        borderBottomColor: "black",
        outlineStyle: 'solid',
        outlineWidth: '1px',
        outlineColor: 'black',
      }
    },
    head: {
      style: {
        height:"22px",
        minHeight:"22px",
        '&:hover': {
          color: "white !important",
       },
      },
    },
    headRow: {
      style: {
        backgroundColor: "var(--primary-main)",
        height:"23px",
        alignItems: 'center',
        minHeight:"0px",
       // minHeight: '56px',
        '&:hover': {
          color: "white",
       },
      },
      denseStyle: {
        minHeight: '0px',
        '&:hover': {
          color: "white !important",
       },
      },
    },
    headCells: {
      style: {
        fontSize: '13px',
        justifyContent: 'center',
        textAlign:"left",
        fontWeight: '550',
        textTransform: 'uppercase',
        //backgroundColor:'black',
        color: "white",
        minHeight:"20px !important",
        paddingLeft:'3px',
        paddingRight:'7px',
        minWidth:'0 !important',
      },
      
       activeSortStyle: {
        color: "white",
        '&:hover:not(:focus)': {
          color: "white",
        },
      },
      inactiveSortStyle: {
        '&:focus': {
          color: "white",
        },
        '&:hover': {
          color: "white",
        },
      }
      
    },
    
    cells: {
      style: {
        fontSize: '12px',
        textAlign: "center",
        paddingLeft:'10px !important',
        paddingRight:'10px !important',
        justifyContent: 'center',
        minWidth:'0 !important',
      },
    },
    //expander
    expanderRow: {
      style: {
        fontSize: '12px',
        minHeight: '20px',
        maxHeight: '2000px',
        height:"fit-content",
        //color: theme.text.primary,
        backgroundColor: "#D5C2A0 !important",
      },
    },
    expanderCell: {
      style: {
        paddingLeft:'0px !important',
        paddingRight:'0px !important',
        minHeight: '20px',
        maxHeight: '20px',
        height:"fit-content",
        flex: '0 0 30px',
      },
    },
    //pagination style
    pagination: {
      style: {
        color: "black",
        fontSize: '13px',
        minHeight: '20px',
        minWidth: '20px',
        height:"45px",
        width:"98%",
        marginLeft: "auto",
        marginRight: "auto",
        backgroundColor: "white",
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
        
      },
      pageButtonsStyle: {
        borderRadius: '50%',
        height: '40px',
        width: '40px',
        padding: '8px',
        margin: '0px',
        cursor: 'pointer',
        transition: '0.4s',
        color: "blue",
        fill: "var(--grey-dark)",
        backgroundColor: 'transparent',
        '&:disabled': {
          cursor: 'unset',
          fill: "var(--grey-main)",
        },
        '&:hover:not(:disabled)': {
          backgroundColor: "var(--grey-main)",
        },
        '&:focus': {
          outline: 'none',
          backgroundColor: "var(--grey-main)",
        },
      },
    },
  };
export const customStyles = {
    title: {
      style: {
        fontColor: 'grey',
        fontWeight: '90px',
      }
    },
    tableWrapper: {
      style: {
        display: 'table',        
      },
    }, 
    header: {
      style: {
        border: "solid 1px" + "var(--secondary-main)",
        fontSize: '18px',
        textAlign:"center",
        color: "white",
        backgroundColor: "var(--primary-dark)",
        minHeight: '20px',
        height:"25px",
        width:"98%",
        maxWidth:"100%",
        margin:"5px",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },  
    table: {
      style: {
        width: '98%',
        border: "solid 1px" + "var(--secondary-main)",
        marginLeft: "auto",
        marginRight: "auto",
      },
      "&:first-child":{
        borderRadius: "2rem 0rem!important",
      }
    },
    rows: {
      style: {
        minHeight: '20px',
        maxHeight: '30px',
        height:"22px",
      },
      highlightOnHoverStyle: {
        color: "var(--primary-main)",
        backgroundColor: "var(--secondary-light)",
        transitionDuration: '0.15s',
        transitionProperty: 'background-color',
        borderBottomColor: "black",
        outlineStyle: 'solid',
        outlineWidth: '1px',
        outlineColor: 'black',
        cursor:"pointer"
      }
    },
    head: {
      style: {
        height:"22px",
        minHeight:"22px",
        '&:hover': {
          color: "white !important",
       },
      },
    },
    headRow: {
      style: {
        backgroundColor: "var(--primary-main)",
        height:"23px",
        alignItems: 'center',
        minHeight:"0px",
       // minHeight: '56px',
        '&:hover': {
          color: "white",
       },
      },
      denseStyle: {
        minHeight: '0px',
        '&:hover': {
          color: "white !important",
       },
      },
    },
    headCells: {
      style: {
        fontSize: '13px',
        justifyContent: 'center',
        textAlign:"left",
        fontWeight: '550',
        textTransform: 'uppercase',
        //backgroundColor:'black',
        color: "white",
        minHeight:"20px !important",
        paddingLeft:'4.5px',
        paddingRight:'7px',
        minWidth:'0 !important',
      },
      
       activeSortStyle: {
        color: "white",
        '&:hover:not(:focus)': {
          color: "white",
        },
      },
      inactiveSortStyle: {
        '&:focus': {
          color: "white",
        },
        '&:hover': {
          color: "white",
        },
      }
      
    },
    
    cells: {
      style: {
        fontSize: '12px',
        textAlign: "center",
        paddingLeft:'10px !important',
        paddingRight:'10px !important',
        justifyContent: 'center',
        minWidth:'0 !important',
      },
    },
    //expander
    expanderRow: {
      style: {
        fontSize: '12px',
        minHeight: '20px',
        maxHeight: '2000px',
        height:"fit-content",
        //color: theme.text.primary,
        backgroundColor: "#D5C2A0 !important",
      },
    },
    expanderCell: {
      style: {
        paddingLeft:'0px !important',
        paddingRight:'0px !important',
        minHeight: '20px',
        maxHeight: '20px',
        height:"fit-content",
        flex: '0 0 30px',
      },
    },
    //pagination style
    pagination: {
      style: {
        color: "black",
        fontSize: '13px',
        minHeight: '20px',
        minWidth: '20px',
        height:"45px",
        width:"98%",
        marginLeft: "auto",
        marginRight: "auto",
        backgroundColor: "white",
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
        
      },
      pageButtonsStyle: {
        borderRadius: '50%',
        height: '40px',
        width: '40px',
        padding: '8px',
        margin: '0px',
        cursor: 'pointer',
        transition: '0.4s',
        color: "blue",
        fill: "var(--grey-dark)",
        backgroundColor: 'transparent',
        '&:disabled': {
          cursor: 'unset',
          fill: "var(--grey-main)",
        },
        '&:hover:not(:disabled)': {
          backgroundColor: "var(--grey-main)",
        },
        '&:focus': {
          outline: 'none',
          backgroundColor: "var(--grey-main)",
        },
      },
    },
  };


export const customStyles2 = {
      tableWrapper: {
      style: {
        display: 'table',        
      },
    }, 
    rows: {
      style: {
        minHeight: '20px !important',
        maxHeight: '100px',
      },
      stripedStyle: {
        backgroundColor: 'white',
      },
      highlightOnHoverStyle: {
        color: "var(--primary-dark)",
        backgroundColor: "var(--secondary-light)",
        transitionDuration: '0.15s',
        transitionProperty: 'background-color',
        borderBottomColor: "black",
        outlineStyle: 'solid',
        outlineWidth: '1px',
        outlineColor: 'black',
      }
    },
    headRow: {
      style: {
        height:"22px",
        alignItems: 'center',
        minHeight:"0px",
        color:"black",
        backgroundColor: 'var(--primary-light)',
        '&:hover': {
          color: "black",
       },
      },
      denseStyle: {
        minHeight: '0px',
        '&:hover': {
          color: "white !important",
       },
      },
    },
    headCells: {
      style: {
        fontSize: '13px',
        justifyContent: 'center',
        textAlign:"left",
        fontWeight: 'bold',
        textTransform: 'uppercase',
        //backgroundColor:'black',
        color: "black",
        minHeight:"20px !important",
        paddingLeft:'7px',
        paddingRight:'7px',
        minWidth:'0 !important',
      },
      
       activeSortStyle: {
        color: "black",
        '&:hover:not(:focus)': {
          color: "black",
        },
      },
      inactiveSortStyle: {
        '&:focus': {
          color: "black",
        },
        '&:hover': {
          color: "black",
        },
      }
      
    },
    
    cells: {
      style: {
        fontSize: '12px',
        textAlign: "center",
        paddingLeft:'10px !important',
        paddingRight:'10px !important',
        justifyContent: 'center',
        minWidth:'0 !important',
      },
    },
  
  };


  export const numberColumn={
    justifyContent:"end !important",
    paddingRight:'20px !important'
  }

  //a conditional style used in data table to apply a style in certain condition in rows only
  export const  conditionalRowStyles  =  [ 
    {
      when: row => row.isbanned===true ||row.activ===0||row.activ===false ||row.closed===1 ||row.destroyed===1,
        style: {
              backgroundColor: "lightgray",
              color:"gray"
        }
    },
    {
      when: row => row.datusd!=null,
        style: {
          backgroundColor: " var(--primary-light)",
              textColor:"white"
        },
    },
    {
      when: row => row.toggleSelected,
      style: {
        backgroundColor: "var(--primary-light)",
        userSelect: "none",
        "&:hover":{
          backgroundColor: " var(--primary-light)",
        }
      }
    },   
    {
      when: row => row.alert===true || row.statusId===3,
        style: {
              color:"red"
        },
    }, 
    {
      when: row => row.statusId===1,
      style: {
        color: "blue",
      }
    },    
    {
      when: row => row.statusId===4,
      style: {
        color: "green",
      }
    },    
  ]; 

   //a conditional cell style used in data table to apply a style in certain condition in cell only
   export const iconCell=
    {
          margin:"5px",
          width:"22px !important",
          maxWidth:" 22px !important",
          minWidth:" 22px !important",
        }

   export const compactStyle = {
          tableWrapper: {
            style: {
              display: 'table',        
            },
          },
          table: {
            style: {
              width: '98%',
              marginLeft: "auto",
              marginRight: "auto",
            },
          },
          rows: {
            style: {
              minHeight: '20px',
              maxHeight: '30px',
              width:'100%',
              height:"23px",
              outline:'none',
              border:'none !important',
              //borderBottom:"none !important"
            },
            highlightOnHoverStyle: {
              color: " var(--primary-dark)" + " !important",
              backgroundColor: "var(--secondary-light)",
              transitionDuration: '0.15s',
              transitionProperty: 'background-color',
              borderBottom: "1px solid darkgrey !important",
              outlineStyle: 'solid !important',
              outlineWidth: '1px !important',
              outlineColor:  'darkgrey !important',
            }
          },          
          cells: {
            style: {
              fontSize: '12px',
              paddingLeft:'10px !important',
              paddingRight:'10px !important',
              textAlign: "center",
              justifyContent: 'center',
            },
          },
          //expander
          expanderRow: {
            style: {
              fontSize: '12px',
              minHeight: '20px',
              maxHeight: '2000px',
              height:"fit-content",
              //color: theme.text.primary,
              backgroundColor: "#D5C2A0 !important",
            },
          },
          expanderCell: {
            style: {
              paddingLeft:'0px !important',
              paddingRight:'0px !important',
              minHeight: '20px',
              maxHeight: '2000px',
              width:'20px',
              height:"fit-content",
              flex: '0 0 24px',
              minWidth:"30px"
            },
          },
        };
        export const customStylesBon = {
          header: {
            style: {
              border: "solid 2px black",
              fontSize: '18px',
              textAlign:"center",
              color: "black",
              backgroundColor: " var(--secondary-main)",
              minHeight: '20px',
              height:"25px",
              width:"95%",
              maxWidth:"100%",
              margin:"0px",
              paddingLeft:"0%",
              marginLeft: "auto",
              marginRight: "auto",
            },
          },  
          title: {
            style: {
              fontColor: 'red',
              fontWeight: '900',
            }
          },
          table: {
            style: {
              width: '95%',
              border: "solid 1px" + " var(--secondary-main)",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop:'10px'
            }
          },
          rows: {
            style: {
              minHeight: '22px',
              maxHeight: '32px',
              height:"26px",
            },
            highlightOnHoverStyle: {
              color: " var(--primary-light)",
              backgroundColor: " var(--secondary-light)",
              transitionDuration: '0.15s',
              transitionProperty: 'background-color',
              borderBottomColor: "black",
              outlineStyle: 'solid',
              outlineWidth: '1px',
              outlineColor: 'black',
            }
          },
          headCells: {
            style: {
              fontSize: '13px',
              justifyContent: 'center',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              color: "black",
              background: " var(--secondary-main)",
            },
            activeSortStyle: {
              color: "white",
              '&:hover:not(:focus)': {
                color: "white",
              },
            },
            inactiveSortStyle: {
              '&:focus': {
                color: "white",
              },
              '&:hover': {
                color: "white",
              },
            }
          },
          headRow: {
            style: {
              fontSize: '15px',
              justifyContent: 'center',
              height: "30px",
              backgroundColor:'black',
              minHeight:"30px !important",
              '&:hover': {
                color: "white !important",
             },
            }
          },
          cells: {
            style: {
              fontSize: '12px',
              padding: '1px 3px',
              textAlign: "center",
              justifyContent: 'center',
            },
          },
           
        };
   
        export const mtnControlFourni=[
          {
            when: row => row.mtn>=0&&row.type!=='achat',
              style: {
                color:"green",
                justifyContent:"end",
                paddingRight:'20px'
              }
          }, 
          {
            when: row => row.mtn<0&&row.type!=='achat',
              style: {
                color:"red",
                justifyContent:"end",
                paddingRight:'20px'
              }
          }, 
        ]

  