
import React from 'react'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer ,Legend} from 'recharts';

export default function Area_chart(props){
    function CustomTooltip({ payload, label, active,...props }) {
        if (active && payload[0]!==undefined) {
          return (
            <div className="custom-tooltip" style={{ border:"2px solid"+props.color,}}>
              <p style={{color:'black'}}>{`${label}`}</p>
              <p className="intro" style={{color:props.color}}>{props.name}:{(payload.length!==0)?new Intl.NumberFormat().format(`${payload[0].value}`):0 }</p>
            </div>
          );
        }
        return null;
      }

    const CostumLegend = (props) => {      
        return (
            <div>
                  <p className='legend'>{props.name}</p>
            </div>
        );
      }

    return (
      <div>
        <ResponsiveContainer width="95%" height={props.height}  title={props.title}>
          <AreaChart width={300} height={150} data={props.data} margin={props.margin}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={props.dataKeyX} tick={{ fill: 'black',fontSize:'12px',dy:3,angle: -20}}  />
            <YAxis tick={{ fill: 'black',fontSize:'13px'}}
              /*tickFormatter={(tick) => {
                if(tick >= 1000 && tick < 1000000) return ((tick / 1000) + 'K')
                else if (tick >= 1000000)return ((tick / 1000000) + 'M');
                else return tick;
              }} */ 
              />
            <Tooltip content={<CustomTooltip name={props.name} color={props.fill}/>}/>
            <Area type="monotone" dataKey={props.dataKeyY} stroke={props.fill} fill={props.fill} />
            <Legend content={<CostumLegend name={props.name}/>}/>
          </AreaChart>
        </ResponsiveContainer>
     </div>
    );
}