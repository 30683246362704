import React,{useState,useRef} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog} from '@material-ui/core';
import Draggable from 'react-draggable';
import SaveIcon from '@material-ui/icons/Save';
import {TextField} from '@material-ui/core';
import { ValidatorForm} from 'react-material-ui-form-validator';
import AddIcon from '@material-ui/icons/Add';
import {axiosPostAction, axiosResponse} from'../../../Gfunc'
import { useStyles,paperPropsSmallTall } from '../../../css/muiStyle';
import {AlertCompo} from '../../../js/components'
import { Autocomplete } from '@material-ui/lab';

//add new user
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Adm_usr_add(props) {
    const [name,setName]=useState('');
    const [nickname,setNickname]=useState('');
    const [webtelco,setWebtelco]=useState('');
    const [type,setType]=useState('');
    const [open, setOpen] = useState(false);
    const [tel, setTel] =useState('');
    const addMessage="Ajouter un nouveau utilisateur"
    const alertError="l'utilisateur été ajouté"
    const labelText="Nom "
    const textNickname="Pseudo"
    const labelPhone="Contact "
    const labelWeb="Webteleco"
    const service="_usr.php"
    const classes=useStyles();
    const [severity, setSeverity] =useState("info");
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');

   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpenA(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpenA(false)
    }, 5000);
  }

    const handleClickOpen = () => { 
      props.handleOpenType()
      setName('');
      setNickname('');
      setTel('');
      setWebtelco('')
      setType('')
      setOpen(true); 

    };
    const handleClose = () => { setOpen(false); };

        
    const handleSubmit= async(e) =>{
        e.preventDefault();
        const data={
          nickname:nickname,
          name:name,
          tel:tel,
          id_webtelco:webtelco,
          typ:type
        }
    const response=await axiosPostAction(service,"add",data)
        if(response.data.err&&response.data.err!=='-1'){
            handleAlert(true,'error',response.data.err)
        }else{
        axiosResponse(response,()=>{
                setOpen(false);
                if (response.data.jData) handleAlert(true,'success',alertError);
                props.callback();
        })
      }
    }
   const handleWebTelco=(e)=>{
     if(e.target.value.length<4)
      setWebtelco(e.target.value)
      else handleAlert(true,"warning","Le code doit comporter 3 caractères!")
   }
   const handleWebTelcoBlur=(e)=>{
     if(e.target.value.length<2&&e.target.value.length!==0){
      document.getElementById('webtelco').focus()
      handleAlert(true,"warning","Le code doit comporter 3 caractères!")}
   }

  return (
    <div>
      <Button  className={classes.topTabBtn}  variant="contained" color="primary" onClick={handleClickOpen} startIcon={<AddIcon/>}>NOUVEAU UTILISATEUR</Button>
      <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmallTall}
      >
        <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >{addMessage}</DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true}/>
            <DialogContent>
            <ValidatorForm ref={useRef(null)} id='userForm'onSubmit={handleSubmit} onError={errors => console.log(errors)} >

                   <TextField className='smallText' variant="outlined" size="small" label={textNickname} id="nickname" name="nickname"  value={nickname}
                        onChange={e =>setNickname(e.target.value)} 
                        autoFocus required/>  

                    <TextField className='smallText' variant="outlined" size="small" label={labelText} id="iName" name="iName"  value={name} 
                        onChange={e =>setName(e.target.value)} 
                        required />

                    
                    <TextField className='smallText' variant="outlined" size="small" label={labelPhone} id="phone" name="phone" value={tel}
                        onChange={e =>setTel(e.target.value)}
                       inputProps={{ pattern: "[0][5-7][4-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]",maxLength:10 }} 
                       required
                    />

                    <TextField className="smallText" variant="outlined" size="small" label={labelWeb} id="webtelco" name="webtelco"  value={webtelco} 
                        onChange={e =>handleWebTelco(e)} onBlur={e=>{handleWebTelcoBlur(e)}}
                        required
                    />
                  <Autocomplete
                                disableClearable
                                className='smallAuto'
                                id="iType"
                                onChange={(e,newValue) =>{
                                    setType(newValue.id)}
                                  } 
                                options={props.type}
                                getOptionLabel={(option) => option.descr}
                                getOptionSelected={(option, value) => option.id === value.id}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={"Type"}
                                    variant='outlined'
                                    margin='dense'
                                    size="small"
                                    required/>
                                )}/>
                  </ValidatorForm>
           </DialogContent>
            <DialogActions>
            <Button variant="contained" type='submit' form="userForm" color="primary" startIcon={<SaveIcon />}>VALIDER</Button>
            <Button variant="contained" onClick={handleClose} color="secondary">FERMER</Button>
            </DialogActions>
      </Dialog>
      </div>
  );
}
