import React,{useState,useRef, useCallback} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog, Checkbox, FormControlLabel} from '@material-ui/core';
import Draggable from 'react-draggable';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import { useStyles,paperPropsBig } from '../../../css/muiStyle';
import "../../../css/referentiel.css"
import { Autocomplete } from '@material-ui/lab';
import { Add } from '@material-ui/icons';
import { AlertCompo } from '../../../js/components'



//add new marque
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Ach_add_vers(props) {
    const Gfunc=require('../../../Gfunc')
    const [open, setOpen] =useState(false);
    const [prod,setProd]=useState('');
    const [prodL,setProdL]=useState([]);
    const [cat,setCat]=useState('');
    const [catN,setCatN]=useState('');
    const [catg,setCatg]=useState('');
    const [mrq,setMrq]=useState('');
    const [mrqN,setMrqN]=useState('');
    const [sim,setSim]=useState('');
    const [simN,setSimN]=useState('');
    const [achat,setAchat]=useState(0);
    const [vente1,setVente1]=useState(0);
    const [vente2,setVente2]=useState(0);
    const [defQte,setDefQte]=useState(0);
    const [limite,setLimite]=useState(0);
    const [nameT,setNameT]=useState('');
    const [priceT,setPriceT]=useState('');
    const [promo,setPromo]=useState(false);
    const classes=useStyles();
    const addTitle="Ajouter un produit"
    const labelText1="Nom"
    const labelText2="Categorie"
    const labelText3="Marque"
    const labelText4="Prix d'achat"
    const labelText5="Prix de vente 1"
    const labelText6="Prix de vente 2"
    const labelText7="Sim"
    const labelText16="Qte. par défaut"
    const labelText17="Limite"
    const labelText18="Nom sur ticket"
    const labelText19="Prix sur ticket"
    const service="_prd.php" 
    const [openA, setOpenA] =useState(false);
    const [message, setMessage] =useState('');
    const [severity, setSeverity] =useState('info');
    
    const handleAlert=(openA,severity,message)=>{
      setOpenA(openA)
      setSeverity(severity)
      setMessage(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }

    //open dialogue
    const handleClickOpen = () => { 
      props.handleOpenCat()
      handleOpenProd()
      setOpen(true); 
      handleReset()
    };

    //close dialogue
    const handleClose = () => { setOpen(false); };
    
    //fetch catalogues
    const fetchProd= useCallback(async(serv="_prd.php", act = "gets") => {
      const response = await Gfunc.axiosGetAction(serv,act);
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{
          setProdL(response.data.jData);    
      })
    },[Gfunc])

    //fetch catalogues
  const handleOpenProd = useCallback(async () => {
    if(prodL.length===0)
    fetchProd()
  },[fetchProd,prodL])

    const handleReset= async(e) =>{
      setProd('');
      setCatg('');
      setCat('');
      setCatN('');
      setMrq('');
      setMrqN('');
      setSim('');
      setSimN('');
      setAchat(0);
      setVente1(0);
      setVente2(0);
      setLimite(10);
      setDefQte('')
      setPromo(false)
      setPriceT('')
      setNameT('')
    }
    const handleSubmit= async(e) =>{
      if(parseFloat(achat)<=parseFloat(vente1)&&((vente2===0||vente2==="")?true:parseFloat(achat)<=parseFloat(vente2))){
        const data={
          name:prod,
          marque:mrq,
          sim_id:sim,
          categorie:cat,
          achat:parseFloat(achat),
          vente:parseFloat(vente1),
          vente2:parseFloat(vente2),
          catalogId:catg,
          defQte:Gfunc.zeroToNull(defQte),
          limite:Gfunc.zeroToNull(limite),
          tiketName:nameT,
          tiketPrice:parseFloat(priceT),
          promo:promo
         // prixPublic: Gfunc.zeroToNull(prxpub)
        }
        const respon=await Gfunc.axiosPostAction(service,"add",data)
        if(respon.data.err&&respon.data.err!=='-1'){
          handleAlert(true,'error',respon.data.err)
        }else
        Gfunc.axiosResponse(respon,()=>{
          if(respon.data.jData){
            props.callback()
            props.handleAlert(true,'success',"Le produit est ajouté.")
          }
          setOpen(false)
        })
      }
      else handleAlert(true,'error',"Le prix de vente doit être supérieur à celui d'achat!")
      }  
  return (
    <div>
      <Button className={classes.topTabBtn} variant="contained" color="primary" onClick={handleClickOpen} startIcon={<Add/>}>NOUVEAU PRODUIT</Button>
      <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsBig}
      >
        <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >{addTitle}</DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={message} dialg={true}/>
            <DialogContent id='prdC' className={classes.dialogueContent}>
               <ValidatorForm id="validate"  ref={useRef(null)} onSubmit={handleSubmit} onError={errors => console.log(errors)} >
               <fieldset className="fieldsetTop2" id="prod">
               <legend>Produit:</legend>
                 <div className="rowGrid" id="row-1">   
                 <Autocomplete
                                disableClearable
                                className='smallAuto'
                                id="iProd"
                                freeSolo
                                onChange={(e,newValue) =>{setProd(newValue)}} 
                                //value={prod}
                                options={prodL.map((option) => option.name)}
                                renderInput={(params) => (
                                <TextValidator
                                    {...params}
                                    label={labelText1}
                                    variant="outlined"
                                    margin="dense"
                                    className="smallAuto"
                                    size="small"
                                    value={prod} 
                                    onChange={e=>{setProd(e.target.value)}}
                                    autoFocus
                                    required/>
                                )} />
                              <Autocomplete
                                disableClearable
                                className='smallAuto'
                                id="iCat"
                                onChange={(e,newValue) =>{
                                    setCat(newValue.id)
                                    setCatN(newValue.name)
                                   }
                                 } 
                                options={props.catL}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) => option.id === value.id}
                                renderInput={(params) => (
                                <TextValidator {...params}
                                    label={labelText2}
                                    variant='outlined'
                                    margin='dense'
                                    value={catN}
                                    size="small"
                                    validators={['required']}
                                    errorMessages={['Veuillez renseigner ce champ!']}
                                      />  
                                )}/>
                              <Autocomplete
                                disableClearable
                                className='smallAuto'
                                id="iMrq"
                                onChange={(e,newValue) =>{
                                    setMrq(newValue.id)
                                    setMrqN(newValue.name)
                                  }
                                 } 
                                 onFocus={e=>{props.handleOpenMrq();}}
                                options={props.mrqL}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) => option.id === value.id}
                                renderInput={(params) => (
                                <TextValidator {...params}
                                    label={labelText3}
                                    variant='outlined'
                                    margin='dense'
                                    value={mrqN}
                                    size="small"
                                    validators={['required']}
                                    errorMessages={['Veuillez renseigner ce champ!']}
                                      />  
                                )}/>
                              <Autocomplete
                                disableClearable
                                className='smallAuto'
                                id="iSim"
                                onChange={(e,newValue) =>{
                                    setSim(newValue.id)
                                    //setSimN(newValue.number)
                                  }
                                 } 
                                 onFocus={e=>{props.handleOpenSim();}}
                                options={props.simL}
                                getOptionLabel={(option) => option.number + (option.simCode!=null?` [ ${option.simCode} ]`:"")}
                                getOptionSelected={(option, value) => option.id === value.id}
                                renderInput={(params) => (
                                <TextValidator {...params}
                                    label={labelText7}
                                    variant='outlined'
                                    margin='dense'
                                    //value={simN}
                                    size="small"
                                    //validators={['required']}
                                    //errorMessages={['Veuillez renseigner ce champ!']}
                                      />  
                                )}/>
                    </div>
                    <div className="rowGrid" id="row-2">
                    <TextValidator margin="dense"  label={labelText4} size="small" type='number' value={achat} variant="outlined"
                        className='smallText'
                        id="iAchat"
                        InputProps={{ inputProps: { min: 0 ,step:0.0001} }}
                        onChange={e =>setAchat(e.target.value)} 
                        validators={['required']}
                        errorMessages={['Veuillez renseigner ce champ!']}
                        />
                    <TextValidator margin="dense"  label={labelText5} size="small" type='number' value={vente1} variant="outlined"
                        className='smallText'
                        id="iVente1"
                        InputProps={{ inputProps: { min: 0,step:0.0001} }}
                        onChange={e =>setVente1(e.target.value)}
                        validators={['required']}
                        errorMessages={['Veuillez renseigner ce champ!']}
                        />
                    <TextValidator margin="dense"  label={labelText6} size="small" type='number' value={vente2} variant="outlined"
                        className='smallText'
                        id="iVente2"
                        InputProps={{ inputProps: { min: 0,step:0.0001} }}
                        onChange={e =>setVente2(e.target.value)} 
                        /> 
                    <TextValidator margin="dense"  label={labelText17} size="small" type='number' value={limite} variant="outlined"
                        className='smallText'
                        InputProps={{ inputProps: { min: 0 } }}
                        id="iLimite" required
                        onChange={e =>setLimite(e.target.value)} 
                        /> 
                    <TextValidator margin="dense"  label={labelText16} size="small" type='number' value={defQte} variant="outlined"
                        className='smallText'
                        InputProps={{ inputProps: { min: 0 } }}
                        id="iQte"
                        onChange={e =>setDefQte(e.target.value)} 
                        /> 
                    </div>
                    <div className="rowGrid" id="row-2">
                    <TextValidator margin="dense"  label={labelText18} size="small" value={nameT} variant="outlined"
                        className='smallText'
                        id="iNameT"
                        onChange={e =>setNameT(e.target.value)}
                        /> 
                    <TextValidator margin="dense"  label={labelText19} size="small"  type='number' value={priceT} variant="outlined"
                        className='smallText'
                        id="iPriceT"
                        InputProps={{ inputProps: { min: 0,step:0.0001 } }}
                        onChange={e =>setPriceT(e.target.value)} 
                        /> 
                       <FormControlLabel label="Promo" control={ <Checkbox checked={promo} onChange={()=>setPromo(!promo)} name="checked" color="primary"/> }/>
                    </div>
                    </fieldset>
                </ValidatorForm>
            </DialogContent>
            <DialogActions>
            <Button  form="validate" type="submit" variant="contained" color="primary" startIcon={<Add/>}>Ajouter</Button>
            <Button variant="contained" onClick={handleClose} color="secondary">FERMER</Button>
            </DialogActions>
      </Dialog>
      </div>
  );
}