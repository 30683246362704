import React,{useState,useRef} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog,TextField} from '@material-ui/core';
import Draggable from 'react-draggable';
import SaveIcon from '@material-ui/icons/Save';
import { ValidatorForm} from 'react-material-ui-form-validator';
import AddIcon from '@material-ui/icons/Add';
import {axiosPostAction,axiosResponse} from'../../../Gfunc'
import { useStyles,paperPropsSmallTall } from '../../../css/muiStyle';

//Add a new role
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Ref_chg_add(props) {
    const [name,setName]=useState('');
    const [open, setOpen] =useState(false);
    const classes=useStyles();
    const service="_chg.php"
    
    const handleClickOpen = () => { 
      setName('');
      setOpen(true);
    }
    const handleClose = () => { setOpen(false); };
    
    const handleSubmit= async(e) =>{
        e.preventDefault();
        const response=await axiosPostAction(service,"add",{nom:name})
        if(response.data.err&&response.data.err!=='-1'){
         props.handleAlert(true,"error",response.data.err)
        }else
        axiosResponse(response,()=>{
                if (response.data.jData){
                  props.handleAlert(true,"success","Une nouvelle charge a été ajouté avec succès  ")
                    props.callback();
                    setOpen(false);
                } 
        })
    }
  return (
    <div>
      <Button className={classes.topTabBtn} variant="contained" color="secondary" onClick={handleClickOpen} startIcon={<AddIcon/>}>NOUVEAU TYPE CHARGE</Button>
      <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmallTall}
       >
        <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >Ajouter un type de charge</DialogTitle>
        <ValidatorForm ref={useRef(null)} onSubmit={handleSubmit} onError={errors => console.log(errors)} >
            <DialogContent>
                    <TextField  className='smallText' margin="dense" variant="outlined" size="small" label='Nom du type de charge'
                                value={name} onChange={e =>setName(e.target.value)} fullWidth autoFocus required />
            </DialogContent>
            <DialogActions>
              <Button variant="contained" type='submit' color="primary" startIcon={<SaveIcon />}>VALIDER</Button>
              <Button variant="contained"  onClick={handleClose} color="secondary">FERMER</Button>
            </DialogActions>
        </ValidatorForm>
      </Dialog>
      </div>
  );
}
