import React, { useState, useMemo, useCallback } from 'react';
import { DialogActions, DialogContent, DialogTitle, Paper, Button, Dialog, Tooltip, TextField } from '@material-ui/core';
import DataTable from "react-data-table-component";
import Draggable from 'react-draggable';
import { IndeterminateCheckBox, Add } from '@material-ui/icons';
import { axiosResponse, axiosPostAction, removeItemsByAtt } from '../../../Gfunc'
import { useStyles, paperPropsLarge } from '../../../css/muiStyle';
import { customStyles } from '../../../css/datatableStyle'
import { AlertCompo } from '../../../js/components'
import Iconn from '@mdi/react';
import { mdiExpandAll } from '@mdi/js';
import { Autocomplete } from '@material-ui/lab';

//the dialog that adds users to a role**********
function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    )
}

function Ref_bdl_sims(props) {
    const param = props.param
    const [open, setOpen] = useState(false);
    const title = "Les sims du "
    const serviceSims = "_sim.php"
    const service = "_bdl.php"
    const [option, setOption] = useState([]);
    const [data, setData] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);
    const classes = useStyles();
    const [, setDel] = useState(-1);
    var users = []
    const [severity, setSeverity] = useState("info");
    const [openA, setOpenA] = useState(false);
    const [messageA, setMessageA] = useState('');

    //handle alerts properties
    const handleAlert = (open, severity, message) => {
        setOpenA(open)
        setSeverity(severity)
        setMessageA(message)
        setTimeout(function () {
            setOpenA(false)
        }, 5000);
    }


    //fetch the users that have the selected role
    const fetch = useCallback(async () => {
        const dat = { idb: JSON.parse(props.row.DT_RowId.substr(1)), unsets: false }
        const response = await axiosPostAction(service, "getbsims", dat)
        if (response.data.response && response.data.err !== '-1') {
            handleAlert(true, 'error', response.data.err)
        } else {
            axiosResponse(response, () => {
                setData(response.data.jData);
            })
        }
    }, [props.row.DT_RowId])

    //fetch all users that doesn't have the selected role
    const fetchUsers = useCallback(async () => {
        const dat = { idb: JSON.parse(props.row.DT_RowId.substr(1)), unsets: true }
        const response = await axiosPostAction(service, "getbsims", dat)
        if (response.data.err && response.data.err !== '-1') {
            handleAlert(true, 'error', response.data.err)
        } else {
            axiosResponse(response, () => {
                setOption(response.data.jData);
            })
        }
    }, [props.row.DT_RowId])

    //dialog functions************************
    const handleClickOpen = () => {
        if (option.length === 0)
            fetchUsers();
        if (data.length === 0)
            fetch();
        setOpen(true);
    };
    const handleClose = () => { setOpen(false); };

    //Multiselect functions********************
    function onSelect(selectedList, selectedItem) {
        users = selectedList;
        setSelectedValues(users)
    }
    //suppression des options
    function onRemove(selectedList, removedItem) {
        setSelectedValues(selectedList)
    }


    //Add users to role
    const handleAddUsr = useCallback(async () => {
        if (selectedValues.length > 0) {
            var idrss = []
        selectedValues.map((opt) => {
            idrss.push(opt.id)
            return true
        })
        const response = await axiosPostAction(service, "setbsims", { idb: props.row.DT_RowId.substr(1), idss: idrss })
        if (response.data.err && response.data.err !== '-1') {
            handleAlert(true, 'error', response.data.err)
        } else {
            axiosResponse(response, () => {
                handleAlert(true, 'success', 'Le bundle a été attribués avec succès aux Sims sélectionnés.')
                selectedValues.map((item) => {
                    removeItemsByAtt(option, item.id, "id")
                    data.push(item)
                    return true;
                })
            })
        }
            setSelectedValues([])
        } else handleAlert(true, 'Error', 'Au moins une SIM doit être sélectionnée!')

    }, [data, option, selectedValues, service, props.row.DT_RowId])

    const handleSetData = useCallback((row, att) => {
        removeItemsByAtt(data, row.id, att)
    }, [data])

    //Delete users from role
    const handleDeleteUser = useCallback(async (row) => {
        const response = await axiosPostAction(serviceSims, "delsbndl", { ids: row.id, idb: props.row.DT_RowId.substr(1) });
        if (response.data.err && response.data.err !== '-1') {
            handleAlert(true, 'error', response.data.err)
        } else {
            axiosResponse(response, () => {
                handleAlert(true, 'success', "La Sim a été supprimée avec succès.")
                // option.push({id:row.id,name:row.name})
                handleSetData(row, "id")
                setDel(row.id)
                fetchUsers();
            })
        }
    }, [handleSetData, serviceSims, fetchUsers, props])

    const columns = useMemo(
        () => [
            {
                name: "Sim",
                selector: row => row.name,
                sortable: true,
            },
            {
                right: true,
                cell: (row) => {
                    return (
                        <div onClick={() => handleDeleteUser(row)} title="Supprimer sim">
                            <IndeterminateCheckBox className={classes.redIcon} fontSize="small" />
                        </div>
                    );
                },
            }
        ], [handleDeleteUser, classes]);

    return (
        <div>
            <Tooltip title="Attribuer des Sims">
                <Iconn path={mdiExpandAll} onClick={handleClickOpen} size="18px" color="black" />
            </Tooltip>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                PaperProps={paperPropsLarge}
                id="diagRol"
            >
                <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >{title} <b>{props.row.bundle}</b></DialogTitle>
                <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true} />
                <DialogContent className={"bdlSimContainer " + classes.dialogueContent} >
                    <fieldset className="fieldsetTop" id="bdlSim">
                        <legend>Ajouter des sims</legend>
                        {(option.length !== 0) ?
                            <div className="rowGrid" style={{ gridTemplateColumns: "auto 120px" }}>
                                <Autocomplete
                                    multiple
                                    disableCloseOnSelect
                                    limitTags={4}
                                    className='smallAuto'
                                    id="tags-outlined"
                                    options={option}
                                    filterSelectedOptions
                                    getOptionLabel={(option) => option.name}
                                    value={selectedValues}
                                    onChange={(event, newValue) => {
                                        setSelectedValues(newValue);
                                    }}
                                    noOptionsText="Il n'y a plus d'options"
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Sims"
                                            placeholder="Selectioner les sims"
                                        />
                                    )}
                                />
                                <div><Button className={classes.topTabBtn} variant="contained" onClick={handleAddUsr} color="primary" startIcon={<Add />}>AJOUTER</Button></div>
                            </div> : <></>
                        }
                    </fieldset>
                    <div style={{ width: '92%', marginLeft: 'auto', marginRight: 'auto', overflow: "auto" }}>
                        <DataTable
                            className="table"
                            columns={columns}
                            data={data}
                            noHeader={true}
                            customStyles={customStyles}
                            defaultSortAsc={true}
                            noDataComponent={param.ErreurData}
                            highlightOnHover
                            paginationComponentOptions={{ rowsPerPageText: 'Element par page:', rangeSeparatorText: 'sur' }}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" autoFocus onClick={handleClose} color="secondary">FERMER</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
export default Ref_bdl_sims;