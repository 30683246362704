
import React,{useCallback,useEffect,useState} from "react";
import { Paper, TextField, Button, CircularProgress, Backdrop } from '@material-ui/core';
import { useStyles} from '../../css/muiStyle';
import Autocomplete from '@mui/material/Autocomplete';
import {AlertCompo} from "../../js/components"
import { Lock } from "@material-ui/icons";
import '../../css/tol.css'
import $ from 'jquery'
import { ValidatorForm } from "react-material-ui-form-validator";
import { useMediaQuery } from 'react-responsive'
import { Chip, Divider } from "@mui/material";

export default function Tol_clo(props){
  const classes=useStyles()
  const param=props.param;
  const Gfunc=require('../../Gfunc')
  const Api=require('../../js/Apis')
  const [data, setData] = useState([]);
  const [getData, setGetData] =useState(false);
  const [called, setCalled] =useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] =useState(false);
  const [messageA, setMessageA] =useState('');
  const [severity, setSeverity] =useState("info");
  const [mtnChrg,setMtnChrg]=useState(0)
  const [mtnComis,setMtnComis]=useState(0)
  const [disabled,setDisabled] =useState(false);
  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);
  const [totals, setTotals] = useState([]);
  const [totalsG, setTotalsG] = useState([]);
  const [totalsB, setTotalsB] = useState([]);
  const [Comis, setComis] = useState([]);
  const [Chrg, setChrg] = useState([]);
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const service='_clo.php'
  const isTabletOrMobile = useMediaQuery({ query: '(max-width:580px)' })
  const isTablet= useMediaQuery({ query: '(max-width:1080px)' })


  //handle alerts properties
  const handleAlert=(open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  }

  const fetch= useCallback(async(mon,ye) => {
    var mn=''
    setData([])
    setCalled(true)
    setLoading(true);
    var index=months.findIndex((obj => obj.monthName ===mon));
    if(index!==-1){
      mn=months[index].month
    }
    const response= await Gfunc.axiosPostAction(service,"getinfo",{year:ye,month:mn});  
    setLoading(false)    
    if(response.data.err&&response.data.err!=="-1"){
       handleAlert(true,'error',response.data.err)
    }else
       Gfunc.axiosResponse(response,()=>{
          var vente=0;var marge=0;var array=[];var arr=[];var arr1=[];var vente1=0;var marge1=0;var total=[];var sommMg=0;var sommMt=0;
          var sommChrg=0;var sommCom=0;var arrChrg=[];var arrCom=[];
          if(response.data.jData){
                if(response.data.jData.cloture.date!==null&&response.data.jData.cloture.agent!==null&&response.data.jData.cloture.mtn!==null){
                  setDisabled(true)
                }
                response.data.jData.recap.map((item)=>{
                  if(item.categories.length<response.data.jData.categories.length){
                   var table=Gfunc.MissingObjs(item.categories,response.data.jData.categories,'categorie')
                   table.map((el)=>{
                     item.categories.push({categorie:el,mtn:0,marge:0})
                   })
                  }
                  var t=item.categories.sort((a, b) => response.data.jData.categories.indexOf(a.categorie) - response.data.jData.categories.indexOf(b.categorie));
                  t.map((el)=>{
                    el['agent']=item.owner
                    array.push(el)
                  })
                })
                response.data.jData.categories.map((el)=>{
                  marge=0;vente=0
                  array.map((item)=>{
                    if(item.categorie===el){
                      vente=vente+item.mtn
                      marge=marge+item.marge
                    }
                  })
                  arr.push({categorie:el,marge:marge,mtn:vente})
                }) 
                response.data.jData.agents.map((el)=>{
                  marge1=0;vente1=0
                  array.map((item)=>{
                    if(item.agent===el.agent){
                      vente1=vente1+item.mtn
                      marge1=marge1+item.marge
                    }
                  })
                  arr1.push({agent:el.agent,marge:marge1,mtn:vente1})
                }) 
                arr.map((el)=>{
                 sommMg=sommMg+el.marge
                 sommMt=sommMt+el.mtn
                })
                total.push({marge:sommMg,mtn:sommMt})

                response.data.jData.charges.map((el)=>{
                  if(el.nature==='Charge'){
                    sommChrg=sommChrg+el.mtn
                    arrChrg.push(el)
                  }else{
                    sommCom=sommCom+el.mtn
                    arrCom.push(el)
                  }
                })
                setMtnChrg(sommChrg);setMtnComis(sommCom)
                setChrg(arrChrg);setComis(arrCom)
                setTotals(arr); setTotalsG(arr1);setTotalsB(total)  
                setData(response.data.jData);
                
          }
    })
  },[Gfunc,param.urlService,months])

  //handle get Year
  const getYears=useCallback(async()=>{
    setGetData(true)
    const response= await Gfunc.axiosGetAction(service,"getsyear");
    if(response.data.err&&response.data.err!=='-1'){
     handleAlert(true,"error",response.data.err);
    }else
    Gfunc.axiosResponse(response,()=>{
       if(response.data.jData){
           setYears(response.data.jData)
           setYear(response.data.jData[0]['year'])
           handleOpenMonth(response.data.jData[0]['year'])
       }
    })
},[])

//handle get months
const getMonths=useCallback(async(y,val)=>{
if(y!==''&&y!==undefined){
    const response= await Gfunc.axiosPostAction(service,"getsmonth",{year:y});
    if(response.data.err&&response.data.err!=='-1'){
     handleAlert(true,"error",response.data.err);
    }else
    Gfunc.axiosResponse(response,()=>{
       if(response.data.jData){
           setMonths(response.data.jData)
           if(val!=='focus'){
            setMonth(response.data.jData[0]['monthName'])
           }
       }
    })
}else{
    handleAlert(true,'error','Vous devez choisir une année en amont')
}
},[])

//fetch year handler
const handleOpenYear= useCallback(async () => {
    if(years.length===0) getYears()
},[years,getYears])

//fetch utilisateurs handler
const handleOpenMonth = useCallback(async (year,val) => {
    if(months.length===0) getMonths(year,val)
},[months,getMonths])

useEffect(() => {
  if(!getData){handleOpenYear();}
  if(!called&&month!==''&&year!==''){fetch(month,year,1);}
}, [fetch,called,getData,month,year]);

const onChangeDate=(mon,year)=>{
  fetch(mon,year,1)
}

const CalculateTotal=()=>{
  var somme=0;
  $( document ).ready(function() {
    if(data.length!==0&&data.agents.length!==0){
      data.agents.map((el)=>{
        if(el.agent!==''){
          var id='mtn'+el.agent
          somme=somme+parseFloat(document.getElementById(id).value)
        }
      })
    }
    const tot=(somme)?new Intl.NumberFormat().format(somme):0
    document.getElementById('total-comm').innerHTML='<b>Total: </b> '+tot
  });
}

const handleCloture=async()=>{
  var arr=[]
  data.agents.map((el)=>{
    if(el.agent!==''){
      var montant='mtn'+el.agent
      arr.push({agent:el.agent,mtn:parseFloat(document.getElementById(montant).value)})
    }
  })
  var clo=document.getElementById('cloture').value
  var mn=Gfunc.MonthName1(month)
  const response=await Gfunc.axiosPostAction(service,"cloture",{year:year,month:mn,cloture:parseFloat(clo),commissions:arr})
  if(response.data.err && response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err);
  }else{
     Gfunc.axiosResponse(response,()=>{
       if(response.data.jData){
          Api.Caisse();
          fetch(month,year,1)
          setDisabled(true)
          handleAlert(true,'success',"Le mois est cloturé");
        }
    })
  }
}

const CompoAgent=({el,total})=>{
  const [prc,setPrc]=useState(el.pct)
  const [mont,setMont]=useState(total*el.pct/100)

  const UpdatePrc=useCallback((val)=>{
    setPrc(val)
    let value=(total!==0)?parseFloat((total*val)/100).toFixed(2):0;
    setMont(value)
    CalculateTotal();
  },[])
  
  const UpdateMtn=(val)=>{
    let value=(total!==0)?parseFloat((val*100)/total).toFixed(2):0;
    setMont(val)
    setPrc(value)
    CalculateTotal();
  }

  return (
    <div className='com-row'>
        <TextField margin="dense"  label='Agent' size="small" defaultValue={(el.agent!=='')?el.agent:'Base'} variant="outlined"
                                 className='smallText' disabled
                      />
        <TextField margin="dense"  label='Réalisation' size="small" type='number' variant="outlined"
                          value={total} className='smallText' disabled
                      />
        <TextField margin="dense" id={(el.agent!=='')?'pct'+el.agent:'pctBase'} label='%' size="small" type='number' variant="outlined" 
                          className='smallText' InputProps={{ inputProps: { min: 0 ,step:"1"} }} value={prc} 
                          onChange={e=>{UpdatePrc(e.target.value)}}
                      />
        <TextField id={(el.agent!=='')?'mtn'+el.agent:'mtnBase'} margin="dense" label='Montant' size="small" type='number'
                          value={mont}  variant="outlined" 
                          className='smallText' InputProps={{ inputProps: { min: 0 ,step:"1"} }}  
                          onChange={e=>{UpdateMtn(e.target.value)}}
                     />
    </div>
  )
}

  return (
    <div className="content" >
        <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
        <Backdrop className={classes.backdrop} open={loading} >
             <CircularProgress color="inherit" />
        </Backdrop>
        <div className='flex-msl' >
            <div id='auto-clo'>
                <Autocomplete
                    disableClearable
                    onChange={(e,newValue) =>{setYear(newValue);setData([]);setMonth('');setMonths([]);setDisabled(false)}}
                    value={year}
                    className='smallAuto'
                    onFocus={handleOpenYear}
                    options={years.map((option) => option.year)}
                    inputlabelprops={{className:classes.dataLabel}} 
                    renderInput={(params) =>  
                            <TextField margin='dense'  size='small'{...params} variant="outlined"  label="ANNÉE" 
                                       SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                            />
                    }
                 />     
            </div>
            <div id='auto-clo'>
                <Autocomplete
                    disableClearable
                    onChange={(e,newValue) =>{onChangeDate(newValue,year);setMonth(newValue);setDisabled(false);setData([])}}
                    value={month}
                    onFocus={()=>handleOpenMonth(year,'focus')}
                    options={months.map((option) => option.monthName)}
                    className='smallAuto'
                    inputlabelprops={{className:classes.dataLabel}} 
                    renderInput={(params) =>  
                            <TextField margin='dense' 
                                    // error={errorP}
                                    size='small'{...params} variant="outlined"  label="MOIS" 
                                    SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                                />
                    }
                 />     
            </div> 
            <div>
              {
               (data.length!==0&&data.cloture.date!==null&&data.cloture.mtn!==null&&data.cloture.mtn!==null)?
               <fieldset className="fieldsetTopChg" id="stat-cloture">
                  <div id="row-1">
                      <fieldset className="fieldInf" >
                          <legend> Mois clôturé à:</legend>
                          <p>{(data.cloture.mtn!==null)?new Intl.NumberFormat().format(data.cloture.mtn):'---'}</p>
                      </fieldset>
                      <fieldset className="fieldInf" >
                          <legend> le:</legend>
                          <p>{(data.cloture.date!==null)?data.cloture.date.replace(' ', ' à '):'---'}</p>
                      </fieldset>
                      <fieldset className="fieldInf" >
                          <legend> Par:</legend>
                          <p>{(data.cloture.agent!==null)?data.cloture.agent:'---'}</p>
                      </fieldset>
                  </div> 
               </fieldset>:<></>
             }
            </div> 
      </div>
      <div id='all-div'>
       <div id='left'>
       {
       (data.length!==0&&data.balance.length!==0)?
       <>
                <br /><br />
                <Divider textAlign="center">
                  <Chip label="État de la balance" size="small" />
                </Divider>
                <br />
                <br />
        <div>
            <table id='t'>
              <thead>
                <tr >
                        <th colSpan={2}>Actifs</th>
                        <th colSpan={2}>Passifs</th>
                        <th >Balance</th>
                </tr>
              </thead>
              <tbody>
                      <tr>
                        <td> <b>Caisse </b></td>
                        <td id="number">{new Intl.NumberFormat().format(data.balance.caisse)}</td>
                        <td rowSpan={4}><b>Fournisseurs </b></td>
                        <td id="number" rowSpan={4}>{new Intl.NumberFormat().format(data.balance.fournisseurs)}</td>
                        <td id="number" rowSpan={5}> {new Intl.NumberFormat().format(data.balance.balance)}</td>
                      </tr>
                      <tr>
                        <td> <b>Stock </b></td>
                        <td id="number">{new Intl.NumberFormat().format(data.balance.stock)}</td>
                      </tr>
                      <tr>
                        <td> <b>Conso </b></td>
                        <td id="number">{new Intl.NumberFormat().format(data.balance.conso)}</td>
                      </tr>
                      <tr>
                        <td> <b>Wallet </b></td>
                        <td id="number">{new Intl.NumberFormat().format(data.balance.wallet)}</td>
                      </tr>
                      <tr>
                        <td ><b>Total </b></td>
                        <td id="number"><p >{new Intl.NumberFormat().format(data.balance.actifs)}</p></td>
                        <td ><b >Total </b></td>
                        <td id="number">{new Intl.NumberFormat().format(data.balance.passifs)}</td>
                      </tr>
              </tbody>

            </table>
        </div></>:<></>
           }
        <br/>
        {(data.length!==0&&data.recap.length!==0)?
        <>
              <br />
              <br />
              <Divider textAlign="center">
                <Chip label="Récapitulatif de mois" size="small" />
              </Divider>
              <br />
              <br />
        <div id='div-t1'>
            <table id='t1'>
                  <colgroup span="2"><col id='first-col' /></colgroup>
                  <colgroup span="2"></colgroup>
                  <colgroup span="2"></colgroup>
                  <colgroup span="2"></colgroup>
                  <thead>
                  <tr>
                    <td rowSpan="2"></td>
                    {
                     data.categories.map((el,index)=>{
                        return <th colSpan="2" scope="colgroup" id='first-header' key={index}>{el}</th>
                     })
                    }
                    <th colSpan="2" scope="colgroup" id='first-header'>TOTAL</th>
                  </tr>
                  <tr>
                    {
                    data.categories.map((el,index)=>{
                        return (
                          <>
                            <th scope="col" id='second-header' key={index }>Vente</th>
                            <th scope="col" id='second-header'key={index+1}>Marge</th>
                          </>
                        )
                      })
                    }
                    <th scope="col" id='second-header'>Vente</th>
                    <th scope="col" id='second-header'>Marge</th>
                  </tr>
                  </thead>
                  <tbody>
                    {
                      data.recap.map((el,index)=>{
                        return (
                          <tr key={index}>
                            <th scope="row" id='first-header'>{(el.owner!=='')?el.owner:'Base'}</th>
                            {
                              el.categories.map((item)=>{
                                return (
                                  <>
                                    <td id="number">{new Intl.NumberFormat().format(item.mtn)}</td>
                                    <td id="number">{new Intl.NumberFormat().format(item.marge)}</td>
                                  </>
                                )
                              })
                            }
                            <td id="number">{new Intl.NumberFormat().format(totalsG[Gfunc.getIndex(totalsG, el.owner, "agent")].mtn)}</td>
                            <td id="number">{new Intl.NumberFormat().format(totalsG[Gfunc.getIndex(totalsG, el.owner, "agent")].marge)}</td>
                          </tr>
                        )
                      })
                    }
                   <tr>
                    <th scope="row" id='first-header'>Total</th>
                     {
                      totals.map((item)=>{
                        return (
                          <>
                            <td id="number">{new Intl.NumberFormat().format(item.mtn)}</td>
                            <td id="number">{new Intl.NumberFormat().format(item.marge)}</td>
                          </>
                        )
                      })
                     }
                     {
                      totalsB.map((item)=>{
                        return (
                          <>
                            <td id="number">{new Intl.NumberFormat().format(item.mtn)}</td>
                            <td id="number">{new Intl.NumberFormat().format(item.marge)}</td>
                          </>
                        )
                      })
                     }
                  </tr>
                </tbody>
          </table>
       </div></>:<></>}
       </div>
       <div id='right'>
          {
          (data.length!==0&&data.agents.length!==0)?
            <div>
                <br />
                <br />
                <Divider textAlign="center">
                  <Chip label="Commissions" size="small" />
                </Divider>
                <br />
                <br />
               {
                (data.agents.length>1)?
                 <div id='div-ag'>
                 {
                  data.agents.map((el,index)=>{
                    if(el.agent!==''){
                      return (
                        <div key={index}><CompoAgent el={el} total={totalsG[Gfunc.getIndex(totalsG,el.agent,"agent")].marge}/></div>
                       )
                    }
                  })
                 }
                </div>:<div style={{textAlign:'center',fontWeight:'bold'}}>Aucune.</div>
               }
               <div id='divider'><Divider/></div>
                <p id='total-comm' >{CalculateTotal()}</p>
            </div>
          :<></>
          }

          {
                (data.length!==0&&data.charges.length!==0)?
              < >
                <br />
                <br />
                <Divider textAlign="center">
                  <Chip label="Charges/Commissions" size="small" />
                </Divider>
                <br />
                <br />
                  <div id='chrg-com'> 
                  <table id='tab-com'>
                    <thead id='head-com'>
                      <tr>
                        <th>Date</th>
                        <th>Type</th>
                        <th>Charges</th>
                        <th>Montant</th>
                      </tr>
                    </thead>
                    <tbody>
                        {
                          Chrg.map((el,index)=>{
                            return(
                              <tr key={index}>
                                <td id='tab-com-cell' style={{width:(isTabletOrMobile===false)?'220px':''}}>{el.date}</td>
                                <td id='tab-com-cell' style={{minWidth:(isTabletOrMobile===false)?'220px':''}}>{el.type}</td>
                                <td id='tab-com-cell' style={{minWidth:(isTabletOrMobile===false)?'220px':''}}>{el.charge}</td>
                                <td id='tab-com-cell-right' style={{width:(isTabletOrMobile===false)?'220px':''}}>{new Intl.NumberFormat().format(el.mtn)}</td>
                              </tr>
                            )
                          })
                        }
                    </tbody>
                    <tfoot>
                      <tr>
                            <td colSpan='5' id='tab-com-cell-tot'><b>Total des charges :</b> {new Intl.NumberFormat().format(mtnChrg)}</td>
                      </tr>
                    </tfoot>
                  </table>
                  </div><br/>
                  <div id='chrg-com'>
                  <table id='tab-com'>
                    <thead id='head-com'>
                      <tr>
                        <th>Date</th>
                        <th>Type</th>
                        <th>Charges</th>
                        <th>Montant</th>
                      </tr>
                    </thead>
                    <tbody>
                        {
                          Comis.map((el,index)=>{
                            return(
                              <tr key={index}>
                                <td id='tab-com-cell' style={{width:(isTabletOrMobile===false)?'220px':''}}>{el.date}</td>
                                <td id='tab-com-cell' style={{minWidth:(isTabletOrMobile===false)?'220px':''}}>{el.type}</td>
                                <td id='tab-com-cell' style={{minWidth:(isTabletOrMobile===false)?'220px':''}}>{el.charge}</td>
                                <td id='tab-com-cell-right' style={{width:(isTabletOrMobile===false)?'220px':''}}>{new Intl.NumberFormat().format(el.mtn)}</td>
                              </tr>
                            )
                          })
                        }
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan='5' id='tab-com-cell-tot'><b>Total des commissions :</b> {new Intl.NumberFormat().format(mtnComis)}</td>
                      </tr>
                    </tfoot>
                  </table>
                  </div>
              </>
                :<></>
          }
            <br/>
            {
              (data.length!==0&&data.recap.length!==0)?
              <>
                <br />
                <br />
                <Divider textAlign="center">
                  <Chip label="Clôture du mois" size="small" />
                </Divider>
                <br />
                <br />
              <Paper id='paper-clo'> 
              <ValidatorForm  id="achForm" onSubmit={handleCloture} onError={errors => console.log(errors)} >
                    <br />
                <div id='p'>
                  <TextField margin="dense"  label='Résultat' size="small" type='number' 
                             value={totalsB[0].marge} variant="outlined" className='smallText' disabled
                  />
                </div>
                <div id='p'>
                  <TextField margin="dense"  label='Charges' size="small" type='number' value={mtnChrg} variant="outlined"
                             className='smallText' disabled
                  />
                </div>
                <div id='p'>
                  <TextField margin="dense"  label='Commissions' size="small" type='number' value={mtnComis} variant="outlined"
                             className='smallText' disabled
                  />
                </div>
                <div id='p'>
                  <TextField margin="dense"  label='Clôture' size="small" type='number' id='cloture'
                             value={(totalsB[0].marge)-mtnChrg-mtnComis} variant="outlined" className='smallText' disabled
                  />
                </div>
               <div id='div-clo'>
                   <Button variant="contained" className={classes.smallBtn} type='submit'  color="primary" disabled={disabled}
                           startIcon={<Lock fontSize="small"  style={{height:"15px",width:'15px'}} />}>Clôturer</Button>
               </div>
               </ValidatorForm>
              </Paper>
              </>
              :<></>
            }
           
       </div>
      </div>
    </div>    
  );
}
