import React,{useState,useRef} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog, TextField} from '@material-ui/core';
import Draggable from 'react-draggable';
import SaveIcon from '@material-ui/icons/Save';
import { ValidatorForm} from 'react-material-ui-form-validator';
import AddIcon from '@material-ui/icons/Add';
import {axiosPostAction, axiosResponse} from'../../../Gfunc'
import { useStyles,paperPropsSmallTall } from '../../../css/muiStyle';
import {AlertCompo} from '../../../js/components'

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Ach_frs_add(props) {
    const [name,setName]=useState('');
    const [open, setOpen] = useState(false);
    const [tel, setTel] =useState('');
    const nom="fournisseur"
    const addMessage="Ajouter un nouveau "+nom
    const alertError="Le "+nom+" est ajouté"
    const errorMessage='Veuillez renseigner le nom du '+nom
    const labelText="Nom "
    const labelPhone="Numéro du télephone "
    const service="_frs.php"
    const classes=useStyles();
    const [severity, setSeverity] =useState("info");
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');

   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpenA(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpenA(false)
    }, 5000);
  }
    const handleClickOpen = () => { 
      setName('');
      setTel('');
      setOpen(true); 
    };
    const handleClose = () => { setOpen(false); };
    
    const handleSubmit= async(e) =>{
        e.preventDefault();
        const data={
          name:name,
          tel:e.target.phone.value
        }
        const response=await axiosPostAction(service,"add",data)
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,'error',response.data.err)
        }else{
        axiosResponse(response,()=>{
                setOpen(false);
                if (response.data.jData) props.handleAlert(true,'success',alertError);
                props.callback();
        })
      }
    }
  return (
    <div>
      <Button className={classes.topTabBtn} variant="contained" color="primary" onClick={handleClickOpen} startIcon={<AddIcon/>}>NOUVEAU FOURNISSEUR</Button>
      <Dialog
            open={open}
            id='frns'
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmallTall}
      >
        <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >{addMessage}</DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true}/>
            <DialogContent>
                <ValidatorForm ref={useRef(null)} id="frnsForm" onSubmit={handleSubmit} onError={errors => console.log(errors)} >
                        <TextField className='smallText' variant="outlined" size="small" label={labelText} id="iName" name="iName"  value={name}  autoFocus
                            onChange={e =>setName(e.target.value)} 
                            required
                            errormessages={[errorMessage]} />
                        <TextField className='smallText'variant="outlined" size="small" label={labelPhone} id="phone" name="phone" value={tel} 
                            onChange={e =>setTel(e.target.value)} required
                            inputProps={{ pattern: "[0][5-7][4-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]",maxLength:10 }} 
                        />
              </ValidatorForm>
           </DialogContent>
            <DialogActions>
            <Button variant="contained" type='submit' form="frnsForm" color="primary" startIcon={<SaveIcon />}>VALIDER</Button>
            <Button variant="contained" onClick={handleClose} color="secondary">FERMER</Button>
            </DialogActions>
      </Dialog>
      </div>
  );
}
