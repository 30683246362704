import React,{useState,useRef,useCallback,useMemo} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog,TextField,CircularProgress, TextareaAutosize, Box} from '@material-ui/core';
import Draggable from 'react-draggable';
import DataTable from "react-data-table-component";
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import {Add,RotateLeft,Today,Search,IndeterminateCheckBox, Comment, Keyboard} from '@material-ui/icons';
import { useStyles,paperPropsBigFixed, textAreaEdit2 } from '../../../css/muiStyle';
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider,KeyboardDatePicker} from "@material-ui/pickers";
import { fr } from "date-fns/locale";
import {customStyles, mtnControlFourni, numberColumn}from '../../../css/datatableStyle'
import {ReactComponent as Payment} from '../../../img/icons/payment.svg'
import {AlertCompo, AutocompleteS, DatePickerUpd, InputNbrEditable} from '../../../js/components'
import { Autocomplete } from '@material-ui/lab';

//add new marque
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Ach_add_vers(props) {
    const Gfunc=require('../../../Gfunc')
    const param=props.param
    const librery=require('../../../js/data_table_librery')
    const [open, setOpen] =useState(false);
    const [frns,setFrns]=useState('');
    const [,setFrnsN]=useState('');
    const [reset,setReset]=useState(true);
    const [note,setNote]=useState('');
    const [caisse,setCaisse]=useState('');
    const [caisseN,setCaisseN]=useState('');
    const [type,setType]=useState('');
    const [mtn,setMtn]=useState(0);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [data, setData] = useState([]);
    const [typeL,] = useState([{id:-1,name:'Versement'},{id:1,name:'Remboursement'}]);
    const [typeN, setTypeN] = useState('');
    const [loading, setLoading] = useState(false);
    const [resetDP, setResetDP] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [search,setSearch]=useState('');
    const [Item, setItem] = useState('');
    const [ItemN, setItemN] = useState('');
    const [selecto, setSelecto] = useState('');
    const[,setNoData]=useState(false);
    const[doSearch,setDoSearch]=useState(false);
    const [, setDel] = useState('');
    const classes=useStyles();
    const addTitle="Ajouter un versement fournisseur"
    const labelText1="Date"
    const labelText2="Fournisseur"
    const labelText3="Montant"
    const labelText4="Caisse"
    const labelText5="Note"
    const labelText6="Type"
    const service="_ach.php"
    const [severity, setSeverity] =useState("info");
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');

   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpenA(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpenA(false)
    }, 5000);
  }
    const fetch= useCallback(async(page,servic = service,size = perPage,id) => {
      setLoading(true);
      const response=await librery.axiosGet2(page,servic,'getver&f='+id,size)
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
        setOpen(false)
      }else
      Gfunc.axiosResponse(response,()=>{
        if(response.data.data!==undefined){
          setData(response.data.data);
          setTotalRows(response.data.recordsFiltered);
          setDoSearch(true)
          setNoData(true)
        }
      })
      setLoading(false);
    },[Gfunc,perPage,librery]);
    
    const handleDateChange = (date) => {
      setSelectedDate(date);
    }; 
    //handle le changement de la page
    function handlePageChange (page) {
      fetch(page,service,perPage,frns);
      setCurrentPage(page);
    };
    //handle le nombre de lignes par page
     const handlePerRowsChange = async (newPerPage, page) => {
      fetch(page,service, newPerPage,frns);
      setPerPage(newPerPage);
    };
    //handle sort
    const handleOnSort=useCallback(async(column, sortdirection,page=currentPage, size = perPage,id=frns)=>{
      const response=await librery.axiosSort2(column,sortdirection,page,size,service,'getver&f='+id)
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{
        if(response.data.data!==undefined){
        setLoading(true);
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
        data.slice(0)
        setLoading(false);
        }
      })     
    },[currentPage,perPage,Gfunc,librery,service,data,frns])

    //recherche serverside
  const handleSearch=async(size = perPage,id=frns)=>{
    setLoading(true);
    const recherche=search;
    const response=await librery.axiosSearch(service,recherche,1,size,'getver&f='+id)
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
      if(response.data.data!==undefined){
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
      }else setData([])
    })  
    setLoading(false); 
    return recherche
  }

    //delete versement
    const handleSetData=useCallback((row,att)=>{
      Gfunc.removeItemsByAtt(data,row.DT_RowId,att)
      setDel('')
    },[data,Gfunc])

    //handle delete versement 
      const handleDeleteVers=useCallback(async(row)=>{
        const x=window.confirm("Êtes-vous sure de supprimer cet élément?");
        if(x){
          const response = await Gfunc.axiosPostAction(service,"delver",{id:row.DT_RowId.substr(1)})
          if(response.data.err&&response.data.err!=='-1'){
            handleAlert(true,'error',response.data.err)
          }else
          Gfunc.axiosResponse(response,()=>{
                  handleAlert(true,'success',"L'élément est supprimé avec succès.")
                  handleSetData(row,"DT_RowId")
                  setDel(row.DT_RowId)
          })  
        }
      },[handleSetData,Gfunc])

      //modifier un champs avec un select
    const handleSelect=useCallback((row,att,f=()=>{})=>{
      f()
      setSelecto(row[att])
      setItem(row.DT_RowId)
      setItemN(att)
    },[])

      //handle live search
    function liveSearch(e,search){
      if(param.liveSearch&&search.length>3){
      e.preventDefault();
      handleSearch();
      }else{ if (e.keyCode === 13) {
              e.preventDefault();
              handleSearch();
             }
            }
    }
    function liveSearch2(e) {
      handleSearch();
    }

    //open dialogue
    const handleClickOpen = () => { 
      props.handleOpenFrns()
      props.handleOpenUsers()
      setDoSearch(false)
      setPerPage(10);
      setCurrentPage(1);
      setOpen(true); 
      setType(-1)
      setTypeN('Versement')
      setFrns('')
      setMtn('');
      setCaisse('')
      setCaisseN('')
      setNote('')
      setFrnsN('')
      setData([])
    };

  const handleOnEditNote=useCallback(async(note,row,att,action)=>{
    const edt1=note
    var edt=Gfunc.DeleteSpace(edt1);
    if(row[att]!==edt){
      const attrib=row[att];
      const response=await librery.axiosEdit2(service,action,row,edt,att) 
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{row[att]=edt})
      if(response.data.err) {row[att]=attrib}
    }else {row[att]=edt}
    setSelecto('')
  },[librery,Gfunc]);

  const ExpanableComponent = ({ data }) => {
    const [noteB,setNoteB] = useState('');
    return (
        <div className='flex-wrapper' style={{padding:'5px',justifyContent:'center',width:'100%'}}>
          <Comment/>
          <TextareaAutosize  style={textAreaEdit2} onFocus={(e)=>{setNoteB(e.target.value)}} onChange={(e)=>{setNoteB(e.target.value);}} minRows={3} value={(noteB?noteB:(data.note)?data.note:'')} onBlur={e=>handleOnEditNote(noteB,data,"note",(data.type==='achat')?'updbon':'updver')}/>
        </div>
    )
}

const conditionalRowStyles=[
  { when: row => row.type==='achat',
     style: {
           color: "red",
     }
   },
  { when: row => row.type!=='achat',
     style: {
           color: 'green',
     }
   }
]

const handleAutoInput=useCallback(async(row)=>{
  setFrns(row.frnsId)
  setFrnsN(row.frns)
  setMtn(row.mtn);
  document.getElementById('iMtn').focus();
},[])

    //close dialogue
    const handleClose = () => { setOpen(false); };

    const handleChangeFrns= async(idf) =>{
      fetch(1,service,perPage,idf)
    }
  
    const handleReset= async(e) =>{
      setDoSearch(false)
      setPerPage(10);
      setCurrentPage(1);
      setType(-1)
      setTypeN('Versement')
      setCaisse('')
      setCaisseN('')
      setMtn('');
      setNote('')
      setData([])
      setFrns('')
      setFrnsN('')
      setReset(!reset);
    }
    const handleSubmit= async(e) =>{
      const data={
        date:Gfunc.formatDate(new Date(selectedDate)),
        typ:type,
        mtn:parseFloat(mtn),
        frns:parseInt(frns),
        user:parseInt(caisse),
        note:note,
      }
      const respon=await Gfunc.axiosPostAction(service,"addver",data)
      if(respon.data.err&&respon.data.err!=='-1'){
        handleAlert(true,'error',respon.data.err)
      }else
      Gfunc.axiosResponse(respon,()=>{
        Gfunc.Caisse()
        if(currentPage===1)
         fetch(1,service,perPage,frns)
        else setResetDP(!resetDP)
         setType(-1)
         setTypeN('Versement')
         setMtn('');
         setNote('')
         const typee=(type===1)?"remboursement":"versement"
         handleAlert(true,'success','Le '+typee+' est ajouté.')
         document.getElementById('iMtn').focus();
      })
    }
    const columns =useMemo(
      () => [
        {
          name: "DATE",
          minWidth:'100px !important',
          selector: row=>row.date,
          sortable: true,
          sortField:'date',
          cell:(row)=>{
            return(
                <div>
                  <DatePickerUpd selecto={selecto} Item={Item} ItemN={ItemN} row={row}
                setSelecto={setSelecto}
                name="date"
                service={service} action={(row.type==='achat')?'updbon':'updver'} extra=''
                handleSelect={handleSelect} setItem={setItem} handleAlert={handleAlert}/>
              
            </div>
            )
        }
      },
      {
        name: "FOURNISSEUR",
        minWidth:'160px !important',
        selector: row=>row.frns,
        sortable: true,
        sortField:'frns',
        cell: (row) => { 
          return (
              <AutocompleteS
                datein={row.date} agentin={row.agent}
                service={service} action={(row.type!=="achat")?'updver':'updbon'} 
                defaultValue={row.frns} row={row} list={props.frnsL} 
                id={(row.type!=="achat")?'frnsId':'idfrns'} name='frns' attr='name'
                onOpen={props.handleOpenFrns} handleAlert={handleAlert}/>
              );
         },
    },
      {
        name: "MONTANT",
        style:numberColumn,
        conditionalCellStyles:mtnControlFourni,
        selector: row=>row.mtn,
        width:'150px !important',
        sortable: true,
        sortField:'mtn',
        cell: (row) => {
          return (
            <div className='InputNbrEditable'>
                {(row.type==='achat')?
                <div style={{padding:"0 5px"}}>{new Intl.NumberFormat().format(row.mtn)}</div>
                : <div><InputNbrEditable
                    service={service} action='updver'
                    row={row} att="mtn" value={row.mtn} id='DT_RowId'
                    handleAlert={handleAlert} 
                    traetResp={()=>{
                      fetch(currentPage,service,perPage,frns);
                    }}
                />
                </div>
              }
              </div>
           );
         },
    },
    {
      name: "par",
      minWidth:'150px !important',
      selector: row=>row.agent,
      sortable: true,
      sortField:'agent',
      cell: (row) => { 
        return (
          <div >
          {(row.type==="achat")? <div >{row.agent}</div>: 
             <AutocompleteS
              datein={row.date} agentin={row.agent}
              service={service} action={'updver'} 
              defaultValue={row.agent} row={row} list={props.userL} 
              id={'agentId'} name='agent' attr='name'
              onOpen={()=>props.handleOpenUsers()} handleAlert={handleAlert}/>}
        </div> 
            );
       },
  },
  {
    name: "solde",
    style:numberColumn,
    minWidth:'150px !important',
    selector: row=>row.solde,
    sortable: true,
    sortField:'solde',
    cell: (row) => {
      return (
          <div id={"s"+row.DT_RowId}>{new Intl.NumberFormat().format(row.solde)}</div>
       );
     },
},
      {
        width:"45px",
        cell: (row) => {
          return (
            <div id="icons" >
               {(row.type==="achat")
                ?<div onClick={()=>{handleAutoInput(row)}} title="Saisir automatiquement"><Keyboard fontSize="small" className={classes.blackIcon}/></div> :
                <div onClick={()=>{(row.type==="achat")?handleDeleteVers(row):handleDeleteVers(row)}} title="Supprimmer"><IndeterminateCheckBox  fontSize="small" className={classes.redIcon}/> </div>} 
            </div>
           );
         },
      } 
      ],[classes,handleDeleteVers,handleSelect,selecto,Item,ItemN,currentPage,frns,perPage,handleAutoInput,fetch,props]);
  
  
  return (
    <div>
      <Button className={classes.topTabBtn} variant="contained" color="primary" onClick={handleClickOpen} startIcon={<Payment fill='black' style={{height:'20px',width:'20px',padding:"1px"}}/>}>Versement</Button>
      <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsBigFixed}
      >
        <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >{addTitle}</DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true} />
            <DialogContent className={classes.dialogueContent}>
            <fieldset className="fieldsetTop2" id="vers">
               <legend>Information versement:</legend>
               <ValidatorForm id="validate"  ref={useRef(null)} onSubmit={handleSubmit} onError={errors => console.log(errors)} >
                 <div className="rowGrid" id="row-1">
                  <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                              <KeyboardDatePicker
                              disableToolbar
                              margin='dense'
                              size="small"
                              className='smallText'
                              KeyboardButtonProps={{style:{padding:"0px"}}}
                              inputVariant="outlined"
                              variant="inline"
                              maxDate={new Date()}  
                              invalidDateMessage="Le format de la date est incorrect"   
                              maxDateMessage="La date ne doit pas être supérieure à la date maximale"
                              format="dd/MM/yyyy"
                              name="iDate"
                              id="date-picker-inline"
                              label={labelText1}
                              keyboardIcon={<Today className={classes.blackSmallIcon}/>}
                              value={selectedDate}
                              onChange={handleDateChange}
                              required />
                  </MuiPickersUtilsProvider>  
                  <Autocomplete
                                key={reset}
                                disableClearable
                                className='smallAuto'
                                id="iFrns"
                                onChange={(e,newValue) =>{
                                    setFrns(newValue.id)
                                    setFrnsN(newValue.name)
                                    handleChangeFrns(newValue.id)}
                                  } 
                                options={props.frnsL}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) => option.id === value.id}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={labelText2}
                                    variant='outlined'
                                    margin='dense'
                                    size="small"
                                    autoFocus 
                                    required/>
                                )}/>
                    <TextValidator margin="dense"  label={labelText3} size="small" type='number' value={mtn} variant="outlined"
                        className='smallText'
                        id="iMtn"
                        InputProps={{ inputProps: { min: 0 ,step:"0.01"} }}
                        onChange={e =>setMtn(e.target.value)} required/>
                      <Autocomplete
                                disableClearable
                                className='smallAuto'
                                id="iCaisse"
                                onChange={(e,newValue) =>{
                                  //const idf=props.userL[Gfunc.getIndex(props.userL,newValue,"name")].id
                                    setCaisse(newValue.id)
                                    setCaisseN(newValue.name)
                                  }}
                                value={{id:parseInt(caisse),name:caisseN}}
                                options={props.userL}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) => (option.id=== value.id)}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={labelText4}
                                    variant='outlined'
                                    margin='dense'
                                    size="small"
                                    required/>
                                )} />
                    </div>
                    <div className="rowGrid" id="row-2">
                    <TextValidator size="small" variant="outlined" label={labelText5} id="iNote" name="iNote"  value={note}
                        margin="dense"
                        className='smallNote'
                        onChange={e =>setNote(e.target.value)} 
                        multiline
                        rows={2} />
                    <Autocomplete
                                disableClearable
                                className='smallAuto'
                                id="iType"
                                onChange={(e,newValue) =>{
                                    setType(newValue.id)
                                    setTypeN(newValue.name)
                                  } }
                                value={{id:type,name:typeN}}
                                options={typeL}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) => (option.id=== value.id)}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={labelText6}
                                    variant='outlined'
                                    margin='dense'
                                    size="small"
                                    required/>
                                )} />
                    <Button className={classes.diagTabBtn} variant="contained" color="primary" onClick={handleSubmit} startIcon={<Add/>}>Ajouter</Button>
                    <Button className={classes.diagTabBtn} variant="contained" color="primary" onClick={handleReset} startIcon={<RotateLeft/>}>Réinitialiser</Button>

                    </div>
                </ValidatorForm>
                
            </fieldset>
            { (doSearch)?  <Paper className={classes.paperBody} elevation={3}>
       { /*<p className="paperTitle">Liste des bons d'achat</p>*/}
        <div className='table-header'>
            <div className="button-flex-wrapper"> </div>  
            
            <div className='search'>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Search className={classes.blackIcon} sx={{ color: 'action.active', mr: 1, my: 0.5 }} onClick={(e)=>liveSearch2(e)}/>
                      <TextField className="smallText" id="searchBar" variant="outlined" label="Recherche" value={search} size="small" margin='dense' onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} />
                   </Box>
                </div>
            </div> 
            <div id={(data.length<=10)?"versDT1":"versDT"}>  
                <DataTable 
                  persistTableHead
                  columns={columns}
                  data={data}
                  noDataComponent={param.ErreurData}
                  paginationRowsPerPageOptions={[10,15,20,30,50]}
                  progressPending={loading}
                  progressComponent={<CircularProgress className={classes.circularProgress} />}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  //paginationDefaultPage={currentPage}
                  paginationPerPage={10}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  sortServer={true}
                  onSort={handleOnSort}
                  expandableRows 
                  expandableRowsComponent={ExpanableComponent} 
                  //onRowClicked={handleRowClick} //declanche une fonction quand on clique sur une ligne
                  paginationResetDefaultPage={resetDP}
                  noHeader={true}
                  highlightOnHover
                  customStyles={customStyles}
                  paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}            
                  conditionalRowStyles = { conditionalRowStyles }
                /></div>  
            </Paper>:null}
            </DialogContent>
            <DialogActions>
            <Button variant="contained" onClick={handleClose} color="secondary">FERMER</Button>
            </DialogActions>
      </Dialog>
      </div>
  );
}