import React,{useState,useRef} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog,TextField} from '@material-ui/core';
import Draggable from 'react-draggable';
import SaveIcon from '@material-ui/icons/Save';
import { ValidatorForm} from 'react-material-ui-form-validator';
import AddIcon from '@material-ui/icons/Add';
import {axiosPostAction,axiosResponse} from'../../../Gfunc'
import { useStyles,paperPropsSmallTall } from '../../../css/muiStyle';
import {AlertCompo} from '../../../js/components'
//Add a new role
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

function Adm_rol_add(props) {
    const [name,setName]=useState('');
    const [open, setOpen] = React.useState(false);
    const nom="role"
    const addMessage="Ajouter un nouveau "+nom
    const alertError="Le "+nom+" est ajouté."
    const labelText="Nom du "+nom
    const service="_rol.php"
    const classes=useStyles();
    const [severity, setSeverity] =useState("info");
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');

   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpenA(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpenA(false)
    }, 5000);
  }
    
    const handleClickOpen = () => { 
      setName('');
      setOpen(true); 
    };
    const handleClose = () => { setOpen(false); };
    
    const handleSubmit= async(e) =>{
        e.preventDefault();
        const data={name: e.target.iName.value}
        const response=await axiosPostAction(service,"add",data)
        if(response.data.err&&response.data.err!=='-1'){
            handleAlert(true,'error',response.data.err)
        }else{
        axiosResponse(response,()=>{
                setOpen(false);
                if (response.data.jData) props.handleAlert(true,'success',alertError);
                props.callback();
        })
      }
    }
  return (
    <div>
      <Button className={classes.topTabBtn} variant="contained" color="primary" onClick={handleClickOpen} startIcon={<AddIcon/>}>NOUVEAU ROLE</Button>
      <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmallTall}
      >
        <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >{addMessage}</DialogTitle>
        <AlertCompo severity={severity}  open={openA} setOpen={setOpenA} message={messageA} dialg={true}/>
            <DialogContent id='rolC'>
            <ValidatorForm  id="addRol" ref={useRef(null)} onSubmit={handleSubmit} onError={errors => console.log(errors)} >

                    <TextField className='smallText' variant="outlined" size="small" label={labelText} id="iName" name="iName"  value={name} 
                        onChange={e =>setName(e.target.value)} 
                        autoFocus
                        required />
                        </ValidatorForm>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" form='addRol' type='submit' color="primary" startIcon={<SaveIcon />}>VALIDER</Button>
              <Button variant="contained"  onClick={handleClose} color="secondary">FERMER</Button>
            </DialogActions>
        
      </Dialog>
      </div>
  );
}
export default Adm_rol_add;