import React,{useState,useRef, useCallback} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog, TextField, Checkbox, FormControlLabel} from '@material-ui/core';
import Draggable from 'react-draggable';
import SaveIcon from '@material-ui/icons/Save';
import { ValidatorForm} from 'react-material-ui-form-validator';
import AddIcon from '@material-ui/icons/Add';
import { useStyles,paperPropsSmallTall } from '../../../css/muiStyle';
import {AlertCompo} from '../../../js/components'
import { Autocomplete } from '@material-ui/lab';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Ref_bdl_add(props) {
    const classes=useStyles();
    const Gfunc=require('../../../Gfunc')
    const [name,setName]=useState('');
    const [open, setOpen] = useState(false);
    const [opr, setOpr] =useState([]);
    const [selectOpr, setSelectOpr] =useState('');
    const [mtn, setMtn] =useState('');
    const [cost, setCost] =useState('');
    const [USSD, setUSSD] =useState('');
    const [note, setNote] =useState('');
    const [severity, setSeverity] =useState("info");
    const [openA, setOpenA] =useState(false);
    const [check, setCheck] =useState(true);
    const [checkPass, setCheckPass] =useState(true);
    const [messageA, setMessageA] =useState('');
    const service="_bdl.php"


   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpenA(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpenA(false)
    }, 5000);
  }
    const handleClickOpen = () => { 
      setOpen(true); 
      setName('');setOpr([]);setSelectOpr('');setCheck(true);setNote('');setUSSD('');setCheckPass(true);setMtn('');setCost('');
    };


    const handleClose = () => { setOpen(false); };
    
      //fetch operateur
    const fetchOpr= useCallback(async() => {
      const response = await Gfunc.axiosPostAction(service,"getops")
      if(response.data.err){
        handleAlert(true,'error',response.data.err)
      }else 
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData)
          setOpr(response.data.jData);   
      })
    },[Gfunc])


    //fetch fournisseur handler
    const handleOpenOpr= useCallback(async () => {
      if(opr.length===0) fetchOpr()
    },[opr,fetchOpr])


    const handleSubmit= async(e) =>{
      var op=''
      var ind=opr.findIndex((obj => obj.name=== selectOpr))   
      if(ind!==-1)  {
        op=opr[ind].id
      }
      const response = await Gfunc.axiosPostAction(service,"add", {name:name,ussd:(USSD!=='')?USSD:null,operatorId:op,useMount:check,
                            note:(note!=='')?note:'',usePass:checkPass,mount:(mtn!=='')?parseFloat(mtn):null,cost:(cost!=='')?parseFloat(cost):null})
      if(response.data.err){
          handleAlert(true,'error',response.data.err)
      }else{
            Gfunc.axiosResponse(response,()=>{
                if(response.data.jData){
                   props.handleAlert(true,"success", "Un nouveau bundle a été ajouté avec succès")
                   props.callback()
                   setOpen(false)
                }
         })
        }
        
    }

  return (
    <div>
      <Button className={classes.topTabBtn} variant="contained" color="primary" onClick={handleClickOpen} startIcon={<AddIcon/>}>NOUVEAU Bundle</Button>
      <Dialog
            open={open}
            id='frns'
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmallTall}
      >
        <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >Ajouter un nouveau bundle</DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true}/>
            <DialogContent>
                <ValidatorForm ref={useRef(null)} id="frnsForm" onSubmit={handleSubmit} onError={errors => console.log(errors)} >
                   <div id='bdl-row'>
                    <TextField className='smallText' variant="outlined" size="small" label='Nom'  value={name} autoFocus required
                               onChange={e =>setName(e.target.value)} />
                    <Autocomplete
                        disableClearable
                        className='smallAuto'
                        onChange={(e,newValue) =>{setSelectOpr(newValue)} } 
                        id='iPo' 
                        onFocus={handleOpenOpr}
                        value={selectOpr}
                        options={opr.map((option) => option.name)}
                        renderInput={(params) => (
                          <TextField {...params} label='Opérateur' size="small" margin='dense' variant="outlined" required />)} 
                    />
                   </div> 
                   <div id='bdl-ussd'>
                   <TextField className='smallText' id="iMtn" label="USSD" size="small"  value={USSD}  variant="outlined"
                                 onChange={e =>setUSSD(e.target.value)} />
                    </div>
                    <div id='bdl-row'>
                      <TextField type='number' className='smallText' label="Montant" size="small" value={mtn} variant="outlined"
                                 onChange={e =>setMtn(e.target.value)} />
                      <TextField type='number' className='smallText' label="Coût" size="small" value={cost} variant="outlined"
                                 onChange={e =>setCost(e.target.value)} />
                   </div>
                   <div id='bdl-check'>
                        <FormControlLabel label="Utiliser le montant" control={<Checkbox  checked={check} color="primary" 
                                          onChange={e=>{setCheck(e.target.checked)}} inputProps={{ 'aria-label': 'controlled' }}  />}/> 
                        <FormControlLabel label="Utiliser le pin" control={<Checkbox  checked={checkPass} color="primary" 
                                          onChange={e=>{setCheckPass(e.target.checked)}} inputProps={{ 'aria-label': 'controlled' }}  />}/> 
                   </div>
                   <div id='bdl-note'>
                     <TextField size="small" className='smallNote' variant="outlined" label="Note" id="iNote" name="iNote"  
                                value={note} placeholder="Insérer une note" multiline rows={4} 
                                onChange={e =>setNote(e.target.value)} />
                   </div>
                 
                  
              </ValidatorForm>
           </DialogContent>
           <DialogActions>
              <Button variant="contained" type='submit' form="frnsForm" color="primary" startIcon={<SaveIcon />}>VALIDER</Button>
              <Button variant="contained" onClick={handleClose} color="secondary">FERMER</Button>
           </DialogActions>
      </Dialog>
      </div>
  );
}
