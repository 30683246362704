import React,{useState,useRef,useCallback,useMemo} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog,TextField,CircularProgress, TextareaAutosize, Box} from '@material-ui/core';
import Draggable from 'react-draggable';
import DataTable from "react-data-table-component";
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import {Add,RotateLeft,Today,Search,IndeterminateCheckBox, Comment, MonetizationOn} from '@material-ui/icons';
import { useStyles,paperPropsBigFixed, textAreaEdit2 } from '../../../css/muiStyle';
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider,KeyboardDatePicker} from "@material-ui/pickers";
import { fr } from "date-fns/locale";
import {customStyles, mtnControlFourni, numberColumn}from '../../../css/datatableStyle'
import {AlertCompo, DatePickerUpd} from '../../../js/components'
import { Autocomplete } from '@material-ui/lab';
import Iconn from "@mdi/react"
import { mdiWalletPlus } from '@mdi/js';
import {ReactComponent as Dzd} from '../../../img/icons/dzd.svg'
import {ReactComponent as DollarExchange} from '../../../img/icons/dollarExchange.svg'

//add new marque
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Ach_add_versWal(props) {
    const Gfunc=require('../../../Gfunc')
    const param=props.param
    const librery=require('../../../js/data_table_librery')
    const [open, setOpen] =useState(false);
    const [frns,setFrns]=useState('');
    const [,setFrnsN]=useState('');
    const [reset,setReset]=useState(true);
    const [note,setNote]=useState('');
    const [caisse,setCaisse]=useState('');
    const [caisseN,setCaisseN]=useState('');
    const [control,setControl]=useState(false);
    const [mtn,setMtn]=useState(0);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [resetDP, setResetDP] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [search,setSearch]=useState('');
    const [wal, setWal] = useState('');
    const [walL, setWalL] = useState([]);
    const [walN, setWalN] = useState('');
    const [crncy, setCrncy] = useState('');
    const [rate, setRate] = useState('');
    const [Item, setItem] = useState('');
    const [ItemN, setItemN] = useState('');
    const [selecto, setSelecto] = useState('');
    const[,setNoData]=useState(false);
    const[doSearch,setDoSearch]=useState(false);
    const [, setDel] = useState('');
    const classes=useStyles();
    const addTitle="Alimenter wallets"
    const labelText1="Date"
    const labelText2="Fournisseur"
    const labelText3="Montant"
    const labelText7="Devise"
    const labelText8="Taux"
    const labelText4="Caisse"
    const labelText5="Note"
    const labelText6="Wallet"
    const service="_wal.php"
    const [severity, setSeverity] =useState("info");
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');

   //handle alerts properties
   const handleAlert=useCallback((open,severity,message)=>{
    setOpenA(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpenA(false)
    }, 5000);
    },[])

    const fetch= useCallback(async(page,servic = service,size = perPage,id) => {
      setLoading(true);
      const response=await librery.axiosGet2(page,servic,'gethisto&w='+id,size)
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
        setOpen(false)
      }else
      Gfunc.axiosResponse(response,()=>{
        if(response.data.data!==undefined){
          setData(response.data.data);
          setTotalRows(response.data.recordsFiltered);
          setDoSearch(true)
          setNoData(true)
        }
        else setData([])
      })
      setLoading(false);
    },[Gfunc,perPage,librery,handleAlert]);
    
    const handleDateChange = (date) => {
      setSelectedDate(date);
    }; 

    //handle le changement de la page
    function handlePageChange (page) {
      fetch(page,service,perPage,wal);
      setCurrentPage(page);
    };
    
    //handle le nombre de lignes par page
     const handlePerRowsChange = async (newPerPage, page) => {
      fetch(page,service, newPerPage,wal);
      setPerPage(newPerPage);
    };

    //handle sort
    const handleOnSort=useCallback(async(column, sortdirection,page=currentPage, size = perPage,id=wal)=>{
      const response=await librery.axiosSort2(column,sortdirection,page,size,service,"gethisto&w="+wal)
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{
        if(response.data.data!==undefined){
        setLoading(true);
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
        data.slice(0)
        setLoading(false);
        }
      })     
    },[currentPage,perPage,Gfunc,librery,service,data,wal,handleAlert])

      //recherche serverside
    const handleSearch=async(size = perPage,id=wal)=>{
      setLoading(true);
      const recherche=search;
      const response=await librery.axiosSearch(service,recherche,1,size,"gethisto&w="+id)
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{
        if(response.data.data!==undefined){
          setData(response.data.data);
          setTotalRows(response.data.recordsFiltered);
        }else setData([])
      })  
      setLoading(false); 
      return recherche
    }

    //delete versement
    const handleSetData=useCallback((row,att)=>{
      Gfunc.removeItemsByAtt(data,row.DT_RowId,att)
      setDel('')
    },[data,Gfunc])

       //handle delete 
const handleDelete=useCallback(async(row)=>{
  const x=window.confirm("Êtes-vous sure de supprimer cet élément?");
  if(x){
    const response = await Gfunc.axiosPostAction("_cai.php","del",{caisseId:row.DT_RowId.substr(1)})
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else{
        Gfunc.axiosResponse(response,()=>{
            handleAlert(true,'success',"L'élément est supprimé avec succès.")
            handleSetData(row,"DT_RowId")
            setDel(row.DT_RowId)
            setControl(!control)
        })
  }
}
},[Gfunc,handleSetData,handleAlert,control])

      //modifier un champs avec un select
    const handleSelect=useCallback((row,att,f=()=>{})=>{
      f()
      setSelecto(row[att])
      setItem(row.DT_RowId)
      setItemN(att)
    },[])

      //handle live search
    function liveSearch(e,search){
      if(param.liveSearch&&search.length>3){
      e.preventDefault();
      handleSearch();
      }else{ if (e.keyCode === 13) {
              e.preventDefault();
              handleSearch();
             }
            }
    }
    function liveSearch2(e) {
      handleSearch();
    }

    //fetch wallet
  const fetchWal= useCallback(async(serv="_wal.php", act = "gets") => {
    const response = await Gfunc.axiosGetAction(serv,act);
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
        setWalL(response.data.jData);  
      
    })
  },[Gfunc,handleAlert])
    
    //fetch wallet handler
  const handleOpenWal = useCallback(async () => {
    if(walL.length===0)
    fetchWal()
    },[walL,fetchWal])

    //open dialogue
    const handleClickOpen = () => { 
      props.handleOpenFrns()
      props.handleOpenUsers()
      handleOpenWal()
      setDoSearch(false)
      setPerPage(10);
      setCurrentPage(1);
      setOpen(true); 
      setFrns('')
      setMtn('');
      setCaisse('')
      setCaisseN('')
      setNote('')
      setFrnsN('')
      setData([])
    };
    

  const handleOnEditNote=useCallback(async(note,row,att,action)=>{
    const edt1=note
    var edt=Gfunc.DeleteSpace(edt1);
    if(row[att]!==edt){
      const attrib=row[att];
      const response=await librery.axiosEdit2(service,action,row,edt,att) 
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{row[att]=edt})
      if(response.data.err) {row[att]=attrib}
    }else {row[att]=edt}
    setSelecto('')
  },[librery,Gfunc,handleAlert]);

  const ExpanableComponent = ({ data }) => {
    const [noteB,setNoteB] = useState('');
    return (
        <div className='flex-wrapper' style={{padding:'5px',justifyContent:'center',width:'100%'}}>
          <Comment/>
          <TextareaAutosize  style={textAreaEdit2} onFocus={(e)=>{setNoteB(e.target.value)}} onChange={(e)=>{setNoteB(e.target.value);}} minRows={3} value={(noteB?noteB:(data.note)?data.note:'')} onBlur={e=>handleOnEditNote(noteB,data,"note",(data.type==='achat')?'updbon':'updver')}/>
        </div>
    )
}


const conditionalRowStyles=[
  { when: row => row.currency<=0,
     style: {
           color: "red",
     }
   },
  { when: row => row.currency>0,
     style: {
           color: 'green',
     }
   }
]

    //close dialogue
    const handleClose = () => { setOpen(false); handleReset()};

    const handleChangeWal= async(idw) =>{
      fetch(1,service,perPage,idw)
    }
  
    const handleReset= async() =>{
      setDoSearch(false)
      setPerPage(10);
      setCurrentPage(1);
      setCaisse('')
      setCaisseN('')
      setMtn('');
      setRate('');
      setCrncy('');
      setNote('')
      setData([])
      setFrns('')
      setFrnsN('')
      setWal('')
      setWalN('')
      setReset(!reset);
    }
    const handleSubmit= async(e) =>{
      const data={
        date:Gfunc.formatDate(new Date(selectedDate)),
        mtn:parseFloat(mtn),
        frns:parseInt(frns),
        user:parseInt(caisse),
        currency:parseInt(crncy),
        rate:parseFloat(rate),
        walletId:parseInt(wal),
        note:Gfunc.stringToNull(note),
      }
      const respon=await Gfunc.axiosPostAction(service,"recharge",data)
      if(respon.data.err&&respon.data.err!=='-1'){
        handleAlert(true,'error',respon.data.err)
      }else
      Gfunc.axiosResponse(respon,()=>{
        if(currentPage===1)
         fetch(1,service,perPage,wal)
        else setResetDP(!resetDP)
         setMtn('');
         setNote('')
         handleAlert(true,'success',"L'alimentation du wallet est effectuée avec succès")
         document.getElementById('iMtn').focus();
      })
    }
    const columns =useMemo(
      () => [
        {
          name: "DATE",
          minWidth:'100px !important',
          selector: row=>row.date,
          sortable: true,
          sortField:'date',
          cell:(row)=>{
            return(
                <div>
                  <DatePickerUpd selecto={selecto} Item={Item} ItemN={ItemN} row={row}
                setSelecto={setSelecto}
                name="date"
                service={service} action={(row.type==='achat')?'updbon':'updver'} extra=''
                handleSelect={handleSelect} setItem={setItem} handleAlert={handleAlert}/>
              
            </div>
            )
        }
      },
      {
        name: "Agent",
        minWidth:'150px !important',
        selector: row=>row.agent,
        sortable: true,
        sortField:'agent',
      },
      {
        name: "Devise",
        style:numberColumn,
        conditionalCellStyles:mtnControlFourni,
        selector: row=>row.currency,
        width:'150px !important',
        sortable: true,
        sortField:'currency',
        cell: (row) => {
          return (
              <div id={"c"+row.DT_RowId}>{Gfunc.formatterTo2.format(row.currency)} </div>
           );
         },
    },
    {
      name: "solde",
      style:numberColumn,
      minWidth:'150px !important',
      selector: row=>row.solde,
      sortable: true,
      sortField:'solde',
      cell: (row) => {
        return (
            <div id={"s"+row.DT_RowId}>{Gfunc.formatterTo2.format(row.solde)}</div>
        );
      },
  },
  {
      width:"45px",
      cell: (row) => {
          return (
            (row.type==="allimentation")?
            <div id="icons" >
              <div onClick={()=>{handleDelete(row)}} title="Supprimmer"><IndeterminateCheckBox  fontSize="small" className={classes.redIcon}/> </div></div>
              :null
           );
         },
   } 
      ],[classes,handleDelete,handleSelect,selecto,Item,ItemN,Gfunc.formatterTo2,handleAlert]);
  
  
  return (
    <div>
      <Button className={classes.topTabBtn} variant="contained" color="primary" onClick={handleClickOpen} startIcon={<Iconn path={mdiWalletPlus} color="black" size="18px"/>}>Alimenter wallets</Button>
      <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsBigFixed}
      >
        <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >{addTitle}</DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true} />
            <DialogContent className={classes.dialogueContent}>
            <fieldset className="fieldsetTop2" id="wal">
               <legend>Information de chargement:</legend>
               <ValidatorForm id="validate"  ref={useRef(null)} onSubmit={handleSubmit} onError={errors => console.log(errors)} >
                 <div className="rowGrid" id="row-1">
                  <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                              <KeyboardDatePicker
                              disableToolbar
                              margin='dense'
                              size="small"
                              className='smallText'
                              KeyboardButtonProps={{style:{padding:"0px"}}}
                              inputVariant="outlined"
                              variant="inline"
                              maxDate={new Date()}  
                              invalidDateMessage="Le format de la date est incorrect"   
                              maxDateMessage="La date ne doit pas être supérieure à la date maximale"
                              format="dd/MM/yyyy"
                              name="iDate"
                              id="date-picker-inline"
                              label={labelText1}
                              keyboardIcon={<Today className={classes.blackSmallIcon}/>}
                              value={selectedDate}
                              onChange={handleDateChange}
                              required />
                  </MuiPickersUtilsProvider> 
                  <Autocomplete
                                key={reset}
                                disableClearable
                                className='smallAuto'
                                id="iFrns"
                                onChange={(e,newValue) =>{
                                    setFrns(newValue.id)
                                    setFrnsN(newValue.name)
                                    }
                                  } 
                                options={props.frnsL}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) => option.id === value.id || value.id !==''}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={labelText2}
                                    variant='outlined'
                                    margin='dense'
                                    size="small"
                                    autoFocus 
                                    required/>
                                )}/>
                    <Autocomplete
                                disableClearable
                                className='smallAuto'
                                id="iWal"
                                onChange={(e,newValue) =>{
                                  //const idf=props.userL[Gfunc.getIndex(props.userL,newValue,"name")].id
                                    setWal(newValue.id)
                                    setWalN(newValue.name)
                                    handleChangeWal(newValue.id)
                                  }}
                                value={{id:parseInt(wal),name:walN}}
                                options={walL}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) => option.id=== value.id || value.id !==''}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={labelText6}
                                    variant='outlined'
                                    margin='dense'
                                    size="small"
                                    required/>
                                )} />  
                    <TextValidator margin="dense"  label={labelText7} size="small" type='number' value={crncy} variant="outlined"
                        className='smallText'
                        id="iDevise"
                        InputProps={{ inputProps: { min: 0 },
                        endAdornment:
                          <MonetizationOn fontSize="small" className={classes.blackSmallIcon} />,
                          classes: {adornedEnd: classes.adornedEnd}
                      }}
                        onChange={e =>{setCrncy(e.target.value);setMtn(parseInt(e.target.value)*rate)}} required/>
                    <TextValidator margin="dense"  label={labelText8} size="small" type='number' value={rate} variant="outlined"
                        className='smallText'
                        id="iRate"
                        InputProps={{ inputProps: { min: 0 ,step:"0.01"},
                        endAdornment:
                          <DollarExchange fill='black' style={{height:'25px',width:'25px',padding:"1px"}}/>,
                          classes: {adornedEnd: classes.adornedEnd}
                       }}
                        onChange={e =>{setRate(e.target.value);setMtn(parseFloat(e.target.value)*crncy)}} required/>
                          <Autocomplete
                                disableClearable
                                className='smallAuto'
                                id="iCaisse"
                                onChange={(e,newValue) =>{
                                  //const idf=props.userL[Gfunc.getIndex(props.userL,newValue,"name")].id
                                    setCaisse(newValue.id)
                                    setCaisseN(newValue.name)
                                  }}
                                value={{id:parseInt(caisse),name:caisseN}}
                                options={props.userL}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) => option.id=== value.id || value.id !=='' }
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={labelText4}
                                    variant='outlined'
                                    margin='dense'
                                    size="small"
                                    required/>
                                )} />  
                    </div>
                    <div className="rowGrid" id="row-2">
                    <TextValidator size="small" variant="outlined" label={labelText5} id="iNote" name="iNote"  value={note}
                        margin="dense"
                        className='smallNote'
                        onChange={e =>setNote(e.target.value)} 
                        multiline
                        rows={2} />
                    <TextValidator margin="dense"  label={labelText3} size="small" type='number' value={mtn} variant="outlined"
                        className='smallText'
                        disabled
                        id="iMtn"
                        InputProps={{ inputProps: { min: 0 ,step:"0.01"},
                        endAdornment:
                          <Dzd fill='black' style={{height:'25px',width:'25px',padding:"1px"}}/>,
                          classes: {adornedEnd: classes.adornedEnd}
                       }}
                        onChange={e =>setMtn(e.target.value)}/>
                    <Button className={classes.diagTabBtn} variant="contained" color="primary" onClick={handleSubmit} startIcon={<Add/>}>Ajouter</Button>
                    <Button className={classes.diagTabBtn} variant="contained" color="primary" onClick={handleReset} startIcon={<RotateLeft/>}>Réinitialiser</Button>
                    </div>
                </ValidatorForm>
                
            </fieldset>
            { (doSearch)?  <Paper className={classes.paperBody} elevation={3}>
       { /*<p className="paperTitle">Liste des bons d'achat</p>*/}
        <div className='table-header'>
            <div className="button-flex-wrapper"> </div>  
            
            <div className='search'>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Search className={classes.blackIcon} sx={{ color: 'action.active', mr: 1, my: 0.5 }} onClick={(e)=>liveSearch2(e)}/>
                      <TextField className="smallText" id="searchBar" variant="outlined" label="Recherche" value={search} size="small" margin='dense' onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} />
                   </Box>
                </div>
            </div> 
            <div id={(data.length<=10)?"versDT1":"versDT"}>  
                <DataTable 
                  key={control}
                  persistTableHead
                  columns={columns}
                  data={data}
                  noDataComponent={param.ErreurData}
                  paginationRowsPerPageOptions={[10,15,20,30,50]}
                  progressPending={loading}
                  progressComponent={<CircularProgress className={classes.circularProgress} />}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  //paginationDefaultPage={currentPage}
                  paginationPerPage={10}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  sortServer={true}
                  onSort={handleOnSort}
                  expandableRows 
                  expandableRowsComponent={ExpanableComponent} 
                  //onRowClicked={handleRowClick} //declanche une fonction quand on clique sur une ligne
                  paginationResetDefaultPage={resetDP}
                  noHeader={true}
                  highlightOnHover
                  customStyles={customStyles}
                  paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}            
                  conditionalRowStyles = { conditionalRowStyles }
                /></div>  
            </Paper>:null}
            </DialogContent>
            <DialogActions>
            <Button variant="contained" onClick={handleClose} color="secondary">FERMER</Button>
            </DialogActions>
      </Dialog>
      </div>
  );
}