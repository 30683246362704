import React,{useState,useMemo,useCallback} from 'react';
import { DialogActions, DialogContent, DialogTitle, Paper, Button, Dialog, TextField } from '@material-ui/core';
import DataTable from "react-data-table-component";
import Draggable from 'react-draggable';
import {IndeterminateCheckBox,SupervisorAccount,Add} from '@material-ui/icons';
import {axiosResponse,axiosPostAction,removeItemsByAtt} from'../../../Gfunc'
import { useStyles, paperProps3 } from '../../../css/muiStyle';
import {customStyles}from '../../../css/datatableStyle'
import {AlertCompo} from '../../../js/components'
import { Autocomplete } from '@material-ui/lab';


//the dialog that adds users to a role**********
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

function Adm_rol_usr(props) {
    const param=props.param
    const [open, setOpen] =useState(false);
    const title="Les utilisateurs du "
    const serviceUsrs="_usr.php"
    const service="_rol.php"
    const [option, setOption] = useState([]);
    const [data, setData] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);
    const classes=useStyles();
    const [,setDel]=useState(-1);
    var users =[]
    const [severity, setSeverity] =useState("info");
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');

   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpenA(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpenA(false)
    }, 5000);
  }
    

    //fetch the users that have the selected role
    const fetch= useCallback(async() => {
      const dat={idr:props.row.DT_RowId.substr(1),unsets:false}
      const response=await axiosPostAction(service,"getrusrs",dat)
      if(response.data.response&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else{
      axiosResponse(response,()=>{
      setData(response.data.jData);
      })
    }
    },[props.row.DT_RowId])

    //fetch all users that doesn't have the selected role
    const fetchUsers=useCallback(async() => {
      const dat={idr:props.row.DT_RowId.substr(1),unsets:true}
      const response=await axiosPostAction(service,"getrusrs",dat)
      if(response.data.err&&response.data.err!=='-1'){
              handleAlert(true,'error',response.data.err)
      }else{
        axiosResponse(response,()=>{
        setOption(response.data.jData);
    })
  }
    },[props.row.DT_RowId])

    //dialog functions************************
    const handleClickOpen = () => { 
      if(option.length===0)
      fetchUsers();
      if(data.length===0)
      fetch();
      setOpen(true); 
    };
    const handleClose = () => { setOpen(false); };



  
  //Add users to role
  const handleAddUsr=useCallback(async() => {
      var idrss=[]
      selectedValues.map((opt)=>{
        idrss.push(opt.id) 
        return true 
      })
      const response= await axiosPostAction(service ,"setrusers",{ idr:props.row.DT_RowId.substr(1), idus:idrss})
      if(response.data.err&&response.data.err!=='-1'){
         handleAlert(true,'error',response.data.err)
      }else{
        axiosResponse(response,()=>{
            handleAlert(true,'success','Le role a été attribués avec succès aux utilisateurs sélectionnés.')
            selectedValues.map((item)=>{
              removeItemsByAtt(option,item.id,"id")
              data.push(item)
              return true;
            })
        }) 
      }
        setSelectedValues([])
   },[data,option,selectedValues,service,props.row.DT_RowId])
   
   const handleSetData=useCallback((row,att)=>{
    removeItemsByAtt(data,row.id,att)
  },[data])
  
  //Delete users from role
  const handleDeleteUser=useCallback(async(row)=>{
    const response =await axiosPostAction(serviceUsrs,"delurole",{idu:row.id, idr:props.row.DT_RowId.substr(1)});
    if(response.data.err&&response.data.err!=='-1'){
       handleAlert(true,'error',response.data.err)
    }else{
    axiosResponse(response,()=>{
      handleAlert(true,'success',"L'utilisateur a été supprimé avec succès.")
       // option.push({id:row.id,name:row.name})
      handleSetData(row,"id")
      setDel(row.id)
      fetchUsers();
    })
  }
  },[handleSetData,serviceUsrs,fetchUsers,props])

  const columns =useMemo(
    () => [   
      {
          name: "UTILISATEURS",
          selector: row=>row.name,
          sortable: true,
      },
      {
        right: true,
        cell: (row) => {
          return (
            <div onClick={()=>handleDeleteUser(row)} title="Supprimer utilisateur">
              <IndeterminateCheckBox className={classes.redIcon} fontSize="small"/>
            </div>   
          );
         },
      }  
    ],[handleDeleteUser,classes]);
 
  return (
    <div>
            <SupervisorAccount className={classes.blackIcon} onClick={handleClickOpen} />
            <Dialog
                    open={open}
                    onClose={handleClose}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
        PaperProps={paperProps3}
                    id="diagRol"
            >
                <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >{title} <b>{props.row.role}</b></DialogTitle>
                <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true}/>
        <DialogContent className={"userRoleontainer " + classes.dialogueContent}>
                <fieldset className="fieldsetTop" id="Noc">
                  <legend>Ajouter des utilisateurs</legend>
                {(option.length!==0)?
              <div className="rowGrid" style={{ gridTemplateColumns: "auto 120px" }}>
                <Autocomplete
                  multiple
                  className='smallAuto'
                  id="tags-outlined"
                  options={option}
                  filterSelectedOptions
                  getOptionLabel={(option) => option.name}
                  value={selectedValues}
                  onChange={(event, newValue) => {
                    setSelectedValues(newValue);
                  }}
                  noOptionsText="Il n'y a plus d'options"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Utilisateurs"
                      placeholder="Selectioner les bundles"
                    />
                  )}
                />
                    <div><Button className={classes.topTabBtn} variant="contained" onClick={handleAddUsr} color="primary" startIcon={<Add/>}>AJOUTER</Button></div>
                    </div>:<></>
              }
              </fieldset>
          <div style={{ width: '92%', marginLeft: 'auto', marginRight: 'auto', overflow: "auto" }}>              
                <DataTable 
                  className="table"
                  columns={columns}
                  data={data}
                  noHeader={true}
                  customStyles={customStyles}
                  defaultSortAsc={true}
                  noDataComponent={param.ErreurData}    
                  highlightOnHover
                  paginationComponentOptions={{ rowsPerPageText: 'Element par page:', rangeSeparatorText: 'sur'}}               
                />
               </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" autoFocus onClick={handleClose} color="secondary">FERMER</Button>
                </DialogActions>
            </Dialog>
      </div>
  );
}
export default Adm_rol_usr;