import { makeStyles } from '@material-ui/core/styles';
import zIndex from '@material-ui/core/styles/zIndex';

export const useStyles = makeStyles((theme) => ({
  checkboxColor: {
    color: theme.palette.secondary.dark
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    color: "black",
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  circularProgress: {
    color: theme.palette.primary.main,
   },
   //for the check box
   checkStelect: {
    transform:"scale(0.8)",//to control the size
    "&.MuiCheckbox-colorSecondary": {
        color:'green',
    }, 
  },
  //mydesign
  topTabBtn: {
    textDecoration: 'none',
    padding: '0px 5px',
    margin: '2px 3px',
    border: "solid 1px gray",
    backgroundColor:'lightgrey',
    color:'black'
    // background:"#e0e0e0",
    // color:'black',
   // borderRadius:'6%',
   // wight:'bold'
  },
  smallBtn: {
    textDecoration: 'none',
    padding: '0px 15px !important',
    height:'25px',
    margin: '5px 3px 2px 3px !important',
    border: "solid 1px gray !important",
  },
  smallValidate: {
    textDecoration: 'none',
    padding: '0px 15px !important',
    height:'25px',
    margin: '2px 3px 2px 3px !important',
    border: "solid 1px gray !important",
  },
  fieldBtn: {
    textDecoration: 'none',
    padding: '8px 5px',
    paddingBottom:"10px",
    margin: '2px 3px',
    border: "solid 1px gray",
    minWidth:"100px",
    minHeight:"30px",
    height:"32px", 
    // background:"#e0e0e0",
    // color:'black',
   // borderRadius:'6%',
   // wight:'bold'
  },
  topTabLink: {
    textDecoration: 'none',
    padding: '0px 5px',
    margin: '2px 3px',
    border: "solid 1px gray",
    backgroundColor: theme.palette.secondary.main,
    color:'black',
    borderRadius:'10%',
    fontWeight:'normal'

  },
  topDivTitle: {
    width: 'calc(100% )!important',
    color:"white",
    cursor: 'move',
    background:theme.palette.primary.dark,
    textDecoration: 'none',
    padding: '0px 5px !important',
    margin: '0px !important',
    textAlign: "center"
  },
  textInput: {
    width: '90%',
    background:theme.palette.secondary.light,
    textDecoration: 'none',
    padding: '5px 10px',
    margin: '0px',
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
  },
  selectOpt: {
    width: '90%',
    //minWidth:"150px",
    background:theme.palette.secondary.light,
    textDecoration: 'none',
    padding: '5px 10px',
    margin: '0px',
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
  },
  tdIcon:{
    cursor:'pointer'
  },
  tdIconButton:{
    cursor:'pointer',
    background: "white",
    border: "0",
    height: "25px",
    marginTop: "7px",
    maxWidth: "25px",
    minWidth: "25px",
    boxShadow: "0 0",
  },
  iconBtn: {
    textDecoration: 'none',
    padding: '2px !important',
    height: 'fit-content !important',
    margin: '5px 3px 2px 3px !important',
    //border: "solid 1px gray !important",
    width: "fit-content !important",
    minWidth: "fit-content !important",
  },
  iconBtnBig: {
    textDecoration: 'none',
    padding: '2px !important',
    height: '27px !important',
    margin: '5px 1px!important',
    border: "none",
    width: "27px!important",
    minWidth: "0 !important",
    background: theme.palette.secondary.main,
  },
  styleBtn:{
    padding:'8px 14px',
  },
  TableForm:{
    width:'100px'
  },
  Dialog:{
    cursor: 'move', width: '400px' ,
    color:'red'
    },
    fieldSet:{
      margin: 10,
      paddingHorizontal: 10,
      paddingBottom: 10,
      borderRadius: 5,
      borderWidth: 1,
      alignItems: 'center',
      borderColor: '#000'
    },
    legend:{
        position: 'absolute',
        top: -10,
        left: 10,
        fontWeight: 'bold',
        backgroundColor: 'white'
    },
     //for accordion component
     accordMui:{
      '&.MuiAccordion-root':{
        //height:"40px",
        borderColor:theme.palette.primary.main,
        padding:"0px",
        fontSize:"15px",
        backgroundColor:"white",
        margin:"0px 30px"
      },
      '&.Mui-expanded':{
        color:theme.palette.secondary.dark,
        borderColor:theme.palette.primary.main,
        backgroundColor:theme.palette.secondary.main,
      }
    },
  
    //for switch components (all the following three classes )
    switch_track: {
      backgroundColor: theme.palette.secondary.dark,
  },
    switch_base: {
        color: "white",
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: theme.palette.yellow.dark,
        }
    },
    switch_primary: {
        "&.Mui-checked": {
            color: theme.palette.yellow.dark,
        },
    },
   paperHead:{
     background:theme.palette.secondary.light,  //"#fff176",
     border:theme.palette.secondary.main,
     width:"99%",
     height:"80px",
     padding:"5px",
     paddingLeft:"10px",
     marginLeft:"10px",
   },
   paperBody:{
     background:'#f2f2f2',  //"#fff176",
     width:"98%",
     height:"auto",
     margin:"6px auto",
     padding:'5px 0 10px 0',
   },
   paperBodyFlex:{
     background:'#f2f2f2',  //"#fff176",
     width:"98%",
     height:"auto",
     margin:"6px auto",
     padding:'5px 0 10px 0',
     display:"flex",
     overflow:"hidden"
   },
  paperBodyFlexOver: {
    background: '#f2f2f2',  //"#fff176",
    width: "98%",
    height: "auto",
    margin: "6px auto",
    padding: '5px 0 10px 0',
    display: "flex",
    overflow: "auto"
  },
   paperBody2:{
     background:theme.palette.secondary.light,  //"#fff176",
     width:"99%",
     height:"250px",
     paddingBottom:"10px",
     margin:"10px",
     marginTop:"20px"
   },
  //head textfield...
  headInput:{
    paddingRight:"10px",
    width:"100%",
    minHeight:"30px",
    height:"30px",
    minWidth:"100px",
  },
  input: {
    padding: "8px 14px",
    background:'white',
    "&:focus":{
      background: "white"
    },
  },
//datatable text field 
 dataheadInput:{
  paddingRight:"10px",
  marginBottom:"10px",
  minWidth:"120px",
  width:"auto",
  minHeight:"15px",
  height:"15px",
 },
 dataInput: {
  fontSize: 13,
  fontWeight: "bold",
  padding: "3px 0px",
  color:"black",
  background:theme.palette.secondary.main,
 },
 dataLabel:{
  fontSize: 13,
  fontWeight: "bold",
  color:"black",
  padding:"0px",
  margin:"0px",
  transform: "translate(14px, 6px) scale(1) !important"
},
headInputBon:{
  paddingRight:"10px",
  width:"35%",
  minHeight:"30px",
  height:"30px",
  marginLeft:'15px',
  minWidth:"100px",
},
 //form textfield
   formInput:{
    margin:"10px",
    paddingRight:"10px",
    marginBottom:"30px",
    width:"95%",
    minHeight:"30px",
    height:"30px",
    minWidth:"100px",
   },
   //search textfield...
   search:{
    background:"white",
    color:"black",
    marginLeft:'5px'
   },
   searchInput:{
    background:"white",
    color:"black",
   },
  //edited select textfield...
  selectEdit:{
    minHeight:"10px",
    minWidth:"50px", 
    width:"auto",
    height:"15px",
  },
  inputEdit: {
    fontSize:"13px",
    margin:"0px",
    padding: "0px 0px",
    paddingLeft:'10px',
    color:"white",
    height:"17px",
    background:theme.palette.primary.dark, 
    "&:focus":{
      background:theme.palette.primary.dark, 
      borderRadius:"5px"
    },
  },
  //Icons ...
  redIcon:{
    color:"red",
    cursor:"pointer"
  },
  greenIcon:{
    color:"green",
    cursor:"pointer"
  },
  blackIcon:{
    color:"black",
    cursor:"pointer"
  },
  dtIcon:{
    width:"22px !important",
    maxWidth:" 22px !important",
    minWidth:" 22px !important",
  },
  greenText:{
    width: "fit-content",
    height:"fit-content",
    color:"green",
  },
  gridRoot:{
      margin:"0px"
  },
    //accordion style
    accordionRoot:{
      width:"98%",
      margin:"auto",
      background:theme.palette.primary.light,
      border: `1px solid ${theme.palette.divider}`,
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&.Mui-expanded':{
        margin:'auto',
      }
    },
    accordionSummary:{
      height:"30px",
      minHeight:"30px",
      backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)': 'rgba(0, 0, 0, .03)',
      flexDirection: 'row-reverse',
      '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
      },
      '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
      },
      "&.MuiAccordionSummary-root":{
        '&.Mui-expanded':{
          height:"30px",
          minHeight:"30px",
        }
      }
    },
    accordionDetails:{
      padding: "5px 10px",
      background:theme.palette.secondary.main,
      borderTop: '1px solid rgba(0, 0, 0, .125)',
    },
    dialogueContent:{
        padding: "0 !important",
        overflow:"visible",
    },
     //edited select textfield...
  diagFormText:{
    minHeight:"10px !important",
    minWidth:"30px !important", 
    width:"98% !important",
    height:"23px !important", 
    marginTop:'0px !important',
    marginBottom:'0px !important',
    background:"white !important", 
    padding:'0px !important',
    borderRadius:'5px',
    "&.MuiTextField-root":{
      minHeight:"10px !important",
      minWidth:"30px !important", 
      width:"98% !important",
      height:"23px !important", 
      marginTop:'0 !important',
      marginBottom:'0 !important',
      background:"white !important", 
      padding:'0px !important',
      borderRadius:'5px',
    },
  },
  diagFormNoteText:{
    minHeight:"10px",
    minWidth:"30px", 
    width:"98%",
    height:"40px", 
    marginTop:'0px',
    "&.MuiTextField-root":{
      background:"white", 
      borderRadius:"5px",
      height:'40px',
    }
  },
  diagFormInput: {
    fontSize:"13px !important",
    margin:"0px !important",
    padding: "2px 0px !important",
    paddingLeft:'10px !important',
    color:"black !important",
    height:"19px !important",
    background:"white !important", 
    borderRadius:"5px !important",
    "&:focus":{
      background:"white !important", 
      borderRadius:"5px !important"
    },
  },
  FormInput: {
    fontSize:"13px !important",
    margin:"0px !important",
    padding: "2px 0px !important",
    paddingLeft:'10px !important',
    color:"black !important",
    height:"17px !important",
    background:"white !important", 
    borderRadius:"5px !important",
    "&:focus":{
      background:"white !important", 
      borderRadius:"5px !important"
    },
  },
  diagFormNoteInput: {
    fontSize:"13px",
    margin:"0px",
    padding: "3px 0px",
    paddingLeft:'10px',
    color:"black",
    height:"fit-parent",
    background:"white", 
    "&:focus":{
      background:"white", 
      borderRadius:"5px"
    },
  },
  diagFormLabel:{
    fontSize: "13px",
    fontWeight: "bold",
    color:"black",
    padding:"0px",
    margin:"0px",
    transform: "translate(14px, 6px) scale(1) !important"
  },
  diagTabBtn: {
    textDecoration: 'none',
    fontSize:"12px",
    padding: '0px 5px',
    margin: '0px 3px',
    height:"23px",
    minHeight:'10px',
    border: "solid 1px gray",
    // background:"#e0e0e0",
    // color:'black',
   // borderRadius:'6%',
   // wight:'bold'

  },
  backdrop: {
   // top:'67px',
    zIndex: theme.zIndex.drawer + 1 +"!important",
    color: '#fff',
  },
  backdropDiag: {
    zIndex: theme.zIndex.drawer + 1 +"!important",
    color: '#fff',
  },
  radioBtn:{
    padding: "0 5px",
    transform: "scale(0.75)"
 },
  //for editibale date....
  selectEdit2:{
    minHeight:"10px",
    minWidth:"50px", 
    width:"fit-content",
    height:"17px",
    "&.MuiTextField-root":{
      background:theme.palette.primary.dark, 
      borderRadius:"5px"
    }
  },
  inputEdit2: {
    fontSize:"13px",
    width:"80px",
    margin:"0px",
    padding: "0px 0px",
    paddingLeft:'10px',
    color:"white",
    height:"17px",
    //background:theme.palette.primary.dark, 
    "&:focus":{
      background:theme.palette.primary.dark, 
      borderRadius:"5px"
    },
    "&.MuiInputBase-root":{
      background:"green !important"
    }
  },
  checkStelectableRow: {
    transform:"scale(0.8)",
    "&.MuiCheckbox-root": {
      '&.Mui-checked':{
        color:"#1E90FF",
        padding:"0 !important"
      }
    }, 
  },
  checkGreen: {
    transform:"scale(0.8)",
    "&.MuiCheckbox-root": {
      '&.Mui-checked':{
        color:"green",
        padding:"0 !important"
      }
    }, 
  },
  whiteSmallIcon:{color:"white",fontSize:"15px"},
  blackSmallIcon:{fontSize:"20px",color:"black !important"},
  blackTinyIcon:{fontSize:"15px"},
  greyTinyIcon:{color:'#808080',fontSize:"14px"}
  
  }
  ));

export const paperPropsLarge = {
  style: {
    width: "80%",
    maxWidth: "2000px",
    maxHeight: "90%",
    // height:"auto",
    //overflow:"auto"
  }
}

export const paperProps3 = {
  style: {
    width: "600px",
    maxWidth: "2000px",
    maxHeight: "90%",
    // height:"auto",
    //overflow:"auto"
  }
}
export const paperProps = {
  style: {
    width: "600px",
    maxWidth: "2000px",
    maxHeight: "2000px",
    // height:"auto",
    //overflow:"auto"
  }
}
export const paperPropsFixed = {
  style: {
    width: "600px",
    maxWidth: "2000px",
    height: "400px",
    // height:"auto",
    //overflow:"auto"
  }
}

export const paperPropsTick = {
  style: {
    width: "650px",
    maxWidth: "2000px",
    maxHeight: "2000px",
    // height:"auto",
    //overflow:"auto"
  }
}
export const paperPropsRep = {
  style: {
    width: "900px",
    maxWidth: "2000px",
    maxHeight: "2000px",
    // height:"auto",
    //overflow:"auto"
  }
}
export const paperPropsBig = {
  style: {
    width: "900px",
    maxWidth: "2000px",
    maxHeight: "98%",
    // height:"auto",
    //overflow:"auto"
  }
}
export const paperPropsBig1 = {
  style: {
    width: "1100px",
    maxWidth: "1600px",
    maxHeight: "98%",
    // height:"auto",
    //overflow:"auto"
  }
}
export const paperPropsBigCaiss = {
  style: {
    width: "95%",
    maxWidth: "1600px",
    maxHeight: "98%",
    // height:"auto",
    //overflow:"auto"
  }
}
export const paperPropsSmall = {
  style: {
    maxWidth: "2000px",
    maxHeight: "1000px",
    // height:"1000px",
    width: "600px",
    height: "420px",
    overflow: "auto"
  }
}
export const paperPropsSmall2 = {
  style: {
    maxWidth: "2000px",
    maxHeight: "10000px",
    // height:"1000px",
    width: "750px",
    height: "fit-content",
    overflow: 'hidden'

  }
}
export const paperPropsSmallTall = {
  style: {
    maxWidth: "500px",
    maxHeight: "10000px",
    width: "500px",
    height: "fit-content",
    overflowX: 'hidden !important',
  }
}
export const paperPropsBigFixed = {
  style: {
    maxWidth: "1000px",
    maxHeight: "694px",
    //height:"1000px",
    width: "90%",
    height: "95%",
  }
}
export const paperPropsTicketDig = {
  style: {
    width: "260px",
    maxWidth: "600px",
    maxHeight: "95%",
    // height:"1000px",
    overflow: "auto",
    overflowX: "hidden"
  }
}
export const textAreaEdit = {
   background: "var(--primary-dark)",
   color: "white",
  borderColor: "var(--primary-dark)",
  width: '100%',
  borderRadius: '5px',
  opacity: "1",
  outline: "0",
}
export const textAreaEdit2 = {
   background: "var(--secondary-light)",
   color: "black",
  borderColor: "var(--secondary-dark)",
  borderRadius: '5px',
  opacity: "1",
  outline: "0",
  margin: '5px',
  padding: '5px',
  width: "400px",
  height: 'fit-content'
}

export const multiSelect = {
  multiselectContainer: { // To change css for multiselect (Width,height,etc..)
     background: "var(--secondary-light)",
     width: '100%',
     borderRadius: "10px"
   },
   searchBox: { // To change search box element look
     border: "1px solid #a9a6a6",
     fontSize: "10px",
     minHeight: "40px",
   },
   inputField: { // To change input field position or margin
     margin: "5px",
   },
   chips: { // To change css chips(Selected options)
     background: "var(--primary-main)",
   },
   optionContainer: { // To change css for option container 
     border: "1px solid #a9a6a6",
     boxShadow: "-3px 6px 9px -4px rgba(0,0,0,0.93) ",
     borderTop: 'none',
     fontSize: "13px",
     zIndex: "9000"
   },
   option: { // To change css for dropdown options 
     fontColor: "black !important",
     paddingLeft: "20px"
   },
   groupHeading: { // To chanage group heading style
   }
 }