import React,{useState,useRef} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog,TextField} from '@material-ui/core';
import Draggable from 'react-draggable';
import SaveIcon from '@material-ui/icons/Save';
import { ValidatorForm} from 'react-material-ui-form-validator';
import AddIcon from '@material-ui/icons/Add';
import {axiosPostAction,axiosResponse} from'../../../Gfunc'
import { useStyles,paperPropsSmallTall } from '../../../css/muiStyle';
import {AlertCompo} from '../../../js/components'

//add new marque
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

function Ref_mrq_add(props) {
    const [name,setName]=useState('');
    const [open, setOpen] = React.useState(false);
    const nom="marque"
    const addMessage="Ajouter une nouvelle "+nom
    const alertError="La "+nom+" est ajoutée."
    const labelText="Nom de la "+nom
    const service="_mrq.php"
    const classes=useStyles();
    const [severity, setSeverity] =useState("info");
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');

   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpenA(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpenA(false)
    }, 5000);
  }
    const handleClickOpen = () => { 
      setName('');
      setOpen(true); 
    };
    const handleClose = () => { setOpen(false); };
    
    const handleSubmit= async(e) =>{
      const data={
        name:name,
      }
        e.preventDefault();
        const respon=await axiosPostAction(service,"add",data)
        if(respon.data.err&&respon.data.err!=='-1'){
          handleAlert(true,'error',respon.data.err)
        }else{
        axiosResponse(respon,()=>{
                setOpen(false);
                if (respon.data.jData) props.handleAlert(true,'success',alertError);
                props.callback();
        })
      }
    }
  return (
    <div>
      <Button  className={classes.topTabBtn} variant="contained" color="primary" onClick={handleClickOpen} startIcon={<AddIcon/>}>NOUVELLE MARQUE</Button>
      <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmallTall}
      >
        <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >{addMessage}</DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true}/>
            <DialogContent id="mrqC">
            <ValidatorForm ref={useRef(null)} id="addMrq" onSubmit={handleSubmit} onError={errors => console.log(errors)} >
                    <TextField className='smallText' variant="outlined" size="small" label={labelText} id="iMrq" name="iMrqq"  value={name}
                        onChange={e =>setName(e.target.value)} 
                        autoFocus
                        required />
                        </ValidatorForm>
            </DialogContent>
            <DialogActions>
            <Button variant="contained" form="addMrq" type='submit' color="primary" startIcon={<SaveIcon />}>VALIDER</Button>
            <Button variant="contained" onClick={handleClose} color="secondary">FERMER</Button>
            </DialogActions>
      </Dialog>
      </div>
  );
}
export default Ref_mrq_add;