import React from 'react'

export default function Dj_Ticket({el,props,param}){

    return (
        <div style={{fontFamily:'Arial'}} >
        <div style={{width:'5.2cm',marginTop:'10px',display:'grid',gridTemplateColumns:(props.logo)?'50% 50%':'100% 0%'}}>
          <div style={{textAlign:(props.logo)?'start':'center'}}><img src={param.urlimg+"logo_djezzy.png"} style={{height:"40px"}}/></div>
          <div style={{textAlign:'end'}}>{(props.logo)?<img src={param.urlimg+'logo_ar.png'} style={{height:"40px"}}/>:null}</div>
        </div>
            <div id='p1' style={{fontSize:'12px',fontWeight:'bold'}}></div>
            <div style={{display:'block',textAlign:'start',fontSize:'11px'}}>
                <p style={{marginBottom:'3px'}}>ID TERMINAL : <b>{el.tiketId}</b></p>
                <p style={{marginBottom:'3px'}}>NOM COMMERCANT : <b>{props.owner}</b></p>
                <p style={{marginBottom:'3px'}}>Date : <b>{props.dataa[0]+' '+props.dataa[1]}</b></p>
            </div>
            <div id='p1' style={{fontSize:'12px',fontWeight:'bold'}}></div>
            <div style={{display:'grid',gridTemplateColumns:'48% 48%',fontSize:'11px',width:'5.2cm'}}>
                <div >
                    <p>N° Série :</p>
                    <p>Expire le : </p>
                    <p>Montant TTC :</p>
                </div>
                <div >
                    <p><b>{(el.serial)?el.serial:'--'}</b></p>
                    <p><b>{(el.expire)?el.expire:'--'}</b> </p>
                    <p><b>{(el.prix)?el.prix:0} DA</b></p>
                </div>
            </div> 
            <div style={{fontSize:'13px',margin:'5px 0',textAlign:'center',width:'5.2cm'}}><p ><b>Recharge Djezzy {el.prix} DA</b></p></div>
            <div id='p1' style={{fontSize:'12px',fontWeight:'bold'}}>
            {(props.copie===true)?'Duplicata':'CODE RECHARGE'}</div>
              <div style={{textAlign:'center',fontSize:'18px',width:'5cm',fontWeight:'bold'}}>{el.digit}</div>
            <div id='p1' style={{fontSize:'12px',fontWeight:'bold'}}></div>
            <div style={{fontSize:"12px",textAlign:"center",fontWeight:'bold',width:'5cm',}}>Composez</div>
            <div style={{fontSize:"12px",textAlign:"center",fontWeight:'bold',width:'5cm',}}>*700* les 14 chiffres #</div>
            <div style={{fontSize:"11px",textAlign:"center",marginTop:'5px',width:'5cm',}}>Djezzy n'est pas responsable des  </div>
            <div style={{fontSize:"11px",textAlign:"center",marginBottom:"50px",width:'5cm'}}>tickets non imprimes devant vous</div>


       </div>
    );
}