import React,{useState,useMemo,useCallback} from 'react';
import Multiselect from 'multiselect-react-dropdown';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DataTable from "react-data-table-component";
import { DialogActions, DialogContent, DialogTitle, Paper, TextField } from '@material-ui/core';
import Draggable from 'react-draggable';
import {Add ,SupervisorAccount,IndeterminateCheckBox} from '@material-ui/icons';
import { useStyles, paperProps3, multiSelect } from '../../../css/muiStyle';
import {customStyles}from '../../../css/datatableStyle'
import { AlertCompo } from '../../../js/components';
import { Autocomplete } from '@material-ui/lab';


//ajouter les  roles aux users
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Adm_usr_rol(props) {
    const [data,setData]=useState([]);
    const [option,setOption]=useState([]);
    const [open, setOpen] = useState(false);
    const param=props.param
    const Gfunc=require('../../../Gfunc')
    const service='_usr.php'
    const [selectedValues, setSelectedValues] = useState([]);
    const classes=useStyles();
    const [,setDel]=useState(-1);
    var role=[];
    const [severity, setSeverity] =useState("info");
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');

   //handle alerts properties
    const handleAlert=(open,severity,message)=>{
        setOpenA(open)
        setSeverity(severity)
        setMessageA(message)
        setTimeout(function(){
          setOpenA(false)
        }, 5000);
    }

          //get les roles d'un utilisateur
    const fetch=useCallback(async() => {
      const response=await Gfunc.axiosPostAction(service ,"geturols",{idu:props.row.DT_RowId.substr(1),unsets:false})
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{
        setData(response.data.jData)  
      })
    },[Gfunc,props.row.DT_RowId])

 //get les roles non associe a l'utilisateur 
    const fetchRoles= useCallback( async() => {
    const response=await Gfunc.axiosPostAction(service ,"geturols",{idu:props.row.DT_RowId.substr(1),unsets:true})
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
        setOption(response.data.jData)  
      })       
  },[Gfunc,props.row.DT_RowId])

  const handleSetData=useCallback((row,att)=>{
    Gfunc.removeItemsByAtt(data,row.id,att)
  },[Gfunc,data])
  

  //suppression d'un role
    const handleDeletRole=useCallback(async (row) => {
            const response= await Gfunc.axiosPostAction(service ,"delurole",{idu:props.row.DT_RowId.substr(1), idr:row.id})
            if(response.data.err&&response.data.err!=='-1'){
              handleAlert(true,'error',response.data.err)
            }else
            Gfunc.axiosResponse(response,()=>{
              handleSetData(row,"id")
              setDel(row.id)
              fetchRoles();
              handleAlert(true,"success",'Le rôle est supprimé')
            })
    },[Gfunc,props.row.DT_RowId,handleSetData,fetchRoles])


    //ajouter des role a un utilisateur
   const handleAddRole=useCallback(async() => {
    var idrss=[]
    selectedValues.map((opt)=>{
        idrss.push(opt.id)
        return null
      }
      )
     const response=await Gfunc.axiosPostAction(service ,"seturoles",{ idu:props.row.DT_RowId.substr(1), idrs:idrss})
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,'error',response.data.err)
        }else
        Gfunc.axiosResponse(response,()=>{
          handleAlert(true,'success',"Les rôles sont attribués à l'utilisateur sélectionné.")
          selectedValues.map((item)=>{
            Gfunc.removeItemsByAtt(option,item.id,"id")
            data.push(item)
            return true;
          })
        }) 
        setSelectedValues([])

  },[selectedValues,data,option,Gfunc,service,props])


    const columns =useMemo(
      () => [  
        {
            name: "Role",
            selector: row=>row.name,
            sortable: true,
        },
        {
            right: true,
            cell: (row) => {
                     
             return (
               <div onClick={()=>handleDeletRole(row)} title="Supprimer role"><IndeterminateCheckBox className={classes.redIcon} fontSize="small"/></div>        
             );
            },
      },
      ],[handleDeletRole,classes]);


      //ouvrir la fenetre de dialog
    const handleClickOpen = () => { 
      if(data.length===0)
      fetch();
      if(option.length===0)
      fetchRoles();
      setOpen(true); 
    };

    //fermer la fenetre de dialog
    const handleClose = () => { setOpen(false); };
  //selection des options


  return (
    <div>
    <SupervisorAccount className={classes.blackIcon} onClick={handleClickOpen}/>
      <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
        PaperProps={paperProps3}
            id="diagUser"
      >
        <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >Les roles de: <b>{props.row.nickname}</b></DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true}/>
        <DialogContent className={"userRoleontainer " + classes.dialogueContent}>
        <fieldset className="fieldsetTop" id="Noc">
        <legend>Ajouter des roles</legend>
        {(option.length!==0)?
              <div className="rowGrid" style={{ gridTemplateColumns: "auto 120px" }}>
                <Autocomplete
                  multiple
                  className='smallAuto'
                  id="tags-outlined"
                  options={option}
                  filterSelectedOptions
                  getOptionLabel={(option) => option.name}
                  value={selectedValues}
                  onChange={(event, newValue) => {
                    setSelectedValues(newValue);
                  }}
                  noOptionsText="Il n'y a plus d'options"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Roles"
                      placeholder="Selectioner les bundles"
                    />
                  )}
                />
            <div><Button className={classes.topTabBtn} variant="contained" onClick={handleAddRole} color="primary" startIcon={<Add/>}>AJOUTER</Button></div>
        </div>:<></>
        }
        </fieldset>
          <div id='' style={{ width: '92%', marginLeft: 'auto', marginRight: 'auto', overflow: "auto" }}>
            <DataTable 
                className="table"
                columns={columns}
                data={data}
                noHeader={true}
                defaultSortAsc={true}
                customStyles={customStyles}
                noDataComponent={param.ErreurData}
                highlightOnHover
                paginationComponentOptions={{ rowsPerPageText: 'Element par page:', rangeSeparatorText: 'sur'}}               
              />
          </div>
        </DialogContent>
        <DialogActions>
            <Button variant="contained" autoFocus onClick={handleClose} color="secondary">FERMER</Button>
        </DialogActions>
      </Dialog>
      </div>
  );
}
