import React,{useState,useRef, useCallback} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog, TextField, Tooltip, FormControlLabel, Checkbox} from '@material-ui/core';
import Draggable from 'react-draggable';
import SaveIcon from '@material-ui/icons/Save';
import { ValidatorForm} from 'react-material-ui-form-validator';
import { useStyles,paperPropsSmallTall } from '../../../css/muiStyle';
import {AlertCompo} from '../../../js/components'
import { Add } from '@material-ui/icons';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Ref_bdl_det(props) {
    const classes=useStyles();
    const Gfunc=require('../../../Gfunc')
    const [open, setOpen] = useState(false);
    const [check, setCheck] = useState(false);
    const [etape, setEtape] =useState('');
    const [sucess,setSucess] =useState('');
  const [sucess2, setSucess2] = useState('');
    const [Rsp, setRsp] =useState('');
  const [Rsp2, setRsp2] = useState('');
    const [disabled, setDisabled] =useState(false);
    const [severity, setSeverity] =useState("info");
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const service="_bdl.php"


   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpenA(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpenA(false)
    }, 5000);
  }
    const handleClickOpen = async() => { 
      const tab=await props.fetchDetail(props.row);
      const ind=(tab.length!==0)?tab[tab.length-1].step:-1
      const init=(ind!==-1)?ind+1:1;
      setEtape(init); setSucess(''); setSucess2(''); setRsp(''); setDisabled(false); setCheck(false);
      setOpen(true); 
    };
    
    const handleClose = () => { setOpen(false); };

    const handleSubmit= async() =>{
      const response = await Gfunc.axiosPostAction(service,"addrsp",{bundleId:parseInt(props.row.DT_RowId.substr(1)),step:etape,
        success: sucess, success2: sucess2, response: (Rsp !== '') ? Rsp : null, response2: (Rsp2 !== '') ? Rsp2 : null, passResponse: check
      })
      if(response.data.err){
          handleAlert(true,'error',response.data.err)
      }else{
            Gfunc.axiosResponse(response,()=>{
                if(response.data.jData){
                   //props.handleAlert(true,"success","Une nouvelle étape a été ajoutée")
                   setOpen(false) 
                   props.fetchDetail(props.row)
                }
         })
      }
        
    }

  return (
    <div>
      <Tooltip title={'Ajout une nouvelle étape à '+props.row.bundle}>
        <Add  onClick={handleClickOpen} style={{height:'18px',width:'18px'}}/>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        PaperProps={paperPropsSmallTall}
      >
        <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >Ajouter une nouvelle étape à {props.row.bundle}</DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true}/>
            <DialogContent>
                <ValidatorForm ref={useRef(null)} id="frnsForm" onSubmit={handleSubmit} onError={errors => console.log(errors)} > 
                    <TextField type='number' className='smallText' label="Ètape" size="small" value={etape} variant="outlined"
                               onChange={e =>setEtape(e.target.value)} required/>
                    <TextField className='smallText' label="Succès" size="small" value={sucess} variant="outlined"
                               onChange={e =>setSucess(e.target.value)} required/>
            <TextField className='smallText' label="Succès 2" size="small" value={sucess2} variant="outlined"
              onChange={e => setSucess2(e.target.value)} required />
                    <TextField className='smallText' label="Réponse" size="small" value={Rsp} variant="outlined"
                               onChange={e =>setRsp(e.target.value)} disabled={disabled}/>
            <TextField className='smallText' label="Réponse 2" size="small" value={Rsp2} variant="outlined"
              onChange={e => setRsp2(e.target.value)} disabled={disabled} />
                    <FormControlLabel label="Réponse pin" control={<Checkbox  checked={check} color="primary" 
                                          onChange={e=>{setCheck(e.target.checked);setDisabled(!disabled)}} inputProps={{ 'aria-label': 'controlled' }}  />}/> 
                   
              </ValidatorForm>
           </DialogContent>
            <DialogActions>
              <Button variant="contained" type='submit' form="frnsForm" color="primary" startIcon={<SaveIcon />}>VALIDER</Button>
              <Button variant="contained" onClick={handleClose} color="secondary">FERMER</Button>
            </DialogActions>
      </Dialog>
      </div>
  );
}
