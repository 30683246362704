import React,{useState} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog, TextField} from '@material-ui/core';
import Draggable from 'react-draggable';
import DataTable from "react-data-table-component";
import { useStyles,paperPropsFixed } from '../../../css/muiStyle';
import {customStyles, numberColumn}from '../../../css/datatableStyle'
import Icon from '@mdi/react';
import {mdiFormatListGroup} from '@mdi/js';
import { useCallback } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { AlertCompo } from '../../../js/components';


//add new marque
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Dig_qot_lst(props) {
    const classes=useStyles();
    const param=props.param
    const Gfunc=require('../../../Gfunc')
    const [open, setOpen] =useState(false);
    const [loading, setLoading] =useState(false);
    const [data, setData] =useState([]);
    const [prd, setPrd] =useState([]);
    const [usr, setUsr] =useState([]);
    const [tous, setTous] =useState([]);
    const [selectAg, setSelectAg] =useState('');
    const [selectProd, setSelectProd] =useState('');
    const [severity, setSeverity] =useState("info");
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const service='_prt.php'
  
     //handle alerts properties
     const handleAlert=(open,severity,message)=>{
      setOpen(open)
      setSeverity(severity)
      setMessageA(message)
      setTimeout(function(){
        setOpen(false)
      }, 5000);
    }
    const handleClickOpen = () => { 
      setOpen(true); 
      fetch();
      setSelectProd('');setSelectAg('');
    };

    const handleClose = () => { setOpen(false); };

    const fetch= async() => {
      setLoading(true)
      const response=await Gfunc.axiosGetAction(service,'getqusr')
      setLoading(false)
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else
        Gfunc.axiosResponse(response,()=>{
          if(response.data.jData){
            setData(response.data.jData);
            setTous(response.data.jData);
          }
      })
    }

    //fetch utilisateur
    const fetchUsr= useCallback(async(serv='_usr.php', act = "gets") => {
      const response = await Gfunc.axiosGetAction(serv,act);
      if(response.data.err){
          handleAlert(true,'error',response.data.err)
      }else{
          Gfunc.axiosResponse(response,()=>{
              response.data.jData.unshift({id:'empty',name:'Tous'})
              setUsr(response.data.jData);    
          })
      }
    },[Gfunc])

    //fetch utilisateur handler
    const handleOpenUsr= useCallback(async () => {
        if(usr.length===0) fetchUsr()
    },[usr,fetchUsr])
 
  //fetch products 
  const fetchPrd= useCallback(async(id) => {
    const response = await Gfunc.axiosGetAction("_prd.php","getstockqte")
    if(response.data.err){
      handleAlert(true,'error',response.data.err)
    }else{
        Gfunc.axiosResponse(response,()=>{
          if(response.data.jData){
            response.data.jData.unshift({id:'empty',nom:'Tous'})
            setPrd(response.data.jData);  
          } 
        })
    }
  },[Gfunc,props]);

  //handle the call for fetch products
  const handleOpenPrd=(event)=>{
      if(prd.length===0)  fetchPrd();
  }

    const columns = [
        {
          name: "Agent",
          selector: row=>row.agent,
          sortable: true,
          minWidth:'150px'
        },
        {
          name: "Produit",
          selector: row=>row.produit,
          sortable: true,
          minWidth:'200px'
        },
        {
          name: "Quota",
          style:numberColumn,
          selector: row=>row.quota,
          sortable: true,
          minWidth:'120px'
        },
      ];


    const filtrer=(val,attr)=>{
         if(attr==='agent'){
           setSelectProd('')
         }else{
           setSelectAg('')
         }
         var test=tous.filter(word => word[attr]===val)
         setData(test)        
    } 
   
    const handleChange=(val,attr)=>{
         if(val!=='Tous'){
          filtrer(val,attr)
         }else{setData(tous)}
       }

    const CostumCompo=()=>{
      return(
        <b style={{marginTop:'20px',marginBottom:'20px',fontSize:'20px'}}>Chargement...</b>
      )
    }

  return (
    <div>
      <Button className={classes.topTabBtn} variant="contained" color="primary" onClick={handleClickOpen} 
             startIcon={ <Icon path={mdiFormatListGroup} size={0.85} />}>Liste des QUOTAS</Button>
      <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsFixed}
      >
        <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" > Quotas des agents</DialogTitle>
        <DialogContent>
            <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} />
            <div id='filtre-qouta'>
                <Autocomplete
                    disableClearable
                    value={selectAg}
                    onChange={(e,newValue) =>{setSelectAg(newValue);handleChange(newValue,'agent')}}
                    onFocus={handleOpenUsr}
                    options={usr.map((option) => option.name)}
                    className="smallAutoFiltre"
                    renderInput={(params) =>  
                            <TextField {...params} margin='dense' size='small'  variant="outlined"  label="Agent" 
                                       SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                          />}
                />
                <Autocomplete
                    disableClearable
                    value={selectProd}
                    onChange={(e,newValue) =>{setSelectProd(newValue);handleChange(newValue,'produit')}}
                    onFocus={handleOpenPrd}
                    options={prd.map((option) => option.nom)}
                    className="smallAutoFiltre"
                    renderInput={(params) =>  
                            <TextField {...params} margin='dense' size='small'  variant="outlined"  label="Produit" 
                                       SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                          />}
                />
          </div>
              <DataTable 
                  className='datatable'
                  columns={columns}
                  data={data}
                  noDataComponent={param.ErreurData}
                  progressPending={loading}
                  progressComponent={<CostumCompo/>}
                  highlightOnHover
                  noHeader={true}
                  pagination
                  customStyles={customStyles}
                  paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}            
                />
            </DialogContent>
            <DialogActions>
            <Button variant="contained" onClick={handleClose} color="secondary">FERMER</Button>
            </DialogActions>
      </Dialog>
      </div>
  );
}