import React ,{useRef,useCallback,useState,useMemo, useEffect} from "react";
import DataTable from "react-data-table-component";
import {Button,CircularProgress,Backdrop,TextField, Tooltip} from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";
import { ValidatorForm} from 'react-material-ui-form-validator';
import { MuiPickersUtilsProvider,KeyboardDatePicker} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { fr } from "date-fns/locale";
import {Check, GetApp} from '@material-ui/icons';
import { customStyles} from '../../css/datatableStyle'
import { useStyles } from '../../css/muiStyle';
import  '../../css/reports.css'
import ReactExport from "react-export-excel";
import {AlertCompo, NoDataComponent} from '../../js/components'


export default function Rep_top (props){
    const lodashClonedeep = require('lodash.clonedeep');
    var date = new Date();
    const Gfunc=require('../../Gfunc')
    const param=props.paramf
    const classes = useStyles();
    const [loading,setLoading]=useState(false)
    const [clientsC,setClientsC]=useState([]);
    const [clientsM,setClientsM]=useState([]);
    const [clientsR,setClientsR]=useState([]);
    const [produitsC,setProduitsC]=useState([]);
    const [produitsM,setProduitsM]=useState([]);
    const [produitsR,setProduitsR]=useState([]);
    const [zonesC,setZonesC]=useState([]);
    const [zonesM,setZonesM]=useState([]);
    const [zonesR,setZonesR]=useState([]);
    const [countC,setCountC]=useState(0);
    const [countP,setCountP]=useState(0);
    const [countZ,setCountZ]=useState(0);
    const [selectDateD,SetSelectDateD]=useState(new Date(date.getFullYear(), date.getMonth(), 1))
  const [selectDateF, SetSelectDateF] = useState(new Date())
    const [cat,setCat]=useState([]);
    const [selectcat,setSelectCat]=useState('');
    const [prd,setPrd]=useState([]);
    const [selectprd,setSelectPrd]=useState('');
    const [mrq,setMrq]=useState([]);
    const [selectmrq,setSelectMrq]=useState('');
    const [zone,setZone]=useState([]);
    const [selectZone,setSelectZone]=useState('');
    const [idRowC,setIdRowC]=useState('');
    const [idRowP,setIdRowP]=useState('');
    const [idRowZ,setIdRowZ]=useState('');
    const [openA, setOpenA] =useState(false);
    const [message, setMessage] =useState('');
    const [severity, setSeverity] =useState('info');
    const [data,setData]=useState(false);
    const [trim,setTrim]=useState('');
    const [month,setMonth]=useState('');
    const [monthName,setMonthName]=useState('');
    const [ann,setAnn]=useState('');
    const [exterctData,setExterctData]=useState(false);
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    const [annees,setAnnees]=useState([]);
    const [mois,setMois]=useState([]);
    const qrt=[{id:1,name:"Q1"},{id:2,name:"Q2"},{id:3,name:"Q3"},{id:4,name:"Q4"}]
  const [qrts, setQrts] = useState([]);
    const messageC='Aucune donnée trouvée'
    const service='_rep.php'

   
   const conditionalRowStyles1 = [
      {
        when: row => row.id===idRowC,
        style: {
          backgroundColor: '#e4d5b7',
          color: '#990000',
        },
      },
    ];

   const conditionalRowStyles2 = [
      {
        when: row => row.produit===idRowP,
        style: {
          backgroundColor: '#e4d5b7',
          color: '#990000',
        },
      },
    ];

    const conditionalRowStyles3= [
      {
        when: row => row.zone===idRowZ,
        style: {
          backgroundColor: '#e4d5b7',
          color: '#990000',
        },
      },
    ];

    const handleAlert=(openA,severity,message)=>{
      setOpenA(openA)
      setSeverity(severity)
      setMessage(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }

     //recuperer les categories 
     const fetchCat= useCallback(async() => {
        const response = await Gfunc.axiosGetAction('_cat.php','gets');
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,'error',response.data.err)
        }else{
          Gfunc.axiosResponse(response,()=>{
                response.data.jData.unshift({id:'empty',name:'Toutes'})
                setCat(response.data.jData);   
          })
        }
    },[Gfunc]);

    //remplir Select Categorie 
    const handleOpenCat= useCallback(async () => {
       if(cat.length===0) fetchCat()
    },[cat,fetchCat])

    //recuperer les marque 
    const fetchMrq= useCallback(async() => {
        const response = await Gfunc.axiosGetAction('_mrq.php','gets');
        if(response.data.err&&response.data.err!=='-1'){
           handleAlert(true,'error',response.data.err)
        }else{
          Gfunc.axiosResponse(response,()=>{
                response.data.jData.unshift({id:'empty',name:'Toutes'})
                setMrq(response.data.jData);   
          })
        }
    },[Gfunc]);

    //remplir Select Categories 
    const handleOpenMrq= useCallback(async () => {
       if(mrq.length===0) fetchMrq()
    },[mrq,fetchMrq])

  //fetch products
  const fetchPrd= useCallback(async(serv="_prd.php", act = "gets",act2="getspromo") => {
    const response = await Gfunc.axiosGetAction(serv,act);
    const response2 = await Gfunc.axiosGetAction(serv,act2);
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
      if(response2.data.err&&response.data.err!=="-1"){
        handleAlert(true,'error',response.data.err)
      }else{
        if(response2.data.jData&&response.data.jData)
        { 
          var arr=response2.data.jData.concat(response.data.jData)
          arr.unshift({id:'empty',name:'Toutes'})
          setPrd(arr)
        }
        else {
              if(response2.data.jData) {
                response2.data.jData.unshift({id:'empty',name:'Toutes'})
                setPrd(response2.data.jData);
              }
              else {
                response.data.jData.unshift({id:'empty',name:'Toutes'})
                setPrd(response.data.jData);
              }
            }
      }    
    })
  }
  },[Gfunc])

    //remplir Select Categorie 
    const handleOpenPrd= useCallback(async () => {
       if(prd.length===0) fetchPrd()
    },[prd,fetchPrd])

    //recuperer les produits
    const fetchZone= useCallback(async() => {
        const response = await Gfunc.axiosGetAction('_cli.php','getszone');
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,'error',response.data.err)
        }else{
          Gfunc.axiosResponse(response,()=>{
                response.data.jData.unshift({id:'empty',name:'Toutes'})
                setZone(response.data.jData);   
          })
        }
    },[Gfunc]);

    //remplir Select Categorie 
    const handleOpenZone= useCallback(async () => {
       if(zone.length===0) fetchZone()
    },[zone,fetchZone])

     //handle get Year
     const getYears=useCallback(async()=>{
      const response= await Gfunc.axiosGetAction("_clo.php","getsyear");
      if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,"error",response.data.err);
      }else
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData){
          if (parseInt(response.data.jData[0]['year']) === date.getFullYear()) {
            const currentQuarter = Math.ceil((date.getMonth() + 1) / 3); // Calculate the current quarter
            setQrts(qrt.filter(quarter => quarter.id <= currentQuarter))
          }
            setAnnees(response.data.jData)
            setAnn(response.data.jData[0]['year'])
            if(response.data.jData.length>0){
              getMonths(response.data.jData[0]['year'])
            }
           // handleOpenMonth(response.data.jData[0]['year'])
        }
      })
     }, [mois, qrts])


  //fetch year handler
  const handleOpenYear= useCallback(async () => {
      if(annees.length===0) getYears()
  },[annees,getYears])

  //handle get months
  const getMonths = useCallback(async (y) => {
    var mnts = []
    if (y !== '' && y !== undefined) {
      const response = await Gfunc.axiosPostAction("_clo.php", "getsmonth", { year: y });
      if (response.data.err && response.data.err !== '-1') {
        handleAlert(true, "error", response.data.err);
      } else
        Gfunc.axiosResponse(response, () => {
          if (response.data.jData) {
  // setMois(response.data.jData)
            if (response.data.jData.length > 0) {
                setMois(response.data.jData);
                setMonth(response.data.jData[0].month)
                setMonthName(response.data.jData[0].monthName)
              mnts = response.data.jData
              }
          }
        })
    } else {
      handleAlert(true, 'error', 'Vous devez choisir une année en amont')
    }
    return mnts
  }, [])

  //handle get months
  const getMonths2 = useCallback(async (y) => {
    var mnts = []
    if (y !== '' && y !== undefined) {
      const response = await Gfunc.axiosPostAction("_clo.php", "getsmonth", { year: y });
      if (response.data.err && response.data.err !== '-1') {
        handleAlert(true, "error", response.data.err);
      } else
        Gfunc.axiosResponse(response, () => {
          if (response.data.jData) {
            // setMois(response.data.jData)
            if (response.data.jData.length > 0) {
              setMois(response.data.jData);
              mnts = response.data.jData
            }
          }
        })
    } else {
      handleAlert(true, 'error', 'Vous devez choisir une année en amont')
    }
    return mnts
  }, [])


useEffect(()=>{
  handleOpenYear();
},[handleOpenYear])
    

    const handleSubmit= async() =>{
        setClientsC([]);setClientsM([]);setProduitsC([]);setProduitsM([]);setZonesC([]);setZonesM([]);
        setCountC(0);setCountP(0);setCountZ(0);
        setLoading(true)
        var marque='';var produit='';var categorie='';var Zone='';
        var hasC=0;var hasp=0;var hasZ=0;

        var indM=mrq.findIndex((obj => obj.name ===selectmrq));
        if(indM!==-1){ marque=mrq[indM].id}

        var indP=prd.findIndex((obj => obj.name ===selectprd));
        if(indP!==-1){produit=prd[indP].id}

        var indC=cat.findIndex((obj => obj.name ===selectcat));
        if(indC!==-1){categorie=cat[indC].id}

        var indZ=zone.findIndex((obj => obj.name ===selectZone));
        if(indZ!==-1){Zone=zone[indZ].id}
        const response=await Gfunc.axiosPostAction(service,"gettop",{date1:Gfunc.formatDate(selectDateD), date2:Gfunc.formatDate(selectDateF),brand:(marque!=='empty')?marque:'',produit:(produit!=='empty')?produit:'',
                                                              categorie:(categorie!=='empty')?categorie:'',zone:(Zone!=='empty')?Zone:''})
        setLoading(false)
        if(response.data.err&&response.data.err!=='-1'){
            handleAlert(true,'error',response.data.err)
        }else{
            Gfunc.axiosResponse(response,()=>{
               //topClient
              var arrCC=lodashClonedeep(response.data.jData.topClient)
              var arrMC=lodashClonedeep(response.data.jData.topClient)
              var arrRC=lodashClonedeep(response.data.jData.topClient)
              response.data.jData.topClient.map((el)=>{
                if(el.hasOwnProperty('mben')){
                  hasC=hasC+1;
                }
              })
              var ordCC=arrCC.sort(function (a, b) {return b.ca - a.ca;}) 
              var ordMC=arrMC.sort(function (a, b) { return b.mben - a.mben;}) 
              var ordRC=arrRC.sort(function (a, b) { return b.nbc - a.nbc;}) 
              ordCC.map((el,index)=>{
                el['index']=index+1
                ordMC[index]['index']=index+1
                ordRC[index]['index']=index+1
              })

              //topProduit
              var arrCP=lodashClonedeep(response.data.jData.topProduit)
              var arrMP=lodashClonedeep(response.data.jData.topProduit)
              var arrRP=lodashClonedeep(response.data.jData.topProduit)
              response.data.jData.topProduit.map((el)=>{
                if(el.hasOwnProperty('mben')){
                  hasp=hasp+1;
                }
              })
              var ordCP=arrCP.sort(function (a, b) {return b.ca - a.ca;}) 
              var ordMP=arrMP.sort(function (a, b) {return b.mben - a.mben;}) 
              var ordRP=arrRP.sort(function (a, b) {return b.nbc - a.nbc;}) 
              ordCP.map((el,index)=>{
                el['index']=index+1
              })
              ordMP.map((el,index)=>{
                el['index']=index+1
                ordRP[index]['index']=index+1
              })
              /*ordRP.map((el,index)=>{
                el['index']=index+1
              })*/

              //topZone
              var arrCZ=lodashClonedeep(response.data.jData.topZone)
              var arrMZ=lodashClonedeep(response.data.jData.topZone)
              var arrRZ=lodashClonedeep(response.data.jData.topZone)
              response.data.jData.topZone.map((el)=>{
                if(el.hasOwnProperty('mben')){
                  hasZ=hasZ+1;
                }
              })
              var ordCZ=arrCZ.sort(function (a, b) {return b.ca - a.ca;}) 
              var ordMZ=arrMZ.sort(function (a, b) {return b.mben - a.mben;}) 
              var ordRZ=arrRZ.sort(function (a, b) {return b.nbc - a.nbc;}) 
              ordCZ.map((el,index)=>{
                el['index']=index+1
              })
              ordMZ.map((el,index)=>{
                el['index']=index+1
                ordRZ[index]['index']=index+1
              })

              setCountC(hasC);
              setCountP(hasp)
              setCountZ(hasZ)

              setClientsC(ordCC)
              setClientsM(ordMC)
              setClientsR(ordRC)
              setProduitsC(ordCP)
              setProduitsM(ordMP)
              setProduitsR(ordRP)
              setZonesC(ordCZ)
              setZonesM(ordMZ)
              setZonesR(ordRZ)
              setData(true)
              setExterctData(response.data.jData.extractData)
            })
        }
    }


    const columnsCC =useMemo(
        () => [ 
          {
            name: "",
            selector: row=>row.index,
            width:'50px !important'
          }, 
          {
            name: "Client",
            selector: row=>row.zone+' '+row.commercant,
            minWidth:"250px !important",
            sortable: true, 
          }, 
          {
            name: "Quantité",
            selector: row=>row.ca,
            width:"100px !important",
            sortable: true, 
            cell: (row) => {
                return (
                  <div >
                    {new Intl.NumberFormat().format(row.ca)} 
                   </div>
                )
            }
          }, 
        ],[]);

    const columnsCM =useMemo(
        () => [
          {
            name: "",
            selector: row=>row.index,
            width:'50px !important'
          },  
          {
            name: "Client",
            selector: row=>row.zone+' '+row.commercant,
            minWidth:"250px !important",
            sortable: true, 
          }, 
          {
            name: "Marge",
            selector: row=>row.mben,
            width:"100px !important",
            sortable: true, 
            cell: (row) => {
                return (
                  <div >
                    {new Intl.NumberFormat().format(row.mben)} 
                   </div>
                )
            }
          }, 
        ],[]);

    const columnsCR =useMemo(
        () => [
          {
            name: "",
            selector: row=>row.index,
            width:'50px !important'
          },  
          {
            name: "Client",
            selector: row=>row.zone+' '+row.commercant,
            minWidth:"250px !important",
            sortable: true, 
          }, 
          {
            name: "transaction",
            selector: row=>row.nbc,
            width:"100px !important",
            sortable: true, 
            cell: (row) => {
                return (
                  <div >
                    {new Intl.NumberFormat().format(row.nbc)} 
                   </div>
                )
            }
          }, 
        ],[]);

    const columnsPC =useMemo(
        () => [ 
          {
            name: "",
            selector: row=>row.index,
            width:'50px !important'
          }, 
          {
            name: "Produit",
            selector: row=>row.produit,
            minWidth:"200px !important",
            sortable: true, 
            cell: (row) => {
              return (
                <Tooltip title={row.produit}>
                  <div >
                    {row.produit} 
                  </div>
                 </Tooltip>
              )
            }
          }, 
          {
            name: "Marque",
            selector: row=>row.brand,
            width:"120px !important",
            sortable: true, 
          }, 
          {
            name: "Quantité",
            selector: row=>row.ca,
            width:"100px !important",
            sortable: true, 
            cell: (row) => {
                return (
                  <div >
                    {new Intl.NumberFormat().format(row.ca)} 
                   </div>
                )
            }
          }, 
        ],[]);

    const columnsPM =useMemo(
        () => [ 
          {
            name: "",
            selector: row=>row.index,
            width:'50px !important'
          }, 
          {
            name: "Produit",
            selector: row=>row.produit,
            minWidth:"200px !important",
            sortable: true, 
            cell: (row) => {
              return (
                <Tooltip title={row.produit}>
                  <div >
                    {row.produit} 
                  </div>
                 </Tooltip>
              )
            }
          }, 
          {
            name: "Marque",
            selector: row=>row.brand,
            width:"120px !important",
            sortable: true, 
          }, 
          {
            name: "Marge",
            selector: row=>row.mben,
            width:"100px !important",
            sortable: true, 
            cell: (row) => {
                return (
                  <div >
                    {new Intl.NumberFormat().format(row.mben)} 
                   </div>
                )
            }
          }, 
        ],[]);

    const columnsPR =useMemo(
        () => [ 
          {
            name: "",
            selector: row=>row.index,
            width:'50px !important'
          }, 
          {
            name: "Produit",
            selector: row=>row.produit,
            minWidth:"200px !important",
            sortable: true, 
            cell: (row) => {
              return (
                <Tooltip title={row.produit}>
                  <div >
                    {row.produit} 
                  </div>
                 </Tooltip>
              )
            }
          }, 
          {
            name: "Marque",
            selector: row=>row.brand,
            width:"120px !important",
            sortable: true, 
          }, 
          {
            name: "Transaction",
            selector: row=>row.nbc,
            width:"100px !important",
            sortable: true, 
            cell: (row) => {
                return (
                  <div >
                    {new Intl.NumberFormat().format(row.nbc)} 
                   </div>
                )
            }
          }, 
        ],[]);

    const columnsZC =useMemo(
        () => [ 
          {
            name: "",
            selector: row=>row.index,
            width:'50px !important'
          }, 
          {
            name: "Zone",
            selector: row=>row.zone,
            minWidth:"100px !important",
            sortable: true, 
          }, 
          {
            name: "Description",
            selector: row=>row.description,
            minWidth:"150px !important",
            sortable: true, 
          }, 
          {
            name: "Quantité",
            selector: row=>row.ca,
            width:"110px !important",
            sortable: true, 
            cell: (row) => {
                return (
                  <div >
                    {new Intl.NumberFormat().format(row.ca)} 
                   </div>
                )
            }
          }, 
        ],[]);

    const columnsZM =useMemo(
        () => [ 
          {
            name: "",
            selector: row=>row.index,
            width:'50px !important'
          }, 
          {
            name: "Zone",
            selector: row=>row.zone,
            minWidth:"100px !important",
            sortable: true, 
          }, 
          {
            name: "Description",
            selector: row=>row.description,
            minWidth:"150px !important",
            sortable: true, 
          }, 
          {
            name: "Marge",
            selector: row=>row.mben,
            width:"110px !important",
            sortable: true, 
            cell: (row) => {
                return (
                  <div >
                    {new Intl.NumberFormat().format(row.mben)} 
                   </div>
                )
            }
          }, 
        ],[]);

    const columnsZR =useMemo(
        () => [ 
          {
            name: "",
            selector: row=>row.index,
            width:'50px !important'
          }, 
          {
            name: "Zone",
            selector: row=>row.zone,
            minWidth:"100px !important",
            sortable: true, 
          }, 
          {
            name: "Description",
            selector: row=>row.description,
            minWidth:"150px !important",
            sortable: true, 
          }, 
          {
            name: "transaction",
            selector: row=>row.nbc,
            width:"110px !important",
            sortable: true, 
            cell: (row) => {
                return (
                  <div >
                    {new Intl.NumberFormat().format(row.nbc)} 
                   </div>
                )
            }
          }, 
        ],[]);

  const handleChangeMon = (newValue, year) => {
          setMonth(newValue.month)
          setMonthName(newValue.monthName)
    const cureentDay = new Date()
    const cureentMonth = cureentDay.getMonth() + 1
          setTrim('');
          var debmois=parseInt(newValue.month)
    var fin = cureentDay
    if (parseInt(year ?? ann) === cureentDay.getFullYear()) {
      if (debmois < cureentMonth) {
        fin = Gfunc.DateFinMonth(debmois, year ?? ann)
      }
    } else fin = Gfunc.DateFinMonth(debmois, year ?? ann)
    var deb = Gfunc.DateDebMonth(debmois, year ?? ann)

          SetSelectDateD(deb)
          SetSelectDateF(fin)
        }
      
  const handleChangeTrim = (semester, year) => {
    setMonth('');
    setMonthName('');
    setTrim(semester)
    var debtrim = qrt[Gfunc.getIndex(qrt, semester, "name")].id
    var days = Gfunc.TrimDebFin(debtrim, year ?? ann)
    SetSelectDateD(days[0])
    if (days[1] > date)
      SetSelectDateF(date)
    else
      SetSelectDateF(days[1])
  }

  const handleChangeAnn = async (ann) => {
    setAnn(ann)
    const mnts = await getMonths2(ann)
    if (parseInt(ann) === date.getFullYear()) {
      const currentQuarter = Math.ceil((date.getMonth() + 1) / 3); // Calculate the current quarter
      setQrts(qrt.filter(quarter => quarter.id <= currentQuarter))

      if (trim !== "") {
        if (parseInt(trim.substr(1)) >= currentQuarter) handleChangeTrim("Q" + currentQuarter, ann)
      } else
        if (mnts.length > 0) {
          handleChangeMon({ month: mnts[0].month, monthName: mnts[0].monthName }, ann)
        }
    } else {
      if (qrts.length < 4) setQrts([...qrt])


    //handleOpenMonth(ann)
      if (trim !== "") {
        handleChangeTrim(trim, ann)
      } else
        if (mnts.length > 0) {
          handleChangeMon({ month: mnts[0].month, monthName: mnts[0].monthName }, ann)
        }

    }
  }

    return (
    <div className='content'>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={message}/>
        <Backdrop className={classes.backdrop} open={loading} >
             <CircularProgress color="inherit" />
        </Backdrop>
       <fieldset className="fieldsetTop" id="consom">
        <legend>Classements des consommations</legend>
        <div id='row-re'>
        <Autocomplete
                    disableClearable
                    value={ann}
                    onChange={(e,newValue) =>{handleChangeAnn(newValue);}}
                    options={annees.map((option) => option.year)}
                    className='smallAuto' 
                    renderInput={(params) =>  
                        <TextField margin='dense' 
                            size='small'{...params} variant="outlined"  label="Année..." SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                        />}
                />
            <Autocomplete
                    disableClearable
                    value={trim}
              onChange={(e, newValue) => { handleChangeTrim(newValue); }}
              options={qrts.map((option) => option.name)}
                    className='smallAuto' 
                    renderInput={(params) =>  
                        <TextField margin='dense' 
                            size='small'{...params} variant="outlined"  label="Trimestre..." SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                        />}
                />
            <Autocomplete
                    disableClearable
                    value={{month:month,monthName:monthName}}
                    onChange={(e,newValue) =>{handleChangeMon(newValue);}}
                    options={mois}
                    getOptionLabel={(option) => option.monthName}
                    getOptionSelected={(option) => option.month}
                    className='smallAuto' 
                    renderInput={(params) =>  
                        <TextField margin='dense' 
                            size='small'{...params} variant="outlined"  label="Mois..." SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                        />}
                />

        </div>
        <ValidatorForm  id="formAdd"  ref={useRef(null)} onSubmit={handleSubmit} >
            <div className="rowGrid" id="row-1">
              <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                   <KeyboardDatePicker disableToolbar value={selectDateD} variant="inline" placeholder="01/01/2020" onChange={date => SetSelectDateD(date)}
                                       minDate='10/10/2018' format="dd/MM/yyyy" className='smallText' margin="dense" inputVariant="outlined"
                                       invalidDateMessage="Le format de la date est incorrect" 
                               />
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                   <KeyboardDatePicker disableToolbar value={selectDateF} variant="inline" placeholder="10/10/2018" onChange={date => SetSelectDateF(date)}
                                        minDate="01/01/2018" format="dd/MM/yyyy" className='smallText' margin="dense" inputVariant="outlined"
                                        invalidDateMessage="Le format de la date est incorrect" 

                               />
              </MuiPickersUtilsProvider>
              <Autocomplete
                    disableClearable
                    onChange={(e,newValue) =>{setSelectCat(newValue)}}
                    value={selectcat}
                    options={cat.map((option) => option.name)}
                    onFocus={handleOpenCat}
                    className='smallAuto' 
                    renderInput={(params) =>  
                        <TextField margin='dense' 
                            size='small'{...params} variant="outlined"  label="Catégorie..." SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                        />}
                />
              <Autocomplete
                    disableClearable
                    onChange={(e,newValue) =>{setSelectMrq(newValue)}}
                    value={selectmrq}
                    options={mrq.map((option) => option.name)}
                    onFocus={handleOpenMrq}
                    className='smallAuto' 
                    renderInput={(params) =>  
                        <TextField margin='dense' 
                            size='small'{...params} variant="outlined"  label="Marque..." SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                        />}
                />
              <Autocomplete
                    disableClearable
                    onChange={(e,newValue) =>{setSelectPrd(newValue)}}
                    value={selectprd}
                    options={prd.map((option) => option.name)}
                    onFocus={handleOpenPrd}
                    className='smallAuto' 
                    renderInput={(params) =>  
                        <TextField margin='dense' 
                            size='small'{...params} variant="outlined"  label="Produit..." SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                        />}
                />
               <Autocomplete
                    disableClearable
                    onChange={(e,newValue) =>{setSelectZone(newValue)}}
                    value={selectZone}
                    options={zone.map((option) => option.name)}
                    onFocus={handleOpenZone}
                    className='smallAuto' 
                    renderInput={(params) =>  
                        <TextField margin='dense' 
                            size='small'{...params} variant="outlined"  label="Zone..." SelectProps={{ MenuProps: {closeAfterTransition:true } }}
                        />}
                />
              <Button  className={classes.smallBtn} variant="contained" tabIndex="3" type='submit' id="bouton" name="addBtn" color="primary" startIcon={<Check/>}>VALIDER</Button>
              {
                ((clientsC.length!==0||produitsC.length!==0||zonesC.length!==0)&&exterctData)?
                <ExcelFile  element={ 
                              <Button  className={classes.smallBtn} variant="contained" name="addBtn1" color="primary" 
                                        title='Exporter les Données' startIcon={<GetApp/>}>Exporter</Button> }>
                { 
                 (clientsC.length!==0)?
                  <ExcelSheet data={clientsC} name="Top client"  >
                    <ExcelColumn label="Client" value="commercant"/>
                    <ExcelColumn label="Zone" value="zone"/>
                    <ExcelColumn label="Consommation" value="ca" />
                    <ExcelColumn label="" value="" />
                    <ExcelColumn label="" value="" />
                    <ExcelColumn label="" value="" />
                    <ExcelColumn label="" value="" />
                    <ExcelColumn label="Client" value="commercant"/>
                    <ExcelColumn label="Zone" value="zone"/>
                    <ExcelColumn label="Marge" value="mben" />
                  </ExcelSheet>:<></>
                }
                { 
                 (produitsC.length!==0)?
                  <ExcelSheet data={produitsC} name="Top produit"  >
                    <ExcelColumn label="Produit" value="produit"/>
                    <ExcelColumn label="Marque" value="brand"/>
                    <ExcelColumn label="Consommation" value="ca" />
                    <ExcelColumn label="" value="" />
                    <ExcelColumn label="" value="" />
                    <ExcelColumn label="" value="" />
                    <ExcelColumn label="" value="" />
                    <ExcelColumn label="Produit" value="produit"/>
                    <ExcelColumn label="Marque" value="brand"/>
                    <ExcelColumn label="Marge" value="mben" />
                  </ExcelSheet>:<></>
                }
                { 
                 (zonesC.length!==0)?
                  <ExcelSheet data={zonesC} name="Top zone"  >
                    <ExcelColumn label="Zone" value="zone"/>
                    <ExcelColumn label="Description" value="description"/>
                    <ExcelColumn label="Consommation" value="ca" />
                    <ExcelColumn label="" value="" />
                    <ExcelColumn label="" value="" />
                    <ExcelColumn label="" value="" />
                    <ExcelColumn label="" value="" />
                    <ExcelColumn label="Zone" value="zone"/>
                    <ExcelColumn label="Description" value="description"/>
                    <ExcelColumn label="Marge" value="mben" />
                  </ExcelSheet>:<></>
                }
            </ExcelFile>:<></>
           }
            </div>
        </ValidatorForm>
        </fieldset>
        {
          (clientsC.length!==0)?
          <div id='div-tabs'>
          <div>
           <DataTable
              persistTableHead 
              title="Top client par consommation"
              columns={columnsCC}
              data={clientsC}
              defaultSortAsc={true}
             // noDataComponent={param.ErreurData}
              paginationRowsPerPageOptions={[10,30,50,100]}
              pagination
              highlightOnHover
              onRowClicked={(row)=>setIdRowC(row.id)}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
              customStyles={customStyles}   
              conditionalRowStyles={conditionalRowStyles1}
          />
          </div>
          {
           (countC>0)?
            <div>
             <DataTable
               persistTableHead 
               title="Top client par marge"
               columns={columnsCM}
               data={clientsM}
               defaultSortAsc={true}
               //noDataComponent={param.ErreurData}
               paginationRowsPerPageOptions={[10,30,50,100]}
               pagination
               highlightOnHover
               onRowClicked={(row)=>setIdRowC(row.id)}
               paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
               customStyles={customStyles}   
               conditionalRowStyles={conditionalRowStyles1}
             />
            </div>
           :<></>
          }
          {
           (countC>0)?
            <div>
             <DataTable
               persistTableHead 
               title="Top client par nombre de transactions"
               columns={columnsCR}
               data={clientsR}
               defaultSortAsc={true}
               //noDataComponent={param.ErreurData}
               paginationRowsPerPageOptions={[10,30,50,100]}
               pagination
               highlightOnHover
               onRowClicked={(row)=>setIdRowC(row.id)}
               paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
               customStyles={customStyles}   
               conditionalRowStyles={conditionalRowStyles1}
             />
            </div>
           :<></>
          }
        </div>:<></>
      }
        {
          (produitsC.length!==0)?
          <div id='div-tabs'>
          <div>
           <DataTable
              persistTableHead 
              title="Top produit par consommation"
              columns={columnsPC}
              data={produitsC}
              defaultSortAsc={true}
              //noDataComponent={param.ErreurData}
              paginationRowsPerPageOptions={[10,30,50,100]}
              pagination
              highlightOnHover
              onRowClicked={(row)=>setIdRowP(row.produit)}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
              customStyles={customStyles}   
              conditionalRowStyles={conditionalRowStyles2}
          />
          </div>
          {
           (countP>0)?
            <div>
             <DataTable
               persistTableHead 
               title="Top produit par marge"
               columns={columnsPM}
               data={produitsM}
               defaultSortAsc={true}
               //noDataComponent={param.ErreurData}
               paginationRowsPerPageOptions={[10,30,50,100]}
               pagination
               highlightOnHover
               onRowClicked={(row)=>setIdRowP(row.produit)}
               paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
               customStyles={customStyles}   
               conditionalRowStyles={conditionalRowStyles2}
             />
            </div>
           :<></>
          }
          {
           (countP>0)?
            <div>
             <DataTable
               persistTableHead 
               title="Top produit par nombre de transactions"
               columns={columnsPR}
               data={produitsR}
               defaultSortAsc={true}
               //noDataComponent={param.ErreurData}
               paginationRowsPerPageOptions={[10,30,50,100]}
               pagination
               highlightOnHover
               onRowClicked={(row)=>setIdRowP(row.produit)}
               paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
               customStyles={customStyles}   
               conditionalRowStyles={conditionalRowStyles2}
             />
            </div>
           :<></>
          }
        </div>:<></>
      }
        {
          (zonesC.length!==0)?
          <div id='div-tabs'>
          <div>
           <DataTable
              persistTableHead 
              title="Top zone par consommation"
              columns={columnsZC}
              data={zonesC}
              defaultSortAsc={true}
              //noDataComponent={param.ErreurData}
              paginationRowsPerPageOptions={[10,30,50,100]}
              pagination
              highlightOnHover
              onRowClicked={(row)=>setIdRowZ(row.zone)}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
              customStyles={customStyles}   
              conditionalRowStyles={conditionalRowStyles3}
          />
          </div>
          {
           (countZ>0)?
            <div>
             <DataTable
               persistTableHead 
               title="Top zone par marge"
               columns={columnsZM}
               data={zonesM}
               defaultSortAsc={true}
               //noDataComponent={param.ErreurData}
               paginationRowsPerPageOptions={[10,30,50,100]}
               pagination
               highlightOnHover
               onRowClicked={(row)=>setIdRowZ(row.zone)}
               paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
               customStyles={customStyles}   
               conditionalRowStyles={conditionalRowStyles3}
             />
            </div>
           :<></>
          }
          {
           (countZ>0)?
            <div>
             <DataTable
               persistTableHead 
               title="Top zone par nombre de transactions"
               columns={columnsZR}
               data={zonesR}
               defaultSortAsc={true}
               //noDataComponent={param.ErreurData}
               paginationRowsPerPageOptions={[10,30,50,100]}
               pagination
               highlightOnHover
               onRowClicked={(row)=>setIdRowZ(row.zone)}
               paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
               customStyles={customStyles}   
               conditionalRowStyles={conditionalRowStyles3}
             />
            </div>
           :<></>
          }
        </div>:<></>
      }
      {
         (data&&clientsC.length===0&&produitsC.length===0&&zonesC.length===0)?<NoDataComponent message={messageC}/>:<></>
      }
    </div>   
    );
    }