import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Nothing from './error';
import Empt from './empty';
import MainMenu from './main_menu';
import ParamContext from '../Context';
import version from '../version.json'; // Assuming version.json exports an array directly

function Pages(props) {
    const param = props.param;
    const isDesktopOrLaptop = useMediaQuery({ query: '(max-width: 850px)' });
    const Gfunc=require("../Gfunc")

   
    if (!Gfunc.isEmpty(props.menu)) {
        return (
            <div>
                <ParamContext.Provider value={param}>
                    <MainMenu men={props.menu} param={param} />
                    <Switch>
                        <Route
                            exact
                            path="/"
                            render={() => (
                                <Redirect to={`/${localStorage.getItem('cscope')}/${localStorage.getItem('scope')}`} />
                            )}
                        />
                        {Object.keys(props.menu).map((key, index) => {
                            const value = props.menu[key];
                            let Component = null;

                            if (value[2] === 2) {
                                try {
                                    Component = require(`./content/${value[1]}`).default;
                                } catch (ex) {
                                    Component = null;
                                }

                                return Component ? (
                                    <Route key={index} exact path={`/${value[0]}/${key}`} component={(props) => <Component {...props} param={param} />} />
                                ) : (
                                    <Route
                                        key={index}
                                        exact
                                        path={`/${value[0]}/${key}`}
                                        component={(props) => <Empt {...props} param={param} message="Page introuvable" hideButton={true} />}
                                    />
                                );
                            }

                            if (value[2] === 1) {
                                const navObjKey = Object.keys(props.menu)[Gfunc.navObj(props.menu, key, 1)];
                                const navObjValue = props.menu[navObjKey];

                                if (navObjValue[2] !== 1) {
                                    try {
                                        Component = require(`./content/${navObjValue[1]}`).default;
                                    } catch (ex) {
                                        Component = null;
                                    }

                                    return Component ? (
                                        <Route key={index} exact path={`/${key}`} component={(props) => <Component {...props} param={param} />} />
                                    ) : (
                                        <Route
                                            key={index}
                                            exact
                                            path={`/${value[0]}/${key}`}
                                            component={(props) => <Empt {...props} param={param} message="Page introuvable" hideButton={true} />}
                                        />
                                    );
                                } else {
                                    return <Route key={index} exact path={`/${key}`} component={(props) => <Empt {...props} message="Page introuvable" />} />;
                                }
                            }

                            return null;
                        })}
                        <Route component={(props) => <Nothing {...props} />} />
                    </Switch>
                    <div id="footer">
                    <span id={(isDesktopOrLaptop===false)?"copyright":'copyright1'}> {param.webApp} {version[0].build} &copy; {param.copyright}</span>
                    {(isDesktopOrLaptop===false)? <span id="powered">powered by {param.company} for {param.licence}</span>:<></> }
                    {(isDesktopOrLaptop===false)?   <span id="lastMAJ"> Last update : {version[0].date}</span>:<></>}
                     </div>
                    
                </ParamContext.Provider>
            </div>
        );
    } else {
        return <Route exact path={'/Empty'} component={(props) => <Nothing {...props} />} />;
    }
}

export default Pages;
