import React,{ useState, useEffect ,useCallback,useMemo} from "react";
import DataTable  from "react-data-table-component";
import ContentEditable from 'react-contenteditable';
import TextField from '@material-ui/core/TextField';
import {CircularProgress,Paper, Box} from '@material-ui/core';
import {Search,Lock,LockOpen} from '@material-ui/icons';
import DraggableDialog from './modals/ref_cat_add'
import { useStyles } from '../../css/muiStyle';
import {conditionalRowStyles,customStyles}from '../../css/datatableStyle'
import { AlertCompo ,NoDataComponent,NotAllowedComponent} from "../../js/components";


export default function Ref_cat (props){
  const param=props.param
  const Gfunc=require('../../Gfunc')
  const librery=require('../../js/data_table_librery')
  const [data, setData] = useState([]);
  const [called, setCalled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search,setSearch]=useState('');
  const [,setIcon]=useState('');
  const service='_cat.php'
  const srv="categorie"
  const classes=useStyles();
  const [severity, setSeverity] =useState("info");
  const [open, setOpen] =useState(false);
  const [messageA, setMessageA] =useState('');
  const[noData,setNoData]=useState(false);
  const[doSearch,setDoSearch]=useState(false);
  const[notAllowed,setNotAllowed]=useState(false);
  const[errMessage,setErrMessage]=useState('');
  const[records,setRecords]=useState('');
  const [resetDP, setResetDP] = useState(false);


   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  }
 
  const fetch= async(page,servic = service, size = perPage) => {
    setCalled(true);
    setLoading(true);
    const response=await librery.axiosGet(page,servic,size+"&search="+search)
    if(response.data.err&&response.data.err!=='-1'){
      setErrMessage(response.data.err)
      setNotAllowed(true)
    }else{
      Gfunc.axiosResponse(response,()=>{
        setRecords(response.data.recordsFiltered)
        if(response.data.data!==undefined){
          setData(response.data.data);
          setTotalRows(response.data.recordsFiltered);
          setLoading(false);
          setNoData(true)
          setDoSearch(true)
        }else{
          setDoSearch(false)
          setData([])
        }   
      }) 
      setLoading(false)
  }
  }

  useEffect(() => {
    if(!called)
    fetch(1);
  }, [fetch,called]);
  
  const flagMan =useCallback(async(row)=>{
    const response= await Gfunc.axiosPostAction('flagman.php','1',({ srv:srv, id: row.DT_RowId.substr(1),att:'activ', val: row.activ }))
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{ 
      if (response.data.jData) {
        const objIndex = data.findIndex((obj => obj.DT_RowId === row.DT_RowId));
        setIcon(row.DT_RowId)
        if(row.activ){
          data[objIndex].activ=0
        }else data[objIndex].activ=1
        setIcon('')     
      }else handleAlert(true,'error',response.data.err);  
   })
  }
},[Gfunc,data])

//update field
const handleOnEdit=useCallback(async(e,row,att)=>{
  const edt1=e.target.innerHTML
  const edt=Gfunc.DeleteSpace(edt1);
      if(row[att]!==edt){
        const attrib=row[att];
        const response=await librery.axiosEdit(service,row,edt,att) 
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,'error',response.data.err)
        }else{
        Gfunc.axiosResponse(response,()=>{row[att]=edt;e.target.innerHTML=edt})
        }
        if(response.data.err) {e.target.innerHTML=attrib}
      }else {e.target.innerHTML=edt}
},[librery,Gfunc]);

const handleLockIcon=(val)=>{
  setIcon(val);
}
useEffect((val) => {
handleLockIcon(val);
}, []);
const columns =useMemo(
    () => [   
      {
          name: "NOM",
          selector:row=>row.categorie,
          minWidth:"180px !important",
          sortField: "categorie",
          sortable: true,
          cell: (row) => {
            return (
                <ContentEditable
                html={row.categorie}
                name="cat"
                data-column="item"
                className="content-editable"
                disabled={false} 
                onBlur={e => handleOnEdit(e,row,"categorie")}
                />
             );
           },
      },
      {
        name: "DATE",
        selector:row=>row.datin,
        minWidth:"120px !important",
        sortField: "datin",
        sortable: true
      },
      {
        name: "UTILISATEUR",
        selector:row=>row.user,
        minWidth:"150px !important",
        sortField: "user",
        sortable: true
      }, 
      {
        right:true,
        sortable: true,
        cell: (row) => {
          return (
            <div onClick={()=>flagMan(row)} title="Activer/Desactiver">{(row.activ)?<LockOpen className={classes.redIcon} fontSize="small"/>:<Lock className={classes.greenIcon} fontSize="small"/> }</div>        
            );
         },
      }  
    ],[flagMan,handleOnEdit,classes]);
 
  const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage+"&search="+search)=>{
      setLoading(true);
      const response=await librery.axiosSort(column,sortdirection,page,size,service)
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
        setLoading(false);
      }) 
    }    
  }

   //recherche serverside
   const handleSearch=async(size = perPage)=>{
    setLoading(true);
    setCurrentPage(1)
    const recherche=search;
    const response=await librery.axiosSearch(service,recherche,1,size,"get")
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
      if(response.data.data!==undefined){
      setData(response.data.data);
      setTotalRows(response.data.recordsFiltered);
      }else setData([])
    })   
    setLoading(false);
    return recherche
  }
}

function handlePageChange (page) {
  fetch(page,service,perPage);
  setCurrentPage(page);
};
 const handlePerRowsChange = async (newPerPage, page) => {
  fetch(page,service, newPerPage);
  setPerPage(newPerPage);
};
function liveSearch(e,search){
  if(param.liveSearch&&search.length>3){
     e.preventDefault();
     handleSearch();
  }else{ if (e.keyCode === 13) {
            e.preventDefault();
            handleSearch();
          }
  }
}
function liveSearch2() {
  handleSearch();
}
  
  return (
    <div className="content">
        <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
        <Paper className={classes.paperBody} elevation={5}>
        <div className='table-header'>
          <div className='button-flex-wrapper'><DraggableDialog handleAlert={handleAlert} callback={()=>{if(currentPage===1) fetch(1);else setResetDP(!resetDP)}}/></div>
          <div className='search'>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Search className={classes.blackIcon} sx={{ color: 'action.active', mr: 1, my: 0.5 }} onClick={(e)=>liveSearch2(e)}/>
                      <TextField className="smallText" id="searchBar" variant="outlined" label="Recherche" value={search} size="small" margin='dense' onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} autoFocus/>
                </Box>
            </div>
        </div>
         {
           (data.length!==0||doSearch)?
           <div>
            <DataTable 
              columns={columns}
              data={data}
              noDataComponent={param.ErreurData}
              paginationRowsPerPageOptions={[10,30,50,100]}
              progressPending={loading}
              progressComponent={<CircularProgress className={classes.circularProgress} />}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationResetDefaultPage={resetDP}
              paginationDefaultPage={1}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              sortServer={true}
              onSort={handleOnSort}
              noHeader={true}
              highlightOnHover
              customStyles={customStyles}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}
              conditionalRowStyles = { conditionalRowStyles }
            />
          </div>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData || records===0)?<NoDataComponent message="Aucun résultat trouvé"/>:null}

      </Paper>
    </div>    

  );
}