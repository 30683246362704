import React,{useState} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog,Typography} from '@material-ui/core';
import Draggable from 'react-draggable';
import DataTable from "react-data-table-component";
import { useStyles,paperProps } from '../../../css/muiStyle';
import {customStyles, numberColumn}from '../../../css/datatableStyle'
import Iconn from "@mdi/react"
import { mdiWallet } from '@mdi/js';
import {ReactComponent as DollarExchange} from '../../../img/icons/dollarExchange.svg'
import { MonetizationOn } from '@material-ui/icons';



//add new marque
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Ach_add_SldWal(props) {
    const param=props.param
    const Gfunc=require('../../../Gfunc')
    const [open, setOpen] =useState(false);
    const [loading, setLoading] =useState(false);
    const [data, setData] =useState([]);
    const [total, setTotal] =useState('');
    const addTitle="Soldes wallets"
    const service="_wal.php"
    const classes=useStyles();

     
    const fetch= async() => {
      setLoading(true)
      const response=await Gfunc.axiosGetAction(service,'getsolds')
      if(response.data.err&&response.data.err!=='-1'){
        props.handleAlert(true,"error",response.data.err)
        setOpen(false)
      }else
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData!==undefined){
          setData(Object.entries(Gfunc.groupBy(response.data.jData,'nom')));
          setTotal(response.data.jData[response.data.jData.length-1])
        }
      })
      setLoading(false)
    }

    const handleClickOpen = () => { 
      fetch()
      setOpen(true); 
    };

    const handleClose = () => { setOpen(false); };

    const ExpanableComponent = ({ data }) => {
      
        return (
          <>
          <div className='digPaper'>
            {(data[1].length>0)?
            <table id='soldeWallet'>
                  <thead>
                    <tr>
                      <th><span className='flex-wrapper-centered verticalGap'><p>Taux </p><DollarExchange fill='black' style={{height:'20px',width:'20px',padding:"1px"}}/></span></th>
                      <th><span className='flex-wrapper-centered verticalGap'><p>Solde </p><MonetizationOn fontSize="small" className={classes.blackSmallIcon} /></span></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data[1].map((elem,index)=>{
                       return (<tr key={index}><td>{new Intl.NumberFormat().format(elem.rate)}</td><td>{new Intl.NumberFormat().format(elem.solde)}</td></tr>)
                    })}
                  </tbody>
            </table>:null
            }
            </div>
            </>
        )
    }
 

    const columns = [
        {
          name: "NOM",
          selector: row=>row[0],
          //sortable: true,
        },
        {
          name: "Solde",
          style:numberColumn,
          //sortable: true,
          cell: (row) => {
            return (
              <div>
                {new Intl.NumberFormat().format(Gfunc.sumArObj(row[1],'solde'))} 
              </div>
             );
           },
        },
      ];
  return (
    <div>
      <Button className={classes.topTabBtn} variant="contained" color="primary" onClick={handleClickOpen} startIcon={<Iconn path={mdiWallet} color="black" size="18px"/>}>Soldes wallets</Button>
      <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperProps}
      >
        <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >{addTitle}</DialogTitle>
            <DialogContent>
              <Paper className='crnsTotal'>
               <Typography><b>Total:</b> {Intl.NumberFormat().format(total)}</Typography>
              </Paper>
              <DataTable 
                  columns={columns}
                  data={data.slice(0, -1)}
                  noDataComponent={param.ErreurData}
                  progressPending={loading}
                  highlightOnHover
                  noHeader={true}
                  customStyles={customStyles}
                  expandableRows
                  expandableRowsComponent={ExpanableComponent} 
                  paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}            
                />
            </DialogContent>
            <DialogActions>
            <Button variant="contained" onClick={handleClose} color="secondary">FERMER</Button>
            </DialogActions>
      </Dialog>
      </div>
  );
}