import React,{ useState, useEffect ,useCallback} from "react";
import {TextField,Button,CircularProgress,Paper, Box, Card, CardContent, FormControlLabel, Checkbox, Tooltip} from '@material-ui/core';
import {Search, Today} from '@material-ui/icons';
import { useStyles } from '../../css/muiStyle';
import { Autocomplete } from "@material-ui/lab";
import { AlertCompo, NoDataComponent,NotAllowedComponent } from "../../js/components";
import DateFnsUtils from "@date-io/date-fns";
import { fr } from "date-fns/locale";
import  '../../css/tol.css'
import { MuiPickersUtilsProvider,KeyboardDatePicker} from "@material-ui/pickers";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useRef } from "react";
import Icon from "@mdi/react";
import { mdiAccount, mdiClock } from "@mdi/js";
import Soldes3 from './modals/tol_dg3_sld'
import axios from "axios";

export default function Tol_dg2 (props){
  const param=props.param
  const Gfunc=require('../../Gfunc')
  const [detail, setDetail] = useState([]);
  const [data, setData] = useState([]);
  const [tous, setTous] = useState([]);
  const [prodAirL, setProdAirL] = useState([]);
  const [prod, setProd] = useState(null);
  const [ussd, setUssd] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [search,setSearch]=useState('');
  const service='_usd.php'
  const classes=useStyles();
  const[noData,setNoData]=useState(false);
  const[doSearch,setDoSearch]=useState(false);
  const[notAllowed,setNotAllowed]=useState(false);
  const [severity, setSeverity] =useState("info");
  const [open, setOpen] =useState(false);
  const [messageA, setMessageA] =useState('');
  const[errMessage,setErrMessage]=useState('');
  const[records,setRecords]=useState('');
  const [selectedDate,setSelectedDate]=useState(new Date())
  const [checked,setChecked]=useState(false);

  
  //handle alerts properties
  const handleAlert=(open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  }

  useEffect(()=>{
    
    if(prod!=null&&selectedDate!=null)
     fetch()
  },[prod,selectedDate])


 //fetch marque
  const fetch= useCallback(async() => {
    if(prod!=null){
    setLoading(true);
    setUssd(null)
    setDetail([])
    setChecked(false)
    const response=await Gfunc.axiosPostAction(service,"getussds3",{date:Gfunc.formatDate(selectedDate),prodId:prod.prodId})
    if(response.data.err&&response.data.err!=='-1'){
      setErrMessage(response.data.err)
      setNotAllowed(true) 
      handleAlert(true,"error",response.data.err) 
       }else{
      Gfunc.axiosResponse(response,()=>{
        setRecords(response.data.recordsFiltered)
        if(response.data.jData!==undefined) {
          setTous(response.data.jData)
          setData(response.data.jData);
          setNoData(true)
          setDoSearch(true)
          }else{
            setDoSearch(false)
            setData([])
          }
      })
  }
    setLoading(false);}
  },[Gfunc,prod,selectedDate]);

  //fetch
  const fetchDetail= useCallback(async(ussdId) => {
    setLoading2(true);
    const response=await Gfunc.axiosPostAction(service,"getussd3",{ussdId:ussdId})
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,"error",response.data.err)
    }else{
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData!==undefined) {
          setDetail(response.data.jData)
        }else{
          setDetail([])
        }
      })
    }
    setLoading2(false);
  },[Gfunc]);

  useEffect(()=>{
    if(ussd!=null)
     fetchDetail(ussd.id)
  },[ussd])


  //filter by 
  const handleFilter=useCallback((filtre,e,v)=>{
      if(filtre==="tous"){
       // setSelecto('')  
        setData(tous)
      }
      if(filtre==='success'){
          //setSelecto(v);
          setData(tous.filter((d)=>{return (d.success===false)}))
      }
  },[tous]);


const handleSearch=()=>{
  const filters=[]
  var keys=[] 
  keys=Object.keys(tous[0])
    keys.map( key =>{ 
      const f={}  
      f["att"]=key
      filters.push(f)
      return true
    }) 
  setData(tous.filter((d)=>filters.some(filterEl =>((d[filterEl.att].toString().toLowerCase()).includes(search.toLowerCase())))))
}

function dynamicSort(property) {
  var sortOrder = 1;
  if(property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
  }
  return function (a,b) {
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
  }
}
const handleOnSort=(column, sortdirection)=>{  
  //objs.sort((a,b,att) => (a.last_nom > b.last_nom) ? 1 : ((b.last_nom > a.last_nom) ? -1 : 0))

  setData(data.sort( dynamicSort(column.selector)) );
}

  function liveSearch(e){
    if(search.length>-1){
      e.preventDefault();
      handleSearch();
    }
  }
  function liveSearch2() {
    handleSearch();
  }
  const handleDateChange = (date) => {
    setSelectedDate(date);
  }; 
  
 //fetch users
 const fetchProdAir= useCallback(async(serv="_usd.php", act = "getatp3") => {
    const response = await Gfunc.axiosGetAction(serv,act);
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
        setProdAirL(response.data.jData);    
    })
  }
  },[Gfunc,handleAlert])

  //fetch utilisateurs handler
const handleOpenProdAir = useCallback(async () => {
    if(prodAirL.length===0)
    fetchProdAir()
    },[prodAirL,fetchProdAir])

 
const handleClickSoldes = useCallback(async () => {
    await axios.get(param.timefanSolde3+`?p=${prod.prodId}`)
    .then(resp=>{
      handleAlert(true,"success","Succès!")
    })
    .catch(e=>{
      handleAlert(true,"error","Échec. Veuillez réessayer plus tard.")
    })
},[prod])

const handleClickDiags = useCallback(async () => {
  prod.prodId&&await axios.get(param.timefanDiag3+`?p=${prod.prodId}`)
  .then(resp=>{
    handleAlert(true,"success","Succès!")
  })
  .catch(e=>{
    handleAlert(true,"error","Échec. Veuillez réessayer plus tard.")
  })
    },[prod])


  return (
    <div className="content2 soldesContent" >
        <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
        <fieldset className="fieldsetTop" id="saisie">
          <legend>Diagnostique Niv. 3</legend>
          
        <ValidatorForm noValidate id="formDiag2" ref={useRef(null)} onSubmit={()=>{}} /*onKeyPress={e=>detectTouche(e)} */ >
            <div className="rowGrid" id="row-1">
                <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                     <KeyboardDatePicker
                        margin='dense'
                        size="small"
                        className='smallText'
                        KeyboardButtonProps={{style:{padding:"0px"}}}
                        inputVariant="outlined"
                        variant="inline"
                        maxDate={new Date()}  
                        invalidDateMessage="Le format de la date est incorrect"   
                        maxDateMessage="La date ne doit pas être supérieure à la date maximale"
                        format="dd/MM/yyyy"
                        name="iDate"
                        id="iDate"
                        orientation='landscape'
                        placeholder={"Date"}
                        keyboardIcon={<Today className={classes.blackSmallIcon}/>}
                        value={selectedDate}
                        onChange={handleDateChange} />
                </MuiPickersUtilsProvider>
                <Autocomplete
                                disableClearable
                                className='smallAuto'
                                id="iProd"
                                onChange={(e,newValue) =>{setProd(newValue);}} 
                                onOpen={handleOpenProdAir}
                                options={prodAirL}
                                getOptionLabel={(option) => option.prodName}
                                getOptionSelected={(option, value) => option.prodId === value.prodId}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={"Produit"}
                                    variant='outlined'
                                    size="small"
                                    autoFocus 
                                    required/>
                                )}/>

               <div className="rowGrid" id="buttons">
               <Button className={classes.smallBtn} disabled={!prod} variant="contained" onClick={handleClickSoldes}  name="sldBtn" color="primary" > Soldes </Button>
               <Button className={classes.smallBtn} disabled={!prod} variant="contained"  onClick={handleClickDiags} name="diagBtn" color="primary" > Diags </Button>
               </div>
               {
                (prod!=null&&Object.keys(prod).length !== 0)?
                <div id="dscr">
                  <span> {`${prod.nrc} ${prod.nrcName} / ${prod.wilaya} ${(prod.number)??""}`} </span>
                </div>:null
              }
               </div>
        
        </ValidatorForm>
        </fieldset>
        
      <Paper className={classes.paperBodyFlexOver} elevation={3}>
        {(!loading)?<div id="ussdsList">
        <div className="table-header">
        <div className="button-flex-wrapper" > 
            {prod&&<Soldes3 param={param} date={Gfunc.formatDate(selectedDate)} prodId={prod.prodId}/>}
            <FormControlLabel labelPlacement="end" style={{justifyContent:"left",margin:0}} control={
            <Checkbox checked={checked} onChange={e=>{setChecked(!checked);if(!checked) handleFilter('success',checked);else setData(tous)}} name="checked" color="primary"/> } label="Echecs"/>
        </div>
          <div className='search'>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Search className={classes.blackIcon} sx={{ color: 'action.active', mr: 1, my: 0.5 }} onClick={(e)=>liveSearch2(e)}/>
                      <TextField className="smallText" id="searchBar" variant="outlined" label="Recherche" value={search} size="small" margin='dense' onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} autoFocus/>
                   </Box>
            </div>
          </div>
          {
            (data.length!==0)?
            <div id="scroll">
                {
                  data.map((e)=>{
                    return (
                      <Card id="ussdCard" style={{background:ussd&&e.ussdId===ussd.id&&"var(--secondary-light)"}} onClick={()=>setUssd({id:e.ussdId,mainResp:e.mainResponse})}>
                        <CardContent id="cardContent">
                          <div style={{display:"flex",fontSize:"13px"}}>
                              <div className="flex-wrapper" id="item1">
                                <Icon path={mdiClock} size={"20px"}/>
                                <span>{e.time}</span>
                              </div>
                              {<div className="flex-wrapper" sid="item2">
                                <Icon path={mdiAccount} size={"20px"}/>
                                <span>{e.client}</span>
                              </div>}
                          </div>

                          <div className="flex-wrapper-centered" style={{color:e.success?"green":"red"}}>
                            <span>{e.mainUSSD}</span>
                        </div>
                        </CardContent>
                      </Card>
                    )
                  })
                }
            </div>
          :(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData)?<NoDataComponent message="Aucun résultat trouvé"/>:null
          }
        </div>:<div className="flex-wrapper-centered"><CircularProgress className={classes.circularProgress} /></div>
        }
        <div className="flex-wrapper-centeredTop messageTable" >
          {ussd&&((!loading2)?<table id="tab" style={{width:"100%"}}>
            <thead>
              <tr>
              <th style={{width:'80px'}}>Étape</th>
              <th style={{width:'auto'}}>Message</th>
              <th style={{width:'150px'}}>Réponse</th>
              </tr>
            </thead>
            <tbody>
              <Tooltip title={((detail.length > 0) ? ("Mot clé: " + detail[0].keyword + ((detail[0].keyword2) ? ", Mot clé 2: " + detail[0].keyword2 : "")) : "")}>
              <tr style={{color:(detail.length>0)&&ussd.mainResp.includes(detail[0].keyword)&&"green"}}>
                <td style={{width:'80px'}}>0</td>
                <td style={{width:'auto'}}>{ussd.mainResp ?? ""}</td>
                <td style={{width:'150px'}}>{(detail.length>0)&&detail[0].send}</td>
              </tr>
              </Tooltip>
              {(() => {
                const rows = [];
                for (let i = 0; i < detail.length-1; i++) {
                  rows.push(
                    <Tooltip title={("Mot clé: " + (detail[i + 1].keyword ?? "")) + ((detail[i + 1].keyword2) ? ", Mot clé 2: " + detail[i + 1].keyword2 : "")}>
                    <tr key={i + 1} style={{color:detail[i].response.includes(detail[i+1].keyword)&&"green"}}>
                      <td>{i + 1}</td>
                      <td>{detail[i].response}</td>
                      <td>{(i + 1 < detail.length) ? detail[i + 1].send : ""}</td>
                    </tr>
                    </Tooltip>
                  );
                }
                return rows;
              })()}
            </tbody>

          </table>:<CircularProgress className={classes.circularProgress} />)}
        </div>
      </Paper>
      
    </div>    

  );
}