import React,{useRef,useEffect} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog} from '@material-ui/core';
import Draggable from 'react-draggable';
import { useStyles, paperPropsTicketDig } from '../../../css/muiStyle';
import '../../../css/digit.css'
import ReactToPrint from 'react-to-print';
import $ from 'jquery'
import AT_Ticket from '../../../compo/AtTicket';
import Mo_Ticket from '../../../compo/MoTicket';
import Dj_Ticket from '../../../compo/DjTicket';
import Or_Ticket from '../../../compo/OrTicket';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}


export default function Dig_prt_imprimer_el(props) {
  const classes=useStyles();
  const param=props.param
  const componentRef = useRef();
  const printRef = useRef();
  const pageStyle =(props.produit.includes("D"))?"@page { size: 5cm 8.2cm;}" :"@page { size: 5cm 7.7cm;}";
  //const pageStyle="@media print { @page { size: auto; margin: 10; height: 7cm; } }"



  useEffect(() => {
    $(document).ready(function() {
       printRef.current.handleClick();
    });
  }, []);

  const handleAfterPrint = React.useCallback(() => {
    props.handleClose();
    props.callback()
    props.setDatadet([])
  }, []);
 
  const CompoPrint=({el})=>{
    if(props.produit.includes("I")){
      return <AT_Ticket el={el} props={props} param={param}/>
    }
    if(props.produit.includes("D")){
      return <Dj_Ticket el={el} props={props} param={param}/>
    }
    if(props.produit.includes("M")){
      return <Mo_Ticket el={el} props={props} param={param}/>
    }
    if(props.produit.includes("O")){
      return  <Or_Ticket el={el} props={props} param={param}/> 
    }
  }
  return (
    <div>
      <Dialog
            open={props.open}
            onClose={props.handleClose}
            id='myDialog'
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsTicketDig}
      >
        <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" ></DialogTitle>
        <DialogContent>
        <div>
            <ReactToPrint
                trigger={() => <React.Fragment/>}
                content={() => componentRef.current}
                ref={printRef}
                pageStyle={pageStyle}
                onAfterPrint={handleAfterPrint}
            />
            <div ref={componentRef} >
            {
              props.data.map((el,index)=>{
                return (
                   <div key={index}>
                      <CompoPrint el={el}/>
                   </div>
                )
              })
            }
            </div>
        </div>
            </DialogContent>
            <DialogActions>
           <Button variant="contained" onClick={props.handleClose} color="secondary">FERMER</Button>
            </DialogActions>
      </Dialog>
      </div>
  );
}
