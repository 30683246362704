import React,{ useState, useEffect ,useCallback,useMemo} from "react";
import ContentEditable from 'react-contenteditable';
import {TextField,Button,Checkbox,FormControlLabel,Paper, Box} from '@material-ui/core';
import {Search,Delete,CheckCircleOutline,NotInterested,Restore} from '@material-ui/icons';
import DigExtra from './modals/dig_con_extr'
import DigExtraAuto from './modals/dig_con_extrAuto'
import DlvCdPromo from './modals/dig_con_dlvCdPromo'
import {conditionalRowStyles,customStyless}from '../../css/datatableStyle'
import {AutocompleteS, BackdropCompo} from "../../js/components"
import { useStyles } from '../../css/muiStyle';
import '../../css/digit.css'
import { AlertCompo,NoDataComponent,NotAllowedComponent } from "../../js/components";
import { mdiCheckboxMarkedCircleOutline, mdiCheckboxMultipleMarkedCircleOutline, mdiHandBackLeft } from "@mdi/js";
import Iconn from "@mdi/react";
import { Tooltip } from "@mui/material";
import DTable from "../../compo/DTable";


export default function Dig_con(props){
  const param=props.param
  const Gfunc=require('../../Gfunc')
  const librery=require('../../js/data_table_librery')
  const lodashClonedeep = require('lodash.clonedeep');
  const [data, setData] = useState([]);
  const [datach, setDatach] = useState([]);
  const [called, setCalled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [control, setControl] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [search,setSearch]=useState('');
  const [digSearch,setdigSearch]=useState('');
  const [,setIcon]=useState(false);
  const [,setDel]=useState('');
  const [libre, setLibre] = useState([]);
  const [nlibre, setNlibre] = useState([]);
  const [prod, setProd] = useState([]);
  const [checkElem,setCheckElem]=useState([])
  const [check,setCheck]=useState([])
  const [checkInfo,setCheckInfo]=useState({})
  const [checkInfo2,setCheckInfo2]=useState({})
  const [severity, setSeverity] =useState("info");
  const [open, setOpen] =useState(false);
  const [messageA, setMessageA] =useState('');
  const[noData,setNoData]=useState(false);
  const[doSearch,setDoSearch]=useState(false);
  const[notAllowed,setNotAllowed]=useState(false);
  const[errMessage,setErrMessage]=useState('');
  const[ch,setCh]=useState(false);
  const[records,setRecords]=useState('');
  const classes = useStyles();
  const filter='&digSearch='+digSearch
  const service='_dig.php'
  const srv="digit"
      
   //handle alerts properties
   const handleAlert=useCallback((open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  },[])

  // fetch digs
  const fetch= useCallback(async(page, size = perPage+"&search="+search) => {
    setCalled(true)
    setLoading(true);
    setCheck([])
    setCh(false)
    const response=await librery.axiosGet(page,service,size+filter+'&search='+search)
    if(!called) setLoadingSubmit(false)
    if(response.data.err&&response.data.err!=="-1"){
      setErrMessage(response.data.err)
      setNotAllowed(true)
    }else{
    Gfunc.axiosResponse(response,()=>{
      setRecords(response.data.recordsFiltered)
      if(response.data.data!==undefined){
        setData(response.data.data);
        setDatach(response.data.data)
        setTotalRows(response.data.recordsFiltered);
        setNoData(true)
        setDoSearch(true)
      }else{
        setDoSearch(false)
        setData([])
      }
    })
    setLoading(false);
  }
  },[Gfunc,filter,librery, perPage, search, called])

  //fetch products
  const fetchProd= useCallback(async(serv="_prd.php", act = "gets") => {
    const response = await Gfunc.axiosGetAction(serv,act);
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
        setProd(response.data.jData);    
    })
    }
  },[Gfunc,handleAlert])

  //fetch produit handler
const handleOpenProd = useCallback(async () => {
  if(prod.length===0)
  fetchProd()
  },[prod,fetchProd])


  useEffect(() => {
    if(!called){
      setLoadingSubmit(true)
      fetch(1);}
  }, [fetch,called]);

  
  
  const handleOnEdit=useCallback(async(e,row,att)=>{
    e.preventDefault();
    const edt1=e.target.innerHTML
    const edt=Gfunc.DeleteSpace(edt1);
    if(row[att]!==edt){
      const attrib=row[att];
      const response=await librery.axiosEdit(service,row,edt,att) 
      if(response.data.err&&response.data.err!=="-1"){
        handleAlert(true,'error',response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{row[att]=edt;e.target.innerHTML=edt})
      }
      if(response.data.err) {e.target.innerHTML=attrib}
    }else {e.target.innerHTML=edt}
  },[librery,Gfunc,handleAlert]);

  const handleOnEditConsumed=useCallback(async(e,row,att)=>{
    e.preventDefault();
    const edt1=e.target.innerHTML
    const edt=Gfunc.DeleteSpace(edt1);
    if(row[att]!==edt){
      const attrib=row[att];
      const response=await Gfunc.axiosPostAction(service,"updconsumed",{att:att,id:row.DT_RowId,val:edt})
      if(response.data.err&&response.data.err!=="-1"){
        handleAlert(true,'error',response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{row[att]=edt;e.target.innerHTML=edt})
      }
      if(response.data.err) {e.target.innerHTML=attrib}
    }else {e.target.innerHTML=edt}
  },[Gfunc,handleAlert]);

  //handle sort
  const handleOnSort=useCallback(async(column, sortdirection,page=currentPage, size = perPage+"&search="+search+filter)=>{
    setLoading(true);
    const response=await librery.axiosSort(column,sortdirection,page,size,service)
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
      if(response.data.data!==undefined){
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
        setLoading(false);}
     })    
    } 
  },[librery,Gfunc,currentPage,perPage,filter,search,handleAlert])
  
  //recherche serverside
const handleSearch=async(size = perPage)=>{
  var elem = document.getElementById("searchBar")
  if(elem) {
    elem.focus();
    elem.select()
  }
  setLoading(true);
  setCheck([])
  setCh(false)
  setCurrentPage(1)
  const recherche=search;
  const response=await librery.axiosSearch(service,recherche+filter,1,size,"get")
  if(response.data.err&&response.data.err!=="-1"){
    handleAlert(true,'error',response.data.err)
  }else{
  Gfunc.axiosResponse(response,()=>{
    if(response.data.data!==undefined){
      setData(response.data.data);
      setDatach(response.data.data);
      setTotalRows(response.data.recordsFiltered);
    }else setData([])
  })   
  setLoading(false);
  return recherche
}
}

  const Vider=(val)=>{
    setCheck([])
  }

 //handle restore multiple
  const handleRestores=useCallback(async()=>{
    const ids=[]
    const idss=[]
    if(check.length!==0){
      const x=window.confirm("Êtes-vous sure de rétablir ces éléments?");
      if(x){
        check.map((item)=>{
          if(!checkInfo2[item] && !checkInfo[item]){
            ids.push(item)
            idss.push("c"+item)
          }
            return true
        })   
        if(ids.length!==0){  
          const response=await Gfunc.axiosPostAction(service ,"recoverdigs",{ids:ids})
          if(response.data.err&&response.data.err!=="-1"){
            handleAlert(true,'error',response.data.err)
          }else{
          Gfunc.axiosResponse(response,()=>{
            handleAlert(true,'success','Les digits sélectionnés sont rétablis.')
            idss.map(y=>{
              const index=Gfunc.getIndex(data,y,"DT_RowId")
              data[index].datusd=null
              data[index].client=''
              return true
            })
          })
           setCheck([])
           setCh(false)    
            }
          }else handleAlert(true,'error','Vous ne pouvez pas rétablir les digits non utilisés!')
    }
    }else handleAlert(true,'error','Veuillez sélectionner au moins un élément!')
    
  },[check,Gfunc,data,handleAlert,checkInfo,checkInfo2])
 
 //handle restore
 const handleRestore=useCallback(async(row,ind)=>{
  const ids=[]
  const x=window.confirm("Êtes-vous sure de rétablir cet élément?");
  if(x && row.datusd && !row.isbanned) {
    ids.push(row.DT_RowId.substr(1)) 
    const response=await Gfunc.axiosPostAction(service ,"recoverdigs",{ids:ids})
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
      handleAlert(true,'success',"Le digit est rétabli.")
      row.datusd=null
      row.client=''
      data[ind].datusd=null
      data[ind].client=''
      setData(data)
      setControl(!control)
    })
  }
  }
 },[Gfunc,data,control,handleAlert])

 const handleSetData=useCallback((row,att)=>{
  Gfunc.removeItemsByAtt(data,row.DT_RowId,att)
  setDel('')
},[data,Gfunc])

 //handle delete digit
 const handleDeleteDigit=useCallback(async(row)=>{
  const ids=[]
  const x=window.confirm("Êtes-vous sure de supprimer cet élément?");
  if(x && row.isbanned && !row.datusd){
  ids.push(row.DT_RowId.substr(1))
    const response=await Gfunc.axiosPostAction(service ,"deldigs",{ids:ids})
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
        handleAlert(true,'success',"Le digit est supprimé.")
        handleSetData(row,"DT_RowId")
        setDel(row.DT_RowId)
    })
  }
  }
},[Gfunc,handleSetData,handleAlert])
const handleTest=(val)=>[
  setLibre(val)
]
//handle delete multiple
const handleDeleteDigits=useCallback(async()=>{
    const ids=[]
    const idss=[] 
    if(check.length!==0){
      console.log(check)
      console.log(checkInfo)
      console.log(checkInfo2)
      const x=window.confirm("Êtes-vous sure de supprimer ces éléments?");
      if(x){
        check.map(e=>{
          if(checkInfo[e] && checkInfo2[e]) {ids.push(e); idss.push("c"+e)}
          return true
        }) 
        if(ids.length!==0){ 
          const response=await Gfunc.axiosPostAction(service ,"deldigs",{ids:ids})
          if(response.data.err&&response.data.err!=="-1"){
            handleAlert(true,'error',response.data.err)
          }else{
          Gfunc.axiosResponse(response,()=>{
            handleAlert(true,'success','Les digits sélectionnés sont supprimé.')
            setLibre(check)
            idss.map(y=>{
              const ind= data.findIndex(obj=>obj.DT_RowId===y)
              data.splice(ind,1)
              //dat=Gfunc.removeItemsByAtt(dat,y,"DT_RowId");
              return true
            })
            //setData(dat)
            setCh(false)
          })
        }
        }else handleAlert(true,'error','Vous ne pouvez pas supprimer les digits librés/utilisés!')
        setCheck([])
        handleTest([])
     }
    }else handleAlert(true,'error','Veuillez sélectionner au moins un élément!')
  },[Gfunc,data,check,handleAlert,checkInfo,checkInfo2])

   //handle liberer All
 const handleLibererAll=useCallback(async()=>{
      const response=await Gfunc.axiosPostAction(service ,"releaseall")
      if(response.data.err&&response.data.err!=="-1"){
        handleAlert(true,'error',response.data.err)
      }else{
        if(response.data.jData>1){
          handleAlert(true,"success",response.data.jData+" éléments ont été liberés")
        }
        if(response.data.jData===1){
          handleAlert(true,"success",response.data.jData+" élément a été liberé")
        }
        if(response.data.jData===0){
          handleAlert(true,"warning"," Aucun élément n'a été liberé")
        }
        fetch(1);
      }
 },[handleAlert,Gfunc,service,fetch])
 
 // liberer multiple ligne
const handleLiberer=useCallback(async()=>{
  const ids=[];
  if(check.length!==0){
    check.map(e=>{
      if(checkInfo[e]) ids.push(e)
      return true;
    }) 
    if(ids.length!==0)  {
      const response=await Gfunc.axiosPostAction(service ,"releasedigs",{ids:ids})
      if(response.data.err&&response.data.err!=="-1"){
        handleAlert(true,'error',response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
        handleAlert(true,'success','Les digits selectionnés sont liberés avec succés.')
        setLibre(check)
        let dat=[...data]
        ids.map((id)=>{
          const index=Gfunc.getIndex(data,'c'+id,'DT_RowId')
           if(index>=0){
             dat[index].isbanned=false
           }
         return true
        })
        setData(dat)
        setCh(false)
        setCheck([]);
      })
    }
  }else handleAlert(true,'error',"Les digits sélectionnés sont déjà libérés!")
  }else handleAlert(true,'error','Veuillez sélectionner au moins un élément!')
 },[check,Gfunc,data,checkInfo,handleAlert])


 //handle suspendre multiple
 const handleSuspendre=useCallback(async()=>{
  const ids=[]
  
  if(check.length!==0){
    check.map((item)=>{
      if(!checkInfo[item]&&checkInfo2[item])
        ids.push(item)
        return true;
    }) 
    if(ids.length!==0){
      const response=await Gfunc.axiosPostAction(service ,"bandigs",{ids:ids})
      if(response.data.err&&response.data.err!=="-1"){
        handleAlert(true,'error',response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData){
        handleAlert(true,'success','Les digits selectionnés sont suspendus.')
        setNlibre(check)
        var index=null
        let dat=[...data]
        ids.map((id)=>{
          const index=Gfunc.getIndex(data,'c'+id,'DT_RowId')
           if(index>=0){
             dat[index].isbanned=true
           }
         return true
        })
        setData(dat)
        setCh(false)
        setCheck([]);}
      })
    }
    }else handleAlert(true,'error',"Les digits sélectionnés sont déjà suspendus ou bien utilisés!")
  }else handleAlert(true,'error','Veuillez sélectionner au moins un élément!')
 },[Gfunc,check,data,handleAlert,checkInfo,checkInfo2])


 const flagMan =useCallback( async( row)=>{
  const response= await Gfunc.axiosPostAction('flagman.php','1',({ srv:srv, id: row.DT_RowId.substr(1),att:'banned', val: row.isbanned }))
  if(response.data.err&&response.data.err!=="-1"){
    handleAlert(true,'error',response.data.err)
  }else{
  Gfunc.axiosResponse(response,()=>{ 
    const objIndex = data.findIndex((obj => obj.DT_RowId === row.DT_RowId));
      if (response.data.jData) {
        setIcon(true)
        if(row.isbanned){
          data[objIndex].isbanned=false
        }else data[objIndex].isbanned=true
        setIcon(false)     
      }else handleAlert(true,'error',response.data.err);
          })
  }
},[Gfunc,data,handleAlert])

const HandleChange=useCallback((e,row)=>{
  var checkk =[...check]
  var checkkElem=[...checkElem]
  const tmp=parseInt(row.DT_RowId.substr(1));
  if(e.target.checked===true){
    if(!checkk.includes((tmp))){
      checkk.push(parseInt(tmp))
      checkkElem.push(row)
      let inf=checkInfo; inf[tmp]=row.isbanned
      let inf2=checkInfo2; inf2[tmp]=!(row.datusd)
      setCheckInfo(inf)
      setCheckInfo2(inf2)
    }
 }
 else{
     let index = checkk.indexOf(tmp);
     if(index>-1){checkk.splice(index,1);checkkElem.splice(index,1)}
 }
 setCheckElem(checkkElem)
 setCheck(checkk)
},[check,checkElem,checkInfo,checkInfo2])

const Ch=({datach,setCheck,ch})=>{
  const test=(val)=>{
    setCh(val)
    if(val===true){
      let checkk=[]
      var checkkElem=[]
      let inf=checkInfo; 
      let inf2=checkInfo2;
      lodashClonedeep(datach).map(e=>{
         let tmp=parseInt(e.DT_RowId.substr(1))
         checkk.push((tmp))
         checkkElem.push(e)
         inf[tmp]=e.isbanned
         inf2[tmp]=!(e.datusd)
         return true
      })
      setCheckInfo(inf)
      setCheckInfo2(inf2)
      setCheck(checkk)
      setCheckElem(checkkElem)
    }else{
      setCheckInfo({})
      setCheckInfo2({})
      setCheck([])
      setCheckElem([])
    }  
  }

  return(  
    <FormControlLabel control={<Checkbox  className={classes.checkStelectableRow} defaultChecked={ch}
                  onChange={e=>test(e.target.checked)} inputProps={{ 'aria-label': 'controlled' }}/>}
               />
  )
}

const columns =useMemo(
    () => [ 
      {
        name:<Ch datach={datach} setCheck={setCheck} ch={ch}/>,
        width:"55px",
        cell: (row) => {
          return (
            <div >
               <FormControlLabel control={<Checkbox checked={check.includes(parseInt(row.DT_RowId.substr(1)))} className={classes.checkStelectableRow}
                  onChange={e=>HandleChange(e,row)} inputProps={{ 'aria-label': 'controlled' }}/>}
               />
            </div>
           );
         },
      },  
       {
        name:"Date",
        width:'128px !important',
        selector: row=>row.datin,
        sortField:'datin',
        sortable: true,
       },
       {
            name: "DIGIT",
            minWidth:'240px !important',
            selector: row=>row.digit,
            sortField:'digit',
            sortable: true,
            cell: (row) => {
              if(libre.length!==0){
              libre.map((item)=>{
               if(item.DT_RowId===row.DT_RowId && row.isbanned===true){
                  row.isbanned=false;
               }
               return true;
              })
              setTimeout(() => { setLibre([])},0) ; 
            }
              if(nlibre.length!==0){
                nlibre.map((item)=>{
                if(item.DT_RowId===row.DT_RowId ){
                  if(!row.isbanned && !row.datusd)
                    row.isbanned=true;
                }
                return true;
                })
                setTimeout(() => { setNlibre([])},0) ; 
            }
              return (
              (row.digit!=null)?<div>
                   {(!row.datusd)?
                   <ContentEditable
                   html={row.digit}
                   name="digit"
                   id={"digit"+row.DT_RowId}
                   data-column="item"
                   className="content-editable"
                   title={(!row.datupd)?"Entré le: "+row.datin +' par: ' +row.agent:"Édité le: "+row.datupd +' par: ' +row.usrupd}
                   onBlur={e => handleOnEdit(e,row,'digit')}/>:
                   <ContentEditable
                   html={row.digit}
                   name="digit"
                   id={"digit"+row.DT_RowId}
                   data-column="item"
                   className="content-editable"
                   title={(!row.datupd)?"Entré le: "+row.datin +' par: ' +row.agent:"Édité le: "+row.datupd +' par: ' +row.usrupd}
                   onBlur={e => handleOnEditConsumed(e,row,'digit')}/>}
              </div>
              :<div>*** SECRET ***</div>
               );
             },
        },
        {
          name: "SERIAL",
          minWidth:'240px !important',
          selector: row=>row.serial,
          sortField:'serial',
          sortable: true,
          cell: (row) => {
            if(libre.length!==0){
              libre.map((item)=>{
               if(item.DT_RowId===row.DT_RowId && row.isbanned===true ){
                  row.isbanned=false;
               }
               return true;
              })
              setTimeout(() => { setLibre([])},100) ; 
            }

              if(nlibre.length!==0){
                nlibre.map((item)=>{
                if(item.DT_RowId===row.DT_RowId ){
                  if(!row.isbanned && !row.datusd)
                    row.isbanned=true;
                }
                return true;
                })
                setTimeout(() => { setNlibre([])},100) ; 
            }
              return (
              <div>
              {(!row.datusd)?
              <ContentEditable
              html={row.serial}
              name="serial"
              id={"serial"+row.DT_RowId}
              data-column="item"
              className="content-editable"
              title={(!row.datupd)?"Entré le: "+row.datin +' par: ' +row.agent:"Édité le: "+row.datupd +' par: ' +row.usrupd}
              onBlur={e => handleOnEdit(e,row,'serial')}
            />: <ContentEditable
            html={row.serial}
            name="serial"
            id={"serial"+row.DT_RowId}
            data-column="item"
            className="content-editable"
            title={(!row.datupd)?"Entré le: "+row.datin +' par: ' +row.agent:"Édité le: "+row.datupd +' par: ' +row.usrupd}
            onBlur={e => handleOnEditConsumed(e,row,'serial')}
          />}
            </div>
               );
             },
      },
      {
        name: "PRODUIT",
        minWidth:'240px !important',
        sortField:'prod',
        selector: row=>row.prod,
        sortable: true,
        cell: (row) => { 
            return (
              <div>
             {(!row.datusd)?
              <AutocompleteS
              service={service} action={'upd'} 
              defaultValue={row.prod} row={row} list={prod} 
              id={'idprod'} name='prod' attr='name'
              onOpen={handleOpenProd} handleAlert={handleAlert}/>:row.prod}
              </div>
             );
        },
      },
      {
        name: "CLIENT",
        minWidth:'180px !important',
        selector: row=>row.client,
        sortField:'client',
        sortable: true,   
      },
      {
        name: "CONSOMMATION",
        minWidth:'128px !important',
        selector: row=>row.datusd,
        sortField:'datusd',
        sortable: true,  
      },
      {
        right:true,
        cell: (row,index) => {
            return (
                <div className='rowGrid' id='twoIcons' >
                    {(row.datusd&&!row.isbanned)?<div title="Rétablir"><Restore fontSize="small" className={classes.blackIcon} onClick={()=>{handleRestore(row,index)}} /> </div>:null }
                     <div onClick={()=>flagMan(row)} title="Suspendre/Liberer">{(!row.isbanned&&!row.datusd)?<NotInterested className={classes.redIcon} fontSize="small"/>:((!row.datusd)?<CheckCircleOutline className={classes.greenIcon} fontSize="small"/>:<></>)}</div>  
                     <div title="Supprimer">{(row.isbanned)?<Delete className={classes.redIcon} fontSize="small" onClick={()=>{handleDeleteDigit(row)}} />:<></>}</div>
                </div>
             );
        },   
      },
    ],[handleOnEdit,handleDeleteDigit,handleRestore,flagMan,libre,nlibre,prod,classes,handleOpenProd,check,ch,datach,HandleChange,handleAlert,handleOnEditConsumed]);
  
 
function liveSearch(e,search){
  if(param.liveSearch&&search.length>3){
     e.preventDefault();
     handleSearch();
  }else{ if (e.keyCode === 13) {
            e.preventDefault();
            handleSearch();
          }
  }
}
function liveSearch2() {
  handleSearch();
}
  const ExpanableComponent = ({ data }) => {
    const [frns, setFrns] = useState([]);
    const [lot, setLot] = useState([]);

     //fetch fournisseurs
  const fetchFrns= useCallback(async(serv="_frs.php", act = "gets") => {
    const response = await Gfunc.axiosGetAction(serv,act);
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
        setFrns(response.data.jData);    
    })
  }
  },[])

     //fetch fournisseur handler
     const handleOpenFrns = useCallback(async () => {
      if(frns.length===0)
      fetchFrns()
      },[frns,fetchFrns])
  
  //fetch lot
  const fetchLot= useCallback(async(serv="_lot.php", act = "gets") => {
    const response = await Gfunc.axiosGetAction(serv,act);
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
        setLot(response.data.jData);    
    })
  }
  },[])
.datusd
  //fetch fournisseur handler
const handleOpenLot = useCallback(async () => {
  if(lot.length===0)
  fetchLot()
  },[lot,fetchLot])
      return (
        <Paper className='digPaper grid-wrapper'>
              <div className="flex-wrapper conFrns">
                  <b>Fournisseur: </b> 
                  {(data.isbanned)?
                  <AutocompleteS
                  service={service} action={'upd'} 
                  defaultValue={data.frns} row={data} list={frns} 
                  id={'idfrns'} name='frns' attr='name'
                  onOpen={handleOpenFrns} handleAlert={handleAlert} />:<div className="smallSelectDT">{data.frns}</div>}
             </div>
              <div className="flex-wrapper conLot">
                 { (data.manual)?<Tooltip title="Saisie manuelle"><Iconn path={mdiHandBackLeft} size="18px" /></Tooltip>:null}
                  <b>Lot: </b> 
                  {(data.isbanned)?
                  <AutocompleteS 
                  service={service} action={'upd'} 
                  defaultValue={data.lot} row={data} list={lot} 
                  id={'idlot'} name='lot' attr='name'
                  onOpen={handleOpenLot} handleAlert={handleAlert} />:<div className="smallSelectDT">{data.lot}</div>}
              </div>
              <div className="flex-wrapper conAgent">
                  <b>Agent:</b> 
                  <div className="smallSelectDT">
                  {data.agent}  
                  </div> 
              </div>
              <div className="flex-wrapper conAgent">
                  <b>Numero:</b> 
                  <div className="smallSelectDT">
                  {data.number}  
                  </div> 
              </div>
          </Paper>
      )
  }
  return (
    <div className="content">
       <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
       <BackdropCompo className={classes.backdrop} open={loadingSubmit}/>
       <Paper className={classes.paperBody} elevation={5}>
         <div className="table-header">
          <div className="button-flex-wrapper" >
            <DigExtraAuto handleAlert={handleAlert}  callback={()=>fetch(currentPage)} />
            <DigExtra handleAlert={handleAlert} ids={checkElem} Vider={Vider} callback={()=>fetch(currentPage)} />
            <DlvCdPromo handleAlert={handleAlert} Vider={Vider} callback={()=>fetch(currentPage)} />
            <Button   className={classes.topTabBtn}  variant="contained"  color="primary" onClick={()=>handleDeleteDigits(data)} startIcon={<Delete />}>Supprimer</Button> 
            <Button   className={classes.topTabBtn}  variant="contained"  color="primary" onClick={handleLiberer} startIcon={<Iconn path={mdiCheckboxMarkedCircleOutline} color="black" size="20px"/>} >Liberer</Button>  
            <Button   className={classes.topTabBtn}  variant="contained"  color="primary" onClick={handleLibererAll} startIcon={<Iconn path={mdiCheckboxMultipleMarkedCircleOutline} color="black" size="20px"/>} >Liberer tous</Button>  
            <Button   className={classes.topTabBtn}  variant="contained"  color="primary" onClick={handleSuspendre} startIcon={<NotInterested />} >Suspendre</Button>  
            <Button   className={classes.topTabBtn}  variant="contained"  color="primary" onClick={handleRestores} startIcon={<Restore />} >rétablir</Button> 
          </div>
          <div className='search'>
                <Box sx={{ display: 'grid', alignItems: 'center',gridTemplateColumns:"auto auto 150px",gridGap:"3px"}}>
                      <Search className={classes.blackIcon} sx={{ color: 'action.active' ,mr: 1, my: 0.5 }} onClick={(e)=>liveSearch2(e)}/>
                      <TextField  className="smallText" id="searchBar" inputProps={{autoComplete: 'off', form: {autoComplete: 'off',}}} variant="outlined"  label="Recherche" value={search} size="small" margin='dense' onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} autoFocus/>
                      <TextField className="smallText" id="searchBar2" inputProps={{autoComplete: 'off', form: {autoComplete: 'off'}}} variant="outlined" label="Digit" value={digSearch} size="small" margin='dense' onKeyUp={e => liveSearch(e)} onChange={e => setdigSearch(e.target.value)} />
                </Box>
          </div> 
          </div>
          <div style={{margin:"0 1%",minHeight:"20px",textAlign:"center"}}>{(check.length)?"Éléments sélectionnés: "+check.length:" "}</div>
          {
            (data.length!==0||doSearch)?
            <div>
              <DTable
             currentPage={currentPage}
             data={data}
             columns={columns}
             loading={loading}
             fetch={fetch}
             handleOnSort={handleOnSort}
             //resetDP={resetDP}
             totalRows={totalRows}
             perPage={perPage}
             setPerPage={setPerPage}
             setCurrentPage={setCurrentPage}
             paginationRowsPerPageOptions={[10,25,50,75,100]}
             customStyles={customStyless}
             conditionalRowStyles = { conditionalRowStyles }
             expandableRows ={true}
             expandableRowsComponent={ExpanableComponent} 
           />
           </div>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData || records===0)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
            </Paper>
    </div> 
  );
}