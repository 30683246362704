import React,{useState} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog,Tooltip} from '@material-ui/core';
import Draggable from 'react-draggable';
import {ListAlt} from '@material-ui/icons';
import {AlertCompo,NotAllowedComponent,NoDataComponent} from '../../../js/components'
import { useStyles,paperPropsBig } from '../../../css/muiStyle';
import DataTable from "react-data-table-component";
import {customStyles, numberColumn}from '../../../css/datatableStyle'


function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Ach_wal_hist(props) {
    const param=props.param
    const Gfunc=require('../../../Gfunc')
    const librery=require('../../../js/data_table_librery')
    const [open, setOpen] = useState(false);
    const [loading, setLoading] =useState(false);
    const [severity, setSeverity] =useState("info");
    const [openA, setOpenA] =useState(false);
    const [data,setData]=useState([]);
    const [notAllowed,setNotAllowed]=useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(50);
    const [currentPage, setCurrentPage] = useState(1);
    const [messageA, setMessageA] =useState('');
    const[errMessage,setErrMessage]=useState('');
    const[records,setRecords]=useState('')
    const[noData,setNoData]=useState(false);

    const service="_wal.php"
    const classes=useStyles();
    
    //handle alerts properties
    const handleAlert=(open,severity,message)=>{
    setOpenA(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpenA(false)
    }, 5000);
  }
  const conditionalRowStyles=[
    { when: row => row.currency<=0,
       style: {
             color: "red",
       }
     },
    { when: row => row.currency>0,
       style: {
             color: 'green',
       }
     }
  ]
  
    const handleClickOpen = () => { 
      setOpen(true); 
      fetch(1);
    };
    const handleClose = () => { setOpen(false); };
    

    const fetch=async(page,servic = service, size = perPage) => {
        setLoading(true);
        const response=await librery.axiosGet2(page+"&w="+props.row.DT_RowId.substr(1),servic,'gethisto',size)
        if(response.data.err &&response.data.err!=="-1"){
          setErrMessage(response.data.err)
          setNotAllowed(true)  
        }else{
        Gfunc.axiosResponse(response,()=>{
          setRecords(response.data.recordsFiltered)
          if(response.data.data!==undefined){
            setData(response.data.data);
            setTotalRows(response.data.recordsFiltered);
            setLoading(false);
            setNoData(true)
          }else{
            setNoData(true)
            setData([])
          }
        })
      }
      }
  
  
  const columns = [
    {
      name: "Date",
      selector: row=>row.date,
      sortable: true,
      width:'150px !important'
    },
    {
      name: "agent",
      selector: row=>row.agent,
      sortable: true,
      minWidth:'150px !important',
    },
    {
      name: "Devise",
      style:numberColumn,
      selector: row=>row.currency,
      sortable: true,
      minWidth:'150px !important',
      cell: (row) => {
        return (
          <div>
            {Gfunc.formatterTo2.format(row.currency)}
         </div>
        );
      }
      },
      {
        name: "Solde",
        style:numberColumn,
        selector: row=>row.solde,
        sortable: true,
        minWidth:'150px !important',
        cell: (row) => {
          return (
            <div>
              {Gfunc.formatterTo2.format(row.solde)} 
            </div>
           );
         },
      },
  ];

  function handlePageChange (page) {
    fetch(page,service,perPage);
    setCurrentPage(page);
  };
   const handlePerRowsChange = async (newPerPage, page) => {
    fetch(page,service, newPerPage);
    setPerPage(newPerPage);
  };

  const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage)=>{
    const response=await librery.axiosSort(column,sortdirection,page,size,service)
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
      setData(response.data.data);
      setTotalRows(response.data.recordsFiltered);
    })
  }     
}
 

  return (
    <div>
      <Tooltip title="Historique d'un wellat ">
        <ListAlt fontSize="small"  className={classes.blackIcon}  onClick={handleClickOpen}/>
      </Tooltip>  
      <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsBig}
      >
        <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >Historique d'un wallet</DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true}/>
            <DialogContent>
            
                <br/>
                {
                  (data.length!==0)?
                   <DataTable 
                      columns={columns}
                      data={data}
                      pagination
                      paginationRowsPerPageOptions={[10,30,50,70,100,150]}
                      noDataComponent={param.ErreurData}
                      progressPending={loading}
                      highlightOnHover
                      noHeader={true}
                      paginationServer
                      paginationTotalRows={totalRows}
                      paginationDefaultPage={1}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      sortServer={true}
                      onSort={handleOnSort}
                      paginationPerPage={50}
                      conditionalRowStyles = { conditionalRowStyles }
                      customStyles={customStyles}
                      paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}            
                 />:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData || records===0)?<NoDataComponent message="Aucun résultat trouvé"/>:null
                }
                
           </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={handleClose} color="secondary">FERMER</Button>
            </DialogActions>
      </Dialog>
      </div>
  );
}
