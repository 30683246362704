import React,{ useState, useEffect ,useCallback,useMemo} from "react";
import ContentEditable from 'react-contenteditable';
import TextField from '@material-ui/core/TextField';
import {Paper, Box} from '@material-ui/core';
import {Search,Lock,LockOpen} from '@material-ui/icons';
import DraggableDialog from './modals/adm_rol_add'
import RolDialog from './modals/adm_rol_usr'
import {conditionalRowStyles,customStyles}from '../../css/datatableStyle'
import { useStyles } from '../../css/muiStyle';
import { AlertCompo,NoDataComponent,NotAllowedComponent } from "../../js/components";
import DTable from "../../compo/DTable";


export default function Adm_rol(props){
  const param=props.param
  const Gfunc=require('../../Gfunc')
  const librery=require('../../js/data_table_librery')
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [called, setCalled] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search,setSearch]=useState('');
  const [,setIcon]=useState('');
  const [severity, setSeverity] =useState("info");
  const [open, setOpen] =useState(false);
  const [messageA, setMessageA] =useState('');
  const [errMessage,setErrMessage]=useState('');
  const [records,setRecords]=useState('');
  const [notAllowed,setNotAllowed]=useState(false);
  const [doSearch,setDoSearch]=useState(false);
  const [noData,setNoData]=useState(false);
  const service='_rol.php';
  const srv = "role";
  const classes = useStyles();
  const [resetDP, setResetDP] = useState(false);
  const filter = "&search="+search;
  
   //handle alerts properties
   const handleAlert=useCallback((open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  },[])

  const fetch= useCallback(async(page, size = perPage) => {
    setCalled(true)
    setLoading(true);
    const response=await librery.axiosGet(page+filter,service,size)
    if(response.data.err&&response.data.err!=="-1"){
      setErrMessage(response.data.err)
      setNotAllowed(true)
    }else{
    Gfunc.axiosResponse(response,()=>{
      setRecords(response.data.recordsFiltered)
      if(response.data.data){
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
        setLoading(false);
        setNoData(true)
        setDoSearch(true)
      }else{
        setDoSearch(false)
        setData([])
      }
    })
  }
  },[Gfunc,librery, perPage,service,filter])

  useEffect(() => {
    if(!called)
    fetch(1);
  }, [fetch,called]);
  
  const flagMan =useCallback(async(row)=>{
    const response= await Gfunc.axiosPostAction('flagman.php','1',({ srv:srv, id: row.DT_RowId.substr(1),att:'activ', val: row.activ }))
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{ 
      if (response.data.jData) {
        const objIndex = data.findIndex((obj => obj.DT_RowId === row.DT_RowId));
        setIcon(row.DT_RowId)
        if(row.activ){
          data[objIndex].activ=0
        }else data[objIndex].activ=1
        setIcon('')     
      }else handleAlert(true,'error',response.data.err);  
   })
  }
},[Gfunc,data,handleAlert])

  //update field
  const handleOnEdit=useCallback(async(e,row,att)=>{
    const edt1=e.target.innerHTML
    const edt=Gfunc.DeleteSpace(edt1);
    if(att==='nom' ){
      if(row.role!==edt){
        const attrib=row.role;
        const response=await librery.axiosEdit(service,row,edt,att) 
        if(response.data.err&&response.data.err!=="-1"){
          handleAlert(true,'error',response.data.err)
        }else{
        Gfunc.axiosResponse(response,()=>{ row.role=edt ;e.target.innerHTML=edt})
        }
        if(response.data.err) {e.target.innerHTML=attrib}
      }else{e.target.innerHTML=edt}
    }
    else
        if(row[att]!==edt){
          const attrib=row[att];
          const response=await librery.axiosEditD(service,row,edt,att) 
          if(response.data.err&&response.data.err!=="-1"){
            handleAlert(true,'error',response.data.err)
          }else{
          Gfunc.axiosResponse(response,()=>{row[att]=edt;e.target.innerHTML=edt})
          }
          if(response.data.err) {e.target.innerHTML=attrib}
        }else {e.target.innerHTML=edt}
  },[librery,Gfunc,handleAlert]);

  const handleLockIcon=useCallback((val)=>{
      setIcon(val);
  },[]);
  
  useEffect((val) => {
    handleLockIcon(val);
  }, [handleLockIcon]);

  const columns =useMemo(
    () => [   
      {
          name: "NOM",
          selector:row=>row.role,
          minWidth:"150px !important",
          sortField: "role",
          sortable: true,
          cell: (row,column) => {
            return (
            <ContentEditable
            html={row.role}
            name="rol"
            data-column="item"
            className="content-editable"
            disabled={false} 
            onBlur={e => handleOnEdit(e,row,"nom")}
          />
             );
           },
      },
      {
        name: "DATE",
        selector:row=>row.datin,
        minWidth:"110px !important",
        sortField: "datin",
        sortable: true
      },
      {
        name: "UTILISATEUR",
        selector:row=>row.user,
        minWidth:"120px !important",
        sortField: "user",
        sortable: true
      },
      {
        right:true,
        cell: (row) => {
          return (
            <div className="rowGrid" id='twoIcons'>
              <div  title="Ajouter/Supprimer utilisateur"><RolDialog row={row} param={param}/></div> 
              <div onClick={()=>flagMan(row)} title="Activer/Desactiver">{(row.activ)?<LockOpen className={classes.redIcon} fontSize="small"/>:<Lock className={classes.greenIcon} fontSize="small"/> }</div>        
            </div>
          );
         },
      },   
  ],[flagMan,handleOnEdit,classes,param]);
  

  const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage+"&search="+search)=>{
      setLoading(true);
      const response=await librery.axiosSort(column,sortdirection,page,size,service)
      if(response.data.err&&response.data.err!=="-1"){
        handleAlert(true,'error',response.data.err)
      }else{
      Gfunc.axiosResponse(response,()=>{
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
        setLoading(false);
      })    
    } 
  }

  //recherche serverside
const handleSearch=async(size = perPage)=>{
  setLoading(true);
  setCurrentPage(1)
  const recherche=search;
  const response=await librery.axiosSearch(service,recherche,1,size,"get")
  if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
  }else{
  Gfunc.axiosResponse(response,()=>{
    if(response.data.data!==undefined){
    setData(response.data.data);
    setTotalRows(response.data.recordsFiltered);
    }else setData([])
  }) 
} 
  setLoading(false); 
  return recherche
}

  function liveSearch(e,search){
    if(param.liveSearch&&search.length>3){
      e.preventDefault();
      handleSearch();
    }else{ if (e.keyCode === 13) {
              e.preventDefault();
              handleSearch();
            }
    }
  }
  function liveSearch2() {
    handleSearch();
  }

  return (
    <div className="content">
        <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
        <Paper className={classes.paperBody} elevation={3}>
        <div className='table-header'>
          <div className='button-flex-wrapper' label="Ajouter un role" ><DraggableDialog handleAlert={handleAlert} 
               callback={()=>{if(currentPage===1) fetch(1);else setResetDP(!resetDP)}} param={param}/></div>
          <div className='search'>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Search className={classes.blackIcon} sx={{ color: 'action.active', mr: 1, my: 0.5 }} onClick={(e)=>liveSearch2(e)}/>
                      <TextField className="smallText" id="searchBar" variant="outlined" label="Recherche" value={search} size="small" margin='dense' onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} autoFocus/>
              </Box>
            </div>
        
        </div>
        {
          (data.length!==0 ||doSearch)?
          <div>
            <DTable
             currentPage={currentPage}
             data={data}
             columns={columns}
             loading={loading}
             fetch={fetch}
             handleOnSort={handleOnSort}
             resetDP={resetDP}
             totalRows={totalRows}
             perPage={perPage}
             setPerPage={setPerPage}
             setCurrentPage={setCurrentPage}
             paginationRowsPerPageOptions={[10,25,50,75,100]}
             customStyles={customStyles}
             conditionalRowStyles = { conditionalRowStyles }/>
            </div>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData || records===0)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
      </Paper>
    </div>    

  );
}
