  import axios from "axios"
  
  const param=(localStorage.length!==0)?JSON.parse(localStorage.getItem('param')):'';
  const Gfunc=require('../Gfunc')


  export const axiosGet=async (page,service, size)=>{
          const response = await axios.get( param.urlService+service+'?do=get&page='+page+"&per_page="+size,{ headers: Gfunc.getHeader()})
          .catch(function(error){
            error["data"]=""
            return error
         });
          return response ;
  }
  export const axiosGet2=async (page,service,action, size)=>{
          const response = await axios.get( param.urlService+service+'?do='+action+'&page='+page+"&per_page="+size,{ headers: Gfunc.getHeader()})
          .catch(function(error){
            error["data"]=""
            return error
         });
          return response ;
  }

  export function axiosEdit(service,row,edt,attt){
    const response= axios({
      method: 'POST',
      url:param.urlService+service+'?do=upd',
      headers: Gfunc.getHeader(),
      data: JSON.stringify({ id: row.DT_RowId, att: attt, val: (edt !== "") ? edt : null })    
  }).catch(function(error){
    error["data"]=""
    return error
 })
      return response;
}
  export function axiosEditCombine(service,action,row,edt,attt){
    const response= axios({
      method: 'POST',
      url:param.urlService+service+'?do='+action,
      headers: Gfunc.getHeader(),
      data: JSON.stringify({ id1: row.DT_RowId1.substr(1), id2: row.DT_RowId2.substr(1), att: attt, val: (edt !== "") ? edt : null })    
   }).catch(function(error){
    error["data"]=""
    return error
   })
      return response;
 }
 

export const axiosEdit2=async(service,action,row,edt,attt,idd,type)=>{
  var id=row.DT_RowId
  if(!row.DT_RowId)
  {id='c'+row[idd]
  edt=(type==='text')?edt:JSON.parse(edt)
  }
  const response= await axios({
    method: 'POST',
    url:param.urlService+service+'?do='+action,
    headers: Gfunc.getHeader(),
    data: JSON.stringify({ id: id, att: attt, val: (edt !== "") ? edt : null })    
  }).catch(function(error){
    error["data"]=""
    return error
 })
    return response;
}

export function axiosEditD(service,row,edt,attt){
    const response= axios({
    method: 'POST',
    url:param.urlService+service+'?do=upd',
    headers: Gfunc.getHeader(),
      data: JSON.stringify({ id: row.DT_RowId.substr(1), att: attt, val: (edt !== "") ? edt : null })    
  }).catch(function(error){
    error["data"]=""
    return error
 })
   return response;
}
  export function axiosSort(column,sortdirection,page,size,service){
            const response =  axios.get( param.urlService+service+'?do=get&column='+column.sortField+'&direct='+sortdirection+'&page='+page+"&per_page="+size,{ headers: Gfunc.getHeader()})
            .catch(function(error){
              error["data"]=""
              return error
           })
            return response
  }
  export function axiosSort2(column,sortdirection,page,size,service,get){
            const response =  axios.get( param.urlService+service+'?do='+get+'&column='+column.sortField+'&direct='+sortdirection+'&page='+page+"&per_page="+size,{ headers: Gfunc.getHeader()})
            .catch(function(error){
              error["data"]=""
              return error
           })
            return response
  }

  export function axiosSearch(service,recherche,page,size,action){
    const response =  axios.get( param.urlService+service+'?do='+action+'&search='+recherche+'&page='+page+"&per_page="+size,{ headers: Gfunc.getHeader()})
    .catch(function(error){
      error["data"]=""
      return error
   })
    return response
  }

  export function axiosSearchCrypted(service,recherche,crypted,page,size){
    var response=null;
    if(crypted==='')
       { response =  axios.get( param.urlService+service+'?do=get&search='+recherche+'&page='+page+"&per_page="+size,{ headers: Gfunc.getHeader()})
        .catch(function(error){
          error["data"]=""
          return error
       })}
    else
       { response =  axios.get( param.urlService+service+'?do=get&search='+recherche+crypted+'&page='+page+"&per_page="+size,{ headers: Gfunc.getHeader()})
        .catch(function(error){
          error["data"]=""
          return error
       })}
    return response
  }

  export function axiosEditId(service,action,ide,edt,attt){
    const response= axios({
      method: 'POST',
      url:param.urlService+service+'?do='+action,
      headers: Gfunc.getHeader(),
      data:JSON.stringify({ id: ide,att: attt,val:edt})    
  }).catch(function(error){
    error["data"]=""
    return error
 })
      return response;
}

 

  