import React,{useState,useRef} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog,FormControlLabel,Checkbox} from '@material-ui/core';
import Draggable from 'react-draggable';
import SaveIcon from '@material-ui/icons/Save';
import { ValidatorForm,TextValidator} from 'react-material-ui-form-validator';
import AddIcon from '@material-ui/icons/Add';
import axios from "axios"
import { useStyles,paperPropsSmallTall } from '../../../css/muiStyle';
import {AlertCompo} from '../../../js/components'
import { Autocomplete } from '@material-ui/lab';
import '../../../css/digit.css'

//add new lot
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Dig_lot_add(props) {
    const param=props.param;
    const Gfunc=require('../../../Gfunc')
    const [data,setData]=useState([]);
    const [adresse,setAdresse]=useState('');
    const [open, setOpen] = React.useState(false);
    const[select,setSelect]=useState('')
    const classes=useStyles();
    const [severity, setSeverity] =useState("info");
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [checked,setchecked]=useState(true);
    const nom="lot"
    const errorMessage='Veuillez renseigner le nom du '+nom
    const labelAdresse='Adresse'
    const service="_lot.php"
   

   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpenA(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpenA(false)
    }, 5000);
  }
  
    const handleClickOpen = () => { 
      fetch();
      setAdresse(''); setOpen(true); setchecked(true);
    };
    const handleClose = () => { setOpen(false); };
    const handleSubmit= async(e) =>{
      const response=await Gfunc.axiosPostAction(service,"add",{prefix:select,adress:adresse,manual:(checked)?1:0})
      if(response.data.err&&response.data.err!=='-1'){
         handleAlert(true,'error',response.data.err)
      }else{
        Gfunc.axiosResponse(response,()=>{
                setOpen(false);
                if (response.data.jData){
                  handleAlert(true,'success',"la "+nom+" été ajouté")
                }
                props.callback();
        })
      }
    }

    const fetch=async() => {
        const response = await axios.get( param.urlService+service+'?do=getpfx',{ headers: Gfunc.getHeader()});
        if(response.data.err&&response.data.err!=="-1"){
          handleAlert(true,'error',response.data.err)
        }
        else{
        Gfunc.axiosResponse(response,()=>{
          if(response.data.jData){
            setData(response.data.jData);
          }
        })
        }
      }

    const handleChange=(e)=>{
        setchecked(!checked)
    }
 
  return (
    <div>
      <Button  className={classes.topTabBtn}  variant="contained" color="primary" onClick={handleClickOpen} startIcon={<AddIcon/>}>NOUVEAU LOT</Button>
      <Dialog id='dialog'
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmallTall}
      >
        <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >Ajouter un lot</DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true}/>
            <DialogContent>
            <ValidatorForm ref={useRef(null)} id="lotForm" onSubmit={handleSubmit} onError={errors => console.log(errors)}  > 
             <div id='lot-row'>
                  <Autocomplete
                                disableClearable
                                className='smallAuto'
                                id="iPref"
                                onChange={(e,newValue) =>{setSelect(newValue)}} 
                                options={data}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                <TextValidator 
                                    {...params} label={"Préfixe"} variant='outlined' margin='dense' size="small" autoFocus required/>
                                )}/>                
                  <TextValidator className='smallText'  variant="outlined" size="small" label={labelAdresse} id="iNote" name="iNote"  value={adresse}
                                 onChange={e =>setAdresse(e.target.value) } validators={['required']}  errorMessages={[errorMessage]} />
            </div>
             <FormControlLabel control={<Checkbox checked={checked} onChange={e=>handleChange(e)} name="checked" color="primary"/>}
                    label="Saisie manuelle"
                 />
           </ValidatorForm>
            </DialogContent>
            <DialogActions>
            <Button variant="contained" type='submit' form="lotForm" color="primary" startIcon={<SaveIcon />}>VALIDER</Button>
            <Button variant="contained" onClick={handleClose} color="secondary">FERMER</Button>
            </DialogActions>
      </Dialog>
      </div>
  );
}