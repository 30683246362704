import React,{useState,useRef} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog, TextField, Checkbox, FormControlLabel} from '@material-ui/core';
import Draggable from 'react-draggable';
import {Save,Add} from '@material-ui/icons';
import { ValidatorForm} from 'react-material-ui-form-validator';
import { useStyles,paperPropsSmallTall } from '../../../css/muiStyle';
import {AlertCompo} from '../../../js/components'
import { Autocomplete } from '@material-ui/lab';
import $ from 'jquery'

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Ref_sim_add(props) {
    const classes=useStyles();
    const Gfunc=require('../../../Gfunc')
    const [tel,setTel]=useState('');
    const [selectOpr, setSelectOpr] =useState('');
    const [wilaya, setWilaya] =useState({id:0,wilaya:"Aucune"});
    const [codeDist, setCodeDist] =useState({id:0,name:"Aucun"});
    const [password, setPassword] =useState('');
    const [code, setCode] =useState(null);
    const [seuil, setSeuil] =useState(0);
    const [mtn, setMtn] =useState(0);
    const [nbrOp, setNbrOp] =useState(0);
    const [bonus, setBonus] = useState(false);
    const [onService, setOnService] = useState(true);
    const [severity, setSeverity] =useState("info");
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [open, setOpen] = useState(false);
    const service="_sim.php"
    
    $(document).ready(function(){
      $('#tel-edit').on('input', function(ev) {
        var $this = $(this);
        var value = $this.val();
        if (value && value.length >10) {
          $this.val(value.substr(0,10));
        }
      })
      });


   //handle alerts properties
   const handleAlert=(open,severity,message)=>{
    setOpenA(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpenA(false)
    }, 5000);
  }
    const handleClickOpen = () => { 
      setOpen(true); 
      setMtn(0);setSelectOpr('');setNbrOp(0);setSeuil(0);setTel('');setPassword('');setBonus(false);setCode(null);setOnService(true)
    };

    const handleClose = () => { setOpen(false); };


    const handleSubmit= async(e) =>{
      var op=''
      var ind=props.opr.findIndex((obj => obj.name=== selectOpr))   
      if(ind!==-1)  {
        op=props.opr[ind].id
      }
      const response = await Gfunc.axiosPostAction(service,"add", {number:tel,password:password,simCode:code,operatorId:op,bonus:bonus,wilayaId:wilaya,onService:onService,
                                                    minimum:seuil,maxMount:mtn,maxOps:nbrOp,distCode:codeDist})
      if(response.data.err){
          handleAlert(true,'error',response.data.err)
      }else{
            Gfunc.axiosResponse(response,()=>{
                if(response.data.jData){
                   props.handleAlert(true,"success","Un nouvelle sim a été ajoutée avec succès")
                   props.callback()
                   setOpen(false)
                }
         })
        }
        
    }

    const testPhone=(e)=>{
      const re = /^[0-9\b]+$/;
      if (e.target.value==='' || re.test(e.target.value)) {
        setTel(e.target.value)
      }
    }

  return (
    <div>
      <Button className={classes.topTabBtn} variant="contained" color="primary" onClick={handleClickOpen} startIcon={<Add/>}>NOUVELLE SIM</Button>
      <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmallTall}
       >
        <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >Ajouter une nouvelle sim</DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true}/>
            <DialogContent>
                <ValidatorForm ref={useRef(null)} id="frnsForm" onSubmit={handleSubmit} onError={errors => console.log(errors)} >
                   <div id='sim-row'>
                      <TextField  type="tel" variant='outlined' label='Numéro' id="tel-edit" name="iTel" value={tel}  
                                  className='smallText' margin='dense' 
                                  onChange={(e)=>{testPhone(e)}} required autoFocus
                                  inputProps={{ pattern: "[0][5-7][4-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]",maxLength:10 ,autoComplete: 'off', form: {autoComplete: 'off'}}} />  
                      <TextField type='text' variant="outlined" className='smallText' label="Code sim" size="small" 
                                 value={code} onChange={e =>setCode(e.target.value)} required   />
                      <TextField type='text' variant="outlined" className='smallText' label="Mot de passe" size="small" 
                                 value={password} onChange={e =>setPassword(e.target.value)} required  />
                   
                   </div> 
                    <div id='sim-row'>
                    <Autocomplete
                        disableClearable
                        className='smallAuto'
                        onChange={(e,newValue) =>{setSelectOpr(newValue)} } 
                        id='iOp' 
                        onFocus={props.handleOpenOpr}
                        value={selectOpr}
                        options={props.opr.map((option) => option.name)}
                        renderInput={(params) => (
                          <TextField {...params} label='Opérateur' size="small" margin='dense' variant="outlined" required />)} 
                    />
                    <Autocomplete
                        disableClearable
                        className='smallAuto'
                        onChange={(e,newValue) =>{setWilaya(newValue.id)} } 
                        id='iWil' 
                        onFocus={props.handleOpenWilayas}
                        options={props.wilayas}
                        getOptionLabel={(option) => option.wilaya /*+ (option.name!=null?` [ ${option.simCode} ]`:"")*/}
                        getOptionSelected={(option, value) => option.id === value.id}
                        renderInput={(params) => (
                          <TextField {...params} label='Wilaya' size="small" margin='dense' variant="outlined"  required/>)} 
                    />
                    <Autocomplete
                        disableClearable
                        className='smallAuto'
                        onChange={(e,newValue) =>{setCodeDist(newValue.id)} } 
                        id='iDist' 
                        onFocus={props.handleOpenCode}
                        options={props.codeL}
                        getOptionLabel={(option) => option.name /*+ (option.name!=null?` [ ${option.simCode} ]`:"")*/}
                        getOptionSelected={(option, value) => option.id === value.id}
                        renderInput={(params) => (
                  <TextField {...params} label='Distributeur' size="small" margin='dense' variant="outlined" />)} 
                    />
                   </div>
                   <div id='sim-row1'>
                     <TextField type='number' className='smallText' label="Seuil" size="small" value={seuil} variant="outlined"
                                onChange={e =>setSeuil(e.target.value)} required/>
                     <TextField type='number' className='smallText' label="Montant maximal" size="small" value={mtn} variant="outlined"
                                onChange={e =>setMtn(e.target.value)} required/>
                     <TextField type='number' className='smallText' label="Nombre d'opérations maximal" size="small" variant="outlined"
                                value={nbrOp} onChange={e =>setNbrOp(e.target.value)} required/>
                   </div>
                   <div id='sim-check'>
                        <FormControlLabel label="Bonus" control={<Checkbox  checked={bonus} color="primary" 
                                          onChange={e=>{setBonus(e.target.checked)}} inputProps={{ 'aria-label': 'controlled' }}  />}/> 
                        <FormControlLabel label="Service" control={<Checkbox  checked={onService} color="primary" 
                                          onChange={e=>{setOnService(e.target.checked)}} inputProps={{ 'aria-label': 'controlled' }}  />}/> 
                   </div> 
              </ValidatorForm>
           </DialogContent>
           <DialogActions>
               <Button variant="contained" type='submit' form="frnsForm" color="primary" startIcon={<Save/>}>VALIDER</Button>
               <Button variant="contained" onClick={handleClose} color="secondary">FERMER</Button>
           </DialogActions>
      </Dialog>
      </div>
  );
}
