import React,{ useState, useEffect ,useCallback,useMemo} from "react";
import DataTable from "react-data-table-component";
import {TextField,Button,CircularProgress,Paper, Box} from '@material-ui/core';
import {Search,ErrorOutline,FilterListSharp} from '@material-ui/icons';
import {customStyles, numberColumn}from '../../css/datatableStyle'
import { useStyles } from '../../css/muiStyle';
import { Autocomplete } from "@material-ui/lab";
import { NoDataComponent,NotAllowedComponent } from "../../js/components";
import  '../../css/digit.css'

export default function Dig_stk (props){
  const param=props.param
  const Gfunc=require('../../Gfunc')
  const [data, setData] = useState([]);
  const [tous, setTous] = useState([]);
  const [categorie] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [search,setSearch]=useState('');
  const service='_dig.php'
  const classes=useStyles();
  const[noData,setNoData]=useState(false);
  const[doSearch,setDoSearch]=useState(false);
  const[notAllowed,setNotAllowed]=useState(false);
  //const [severity, setSeverity] =useState("info");
  //const [open, setOpen] =useState(false);
  //const [messageA, setMessageA] =useState('');
  const[errMessage,setErrMessage]=useState('');
  const[records,setRecords]=useState('');


  const  conditionalRowStyles  =  [ 
    {
      when: row => row.alert===true||row.conso<0,
        style: {
              color:"red"
        }
    },
  ]

 //fetch marque
  const fetch= useCallback(async() => {
    setLoading(true);
    const response=await Gfunc.axiosGetAction(service,"getdigstock")
    if(response.data.err&&response.data.err!=='-1'){
      setErrMessage(response.data.err)
      setNotAllowed(true)    }else{
    Gfunc.axiosResponse(response,()=>{
       setRecords(response.data.recordsFiltered)
       if(response.data.jData!==undefined) {
        const dat=[]
        Object.keys(response.data.jData).map((key, index)=> {
            var value=response.data.jData[key];
            dat.push(value)
            if(!categorie.includes(value.cat))
            categorie.push(value.cat)
            //this has to be removes once the data is fixed from here 
            if(!value.hasOwnProperty("qte"))
            value["qte"]=0
            //to here 
            return true
        });
        setTous(dat)
        setData(dat);
        setTotalRows(response.data.recordsFiltered);
        setLoading(false);
        setNoData(true)
        setDoSearch(true)
        }else{
          setDoSearch(false)
          setData([])
        }
    })
  }
    setLoading(false);
  },[Gfunc,categorie]);

  useEffect(() => {
    fetch(1);
  }, [fetch]);

  //filter by 
  const handleFilter=useCallback((filtre,e,v)=>{
      if(filtre==="alert")
        setData(tous.filter((d)=>{return (d.alert===true)}))
      if(filtre==="tous"){
       // setSelecto('')  
        setData(tous)
      }
      if(filtre==='categorie'){
          //setSelecto(v);
          setData(tous.filter((d)=>{return (d.cat===v)}))
        }
  },[tous]);

const columns =useMemo(
    () => [   
      {
          name: "PRODUIT",
          minWidth:'300px !important',
          selector: row=>row.nom,
          sortable: true,
      },
      {
        name: "STOCK COMMERCIAL",
        minWidth:'190px !important',
        style:numberColumn,
        selector: row=>row.qte,
        sortable: true,
        cell: (row) => {
          return (
            <div>
             {new Intl.NumberFormat().format(row.qte)}        
            </div>
           );
         },
      },
      {
        name: "DIGITS ACTIFS",
        minWidth:'150px !important',
        style:numberColumn,
        selector: row=>row.digit,
        sortable: true,
        cell: (row) => {
          return (
            <div>
             {new Intl.NumberFormat().format(row.digit)}        
            </div>
           );
         },
      },
      {
        name: "Suspendus",
        minWidth:'130px !important',
        style:numberColumn,
        selector:row=>row.suspend??0,
        sortable: true,
        cell: (row) => {
          return (
            <div>
               {new Intl.NumberFormat().format(parseFloat(row.suspend??0))}        
            </div>
           );
         },
      } , 
      {
        name: "ECART",
        minWidth:'120px !important',
        style:numberColumn,
        selector:row=>Intl.NumberFormat().format(parseFloat(row.qte)-parseFloat(row.digit)),
        sortable: true,
        cell: (row) => {
          return (
            <div>
               {new Intl.NumberFormat().format(parseFloat(row.qte)-parseFloat(row.digit))}        
            </div>
           );
         },
      } , 
      {
        name: "S.MARCHE (J)",
        minWidth:'150px !important',
        style:numberColumn,
        selector: row=> Intl.NumberFormat().format(Math.round(row.stock_size * 100) / 100),
        sortable: true,
        cell: (row) => {
          return (
            <div>
             {new Intl.NumberFormat().format(Math.round(row.stock_size * 100) / 100)}        
            </div>
           );
         },
      },  
      {
        name: "CONSOMMATION /J",
        minWidth:'150px !important',
        style:numberColumn,
        selector: row=>row.conso,
        sortable: true,
        cell: (row) => {
          return (
            <div>
             {new Intl.NumberFormat().format(row.conso)}        
            </div>
           );
         },
      }  
  ],[]);

const handleSearch=()=>{
  const filters=[]
  var keys=[] 
  keys=Object.keys(tous[0])
    keys.map( key =>{ 
      const f={}  
      f["att"]=key
      filters.push(f)
      return true
    }) 
  setData(tous.filter((d) => filters.some(
    filterEl => {
      if (!d[filterEl.att]) return false
      return (
        (typeof d[filterEl.att] !== "String") ?
          d[filterEl.att].toString().toLowerCase() :
          d[filterEl.att].toLowerCase()
      ).includes(search.toLowerCase())
    }

  )
  ))
}
function dynamicSort(property) {
  var sortOrder = 1;
  if(property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
  }
  return function (a,b) {
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
  }
}
const handleOnSort=(column, sortdirection)=>{  
  //objs.sort((a,b,att) => (a.last_nom > b.last_nom) ? 1 : ((b.last_nom > a.last_nom) ? -1 : 0))

  setData(data.sort( dynamicSort(column.selector)) );
}

  function liveSearch(e){
    if(search.length>-1){
      e.preventDefault();
      handleSearch();
    }
  }
  function liveSearch2() {
    handleSearch();
  }
  
  return (
    <div className="content">
        {/*<AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />*/}
        <Paper className={classes.paperBody} elevation={3}>
        <div className="table-header">
        <div className="button-flex-wrapper" >
             <Button  className={classes.topTabBtn} variant="contained"  color="primary" onClick={(e)=>handleFilter('alert',e)} startIcon={<ErrorOutline />} >Alertes</Button>  
             <Button  className={classes.topTabBtn} variant="contained"  color="primary" onClick={(e)=>handleFilter('tous',e)}  startIcon={<FilterListSharp/>}>tous</Button>  
                <Autocomplete
                    id='filtreStk'
                    disableClearable
                    onChange={(e,newValue) =>{handleFilter('categorie',e,newValue);}}
                    className="smallAutoFiltre"
                    options={categorie.map((option) => option)}
                    renderInput={(params) =>  
                       <TextField margin='dense' 
                            // error={errorP}
                            size='small'{...params} variant="outlined"  label="Filtrer" 
                            SelectProps={{ MenuProps: {closeAfterTransition:true } }}/>
            }
            />
          </div>
          <div className='search'>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Search className={classes.blackIcon} sx={{ color: 'action.active', mr: 1, my: 0.5 }} onClick={(e)=>liveSearch2(e)}/>
                      <TextField className="smallText" id="searchBar" variant="outlined" label="Recherche" value={search} size="small" margin='dense' onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} autoFocus/>
                   </Box>
            </div>
          </div>
          {
            (data.length!==0 ||doSearch)?
            <div>
            <DataTable className="table"
              persistTableHead
              columns={columns}
              data={data}
              noDataComponent={param.ErreurData}
              pagination
              paginationRowsPerPageOptions={[10,20,30,50,100]}
              paginationPerPage={50}
              paginationTotalRows={totalRows}
              progressPending={loading}
              progressComponent={<CircularProgress className={classes.circularProgress} />}
              onSort={handleOnSort}
              highlightOnHover
              noHeader={true}
              customStyles={customStyles}
              paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur',selectAllRowsItemText: 'Tous',selectAllRowsItem: true}}
              conditionalRowStyles = { conditionalRowStyles }
            />
          </div>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData || records===0)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
      </Paper>
    </div>    

  );
}